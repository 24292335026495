import { Descriptions, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Statistic } from '@monorepo/react-components';

interface Props {
  programId: string;
  title: string;
  equity: number;
  baseBalance: number;
}

const AccountStopout: FunctionComponent<Props> = ({
  title,
  programId,
  equity,
  baseBalance,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const program = programStore.get(programId);
  const maxLoss = program?.config?.maxLoss || 0;
  const stopoutLevel = baseBalance - maxLoss;

  return (
    <Card
      title={title}
      extra={
        <Tooltip
          title={`The equity level at which the account will be terminated.`}
        >
          <InfoCircleOutlined />
        </Tooltip>
      }
    >
      <Descriptions column={1}>
        <Descriptions.Item label={'Current Level'}>
          <Statistic prefix={'$'} value={equity} precision={2} />
        </Descriptions.Item>
        <Descriptions.Item label={'Stop Out'}>
          <Statistic prefix={'$'} value={stopoutLevel} precision={2} />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default AccountStopout;
