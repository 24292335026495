import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Label, Statistic, Flex, Tooltip } from '@monorepo/react-components';
import { useStore } from '../../../helpers/use-store';
import { PayoutSource } from '@monorepo/types';

const AffiliateStatistics: FunctionComponent = () => {
  const [payoutCompletedTotal, setPayoutCompletedTotal] = useState(0);
  const [affiliateAvailableSum, setAffiliateAvailableSum] = useState(0);
  const {
    dataStore: { payoutStore, userStore },
  } = useStore();

  useEffect(() => {
    const init = async () => {
      if (userStore.currentUser.affiliateId) {
        const [payoutTotal, availableSum] = await Promise.all([
          payoutStore.getPayoutTotalForSource({
            sourceId: userStore.currentUser.affiliateId,
            type: PayoutSource.Affiliate,
          }),
          userStore.getAffiliateAvailableSum(),
        ]);
        setAffiliateAvailableSum(availableSum);
        setPayoutCompletedTotal(payoutTotal);
      }
    };
    init();
  }, []);

  return (
    <Flex gap={8}>
      <Tooltip title="Total commissions due">
        <Label color="colorActive">
          <Statistic value={affiliateAvailableSum} precision={2} prefix={'$'} />
        </Label>
      </Tooltip>

      <Tooltip title="Total commissions paid">
        <Label color="colorSuccess">
          <Statistic value={payoutCompletedTotal} precision={2} prefix={'$'} />
        </Label>
      </Tooltip>
    </Flex>
  );
};
export default observer(AffiliateStatistics);
