import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ApplicationConfig, DashboardLayouts } from '@monorepo/types';
import { defaultSidebar } from '@monorepo/types';
import { isEmpty } from 'lodash';

export type ClientApplicationConfig = Pick<
  ApplicationConfig,
  | 'name'
  | 'dashboardLayout'
  | 'addOns'
  | 'termsAndConditions'
  | 'website'
  | 'featureFlags'
  | 'discordLink'
  | 'showDiscordLink'
  | 'youtubeLink'
  | 'facebookLink'
  | 'twitterLink'
  | 'instagramLink'
  | 'sidebar'
  | 'contracts'
  | 'groupPrograms'
  | 'tradingSystems'
  | 'enableMigrationSync'
  | 'banners'
  | 'countryFilter'
> & { isBlacklisted: boolean };

export class ApplicationConfigStore {
  @observable
  applicationConfig: ClientApplicationConfig = {
    dashboardLayout: DashboardLayouts.TTPDashboard,
    website: '',
    termsAndConditions: '',
    name: '',
    isBlacklisted: false,
    discordLink: '',
    youtubeLink: '',
    facebookLink: '',
    twitterLink: '',
    instagramLink: '',
    sidebar: defaultSidebar,
    contracts: [],
    tradingSystems: [],
    banners: [],
    enableMigrationSync: false,
  };

  constructor() {
    makeObservable(this);
  }

  async get() {
    const {
      apiFactory: { applicationConfigService },
    } = getEnv();

    const appConfig =
      (await applicationConfigService.getAll<ClientApplicationConfig>()) as unknown as ClientApplicationConfig;

    runInAction(() => {
      this.applicationConfig = {
        ...this.applicationConfig,
        ...appConfig,
        sidebar: isEmpty(appConfig.sidebar)
          ? defaultSidebar
          : appConfig.sidebar,
      };
    });
  }

  @computed
  get sellableTs() {
    return (
      this.applicationConfig.tradingSystems
        ?.filter((ts) => ts.sellable)
        .map((ts) => ts.tradingSystem) || []
    );
  }
}
