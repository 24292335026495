const NyseStepThree = () => {
  return (
    <div className={'contracts-steps-body'}>
      <ol>
        <li>
          DATA NOT GUARANTEED – Subscriber understands that no Authorizing SRO,
          no other entity whose information is made available over the
          Authorizing SROs' facilities (an "Other Data Disseminator") and no
          information processor that assists any Authorizing SRO or Other Data
          Disseminator in making Market Data available (collectively, the
          "Disseminating Parties") guarantees the timeliness, sequence, accuracy
          or completeness of Market Data or of Last Update: November 1, 2013
          other market information or messages disseminated by any Disseminating
          Party. Neither Subscriber nor any other person shall hold any
          Disseminating Party liable in any way for (a) any inaccuracy, error or
          delay in, or omission of, (i) any such data, information or message or
          (ii) the transmission or delivery of any such data, information or
          message, or (b) any loss or damage arising from or occasioned by (i)
          any such inaccuracy, error, delay or omission, (ii) nonperformance or
          (iii) interruption in any such data, information or message, due
          either to any negligent act or omission by any Disseminating Party, to
          any "force majeure" (e.g., flood, extraordinary weather conditions,
          earthquake or other act of God, fire, war, insurrection, riot, labor
          dispute, accident, action of government, communications or power
          failure, equipment or software malfunction) or to any other cause
          beyond the reasonable control of any Disseminating Party.
        </li>
        <li>
          PERMITTED USE – Subscriber shall not furnish Market Data to any other
          person or entity. If Subscriber receives Market Data other than as a
          Nonprofessional Subscriber, it shall use Market Data only for its
          individual use in its business.
        </li>
      </ol>
    </div>
  );
};

export default NyseStepThree;
