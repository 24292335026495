import { Skeleton } from 'antd';
import { useStore } from '../../helpers/use-store';
import {
  Flex,
  TextComponent,
  TradingSystemBtn,
} from '@monorepo/react-components';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  TradingSystem,
  TradingSystemNames,
  UserTradingSystemCredentials,
} from '@monorepo/types';
import {
  getTradingPlatform,
  LoadingState,
  TradingPlatformData,
  TradingPlatformServers,
  useLoading,
} from '@monorepo/client-common';

interface Props {
  userId: string;
  tradingSystem: TradingSystem;
}
const TradersLogin: FunctionComponent<Props> = ({ userId, tradingSystem }) => {
  const {
    dataStore: { userStore },
    uiStore,
  } = useStore();
  const [tsUser, setTsUser] = useState<UserTradingSystemCredentials | null>(
    null
  );

  const { loadingState, updateLoadingState } = useLoading();

  const [platformData, setPlatformData] = useState<TradingPlatformData[]>([]);

  const tsUsers = userStore.currentUser.tsUsers || [];

  const selectTsUser = async (logins: UserTradingSystemCredentials[]) => {
    try {
      updateLoadingState(LoadingState.Loading);

      const currentUser = logins.find((user) => {
        return (
          user.tradingSystem === tradingSystem &&
          user.externalId.toString() === userId.toString()
        );
      });

      if (currentUser) {
        const decryptedPassword = await userStore.decryptPassword(
          currentUser.login,
          currentUser.tradingSystem
        );

        currentUser.password = decryptedPassword || '';

        setTsUser(currentUser);
        setPlatformData(
          getTradingPlatform(currentUser.tradingSystem, uiStore.isProd) || []
        );
        return true;
      }

      return false;
    } catch (e) {
      console.error('Failed to get trading system user', e);
      return false;
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  useEffect(() => {
    const init = async () => {
      const userFound = await selectTsUser(tsUsers);

      if (!userFound) {
        userStore.updateLogins().then((logins) => {
          selectTsUser(logins || []);
        });
      }
    };
    init();
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton active={true} />;
  }

  if (!tsUser) {
    return null;
  }

  const handleTraderClick = (link: string) => {
    window.open(link, '_blank');
  };

  const tradingServer = TradingPlatformServers[tsUser.tradingSystem];
  const tradingPlatformName = TradingSystemNames[tsUser.tradingSystem].name;

  return (
    <Flex gap={16} vertical className="traders-login">
      <Flex vertical gap={14}>
        <Flex justify="space-between">
          <TextComponent weight={600} responsive={false}>
            Login
          </TextComponent>
          <TextComponent copyable={true} responsive={false}>
            {tsUser.login}
          </TextComponent>
        </Flex>
        <Flex justify="space-between">
          <TextComponent weight={600} responsive={false}>
            Password
          </TextComponent>
          <TextComponent copyable={true} responsive={false}>
            {tsUser.password}
          </TextComponent>
        </Flex>
        <Flex justify="space-between">
          <TextComponent weight={600} responsive={false}>
            Platform
          </TextComponent>
          <TextComponent copyable weight={600} responsive={false}>
            {tradingPlatformName}
          </TextComponent>
        </Flex>
        {tradingServer && (
          <Flex justify="space-between" wrap="wrap">
            <TextComponent weight={600} responsive={false}>
              Server
            </TextComponent>
            <TextComponent noWrap copyable responsive={false}>
              {tradingServer}
            </TextComponent>
          </Flex>
        )}
      </Flex>
      <Flex gap={16} wrap={'wrap'}>
        {platformData.map((platform) => (
          <TradingSystemBtn
            platform={platform.platform}
            key={platform.platform}
            type="primary"
            onClick={() => handleTraderClick(platform.link)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default TradersLogin;
