export interface FeatureFlags {
  [FeatureFlagNames.DisableAffiliation]?: boolean;
  [FeatureFlagNames.DisablePayments]?: boolean;
}

export enum FeatureFlagNames {
  DisableAffiliation = 'disableAffiliation',
  DisablePayments = 'disablePayments',
}

const isBoolean = (value: any) => {
  return typeof value === 'boolean';
};

export const isFeatureEnabled = ({
  appFeatureFlags,
  userFeatureFlags,
  flag,
}: {
  appFeatureFlags?: FeatureFlags;
  userFeatureFlags?: FeatureFlags;
  flag: FeatureFlagNames;
}) => {
  const handleDisableFlag = (flag: FeatureFlagNames) => {
    return () => {
      const userFlagValue = userFeatureFlags?.[flag];

      if (isBoolean(userFlagValue)) {
        return !userFlagValue;
      }

      const appFlagValue = appFeatureFlags?.[flag];

      if (isBoolean(appFlagValue)) {
        return !appFlagValue;
      }

      return true;
    };
  };

  const featureFlagsMap = {
    [FeatureFlagNames.DisableAffiliation]: handleDisableFlag(flag),
    [FeatureFlagNames.DisablePayments]: handleDisableFlag(flag),
  };

  return featureFlagsMap[flag]();
};
