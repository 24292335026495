import { FunctionComponent, useEffect, useState } from 'react';
import { Modal, Skeleton } from 'antd';
import AccountProfitableDays from '../account-profitable-days/account-profitable-days';
import { useStore } from '../../../../../helpers/use-store';
import { ConsistencyRules } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/client-common';

interface Props {
  accountId: string;
  open: boolean;
  close: () => void;
}

const ProfitableDaysModal: FunctionComponent<Props> = ({
  open,
  close,
  accountId,
}) => {
  const {
    dataStore: { accountStore },
  } = useStore();
  const [profitableDays, setProfitableDays] = useState<Date[]>([]);
  const { loadingState, updateLoadingState } = useLoading();

  useEffect(() => {
    if (open) {
      updateLoadingState(LoadingState.Loading);

      accountStore
        .getConsistencyRule(accountId, ConsistencyRules.ProfitableDays)
        .then((result) => {
          const { profitableDays } = result;

          if (profitableDays) {
            setProfitableDays(profitableDays);
          }
        })
        .finally(() => updateLoadingState(LoadingState.Loaded));
    }
  }, [open]);

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Modal onCancel={close} footer={null} open={open}>
      {isLoading ? (
        <Skeleton active={true} />
      ) : (
        <AccountProfitableDays profitableDays={profitableDays} />
      )}
    </Modal>
  );
};

export default ProfitableDaysModal;
