import { useState } from 'react';

const firstPage = 1,
  defaultLimit = 10;

interface Props {
  limit?: number;
}

export const usePagination = (props: Props = { limit: defaultLimit }) => {
  const [page, setPage] = useState(firstPage);
  const [limit, setLimit] = useState(props.limit || defaultLimit);
  const [total, setTotal] = useState(0);

  return {
    page,
    limit,
    total,
    setPage,
    setLimit,
    setTotal,
    defaultLimit,
    firstPage,
  };
};
