import React, { FunctionComponent } from 'react';
import { Achievement } from '@monorepo/types';
import AchievementTemplate from './templates/achievement-template';
import AchievementSection from './achievement-section';

interface Props {
  achievements: Achievement[];
  title: string;
  isLoaded?: boolean;
}

const AchievementsBlock: FunctionComponent<Props> = ({
  achievements,
  title,
  isLoaded,
}) => {
  if (!achievements.length && isLoaded) {
    return null;
  }

  return (
    <AchievementSection
      title={title}
      isLoading={!isLoaded}
      items={achievements.map((achievement) => (
        <AchievementTemplate achievement={achievement} />
      ))}
    />
  );
};

export default AchievementsBlock;
