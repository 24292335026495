import { ThemeConfig } from 'antd';

export enum Theme {
  Dark = 'dark',
  Light = 'light',
  Default = Theme.Light,
}

export enum ThemeAlgorithm {
  DarkAlgorithm = 'darkAlgorithm',
  DefaultAlgorithm = 'defaultAlgorithm',
}

export enum ThemeColors {
  colorPrimary = 'colorPrimary',
  colorSuccess = 'colorSuccess',
  colorWarning = 'colorWarning',
  colorError = 'colorError',
  colorInfo = 'colorInfo',
  colorLink = 'colorLink',
  colorAttention = 'colorAttention',
  colorActive = 'colorActive',
  colorReverse = 'colorReverse',
  colorErrorLight = 'colorErrorLight',
  colorSecondary = 'colorSecondary',
  colorDescription = 'colorDescription',
}

export type ThemeConfigurationType = ThemeConfig & {
  cssVars: Record<string, string>;
};
