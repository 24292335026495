import { Col, Row, Space } from 'antd';
import {
  CurrencyAvatar,
  FormInput,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import React, { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CurrencyType, cryptoCoins } from '@monorepo/client-common';

interface Props {
  form: UseFormReturn<any>;
}

const CryptoWithdraw: FunctionComponent<Props> = ({ form }) => {
  return (
    <Row gutter={12}>
      <Col span={12}>
        <FormInput
          text="Wallet address"
          form={form}
          controllerProps={{
            name: 'metadata.walletAddress',
          }}
        />
      </Col>
      <Col span={12}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'metadata.cryptoCurrency',
            rules: { required: true },
          }}
          text={'Crypto currency'}
          options={cryptoCoins.map((curr) => ({
            label: (
              <Space>
                <CurrencyAvatar
                  size={'small'}
                  name={curr.value}
                  type={CurrencyType.Crypto}
                />
                <TextComponent>{curr.text}</TextComponent>
              </Space>
            ),
            value: curr.value,
          }))}
        />
      </Col>
    </Row>
  );
};

export default CryptoWithdraw;
