import { Button, Flex, TextComponent } from '@monorepo/react-components';
import { PagePath } from '@monorepo/types';
import { useNavigate } from 'react-router-dom';

const OverviewEmpty = () => {
  const navigate = useNavigate();

  return (
    <Flex vertical gap={46} justify="center" align="center">
      <Flex vertical gap={23} justify="center" align="center">
        <TextComponent weight={700} fontFamily="Oswald" level={1}>
          TAKE THE FIRST STEP
        </TextComponent>
        <TextComponent level={5} textAlign="center">
          It looks like your program list is empty.
        </TextComponent>
        <TextComponent level={5} textAlign="center">
          Browse our top-rated trading programs and choose the one that best
          suits your needs to begin your trading success story.
        </TextComponent>
      </Flex>
      <Button
        onClick={() => navigate(PagePath.NewAccount)}
        size="large"
        type="primary"
      >
        Explore Programs
      </Button>
    </Flex>
  );
};

export default OverviewEmpty;
