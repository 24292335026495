import React, { FunctionComponent } from 'react';
import { CurrencyToSign, ProductType, Program } from '@monorepo/types';
import {
  TextComponent,
  Card,
  Divider,
  Flex,
  Button,
  Statistic,
} from '@monorepo/react-components';
import { ProgramDetails } from './program-details';
import classNames from 'classnames';
import { abbreviatePrice, useResponsiveness } from '@monorepo/client-common';
import { Ribbon } from '../../../../components/ribbon/ribbon';
import { useNewOrderProvider } from '../../components/new-order-provider/new-order-provider';

const ProgramProduct: FunctionComponent<{
  currencyProductPrice: number;
  program: Program;
  active: boolean;
  isAdvised: boolean;
  productType: ProductType;
  disabled: boolean;
  onClick: () => void;
}> = ({
  program,
  active,
  productType,
  onClick,
  isAdvised,
  disabled,
  currencyProductPrice,
}) => {
  const { form } = useNewOrderProvider();
  const { isMobile } = useResponsiveness();
  const [headline] = program.name.split(' ');
  const { paymentData } = form.watch();

  const currencySign = paymentData.currency
    ? CurrencyToSign[paymentData.currency]
    : CurrencyToSign.DOLLAR;

  const shouldShowProgramDetails = productType === ProductType.Program;

  let circleValue: number | string | undefined =
    productType === ProductType.Program
      ? program.config.buyingPower
      : program.price;

  circleValue = abbreviatePrice(circleValue);

  const isActive = active ? active : !active && isAdvised;

  const onProductCardClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  const ActionButton = () => (
    <Button
      ghost={!isAdvised}
      disabled={disabled}
      size="large"
      type={'primary'}
    >
      <TextComponent weight={600} level={5}>
        Select
      </TextComponent>
    </Button>
  );

  return (
    <Ribbon ribbon={program.ribbon}>
      <Card
        active={isActive}
        className="item-program"
        onClick={onProductCardClick}
      >
        <Flex vertical gap={24} justify="space-between">
          <Flex vertical>
            <Flex
              justify={isMobile ? 'space-between' : 'center'}
              align="center"
            >
              <Flex vertical align={isMobile ? 'flex-start' : 'center'}>
                <TextComponent weight={500} level={3}>
                  {headline}
                </TextComponent>
                <TextComponent
                  weight={700}
                  level={1}
                  className={classNames({
                    'item-program__advised-price': isAdvised,
                  })}
                >
                  {circleValue}
                </TextComponent>
                <TextComponent
                  level={6}
                  loading={disabled}
                  responsive={false}
                  weight={500}
                  className="item-program__advised-price"
                >
                  <Statistic
                    prefix={`for only ${currencySign}`}
                    value={currencyProductPrice}
                    precision={0}
                  />
                </TextComponent>
              </Flex>
              {isMobile && <ActionButton />}
            </Flex>
            <Divider className="item-program__divider" />
          </Flex>

          {shouldShowProgramDetails && <ProgramDetails program={program} />}

          {!isMobile && <ActionButton />}
        </Flex>
      </Card>
    </Ribbon>
  );
};

export default ProgramProduct;
