import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbElements, findBreadCrumb } from './breadcrumbs';

type BreadcrumbPath = Partial<BreadcrumbItemType & BreadcrumbSeparatorType>;

interface Props {
  currentPath: BreadcrumbPath;
  fullPath: BreadcrumbPath[];
}

const BreadcrumbLink: FunctionComponent<Props> = ({
  currentPath,
  fullPath,
}) => {
  const breadCrumb = findBreadCrumb(currentPath.path);

  if (breadCrumb && BreadcrumbElements[breadCrumb].disabled) {
    return currentPath.title;
  }

  const currentPathIndex = fullPath.findIndex(
    ({ path }) => path === currentPath.path
  );

  const currentRelativePath = fullPath.slice(0, currentPathIndex + 1);

  const breadCrumbResultLink = currentRelativePath
    .map(({ path }) => path)
    .join('/');

  return <Link to={breadCrumbResultLink}>{currentPath.title}</Link>;
};

export default BreadcrumbLink;
