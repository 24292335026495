import React, { FunctionComponent, ReactNode } from 'react';
import { useResponsiveness } from '@monorepo/client-common';
import {
  Button,
  Flex,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { useState } from 'react';
import classNames from 'classnames';
import { Icons, IconsNames } from '@monorepo/icons';
import SummaryWidget from '../summary-widget/summary-widget';
import { useNewOrderProvider } from '../new-order-provider/new-order-provider';
import { CurrencyToSign } from '@monorepo/types';

import './confirmation-drawer.scss';

interface Props {
  buttonText: ReactNode;
  showSummary?: boolean;
}

const ConfirmationDrawer: FunctionComponent<Props> = ({
  buttonText,
  showSummary = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDesktop } = useResponsiveness();
  const { onActionClick, form, isLoading } = useNewOrderProvider();
  const paymentData = form.watch('paymentData');

  const currencySign = paymentData?.currency
    ? CurrencyToSign[paymentData.currency]
    : CurrencyToSign.DOLLAR;

  const toggleDrawer = () => {
    if (showSummary) {
      setIsOpen(!isOpen);
    }
  };

  const ActionButton = () => (
    <Button
      loading={isLoading}
      onClick={onActionClick}
      type="primary"
      size="large"
      className={classNames('confirmation-drawer__button', {
        'confirmation-drawer__button-min': !isDesktop,
      })}
    >
      <Flex gap={16} align="center">
        <TextComponent level={3}>{buttonText}</TextComponent>
        {isDesktop && (
          <>
            <TextComponent weight={700} level={3}>
              <Statistic
                prefix={currencySign}
                value={paymentData.amount}
                precision={2}
              />
            </TextComponent>
            <TextComponent level={4}>
              <Icons iconName={IconsNames.ChevronRight} />
            </TextComponent>
          </>
        )}
      </Flex>
    </Button>
  );

  if (isDesktop) {
    return <ActionButton />;
  }

  return (
    <Flex
      vertical
      className={classNames('confirmation-drawer', {
        'confirmation-drawer--opened': isOpen,
      })}
    >
      <div className="confirmation-drawer__header">
        <Flex
          vertical
          align="start"
          className="confirmation-drawer__header__total"
          onClick={toggleDrawer}
        >
          <Flex align="center" gap={5}>
            <TextComponent weight={500} level={8} responsive={false}>
              Total
            </TextComponent>
            {showSummary && (
              <Icons
                className={classNames({
                  'confirmation-drawer__header__total__icon': !isOpen,
                })}
                iconName={IconsNames.ChevronUp}
              />
            )}
          </Flex>
          <TextComponent weight={700} level={3} responsive={false}>
            <Statistic
              prefix={currencySign}
              value={paymentData.amount}
              precision={2}
            />
          </TextComponent>
        </Flex>
        <ActionButton />
      </div>
      {showSummary && <SummaryWidget />}
    </Flex>
  );
};
export default ConfirmationDrawer;
