import { Controller, UseFormReturn } from 'react-hook-form';
import React, { FunctionComponent } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  UseControllerProps,
} from 'react-hook-form/dist/types/controller';
import { ErrorMessage } from '@hookform/error-message';
import { Typography } from 'antd';

import './form-field.scss';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

export interface FormFieldProps {
  text?: string;
  placeholder?: string;
  form: UseFormReturn<any>;
  mode?: 'multiple' | 'tags' | undefined;
  controllerProps: UseControllerProps;
  showErrorMessage?: boolean;
  render: ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<any>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
  }) => React.ReactElement;
}

export const FormField: FunctionComponent<
  Omit<FormFieldProps, 'placeholder'>
> = ({ form, controllerProps, text, showErrorMessage = true, render }) => {
  // Default text value :)
  if (controllerProps?.rules?.required === true) {
    controllerProps.rules.required = 'Missing Field';
  }

  return (
    <span className="form-field">
      {text ? <Typography>{text}</Typography> : null}
      <Controller
        {...controllerProps}
        name={controllerProps.name}
        control={form.control}
        render={render}
      />
      {showErrorMessage ? (
        <ErrorMessage
          render={({ message }) => <p className="error-message">{message}</p>}
          errors={form.formState.errors}
          name={controllerProps.name}
        />
      ) : null}
    </span>
  );
};
