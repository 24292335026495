import React, { FunctionComponent } from 'react';
import { TextComponent, Flex } from '@monorepo/react-components';
import dayjs from 'dayjs';

interface Props {
  name: string;
  title: string;
  city: string;
  address: string;
  country: string;
  street: string;
  paymentDate: Date;
}

const InvoiceCompanyDetails: FunctionComponent<Props> = ({
  name,
  title,
  city,
  address,
  country,
  street,
  paymentDate,
}) => {
  const companyAddress = [city, address, country].filter(Boolean).join(',');

  return (
    <Flex gap={4} vertical className="invoice-section">
      <Flex justify="space-between">
        <TextComponent level={7} capitalize={true}>
          {name + '-'}
        </TextComponent>
        <TextComponent level={8}>
          Date Issued :{dayjs(paymentDate).format('ddd MMM DD YYYY')}
        </TextComponent>
      </Flex>

      <TextComponent level={7} capitalize={true}>
        {title + '-'}
      </TextComponent>
      <TextComponent level={7} capitalize={true}>
        {street ? street + ',' : '-'}
      </TextComponent>
      {companyAddress && (
        <TextComponent level={7} capitalize={true}>
          {companyAddress}
        </TextComponent>
      )}
    </Flex>
  );
};

export default InvoiceCompanyDetails;
