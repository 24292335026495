import { Button, Result } from 'antd';

import './error-page.scss';

const ErrorPage = () => {
  const handleNavigate = () => {
    window.location.href = '/';
  };

  return (
    <div className="error-page">
      <Result
        status="error"
        title="Something went wrong..."
        subTitle="Please try again later"
        extra={[
          <Button key="home-btn" type="primary" onClick={handleNavigate}>
            Back Home
          </Button>,
        ]}
      ></Result>
    </div>
  );
};
export default ErrorPage;
