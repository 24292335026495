import { Flex as AntdFlex, FlexProps } from 'antd';
import { FunctionComponent, PropsWithChildren } from 'react';

import './flex.scss';

interface MarketBoxProps extends PropsWithChildren {
  markedColor?: string;
}

const MarkedFlex: FunctionComponent<MarketBoxProps> = ({
  markedColor,
  children,
}) => {
  if (!markedColor) {
    return children;
  }
  return (
    <AntdFlex datatype={markedColor} className="the5ers-marked-flex">
      <div className="the5ers-marked-flex__content">{children}</div>
      <div
        className="the5ers-marked-flex__bg"
        style={{ backgroundColor: markedColor }}
      />
    </AntdFlex>
  );
};

interface Props extends FlexProps {
  markedColor?: string;
}

export const Flex: FunctionComponent<Props> = ({
  children,
  markedColor,
  ...props
}) => {
  return (
    <MarkedFlex markedColor={markedColor}>
      <AntdFlex className="the5ers-flex" {...props}>
        {children}
      </AntdFlex>
    </MarkedFlex>
  );
};
