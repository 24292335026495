import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { drawTotalPayoutAchievementMap } from '../templates/draw-achievements';
import { Application } from '@monorepo/types';
import { useStore } from '../../../helpers/use-store';
import { Image as AntdImage } from 'antd';
import { AchievementsSocialLinks } from '../achievements-social-links';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const imageUrl = {
  [Application.TTP]:
    'https://res.cloudinary.com/dvt6xbblx/image/upload/v1723466791/ttp/achievement-templates/ttp-payout-total_p66cbz.png',
  [Application.THE5ERS]:
    'https://res.cloudinary.com/dvt6xbblx/image/upload/v1723381672/5ers-prod/achievement-templates/the5ers-payout-total_c1xkaj.png',
};

interface Props {
  amount: number;
}

const AchievementTotalPayoutDraw: FunctionComponent<Props> = ({ amount }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [url, setUrl] = useState('');
  const baseImage = imageUrl[project];
  const {
    dataStore: { userStore },
  } = useStore();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const userName = userStore.fullName.split(' ').join(' ');

    if (ctx && canvas) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = baseImage;
      image.onload = () => {
        const dataUrl = drawTotalPayoutAchievementMap({
          ctx,
          canvas,
          userName,
          project,
          payoutAmount: amount,
          image,
        });
        setUrl(dataUrl);
      };
    }

    return () => {};
  }, [amount]);

  if (!url) {
    return <canvas ref={canvasRef} height={580} width={600} />;
  }

  return (
    <AntdImage
      className="achievement-image"
      src={url}
      preview={{
        mask: (
          <AchievementsSocialLinks totalPayoutAmount={amount} imageUrl={url} />
        ),
      }}
    />
  );
};

export default AchievementTotalPayoutDraw;
