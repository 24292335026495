import React, { FunctionComponent, useState } from 'react';
import { Button, Flex, Steps, theme } from 'antd';
import NyseStepOne from './nyse-steps/nyse-step-one';
import NyseStepTwo from './nyse-steps/nyse-step-two';
import NyseStepThree from './nyse-steps/nyse-step-three';
import NyseStepEight from './nyse-steps/nyse-step-eight';
import NyseStepFour from './nyse-steps/nyse-step-four';
import { UseFormReturn } from 'react-hook-form';
import NyseStepFive from './nyse-steps/nyse-step-five';
import NyseStepSix from './nyse-steps/nyse-step-six';
import NyseStepSeven from './nyse-steps/nyse-step-seven';
import NyseStepNine from './nyse-steps/nyse-step-nine';
import NyseStepTen from './nyse-steps/nyse-step-ten';
import { NyseContractData } from '@monorepo/types';
import { useResponsiveness } from '@monorepo/client-common';
export interface NyseFormProps {
  form: UseFormReturn<NyseContractData>;
}

const nextStepAvailable = (
  currentStep: number,
  form: UseFormReturn<NyseContractData>
) => {
  const values = form.watch();
  const availableMap: { [key: number]: boolean } = {
    1: true,
    2: true,
    3: true,
    4: values.firstAgreement,
    5: true,
    6: true,
    7: true,
    8: values.firstName !== '' && values.lastName !== '',
    9: values.signThatProvidedDataIsCorrect,
  };
  return availableMap[currentStep + 1];
};

const NyseModalContent: FunctionComponent<NyseFormProps> = ({ form }) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: '',
      content: <NyseStepOne />,
    },
    {
      title: '',
      content: <NyseStepTwo />,
    },
    {
      title: '',
      content: <NyseStepThree />,
    },
    {
      title: '',
      content: <NyseStepFour form={form} />,
    },
    {
      title: '',
      content: <NyseStepFive />,
    },
    {
      title: '',
      content: <NyseStepSix form={form} />,
    },
    {
      title: '',
      content: <NyseStepSeven form={form} />,
    },
    {
      title: '',
      content: <NyseStepEight form={form} />,
    },
    {
      title: '',
      content: <NyseStepNine form={form} />,
    },
    {
      title: '',
      content: <NyseStepTen form={form} />,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const contentStyle: React.CSSProperties = {
    lineHeight: '30px',
    textAlign: 'left',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const { isMobile } = useResponsiveness();

  const isDisabled = !nextStepAvailable(current, form);
  return (
    <>
      <Flex vertical={!isMobile}>
        <Steps current={current} items={steps} />
        <div style={contentStyle}>{steps[current].content}</div>
      </Flex>
      <div style={{ marginTop: 24 }}>
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()} disabled={isDisabled}>
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default NyseModalContent;
