import { useResponsiveness } from '@monorepo/client-common';
import { Icons, IconsNames } from '@monorepo/icons';
import { Flex, TextComponent } from '@monorepo/react-components';
import { FunctionComponent } from 'react';

import './new-order-step-title.scss';

const NewOrderStepTitle: FunctionComponent<{ title: string }> = ({ title }) => {
  const { isMobile } = useResponsiveness();

  if (!isMobile) {
    return title;
  }

  return (
    <Flex className="new-order-step-title" gap={8} align="center">
      <TextComponent level={6} responsive={false}>
        {title}
      </TextComponent>
      <div className="new-order-step-title__line" />
      <Flex
        align="center"
        justify="center"
        className="new-order-step-title__icon-container"
      >
        <Icons iconName={IconsNames.Check} />
      </Flex>
    </Flex>
  );
};

export default NewOrderStepTitle;
