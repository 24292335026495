import { FunctionComponent } from 'react';
import { Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useStore } from '../../helpers/use-store';
import { ThemeWrapper } from '../theme-wrapper/theme-wrapper';
import { ThemeColors } from '@monorepo/types';

const GoogleLogin: FunctionComponent<{ text?: string; ghost?: boolean }> = ({
  ghost,
  text = 'Login with Google',
}) => {
  const { uiStore } = useStore();

  const onGoogleLogin = () => {
    window.location.href = uiStore.googleLoginUrl;
  };

  return (
    <ThemeWrapper themeColor={ThemeColors.colorInfo}>
      <Button
        block
        type="default"
        ghost={ghost}
        onClick={onGoogleLogin}
        className={'google-login-button'}
      >
        <GoogleOutlined />
        {text}
      </Button>
    </ThemeWrapper>
  );
};

export default GoogleLogin;
