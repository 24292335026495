import { BaseIdentifiers } from '../common/identifiers';

export type Position = BaseIdentifiers & (ClosedPosition | OpenPosition);

export interface ClosedPosition {
  id: string;
  externalId?: string;
  symbol: string;
  openDate: string;
  closeDate: string;
  side: string;
  fee: number;
  entry: number;
  exit: number;
  quantity: number;
  swap?: number;
  comment?: string;
  profitAndLoss: number;
  status: ClosedPositionStatus;
  isValid?: boolean;
  invalidReasons?: PositionInvalidReason[];
}

export enum PositionSide {
  Buy = 'Buy',
  Sell = 'Sell',
  Correction = 'Correction',
}

export enum PositionInvalidReason {
  DifferenceBetweenEntryAndExit10Cents = 'difference-between-entry-and-exit-10-cents',
  Minimum60SecondsBetweenOpenAndClose = 'minimum-60-seconds-position',
  NewsTime = 'news-time',
}

export enum ClosedPositionStatus {
  Win = 'Win',
  Loss = 'Loss',
}

export interface OpenPosition {
  id: string;
  date: string;
  symbol: string;
  side: string;
  swap?: number;
  entry: number;
  fee: number;
  profitAndLoss: number;
  quantity: number;
}
