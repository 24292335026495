export interface CountryFilter {
  type: CountryFilterType;
  countries: string[];
}

export enum CountryFilterType {
  Include = 'include',
  Exclude = 'exclude',
}

export const isValidCountry = (
  countryCode: string,
  countryFilter: CountryFilter
) => {
  const isCountryIncludedInList = countryFilter.countries.includes(countryCode);

  if (countryFilter.type === CountryFilterType.Include) {
    return isCountryIncludedInList;
  }
  if (countryFilter.type === CountryFilterType.Exclude) {
    return !isCountryIncludedInList;
  }

  return false;
};
