const NyseStepFive = () => {
  return (
    <div className={'contracts-steps-body'}>
      <h2>SECTION 2: NONPROFESSIONAL SUBSCRIBER</h2>
      <div>
        <p>
          9. NONPROFESSIONAL SUBSCRIBER DEFINITION -"Nonprofessional Subscriber"
          means any natural person who receives market data solely for his/her
          personal, nonbusiness use and who is not a “Securities Professional.”
          A “Securities Professional” includes an individual who, if working in
          the United States, is:
        </p>
        <p>
          (a) registered or qualified with the Securities and Exchange
          Commission (the "SEC"), the Commodities Futures Trading Commission,
          any state securities agency, any securities exchange or association,
          or any commodities or futures contract market or association.
        </p>
        <p>
          (b) engaged as an "investment advisor" as that term is defined in
          Section 202 (a) (11) of the Investment Advisor's Act of 1940 (whether
          or not registered or qualified under that Act), or
        </p>
        <p>
          (c) employed by a bank or other organization exempt from registration
          under Federal and/or state securities laws to perform functions that
          would require him or her to be so registered or qualified if he or she
          were to perform such functions for an organization not so exempt.A
          person who works outside of the United States will be considered a
          “Securities Professional” if he or she performs the same functions as
          someone who would be considered a “Securities Professional” in the
          United States. Subscriber may not receive Market Data as a
          “Nonprofessional Subscriber” unless the vendor providing that data to
          Subscriber first determines that the individual falls within Paragraph
          9’s definition of “Nonprofessional Subscriber.”
        </p>
      </div>
    </div>
  );
};

export default NyseStepFive;
