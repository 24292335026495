import { Icons, IconsNames } from '@monorepo/icons';
import { Application } from '@monorepo/types';

import './credit-icon.scss';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const CreditIcon = () => {
  const creditIconName =
    project === Application.TTP ? IconsNames.PCredit : IconsNames.FiversCredit;

  return <Icons className="credit-icon" iconName={creditIconName} />;
};
export default CreditIcon;
