import React from 'react';
import { Badge as AntBadge } from 'antd';
import { FunctionComponent } from 'react';
import { RibbonProps } from 'antd/es/badge/Ribbon';

import './badge-ribbon.scss';

export const BadgeRibbon: FunctionComponent<RibbonProps> = (props) => {
  return (
    <AntBadge.Ribbon
      placement="start"
      className="the5ers-badge-ribbon"
      {...props}
    />
  );
};
