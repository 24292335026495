import { BaseIdentifiers } from '../common/identifiers';
import { CountryFilter } from '../common/country-filtering';
import { UserVerificationType } from '../common/user-verification';
import { DashboardLayouts } from '../dashboard-layouts';
import { ApplicationScheduledJob } from './application-scheduled-jobs';
import { AddOnType } from '../add-on/add-on';
import { WithdrawRule } from '../common/withdraw-rule';
import { FeatureFlags } from '../feature-flags/feature-flags';
import { AccountType } from '../account/account';
import { NotificationName } from '../notification';
import { BlacklistEmail } from '../blacklist-email';
import { Pages } from '../sidebar/sidebar';
import { ContractType } from '../contract';
import { TradingSystem } from '../trading-system';

export type ApplicationConfig = BaseIdentifiers & {
  dashboardLayout?: DashboardLayouts;
  currencies: CurrencyItem[];
  blacklistEmails: BlacklistEmail[];
  companyDetails: CompanyDetails;
  banners?: Banner[];
  userVerificationTriggers?: UserVerificationTrigger[];
  addOns?: AddOnType[];
  scheduledJobs: ApplicationScheduledJob[];
  accountWithdrawRules: WithdrawRule[];
  affiliateWithdrawRules: WithdrawRule[];
  timeZone?: string;
  shouldCreateDemoAccount?: boolean;
  name: string;
  termsAndConditions?: string;
  website: string;
  featureFlags?: FeatureFlags;
  targetEmails?: TargetEmails;
  discordLink?: string;
  showDiscordLink?: boolean;
  restrictAccessToDiscord?: boolean;
  youtubeLink?: string;
  facebookLink?: string;
  twitterLink?: string;
  instagramLink?: string;
  sidebar: SidebarItem[];
  contracts?: ApplicationContract[];
  userBuyingPowerLimitation?: number;
  countryFilter?: CountryFilter;
  achievements?: boolean;
  groupPrograms?: boolean;
  enableCognitoMigration?: boolean;
  tradingSystems?: ApplicationTradingSystem[];
  adminNotification?: AdminNotification[];
  enableMigrationSync?: boolean;
};

export interface ApplicationTradingSystem {
  tradingSystem: TradingSystem;
  sellable: boolean;
}

export interface ApplicationContract {
  type: ContractType;
  currentVersion: number;
}

export interface UserVerificationTrigger {
  type: UserVerificationType;
  value: number;
}

export type TargetEmails = Record<AccountType, NotificationName>;

export interface CurrencyItem {
  value: string;
  countryFilter?: CountryFilter;
}

export interface BannerImages {
  desktopUrl: string;
  tabletUrl?: string;
  mobileUrl?: string;
}
export interface Banner {
  name: string;
  placement: BannerPlacement;
  images: BannerImages;
  link?: string;
}

export enum BannerPlacement {
  Header = 'header',
  Sider = 'sider',
}

export interface CompanyDetails {
  // Five Percent Online LTD
  companyName: string;
  // TradeThePool.com
  companyTitle: string;
  city: string;
  street: string;
  address: string;
  country: string;
  zipCode: string;
}

export enum Application {
  TTP = 'ttp',
  THE5ERS = '5ers',
}

export interface SidebarItem {
  label: string;
  page: Pages;
}

export type AdminNotification = {
  name: NotificationName;
  recipients: string[];
};
