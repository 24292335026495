import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useStore } from '../../helpers/use-store';
import { PagePath } from '@monorepo/types';
import { FullStoryConsumer } from '../full-story-consumer/full-story-consumer';
import dayjs from 'dayjs';

const isProduction = import.meta.env.VITE_APP_ENV === 'production';

const specialRoutes: { [key: string]: string } = {
  [PagePath.Overview]: `overview/account`,
};

const createPageName = (base: string, rest: string[]): string | null => {
  const path = `/${base}`;
  if (Object.values(PagePath).includes(path as PagePath)) {
    if (specialRoutes[path as PagePath] && rest.length > 0) {
      return specialRoutes[path as PagePath];
    }
    return base;
  }
  return null;
};

const getLocationKey = (location: string) => {
  const [, base, ...rest] = location.split('/');
  return createPageName(base, rest);
};

const createUserUniqeSession = (userId: string) => {
  const sessionTime = dayjs().format('YYYY-MM-DD-HH:mm:ss');
  return `${userId}-${sessionTime}`;
};

export const LaunchDarkly: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const location = useLocation();
  const ldClient = useLDClient();
  const {
    dataStore: { userStore },
  } = useStore();

  const userLocation = getLocationKey(location.pathname);
  const user = userStore.currentUser;

  useEffect(() => {
    if (!isProduction || !userLocation) {
      return;
    }
    const session = createUserUniqeSession(user.userId);

    ldClient?.identify({
      key: user.userId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      country: user.country,
      kind: 'user',
      custom: {
        location: userLocation,
        session: session,
      },
    });
  }, [location.pathname]);

  return (
    <>
      <FullStoryConsumer page={userLocation} />
      {children}
    </>
  );
};
