import { ComponentProps, FunctionComponent, ReactNode } from 'react';
import {
  Table as AntdTable,
  TableProps,
  PaginationProps as AntdPaginationProps,
} from 'antd';
import classNames from 'classnames';
import { Card } from '../card/card';
import { Flex } from '../flex/flex';
import { TextComponent } from '../text';

import './table.scss';

export type PaginationProps = AntdPaginationProps;

type Props = TableProps<any> & {
  rowHoverable?: boolean;
  label?: string | ReactNode;
};

export const Table: FunctionComponent<Props> = ({
  className,
  rowClassName,
  label,
  rowHoverable = true,
  ...antdProps
}) => {
  if (label) {
    return (
      <Card className="the5ers-table" hoverable={false}>
        <Flex vertical gap={16}>
          <TextComponent responsive={false} level={4}>
            {label}
          </TextComponent>
          <AntdTable
            pagination={{
              hideOnSinglePage: true,
            }}
            {...antdProps}
            rowClassName={classNames('the5ers-table-row', className)}
            className={classNames({
              'disabled-hoverable': !rowHoverable,
            })}
          />
        </Flex>
      </Card>
    );
  }

  return (
    <div className="the5ers-table">
      <AntdTable
        pagination={{
          hideOnSinglePage: true,
        }}
        {...antdProps}
        rowClassName={classNames('the5ers-table-row', className)}
        className={classNames({
          'disabled-hoverable': !rowHoverable,
        })}
      />
    </div>
  );
};

export const TableSummaryRow = (
  props: ComponentProps<typeof AntdTable.Summary.Row>
) => {
  return <AntdTable.Summary.Row {...props} />;
};

export const TableSummaryCell = (
  props: ComponentProps<typeof AntdTable.Summary.Cell>
) => {
  return <AntdTable.Summary.Cell {...props} />;
};
