import { BaseIdentifiers, UserIdentifier } from '../common/identifiers';

export type Notification = BaseIdentifiers &
  UserIdentifier & {
    platforms: NotificationPlatform[];
    message?: string;
    type: NotificationName;
    seen: boolean;
  };

export enum NotificationPlatform {
  APP = 'app',
  EMAIL = 'email',
}

export enum NotificationName {
  Termination = 'termination',
  EvaluationAboutToExpire = 'about-to-expire',
  DemoAboutToExpire = 'demo-is-about-to-expired',
  ReachedExpiration = 'reached-expiration',
  ReachedDailyPause = 'daily-pause-reached',
  ReachedDailyLoss = 'daily-loss-reached',
  AfterPurchase = 'after-purchasing',
  ResetPassword = 'reset-password',
  Welcome = 'welcome',
  UserVerificationRequired = 'user-verification-required',
  UserVerificationCompleted = 'user-verification-completed',
  UserVerificationFailed = 'user-verification-failed',
  EmailConfirmation = 'confirmation-email',
  NewAccount = 'new-account',
  AddOnApplied = 'add-on-applied',
  ReachedTargetEvaluationToEvaluation = 'eval-to-eval-passed',
  ReachedTargetToFunded = 'email-15-reached-target-account-passed',
  ResetDailyPause = 'email-13-account-renewed-after-daily-pause',
  FundedAccountReady = 'email-17-funded-account-is-ready',
  RequestPayout = 'email-payout-request-confirmation',
  GotPaid = 'email-16-you-got-paid',
  ReachedInactivityDays = 'reached-inactivity-days',
  WarnReachingInactivityDays = 'warn-reaching-inactivity-days',
  AdminAccountPayout = 'admin-account-payout',
  AdminAffiliatePayout = 'admin-affiliate-payout',
  AdminPayoutRequest = 'admin-payout-request',
}

export type NotificationData = {
  message: string;
  id: string;
  createdAt?: string;
  icon?: string;
  realTime?: boolean;
};
