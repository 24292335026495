import { Button, ButtonProps, Tooltip, Flex } from '@monorepo/react-components';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import { FunctionComponent } from 'react';
import {
  AnalyticsEventTypes,
  ButtonText,
  FeatureFlagNames,
  PagePath,
} from '@monorepo/types';
import { useFeatureFlags } from '../../hooks/use-feature-flags';
import gtmService from '../../services/analytics/analytics';
import Link from 'antd/es/typography/Link';
import { ThemeWrapper } from '../theme-wrapper/theme-wrapper';
import { ThemeColors } from '@monorepo/types';

const VerificationRequiredButton: FunctionComponent<ButtonProps> = ({
  children,
  disabled,
  ...rest
}) => {
  const {
    dataStore: { applicationConfigStore, userStore },
  } = useStore();
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const paymentsDisabled =
    !isFeatureEnabled(FeatureFlagNames.DisablePayments) ||
    applicationConfigStore.applicationConfig.isBlacklisted;

  const shouldDisableButton =
    disabled || userStore.verificationRequired || paymentsDisabled;

  const getTooltipText = () => {
    if (userStore.verificationRequired) {
      return (
        <Flex>
          <span>
            Verification required:{' '}
            <Link onClick={() => navigate('/profile/kyc')}>Click Here</Link>
          </span>
        </Flex>
      );
    } else if (paymentsDisabled) {
      return 'Payments disabled';
    }
    return '';
  };

  const handleClick = () => {
    gtmService.sendEvent({
      event: AnalyticsEventTypes.NewAccountClick,
      params: {
        button_text: ButtonText.NewAccount,
      },
    });
    navigate(PagePath.NewAccount);
  };

  return (
    <ThemeWrapper themeColor={ThemeColors.colorAttention}>
      <Tooltip title={getTooltipText()}>
        <Button
          disabled={shouldDisableButton}
          onClick={handleClick}
          type="primary"
          {...rest}
        >
          {children}
        </Button>
      </Tooltip>
    </ThemeWrapper>
  );
};

export default VerificationRequiredButton;
