import { Descriptions } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { AccountStats as AccountStatsType } from '@monorepo/types';
import dayjs from 'dayjs';
import { Card } from '@monorepo/react-components';

interface Props {
  accountId: string;
}

const AccountStats: FunctionComponent<Props> = ({ accountId }) => {
  const {
    dataStore: { accountStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const [accountStats, setAccountStats] = useState<AccountStatsType>({
    bestStock: 0,
    bestDay: 0,
    riskRewardRatio: 0,
    successRate: 0,
  });

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getStats(accountId);

        setAccountStats(result);
      } catch (e) {
        console.error(`Failed getting overview`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Card className="metric-card" loading={isLoading} title={`Account Stats`}>
      <Descriptions column={1} size="small">
        <Descriptions.Item label="Best Stock">
          {accountStats.bestStock}
        </Descriptions.Item>
        <Descriptions.Item label="Best Day">
          {accountStats.bestDay
            ? dayjs(accountStats.bestDay).format('DD/MM/YYYY')
            : '-----'}
        </Descriptions.Item>
        <Descriptions.Item label="Risk/Reward Ratio">
          {accountStats.riskRewardRatio
            ? `1:${accountStats.riskRewardRatio}`
            : '------'}
        </Descriptions.Item>
        <Descriptions.Item label="Success Rate">
          {accountStats.successRate
            ? `${accountStats.successRate.toFixed(0)}%`
            : '------'}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default AccountStats;
