import React, { FunctionComponent, ReactNode } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Radio, Space } from 'antd';

interface RadioItem {
  text: string | ReactNode;
  value: any;
}

export const FormRadioGroup: FunctionComponent<
  Omit<FormFieldProps, 'render'> & {
    options: RadioItem[];
    direction?: 'horizontal' | 'vertical';
  }
> = ({ options, direction, ...formFieldProps }) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Radio.Group {...field}>
            <Space direction={direction}>
              {options.map((option) => {
                return (
                  <Radio key={option.value} value={option.value}>
                    {option.text}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        );
      }}
    />
  );
};
