import { Outlet } from 'react-router-dom';
import Logo from '../logo/logo';
import { Application, Theme } from '@monorepo/types';
import classNames from 'classnames';
import { Flex } from '@monorepo/react-components';
import { useResponsiveness } from '@monorepo/client-common';

import './layout-not-auth.scss';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const bgImage =
  project === Application.TTP
    ? 'https://res.cloudinary.com/dvt6xbblx/image/upload/v1720342452/ttp/bg-login_yacvm1.png'
    : 'https://res.cloudinary.com/dvt6xbblx/image/upload/v1720342435/5ers/bg-login_ng287t.png';

const LayoutNotAuth = () => {
  const { isDesktop } = useResponsiveness();

  return (
    <Flex
      justify="space-between"
      style={{ backgroundImage: `url(${bgImage})` }}
      className={classNames('layout-not-auth', {
        'bg-image': true,
        [`bg-${project}`]: true,
      })}
    >
      <Flex
        vertical
        justify="center"
        className={classNames('layout-not-auth__main', {
          'layout-not-auth__main--mobile': !isDesktop,
        })}
      >
        <Logo mode={Theme.Dark} />
        <div className="layout-not-auth__outlet">
          <Outlet />
        </div>
      </Flex>
    </Flex>
  );
};
export default LayoutNotAuth;
