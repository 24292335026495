import { FunctionComponent } from 'react';
import { NyseFormProps } from '../nyse-modal-content';
import { FormRadioGroup } from '@monorepo/react-components';

const NyseStepSeven: FunctionComponent<NyseFormProps> = ({ form }) => {
  return (
    <div className={'contracts-steps-body'}>
      <div>
        <div>
          <div>
            <p>
              (e) Are you engaged to provide investment advice to any individual
              or entity?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'investmentAdvices' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
            <div>
              <p>(f) Are you engaged as an asset manager?</p>
              <FormRadioGroup
                form={form}
                controllerProps={{ name: 'assetManager' }}
                options={[
                  {
                    value: false,
                    text: 'No',
                  },
                  {
                    value: true,
                    text: 'Yes',
                  },
                ]}
              />
            </div>
            <div>
              <p>
                (g) Do you use the capital of any other individual or entity in
                the conduct of your trading?
              </p>
              <FormRadioGroup
                form={form}
                controllerProps={{ name: 'usingOtherCapital' }}
                options={[
                  {
                    value: false,
                    text: 'No',
                  },
                  {
                    value: true,
                    text: 'Yes',
                  },
                ]}
              />
            </div>
          </div>
          <div>
            <p>
              (h) Do you conduct trading for the benefit of a corporation,
              partnership, or other entity?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'tradeForOtherCorpOrOther' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
          </div>
          <div>
            <p>
              (i) Have you entered into any agreement to share the profit of
              your trading activities or receive compensation for your trading
              activities?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'sharingProfit' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
          </div>
          <div>
            <p>
              (j) Are you receiving office space, and equipment or other
              benefits in exchange for your trading or work as a financial
              consultant to any person, firm or business entity?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'sharingSpace' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NyseStepSeven;
