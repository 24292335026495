import { useForm } from 'react-hook-form';
import { User, UserInformation } from '@monorepo/types';
import { App } from 'antd';
import { useStore } from '../../helpers/use-store';
import UploadProfile from '../../components/upload-profile/upload-profile';
import { isEmpty } from 'lodash';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import UserVerificationWrapper from '../../components/user-verification/user-verification-wrapper';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, RadioButtonGroup } from '@monorepo/react-components';
import PageContainer from '../page-container/page-container';
import AccountForm from '../forms/account-form';

enum ProfilePath {
  Profile = 'profile',
  Verification = 'kyc',
}

const Profile = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  const { activeTab = ProfilePath.Profile } = useParams<{
    activeTab?: string;
  }>();
  const { loadingState, updateLoadingState } = useLoading();
  const { isDesktop } = useResponsiveness();
  const navigate = useNavigate();

  const isUpdateInProgress = loadingState === LoadingState.Loading;

  const form = useForm<UserInformation>({
    defaultValues: {
      email: userStore.currentUser.email,
      firstName: userStore.currentUser.firstName || '',
      lastName: userStore.currentUser.lastName || '',
      address: userStore.currentUser.address || '',
      city: userStore.currentUser.city || '',
      zipCode: userStore.currentUser.zipCode || '',
      country: userStore.currentUser.country || '',
      state: userStore.currentUser.state || '',
      phoneNumber: userStore.currentUser.phoneNumber || '',
    },
  });

  const { message } = App.useApp();

  const updateUser = async () => {
    try {
      updateLoadingState(LoadingState.Loading);

      const formValues = form.getValues();
      const changedValues = form.formState.dirtyFields;
      const fieldsToUpdate: Partial<UserInformation> = {};

      Object.keys(changedValues).map((changedValue: string) => {
        fieldsToUpdate[changedValue as keyof UserInformation] =
          formValues[changedValue as keyof UserInformation];
      });

      if (!isEmpty(fieldsToUpdate)) {
        const response = await userStore.update(fieldsToUpdate);

        const {
          userId,
          profileUrl,
          creditBalance,
          confirmationCode,
          ...userData
        } = response as User;

        form.reset(userData);

        return message.success('Your details were updated successfully ');
      }
    } catch (e: any) {
      message.error(e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };
  const getProfile = () => {
    return (
      <Flex
        gap={isDesktop ? 72 : 20}
        style={{ flexDirection: isDesktop ? 'row' : 'column-reverse' }}
      >
        <Flex vertical gap={16}>
          <AccountForm formInstance={form} />
          <Button
            style={{ width: '100%' }}
            size="large"
            type="primary"
            disabled={!form.formState.isDirty || isUpdateInProgress}
            onClick={form.handleSubmit(updateUser)}
          >
            Save Changes
          </Button>
        </Flex>
        <UploadProfile />
      </Flex>
    );
  };

  const handleTabChange = (activeKey: string) => {
    if (activeKey !== activeTab) {
      const path = activeKey === ProfilePath.Profile ? '' : `/${activeKey}`;
      navigate(`/${ProfilePath.Profile}${path}`);
    }
  };

  return (
    <PageContainer helmet="Profile" pageTitle="Profile" className="profile">
      <Flex vertical gap={24}>
        <RadioButtonGroup
          radioGroupProps={{
            size: 'middle',
            style: { width: 'max-content' },
            value: activeTab,
            onChange: (e) => {
              handleTabChange(e.target.value);
            },
          }}
          options={[
            { text: 'My Profile', value: ProfilePath.Profile },
            {
              text: 'Verification',
              value: ProfilePath.Verification,
              disabled: !userStore.verificationRequired,
            },
          ]}
        />
        {activeTab === ProfilePath.Profile ? (
          getProfile()
        ) : (
          <UserVerificationWrapper />
        )}
      </Flex>
    </PageContainer>
  );
};

export default observer(Profile);
