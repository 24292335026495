import React, { FunctionComponent } from 'react';
import { Slider as AntdSlider } from 'antd';
import { SliderSingleProps, SliderRangeProps } from 'antd/lib/slider';
import { ThemeColors } from '@monorepo/types';
import { Flex } from '../flex/flex';
import classNames from 'classnames';

import './progress-slider.scss';

type Props = (SliderSingleProps | SliderRangeProps) & {
  primaryHandle?: boolean;
  trackColor?:
    | ThemeColors.colorSuccess
    | ThemeColors.colorPrimary
    | ThemeColors.colorLink
    | ThemeColors.colorError
    | ThemeColors.colorSecondary
    | 'white';
  untrackColor?:
    | ThemeColors.colorSuccess
    | ThemeColors.colorError
    | 'white'
    | null;
  customTrackWidth?: number;
  negative?: boolean;
};

export const ProgressSlider: FunctionComponent<Props> = ({
  className,
  primaryHandle = true,
  trackColor = 'white',
  untrackColor = 'white',
  customTrackWidth,
  negative,
  ...antdProps
}) => {
  return (
    <Flex
      className={classNames('the5ers-progress-slider', className, {
        'the5ers-progress-slider__primary-handle': primaryHandle,
        'the5ers-progress-slider__track--white': trackColor === 'white',
        'the5ers-progress-slider__track--link':
          trackColor === ThemeColors.colorLink,
        'the5ers-progress-slider__track--primary':
          trackColor === ThemeColors.colorPrimary,
        'the5ers-progress-slider__track--secondary':
          trackColor === ThemeColors.colorSecondary,
        'the5ers-progress-slider__track--error':
          trackColor === ThemeColors.colorError,
        'the5ers-progress-slider__track--success':
          trackColor === ThemeColors.colorSuccess,
        'the5ers-progress-slider__untrack--error':
          untrackColor === ThemeColors.colorError,
        'the5ers-progress-slider__untrack--white': untrackColor === 'white',
        'the5ers-progress-slider__range': antdProps.range,
        'the5ers-progress-slider__range--positive':
          antdProps.range && !negative,
        'the5ers-progress-slider__range--negative': antdProps.range && negative,
      })}
    >
      {customTrackWidth && (
        <div
          className={'the5ers-progress-slider__custom-track'}
          style={{
            width: `${customTrackWidth}%`,
          }}
        />
      )}
      <AntdSlider className="the5ers-progress-slider__slider" {...antdProps} />
    </Flex>
  );
};
