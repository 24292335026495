import { Icons, IconsNames } from '@monorepo/icons';
import {
  Flex,
  TextComponent,
  TextComponentProps,
  Tooltip,
} from '@monorepo/react-components';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
  tooltipText?: string | ReactNode;
  tooltipLevel?: TextComponentProps['level'];
  opacity?: number;
  absolute?: boolean;
}

const DashboardTooltip: FunctionComponent<Props> = ({
  children,
  tooltipText,
  tooltipLevel = 6,
  opacity = 0.3,
  absolute = true,
}) => (
  <Tooltip trigger="hover" title={tooltipText} arrow>
    <Flex
      gap={8}
      align="center"
      className={classNames('dashboard-tooltip', {
        'dashboard-tooltip__absolute': absolute,
      })}
      style={{
        opacity: opacity,
      }}
    >
      <TextComponent level={tooltipLevel}>
        <Icons iconName={IconsNames.QuestionCircle} />
      </TextComponent>
      {children}
    </Flex>
  </Tooltip>
);
export default DashboardTooltip;
