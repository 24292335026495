import { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { ConsistencyRuleResult, ConsistencyRules } from '@monorepo/types';
import ConsistencyRulesComponent from './consistency-rules';

interface Props {
  accountId: string;
  programId: string;
}

const AccountConsistencyRules: FunctionComponent<Props> = ({
  accountId,
  programId,
}) => {
  const {
    dataStore: { accountStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const [consistencyRules, setConsistencyRules] = useState<
    Record<ConsistencyRules, ConsistencyRuleResult>
  >({} as Record<ConsistencyRules, ConsistencyRuleResult>);

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getConsistencyRules(accountId);

        setConsistencyRules(result);
      } catch (e) {
        console.error(`Failed getting consistency rules`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const isLoading = loadingState === LoadingState.Loading;

  if (isLoading) {
    return null;
  }

  return (
    <ConsistencyRulesComponent
      programId={programId}
      accountId={accountId}
      consistencyRules={consistencyRules}
    />
  );
};

export default AccountConsistencyRules;
