import React, { PropsWithChildren } from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import { FunctionComponent } from 'react';
import { useColor } from '../../hooks';
import { ThemeColors } from '@monorepo/types';

export interface ThemeWrapperProps extends PropsWithChildren {
  themeColor: ThemeColors;
  components?: ThemeConfig['components'];
}

export const ThemeWrapper: FunctionComponent<ThemeWrapperProps> = ({
  themeColor,
  children,
  components,
}) => {
  const { getColor } = useColor();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: getColor(themeColor),
        },
        components,
      }}
    >
      {children}
    </ConfigProvider>
  );
};
