import { Menu } from 'antd';
import { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createNavBarItems } from './navigation-utils';
import { useFeatureFlags } from '../../../hooks/use-feature-flags';
import { FeatureFlagNames } from '@monorepo/types';
import { useStore } from '../../../helpers/use-store';
import { Flex } from '@monorepo/react-components';
import NavigationMenuHeader from './navigation-menu-header';
import NavigationMenuFooter from './navigation-menu-footer';
import SiderBanner from './navigation-sider/sider-banner/sider-banner';

import './navigation-menu.scss';

interface Props {
  onClose?: () => void;
  showBanner?: boolean;
}
const NavigationMenu: FunctionComponent<Props> = ({
  onClose,
  showBanner = true,
}) => {
  const {
    dataStore: { applicationConfigStore },
  } = useStore();
  const navigate = useNavigate();
  const { isFeatureEnabled } = useFeatureFlags();
  const { pathname } = useLocation();

  const pages = pathname.split('/').filter((name) => Boolean(name));
  const basePath = '/' + pages[0];
  const {
    sidebar,
    discordLink,
    youtubeLink,
    banners,
    facebookLink,
    instagramLink,
    twitterLink,
    showDiscordLink,
  } = applicationConfigStore.applicationConfig;

  const affiliationItemEnabled = isFeatureEnabled(
    FeatureFlagNames.DisableAffiliation
  );
  const filteredItems = createNavBarItems({ sidebar, affiliationItemEnabled });

  const handleLinkClick = (path: string) => {
    navigate(path);
    onClose?.();
  };

  return (
    <Flex className="navigation-menu" vertical justify="space-between">
      <NavigationMenuHeader
        onCloseMenu={onClose}
        onLogoClick={() => handleLinkClick('/overview')}
      />
      <Flex flex={1} className="navigation-menu__menu-container">
        <Menu
          onClick={(item) => handleLinkClick(item.key)}
          selectedKeys={[basePath]}
          mode="inline"
          items={filteredItems}
        />
      </Flex>
      {showBanner && <SiderBanner banners={banners} />}
      <NavigationMenuFooter
        showDiscordLink={showDiscordLink}
        facebookLink={facebookLink}
        instagramLink={instagramLink}
        twitterLink={twitterLink}
        discordLink={discordLink}
        youtubeLink={youtubeLink}
      />
    </Flex>
  );
};
export default NavigationMenu;
