import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Input } from 'antd';

export const FormPassword: FunctionComponent<Omit<FormFieldProps, 'render'>> = (
  formFieldProps
) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Input.Password
            size="large"
            placeholder={formFieldProps.placeholder}
            {...field}
          />
        );
      }}
    />
  );
};
