export enum UserVerificationLevel {
  Funded = 'funded-accounts',
  BankCardCheck = 'Bank Card check',
  Basic = 'basic-kyc-level',
  Demo = 'sumsub-signin-demo-level',
}

export enum UserVerificationPlatform {
  SumSub = 'sumsub',
}

export enum UserVerificationStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
  InProgress = 'in-progress',
  NotRequired = 'not-required',
}

export interface VerificationData {
  status: UserVerificationStatus;
  updatedAt: Date;
  rejection?: string[];
  rejectionComment?: string;
}

const blockedStatuses = [
  UserVerificationStatus.Rejected,
  UserVerificationStatus.Pending,
  UserVerificationStatus.InProgress,
];

export const isUserBlocked = (verificationData?: VerificationData) => {
  return verificationData && blockedStatuses.includes(verificationData?.status);
};
