import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Divider, Row, Space, Tooltip } from '@monorepo/react-components';
import { Account, Application } from '@monorepo/types';
import ChoosePartnerButton from '../../choose-partners-button/choose-partner-button';
import ClosedPositionsTable from '../../positions/closed-positions';
import OpenPositions from '../../positions/open-positions';
import AccountBalance from '../dashboard-common/account-balance/account-balance';
import AccountOverview from '../dashboard-common/account-overview/account-overview';
import AccountStats from './account-stats/account-stats';
import AccountDailyPerformance from '../dashboard-common/account-daily-performance/account-daily-performance';
import AddOns from '../../add-ons/add-ons';
import { useStore } from '../../../helpers/use-store';
import AccountStopout from '../dashboard-common/account-stoput/account-stopout';
import AccountTarget from '../dashboard-common/account-target/account-target';
import AccountDailyProgress from '../dashboard-common/account-daily-progress/account-daily-progress';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useResponsiveness } from '@monorepo/client-common';

const APPLICATION_TYPE = Application.TTP;

interface Props {
  account: Account;
}

const TtpDashboard: FunctionComponent<Props> = ({ account }) => {
  const { isDesktop } = useResponsiveness();
  const {
    dataStore: { accountStore, programStore },
  } = useStore();
  const [accountDetails, setAccountDetails] = useState({
    profitAndLoss: 0,
    dailyProfitAndLoss: 0,
    credits: 0,
    equity: 0,
    balance: 0,
  });
  const id = account.tsAccount as string;

  const showDailyMetrics = programStore.hasDailyMetrics(account.programId);

  useEffect(() => {
    const getAccountBalance = async () => {
      try {
        const result = await accountStore.getBalance(id);

        setAccountDetails((details) => ({
          ...details,
          profitAndLoss: result.profitAndLoss,
          dailyProfitAndLoss: result.dailyProfitAndLoss,
          balance: result.balance as number,
          credits: result.credits as number,
          equity: result.equity as number,
        }));
      } catch (e) {
        console.error(`Failed getting account details`, e);
      }
    };

    getAccountBalance();
  }, [id]);

  const program = programStore.get(account.programId);

  return (
    <div className="dashboard">
      {account && !isDesktop && (
        <Col xs={24} lg={3} style={{ marginBottom: 10 }}>
          <ChoosePartnerButton account={account} />
        </Col>
      )}
      <AddOns accountId={account._id} />

      <Row gutter={[15, 15]}>
        <Col md={24} lg={10}>
          <AccountOverview
            buyingPowerText={'Buying Power'}
            account={account}
            programId={account?.programId as string}
          />
        </Col>
        <Col flex={'auto'} xs={24} md={12} lg={7}>
          <Row gutter={[15, 15]} style={{ height: '100%' }}>
            <Col span={24}>
              <AccountStopout
                title={'Max Loss'}
                baseBalance={0}
                equity={accountDetails.balance}
                programId={account?.programId as string}
              />
            </Col>
            <Col span={24}>
              <AccountTarget
                title={'Target'}
                extra={
                  <Tooltip
                    title={`Valid P&L (only profitable trades that comply with the program rules)`}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                profitAndLoss={accountDetails.profitAndLoss}
                programId={account?.programId as string}
              />
            </Col>
          </Row>
        </Col>
        {showDailyMetrics && (
          <Col flex={1} xs={24} md={12} lg={7}>
            <AccountDailyProgress
              equity={accountDetails.balance}
              allowedDailyLosses={program?.config.dailyPause as number}
              programId={account?.programId as string}
            />
          </Col>
        )}

        <Col xs={24} md={12} span={17}>
          <AccountBalance
            equity={accountDetails.equity}
            balance={accountDetails.balance}
            baseBalance={0}
            profitAndLoss={accountDetails.profitAndLoss}
            accountId={id}
            application={APPLICATION_TYPE}
          />
        </Col>
        <Col xs={24} md={12} span={7}>
          <Space direction="vertical">
            <Col span={24}>
              <AccountDailyPerformance
                programId={account?.programId as string}
                dailyProfitAndLoss={accountDetails.dailyProfitAndLoss}
                accountId={id}
              />
            </Col>
            <Col span={24}>
              <AccountStats accountId={id} />
            </Col>
          </Space>
        </Col>
      </Row>
      <Divider />
      <OpenPositions
        application={Application.TTP}
        hideSwap={true}
        accountId={id}
      />
      <Divider />
      <ClosedPositionsTable
        application={Application.TTP}
        hideSwap={true}
        accountId={id}
      />
    </div>
  );
};

export default TtpDashboard;
