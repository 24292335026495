import { FormCheckbox } from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import { NyseFormProps } from '../nyse-modal-content';
import { Flex } from 'antd';

const NyseStepTen: FunctionComponent<NyseFormProps> = ({ form }) => {
  return (
    <div className={'contracts-steps-body'}>
      <div>
        <p>
          ACCEPTED AND AGREED: I, the "Subscriber" to which the preceding terms
          and conditions refer, acknowledge that I have read the preceding terms
          and conditions of this Section 2, that I understand them, that I
          answer “No” to the questions set forth in Section 10, and that I
          hereby manifest my assent to, and my agreement to comply with, those
          terms and conditions by "clicking" on the following box:
        </p>
        <div>
          <Flex justify={'center'} gap={10}>
            <FormCheckbox
              form={form}
              controllerProps={{ name: 'finalAgreement' }}
            />
            <span>Agree</span>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default NyseStepTen;
