import { BaseIdentifiers, TimeIdentifiers } from '../../common/identifiers';

export enum AffiliateCommissionStatus {
  Paid = 'paid',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Approved = 'approved',
}

export const AutomaticStatuses = [AffiliateCommissionStatus.Approved];

export type AffiliationCommission = BaseIdentifiers &
  TimeIdentifiers & {
    userId: string;
    affiliateId: string;
    affiliateEmail: string;
    externalId: string;
    dueAt: Date;
    amount: number;
    status: AffiliateCommissionStatus;
    note?: string;
  };
