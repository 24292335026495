import BaseEntityService, { BaseResponse } from '../core/base-entity-service';
import {
  ClosedPosition,
  OpenPosition,
  PaginationResult,
  Trade,
} from '@monorepo/types';

export default class PositionService extends BaseEntityService {
  static override route = 'position';

  async getClosedPositions(accountId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<PaginationResult<ClosedPosition>>
      >(`${this.path}/closed/${accountId}`);

      return response.data;
    } catch (e) {
      console.error(
        `Failed getting closed positions for account: ${accountId}`
      );
      return {
        total: 0,
        results: [],
      };
    }
  }

  async getOpenPositions(accountId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<PaginationResult<OpenPosition>>
      >(`${this.path}/open/${accountId}`);

      return response.data;
    } catch (e) {
      console.error(
        `Failed getting closed positions for account: ${accountId}`
      );
      return {
        total: 0,
        results: [],
      };
    }
  }

  async getValidPositionsCount(accountId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<{ valid: number }>
      >(`${this.path}/valid-positions/${accountId}`);

      return response.data;
    } catch (e) {
      console.error(
        `Failed getting valid positions count for account: ${accountId}`
      );
      return {
        valid: 0,
      };
    }
  }

  async getTrades(accountId: string, positionId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<PaginationResult<Trade>>
      >(`${this.path}/${accountId}/trades/${positionId}`);

      return response.data;
    } catch (e) {
      console.error(
        `Failed getting trades of position ${positionId} for account: ${accountId}`
      );
      return {
        total: 0,
        results: [],
      };
    }
  }
}
