import usStates from '../assets/us-states.json';
import caStates from '../assets/ca-states.json';
import auStates from '../assets/au-states.json';
import countries from '../assets/countries.json';
import { CountryFilter, CountryFilterType } from '@monorepo/types';

const countryToStates: { [key: string]: { text: string; value: string }[] } = {
  us: usStates,
  ca: caStates,
  au: auStates,
};

export const getStates = (country: string) => {
  return countryToStates[country.toLowerCase()];
};

export const getCountries = (
  filter?: CountryFilter
): { text: string; value: string }[] => {
  if (filter) {
    return countries.filter((country) =>
      filter.type === CountryFilterType.Exclude
        ? !filter.countries.includes(country.value)
        : filter.countries.includes(country.value)
    );
  }

  return countries;
};
