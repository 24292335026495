import React, { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { Tabs, Flex } from 'antd';
import { PayoutSource } from '@monorepo/types';
import WithdrawFunds from '../../features/withdraw-funds/withdraw-funds';
import { AffiliationCommissions } from '../../features/affiliate-commissions/affiliate-commissions';
import { AffiliateMainDashboard } from './affiliate-main-dashboard/affiliate-main-dashboard';
import { AffiliatePayouts } from './affiliate-payouts/affiliate-payouts';
import { useResponsiveness } from '@monorepo/client-common';
import AffiliateStatistics from './affiliate-statistics/affiliate-statistics';

const tabItems = [
  {
    key: 'dashboard',
    label: `My Dashboard`,
    children: <AffiliateMainDashboard />,
  },
  {
    key: 'payouts',
    label: `Payouts`,
    children: <AffiliatePayouts />,
  },
  {
    key: 'commissions',
    label: `Commissions`,
    children: <AffiliationCommissions />,
  },
];

const AffiliationDashboard: FunctionComponent = () => {
  const { isMobile } = useResponsiveness();
  const {
    dataStore: { userStore },
  } = useStore();

  return (
    <>
      {isMobile && (
        <Flex justify="flex-end" style={{ marginBottom: 12 }}>
          <AffiliateStatistics />
          <WithdrawFunds
            sourceId={userStore.currentUser.affiliateId}
            source={PayoutSource.Affiliate}
          />
        </Flex>
      )}
      <Tabs
        className={'tabs'}
        type="card"
        defaultActiveKey="dashboard"
        items={tabItems}
        tabBarExtraContent={
          !isMobile ? (
            <Flex gap={8} align={'center'}>
              <AffiliateStatistics />
              <WithdrawFunds
                sourceId={userStore.currentUser.affiliateId}
                source={PayoutSource.Affiliate}
              />
            </Flex>
          ) : null
        }
      />
    </>
  );
};
export default observer(AffiliationDashboard);
