import { CryptoCurrency } from '@monorepo/types';

export const currenciesData = [
  { countryCodeExtended: 'EUR', label: 'Euro', countryCode: 'EU' },
  {
    countryCodeExtended: 'USD',
    label: 'United States dollar',
    countryCode: 'US',
  },
  { countryCodeExtended: 'GBP', label: 'British Pound', countryCode: 'GB' },
  {
    countryCodeExtended: 'AED',
    label: 'United Arab Emirates dirham',
    countryCode: 'AE',
  },
  { countryCodeExtended: 'AUD', label: 'Australian dollar', countryCode: 'AU' },
  { countryCodeExtended: 'BDT', label: 'Bangladesh taka', countryCode: 'BD' },
  { countryCodeExtended: 'BGN', label: 'Bulgarian lev', countryCode: 'BG' },
  { countryCodeExtended: 'CAD', label: 'Canadian dollar', countryCode: 'CA' },
  { countryCodeExtended: 'CHF', label: 'Swiss franc', countryCode: 'CH' },
  { countryCodeExtended: 'CLP', label: 'Chilean peso', countryCode: 'CL' },
  { countryCodeExtended: 'CNY', label: 'Chinese yuan', countryCode: 'CN' },
  { countryCodeExtended: 'CRC', label: 'Costa Rican colón', countryCode: 'CR' },
  { countryCodeExtended: 'CZK', label: 'Czech koruna', countryCode: 'CZ' },
  { countryCodeExtended: 'DKK', label: 'Danish krone', countryCode: 'DK' },
  { countryCodeExtended: 'EGP', label: 'Egyptian pound', countryCode: 'EG' },
  { countryCodeExtended: 'GEL', label: 'Georgian lari', countryCode: 'GE' },
  { countryCodeExtended: 'HKD', label: 'Hong Kong dollar', countryCode: 'HK' },
  { countryCodeExtended: 'HUF', label: 'Hungarian forint', countryCode: 'HU' },
  { countryCodeExtended: 'IDR', label: 'Indonesian rupiah', countryCode: 'ID' },
  { countryCodeExtended: 'INR', label: 'Indian rupee', countryCode: 'IN' },
  { countryCodeExtended: 'JPY', label: 'Japanese yen', countryCode: 'JP' },
  { countryCodeExtended: 'KES', label: 'Kenyan shilling', countryCode: 'KE' },
  { countryCodeExtended: 'KRW', label: 'South Korean won', countryCode: 'KR' },
  { countryCodeExtended: 'LKR', label: 'Sri Lankan rupee', countryCode: 'LK' },
  { countryCodeExtended: 'MAD', label: 'Moroccan dirham', countryCode: 'MA' },
  { countryCodeExtended: 'MXN', label: 'Mexican peso', countryCode: 'MX' },
  { countryCodeExtended: 'MYR', label: 'Malaysian ringgit', countryCode: 'MY' },
  { countryCodeExtended: 'NGN', label: 'Nigerian naira', countryCode: 'NG' },
  { countryCodeExtended: 'NOK', label: 'Norwegian krone', countryCode: 'NO' },
  { countryCodeExtended: 'NPR', label: 'Nepalese rupee', countryCode: 'NP' },
  {
    countryCodeExtended: 'NZD',
    label: 'New Zealand dollar',
    countryCode: 'NZ',
  },
  { countryCodeExtended: 'PHP', label: 'Philippine peso', countryCode: 'PH' },
  { countryCodeExtended: 'PKR', label: 'Pakistani rupee', countryCode: 'PK' },
  { countryCodeExtended: 'PLN', label: 'Polish zloty', countryCode: 'PL' },
  { countryCodeExtended: 'RON', label: 'Romanian leu', countryCode: 'RO' },
  { countryCodeExtended: 'SEK', label: 'Swedish krona', countryCode: 'SE' },
  { countryCodeExtended: 'SGD', label: 'Singapore dollar', countryCode: 'SG' },
  { countryCodeExtended: 'THB', label: 'Thai baht', countryCode: 'TH' },
  { countryCodeExtended: 'TRY', label: 'Turkish lira', countryCode: 'TR' },
  {
    countryCodeExtended: 'TZS',
    label: 'Tanzanian shilling',
    countryCode: 'TZ',
  },
  { countryCodeExtended: 'UAH', label: 'Ukrainian hryvnia', countryCode: 'UA' },
  { countryCodeExtended: 'UGX', label: 'Ugandan shilling', countryCode: 'UG' },
  { countryCodeExtended: 'UYU', label: 'Uruguayan dong', countryCode: 'UY' },
  {
    countryCodeExtended: 'XOF',
    label: 'West African CFA franc',
    countryCode: 'NE',
  },
  {
    countryCodeExtended: 'ZAR',
    label: 'South African rand',
    countryCode: 'ZA',
  },
];

export const cryptoCoins = [
  {
    text: 'USDT (TRC20/Tron)',
    value: CryptoCurrency.USDT,
    icon: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=029',
  },
  {
    text: 'USDC (ERC20/Ethereum)',
    value: CryptoCurrency.USDC,
    icon: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=029',
  },
  {
    text: 'ETH (ERC20/Ethereum)',
    value: CryptoCurrency.ETH,
    icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=029',
  },
  {
    text: 'LTC (Litecoin)',
    value: CryptoCurrency.LTC,
    icon: 'https://cryptologos.cc/logos/litecoin-ltc-logo.svg?v=029',
  },
];

export enum CurrencyType {
  Crypto = 'crypto',
  Regular = 'regular',
}

export type CurrencyComponentProps = {
  type: CurrencyType;
  name: string;
};

export const getCurrencyIcon = ({ name, type }: CurrencyComponentProps) => {
  let icon = '';

  if (type === CurrencyType.Crypto) {
    icon = cryptoCoins.find((c) => c.value === name)?.icon || '';
  }
  if (type === CurrencyType.Regular) {
    const iconName =
      currenciesData.find(
        (c) => c.countryCode === name || c.countryCodeExtended === name
      )?.countryCode || '';
    icon = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${iconName}.svg`;
  }
  return icon;
};

export const abbreviatePrice = (price?: number, prefix?: string) => {
  let result = '0';

  if (!price) {
    return result;
  }

  if (price >= 1000000) {
    result = price.toString().slice(0, -6) + 'M';
  } else if (price >= 1000) {
    result = price.toString().slice(0, -3) + 'K';
  }

  if (prefix) {
    result = `${prefix}${result}`;
  }

  return result;
};
