import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Flex } from 'antd';
import { useStore } from '../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { AccountBalanceChart, Application } from '@monorepo/types';
import dayjs from 'dayjs';
import { Statistic, Card, TextComponent } from '@monorepo/react-components';
import DashboardTooltip from '../dashboard-tooltip/dashboard-tooltip';

import './account-balance.scss';

const getTextByApplication = (
  application: Application
): { equity: string; chartTooltip: string | ReactNode; pnl: string } => {
  const mapApplicationText: {
    [key: string]: {
      equity: string;
      chartTooltip: string | ReactNode;
      pnl: string;
    };
  } = {
    [Application.THE5ERS]: {
      equity: 'Equity',
      pnl: 'P&L',
      chartTooltip: (
        <Flex vertical gap={5}>
          <TextComponent>
            Balance: The value in your account, excluding unrealized P&L and
            credit.
          </TextComponent>
          <TextComponent>
            Equity: The total value of your account, including your balance,
            Unrealized P&L and credit.
          </TextComponent>
          <TextComponent>
            P&L: The net profit or loss for your valid trades closed.
          </TextComponent>
        </Flex>
      ),
    },
    [Application.TTP]: {
      pnl: 'Daily P&L',
      equity: 'Unrealized P&L',
      chartTooltip: 'Difference between balance and P&L',
    },
  };

  return mapApplicationText[application];
};

interface Props {
  accountId: string;
  baseBalance?: number;
  equity: number;
  balance: number;
  credits?: number;
  profitAndLoss: number;
  application: Application;
}

const AccountBalance: FunctionComponent<Props> = ({
  accountId,
  baseBalance = 0,
  equity,
  balance,
  credits = 0,
  profitAndLoss,
  application,
}) => {
  const [chartData, setChartData] = useState<{ x: number; y: number }[]>([]);
  const [accountBalanceChart, setAccountBalanceChart] = useState<
    AccountBalanceChart[]
  >([]);
  const {
    dataStore: { accountStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);

        const response = await accountStore.getBalanceChart(accountId);

        setAccountBalanceChart(response);

        const normalized = response.map((tick, i) => {
          return {
            x: i,
            y: Number(tick.value.toFixed(2)),
          };
        });

        setChartData(normalized);
      } catch (e) {
        console.error(`Failed loading account p&l`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const isLoading = loadingState === LoadingState.Loading;

  const data: ChartData<'line'> = {
    datasets: [
      {
        data: [{ x: -1, y: baseBalance }, ...chartData],
        fill: 'start',
        tension: 0.5,
        pointRadius: 5,
        pointBackgroundColor: [
          'transparent',
          ...accountBalanceChart.map((item) =>
            item.type === 'position' ? 'rgb(85,160,218)' : 'yellow'
          ),
        ],
        backgroundColor: 'rgba(64,172,213,0.2)',
        borderColor: 'rgb(85,160,218)',
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const item = accountBalanceChart[context[0].dataIndex - 1];

            if (!item) {
              return '----';
            }

            return dayjs(item.date).format('MMM D hh:mm');
          },
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(context.parsed.y);
            }
            return label;
          },
        },
        mode: 'nearest',
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    hover: {
      mode: 'point',
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
        display: false,
        type: 'linear',
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return `$${Intl.NumberFormat('en-US').format(Number(value))}`;
          },
        },
      },
    },
  };

  const StatValue: FunctionComponent<{
    value: number;
    positiveColor?: boolean;
  }> = ({ value, positiveColor }) => {
    const isPositiveValue = value > 0;
    return (
      <TextComponent
        level={3}
        weight={500}
        className={isPositiveValue ? 'stat-value--profit' : ''}
      >
        <Statistic
          value={value}
          precision={2}
          prefix={isPositiveValue ? '+$' : '$'}
        />
      </TextComponent>
    );
  };

  const StatTitle: FunctionComponent<{ title: string }> = ({ title }) => (
    <TextComponent level={8} responsive={false} className="stat-title">
      {title}
    </TextComponent>
  );

  const stats = [
    {
      title: getTextByApplication(application).pnl,
      value: profitAndLoss,
      positiveColor: true,
    },
    {
      title: getTextByApplication(application).equity,
      value: equity,
    },
    {
      title: 'Balance',
      value: balance,
    },
  ];

  return (
    <Card
      loading={isLoading}
      className="account-profit-and-loss"
      hoverable={false}
    >
      <Flex vertical gap={12}>
        <Flex justify="space-between" align="center" wrap="wrap">
          <TextComponent level={4} noWrap>
            Account Balance
          </TextComponent>
          <DashboardTooltip
            absolute={false}
            tooltipText={getTextByApplication(application).chartTooltip}
          >
            <TextComponent level={8}>What is this difference?</TextComponent>
          </DashboardTooltip>
        </Flex>

        <Flex gap={20} align="center" wrap="wrap">
          {stats.map((stat, index) => (
            <Flex vertical gap={4} key={index}>
              <StatTitle title={stat.title} />
              <StatValue
                value={stat.value}
                positiveColor={stat.positiveColor}
              />
            </Flex>
          ))}
        </Flex>
        <div className="account-profit-and-loss__chart">
          <Line data={data} options={options} />
        </div>
      </Flex>
    </Card>
  );
};

export default AccountBalance;
