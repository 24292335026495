import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Input, InputProps } from 'antd';

export const FormInput: FunctionComponent<
  Omit<FormFieldProps, 'render'> & { props?: InputProps }
> = (formFieldProps) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Input
            size="large"
            placeholder={formFieldProps.placeholder}
            {...field}
            {...formFieldProps.props}
          />
        );
      }}
    />
  );
};
