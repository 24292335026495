import { Button, Pagination, Result, Skeleton } from 'antd';
import { useStore } from '../../helpers/use-store';
import React, { useEffect, useState } from 'react';
import {
  LoadingState,
  useLoading,
  usePagination,
  useResponsiveness,
} from '@monorepo/client-common';
import { Account } from '@monorepo/types';
import AccountItem from '../account-item/account-item';
import { Card, Flex } from '@monorepo/react-components';
import PageContainer from '../page-container/page-container';
import OverviewEmpty from './overview-empty';

type AccountWithBalance = Account & { balance: number };

const Overview = () => {
  const [accounts, setAccounts] = useState<AccountWithBalance[]>([]);
  const {
    dataStore: { accountStore },
  } = useStore();
  const { isLoading, isLoaded, isLoadError, updateLoadingState } = useLoading();
  const { page, setPage, limit, setLimit, total, setTotal } = usePagination();
  const { isMobile } = useResponsiveness();
  const showExpiry = accounts.some((account) => account.expireDate);
  const showEmpty = accounts.length === 0 && isLoaded;

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);

        const { results: accountsResult, total } = await accountStore.paginate({
          page,
          limit,
        });

        setAccounts(accountsResult as AccountWithBalance[]);
        setTotal(total);
      } catch (e) {
        console.error(`Failed fetching accounts`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
    // eslint-disable-next-line
  }, [page, limit]);

  if (isLoadError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  }

  const loader = () => {
    const skeletons = [1, 2, 3, 4];
    return (
      <Flex vertical gap={16}>
        {skeletons.map((s) => (
          <Card key={s} style={{ padding: 24 }}>
            <Flex gap={24} align="center">
              <Skeleton.Avatar shape={'circle'} active size={56} />
              <Flex
                justify={'space-between'}
                flex={1}
                gap={12}
                vertical={isMobile}
              >
                <Skeleton.Input active block={!isMobile} />
                <Skeleton.Input active block={!isMobile} />
                <Skeleton.Input active block={!isMobile} />
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
    );
  };

  return (
    <PageContainer
      helmet="Overview"
      pageTitle="My Programs"
      className="overview"
    >
      {showEmpty && <OverviewEmpty />}
      {isLoading ? (
        loader()
      ) : (
        <Flex gap={16} vertical>
          {accounts.map((account: any, i) => (
            <AccountItem account={account} key={i} showExpiry={showExpiry} />
          ))}
        </Flex>
      )}
      <Flex
        justify={'center'}
        style={{ marginTop: '12px', marginBottom: '12px' }}
      >
        <Pagination
          hideOnSinglePage={true}
          total={total}
          current={page}
          pageSize={limit}
          onChange={(page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          }}
        />
      </Flex>
    </PageContainer>
  );
};

export default Overview;
