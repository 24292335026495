import React, { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from '../../helpers/use-store';
import { App, Badge, Button, Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import NotificationsList from './notifications-center-popup';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import { Icons, IconsNames } from '@monorepo/icons';

const NotificationsCenter: FunctionComponent = () => {
  const {
    dataStore: { userStore },
    uiStore,
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();

  const [open, setOpen] = useState(false);
  const { notification: AppNotification } = App.useApp();
  const { isMobile } = useResponsiveness();

  const handleOpenChange = async (newOpenStatus: boolean) => {
    setOpen(newOpenStatus);
    if (newOpenStatus) {
      updateLoadingState(LoadingState.Loading);
      await userStore.getUserNotifications();
      updateLoadingState(LoadingState.Loaded);
    }
  };

  useEffect(() => {
    if (userStore.notifications.length > 0) {
      const notification =
        userStore.notifications[userStore.notifications.length - 1];

      if (
        notification?.realTime &&
        !uiStore.displayedNotifications.has(notification.id)
      ) {
        AppNotification.info({
          message: (
            <span dangerouslySetInnerHTML={{ __html: notification.message }} />
          ),
          placement: 'bottomRight',
          duration: 5,
        });
        uiStore.displayedNotifications.add(notification.id);
      }
    }
  }, [userStore.notifications.length]);

  return (
    <Popover
      placement={isMobile ? 'bottom' : 'bottomLeft'}
      defaultOpen={true}
      fresh={true}
      content={
        <NotificationsList loading={loadingState === LoadingState.Loading} />
      }
      title={'Notification'}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button
        type={'text'}
        icon={
          <Badge size={'small'} count={userStore.unreadNotifications}>
            <Icons iconName={IconsNames.Bell} />
          </Badge>
        }
      />
    </Popover>
  );
};
export default observer(NotificationsCenter);
