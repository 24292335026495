import { Col, Row, Space } from 'antd';
import {
  CurrencyAvatar,
  FormInput,
  FormSelect,
  TextComponent,
} from '@monorepo/react-components';
import React, { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { formEmailValidation } from '@monorepo/types';
import { CurrencyType, currenciesData } from '@monorepo/client-common';

interface Props {
  form: UseFormReturn<any>;
}

const WiseWithdraw: FunctionComponent<Props> = ({ form }) => {
  return (
    <Row gutter={12}>
      <Col span={12}>
        <FormInput
          placeholder="example@example.com"
          text={'Email'}
          form={form}
          controllerProps={{
            name: 'metadata.email',
            rules: formEmailValidation(),
          }}
        />
      </Col>
      <Col span={12}>
        <FormSelect
          form={form}
          controllerProps={{
            name: 'metadata.currency',
            rules: { required: true },
          }}
          text={'Payout currency'}
          options={currenciesData.map((curr) => ({
            label: (
              <Space>
                <CurrencyAvatar
                  type={CurrencyType.Regular}
                  name={curr.countryCode}
                  size={'small'}
                />
                <TextComponent capitalize={true}>{curr.label}</TextComponent>
              </Space>
            ),
            value: curr.countryCodeExtended,
          }))}
        />
      </Col>
    </Row>
  );
};

export default WiseWithdraw;
