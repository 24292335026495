import { FormCheckbox } from '@monorepo/react-components';
import { NyseFormProps } from '../nyse-modal-content';
import { FunctionComponent } from 'react';
import { Flex } from 'antd';

const NyseStepFour: FunctionComponent<NyseFormProps> = ({ form }) => {
  return (
    <div className={'contracts-steps-body'}>
      <ol>
        <li>
          DISSEMINATION DISCONTINUANCE OR MODIFICATION – Subscriber understands
          and acknowledges that, at any time, the Authorizing SROs may
          discontinue disseminating any category of Market Data, may change or
          eliminate any transmission method and may change transmission speeds
          or other signal characteristics. The Authorizing SROs shall not be
          liable for any resulting liability, loss or damages that may arise
          therefrom.
        </li>
        <li>
          DURATION; SURVIVAL – This Agreement remains in effect for so long as
          Subscriber has the ability to receive Market Data as contemplated by
          this Agreement. In addition, Vendor may terminate this Agreement at
          any time, whether at the direction of the Authorizing SROs or
          otherwise. Paragraphs 2, 3 and 4, and the first two sentences of
          Paragraph 8, survive any termination of this Agreement.
        </li>
        <li>
          MISCELLANEOUS – The laws of the State of New York shall govern this
          Agreement and it shall be interpreted in accordance with those laws.
          This Agreement is subject to the Securities Exchange Act of 1934, the
          rules promulgated under that act, and the joint-industry plans entered
          into pursuant to that act. This writing contains the entire agreement
          between the parties in respect of its subject matter. Subscriber may
          not assign all or any part of this Agreement to any other person. The
          person executing this Agreement below represents and warrants that he
          or she has legal capacity to contract and, if that person is executing
          this Agreement on behalf of a proprietorship or a business,
          partnership or other organization, represents and warrants that he or
          she has actual authority to bind the organization. Last Update:
          November 1, 2013 ACCEPTED AND AGREED: I, the "Subscriber" to which the
          preceding terms and conditions refer, acknowledge that I have read the
          preceding terms and conditions of this Section 1, that I understand
          them and that I hereby manifest my assent to, and my agreement to
          comply with, those terms and conditions by "clicking" on the following
          box:
        </li>
        <li>
          <Flex gap={10}>
            <FormCheckbox
              form={form}
              controllerProps={{ name: 'firstAgreement' }}
            />

            <span>Agree</span>
          </Flex>
        </li>
      </ol>
    </div>
  );
};

export default NyseStepFour;
