import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { EntityStore } from '../base/entity-store';
import { Partner } from '@monorepo/types';
import PartnerService from '../../../services/entities/partner-service';

export class PartnerStore extends EntityStore<PartnerService, Partner> {
  constructor() {
    const {
      apiFactory: { partnerService },
    } = getEnv();

    super(partnerService);
  }
}
