import { FunctionComponent } from 'react';
import { useStore } from '../../../../helpers/use-store';
import InactiveDaysRule from '../account-rules/inactive-days/inactive-days-rule';
import InactiveDaysProgress from '../progress-cards/inactive-days-progress';

export enum DashboardCardType {
  Rule = 'rule',
  Progress = 'progress',
}

interface Props {
  accountId: string;
  programId: string;
  type: DashboardCardType;
  consecutiveInactivityDays: number;
  isLoading?: boolean;
}

export interface InactiveDayCardProps {
  inactivityDays: number;
  maxInactiveDays: number;
  tooltipText: string;
  isLoading?: boolean;
}

const InactiveDays: FunctionComponent<Props> = ({
  programId,
  type,
  consecutiveInactivityDays = 0,
  isLoading,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const program = programStore.get(programId);

  const maxInactiveDays = program?.config?.inactivityDays || 0;

  const tooltipText = `Accounts with no new orders for more than ${maxInactiveDays} consecutive calendar days will expire.`;

  const InactiveDayCard =
    type === DashboardCardType.Rule ? InactiveDaysRule : InactiveDaysProgress;

  return (
    <InactiveDayCard
      inactivityDays={consecutiveInactivityDays}
      maxInactiveDays={maxInactiveDays}
      tooltipText={tooltipText}
      isLoading={isLoading}
    />
  );
};

export default InactiveDays;
