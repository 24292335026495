import classNames from 'classnames';
import { Button as AntButton, ButtonProps } from 'antd';
import { FunctionComponent } from 'react';
import { useResponsiveness } from '@monorepo/client-common';

import './button.scss';

export type { ButtonProps };

const Button: FunctionComponent<ButtonProps> = (props) => {
  const { isMobile } = useResponsiveness();

  return (
    <AntButton
      className={classNames('the5ers-button', {
        'the5ers-button--mobile': isMobile,
      })}
      {...props}
    />
  );
};

export { Button };
