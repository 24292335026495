import { message, Modal, StatisticProps, Tabs } from 'antd';
import { Button, Flex } from '@monorepo/react-components';
import { Statistic, TextComponent } from '@monorepo/react-components';
import { useStore } from '../../helpers/use-store';
import { useEffect, useState } from 'react';
import {
  Credit as CreditType,
  CreditTransaction as CreditTransactionType,
} from '@monorepo/types';
import { CreditTransaction } from './credit-transaction/credits-transaction';
import {
  LoadingState,
  useLoading,
  useThemeToken,
} from '@monorepo/client-common';
import { observer } from 'mobx-react-lite';
import CountUp from 'react-countup';
import { Credits } from './credits/credits';
import CreditIcon from '../../components/credit-icon/credit-icon';

import './hub-credits.scss';

const formatter: StatisticProps['formatter'] = (value) => (
  <CountUp end={value as number} separator="," decimals={2} />
);

const HubCredits = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  const [visible, setVisibility] = useState<boolean>(false);
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Loading);
  const [transactionsHistory, setTransactionsHistory] = useState<
    CreditTransactionType[]
  >([]);
  const [credits, setCredits] = useState<CreditType[]>([]);
  const [creditsHistorySum, setCreditsHistorySum] = useState<number>(0);

  const { token } = useThemeToken();

  const loading = loadingState === LoadingState.Loading;

  const creditsBalance = userStore.currentUser.creditBalance || 0;
  const creditColor =
    creditsBalance < 0 ? token.colorError : token.colorPrimary;

  const initCreditHistoryModal = async () => {
    try {
      setVisibility(true);

      const creditsHistory = await userStore.getCreditsHistory();

      setTransactionsHistory(creditsHistory.transactions);
      setCredits(creditsHistory.credits);

      const creditsHistorySum = creditsHistory.credits
        .filter((credit) => !credit.expired)
        .reduce((acc, curr) => acc + curr.amount - curr.used, 0);

      setCreditsHistorySum(creditsHistorySum);
      updateLoadingState(LoadingState.Loaded);
    } catch (e) {
      message.error('Something went wrong.');
      updateLoadingState(LoadingState.Error);
      setVisibility(false);
    }
  };

  useEffect(() => {
    if (!visible) {
      return;
    }
    const isCreditsInSync =
      userStore.currentUser.creditBalance === creditsHistorySum;
    if (!isCreditsInSync) {
      userStore.updateCredits();
    }
  }, [creditsHistorySum]);

  return (
    <>
      <Button type="text" onClick={() => initCreditHistoryModal()}>
        <TextComponent
          className="credits-history-button"
          level={7}
          responsive={false}
          weight={700}
          color={creditColor}
        >
          <Statistic
            value={creditsBalance}
            precision={2}
            formatter={formatter}
            prefix={<CreditIcon />}
          />
        </TextComponent>
      </Button>

      <Modal
        className="credits-history-modal"
        open={visible}
        width={800}
        onCancel={() => setVisibility(false)}
        footer={
          <Flex gap={6}>
            <TextComponent>Total available:</TextComponent>
            <TextComponent color={creditColor}>
              <Statistic value={creditsBalance} precision={2} prefix={'$'} />
            </TextComponent>
          </Flex>
        }
      >
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: 'Transactions',
              key: '1',
              children: (
                <CreditTransaction
                  item={transactionsHistory}
                  loading={loading}
                />
              ),
            },
            {
              label: 'Available credit',
              key: '2',
              children: <Credits items={credits} loading={loading} />,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default observer(HubCredits);
