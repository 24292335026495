export enum ConsistencyRules {
  BestTradeProfitLimit = 'best-trade-profit-limit',
  ProfitableDays = 'profitable-days',
  MinimumTrades = 'minimum-trades',
}

export interface ConsistencyRuleResult {
  isValid: boolean;
  current: number;
}

export interface ConsistentRulesConfig {
  rule: ConsistencyRules;
  value: number;
}

export interface BestTradeProfitLimitConfig extends ConsistentRulesConfig {}
export interface MinimumTradeDifferenceBetweenEntryAndExitConfig
  extends ConsistentRulesConfig {}
export interface ProfitableDaysConfig extends ConsistentRulesConfig {}
export interface MinimumTradesConfig extends ConsistentRulesConfig {}
export type ProfitableDaysResult = { profitableDays: Date[] };
export type ConsistencyRulesValues = ProfitableDaysResult;
