import { FunctionComponent, ReactNode } from 'react';
import { Account, AccountType, PagePath, ThemeColors } from '@monorepo/types';
import { Col, Row } from 'antd';
import {
  TextComponent,
  Statistic,
  Card,
  Flex,
  Button,
  Divider,
} from '@monorepo/react-components';
import dayjs from 'dayjs';
import { useResponsiveness } from '@monorepo/client-common';
import { useStore } from '../../helpers/use-store';
import { useNavigate } from 'react-router-dom';
import { Icons, IconsNames } from '@monorepo/icons';
import { ThemeWrapper } from '../../components/theme-wrapper/theme-wrapper';
import { useColor } from '../../hooks';
import classNames from 'classnames';

import './account-item.scss';

const styling: Record<
  AccountType,
  {
    icon: IconsNames;
    color: ThemeColors;
  }
> = {
  [AccountType.Evaluation]: {
    icon: IconsNames.ChartStepper,
    color: ThemeColors.colorPrimary,
  },
  [AccountType.Demo]: {
    icon: IconsNames.CloudAuditing,
    color: ThemeColors.colorInfo,
  },
  [AccountType.Funded]: {
    icon: IconsNames.CoinBag,
    color: ThemeColors.colorSuccess,
  },
  [AccountType.Competition]: {
    icon: IconsNames.Trophy,
    color: ThemeColors.colorError,
  },
};

type AccountWithBalance = Account & { balance: number };

const AccountItem: FunctionComponent<{
  account: AccountWithBalance;
  showExpiry: boolean;
}> = ({ account, showExpiry }) => {
  const { isDesktop } = useResponsiveness();
  const { getColor } = useColor();
  const navigate = useNavigate();
  const {
    dataStore: { programStore },
  } = useStore();

  const program = programStore.get(account.programId);
  const displayProgramName =
    program?.name.split(' ').slice(0, 3).join(' ') || '';

  const displayAccountID = account.tsAccount?.split('accountName').join('');

  const Content: FunctionComponent<{
    title: string;
    value: string | ReactNode;
  }> = ({ title, value }) => (
    <Flex
      vertical={isDesktop}
      justify="space-between"
      align={isDesktop ? 'flex-start' : 'center'}
    >
      <TextComponent className="account-item__title" level={isDesktop ? 8 : 9}>
        {title}
      </TextComponent>
      <TextComponent noWrap capitalize weight={isDesktop ? 500 : 600} level={6}>
        {value}
      </TextComponent>
    </Flex>
  );

  return (
    <Card
      className={classNames('account-item', {
        'account-item-mobile': !isDesktop,
      })}
      onClick={() => navigate(`${PagePath.Overview}/${account.tsAccount}`)}
    >
      <Row
        gutter={[5, 8]}
        className={'information'}
        justify="space-between"
        align={'middle'}
      >
        <Col xs={24} lg={8} xxl={4}>
          <Flex align="center" gap={14}>
            <Flex
              justify="center"
              align="center"
              className="avatar"
              style={{
                backgroundColor: getColor(styling[account.type].color),
              }}
            >
              <Icons iconName={styling[account.type].icon} />
            </Flex>
            <TextComponent
              noWrap
              capitalize
              level={isDesktop ? 4 : 3}
              responsive={false}
              weight={isDesktop ? 500 : 700}
            >
              {account.type}
            </TextComponent>
          </Flex>
        </Col>

        {!isDesktop && <Divider className="divider" />}

        <Col xs={24} lg={8} xxl={2}>
          <Content title="Account ID" value={displayAccountID} />
        </Col>

        {program && (
          <Col xs={24} lg={8} xxl={2}>
            <Content
              title="Account size"
              value={
                <Statistic
                  value={program.config.buyingPower}
                  precision={2}
                  prefix={'$'}
                />
              }
            />
          </Col>
        )}
        <Col xs={24} lg={8} xxl={4}>
          <Content title="Program name" value={displayProgramName} />
        </Col>
        <Col xs={24} lg={8} xxl={2}>
          <Content title="Account status" value={account.status} />
        </Col>
        <Col xs={24} lg={8} xxl={3}>
          <Content
            title="Balance"
            value={
              <Statistic value={account.balance} precision={2} prefix={'$'} />
            }
          />
        </Col>

        {showExpiry && (
          <Col xs={24} lg={8} xxl={3}>
            <Content
              title="Expiry date"
              value={
                account.expireDate
                  ? dayjs(account.expireDate).format('MMM DD YYYY')
                  : '-----'
              }
            />
          </Col>
        )}
        <Col xs={24} md={24} xxl={3}>
          <ThemeWrapper themeColor={styling[account.type].color}>
            <Button
              ghost
              className="button"
              type="primary"
              size={isDesktop ? 'large' : 'middle'}
            >
              Open Dashboard
            </Button>
          </ThemeWrapper>
        </Col>
      </Row>
    </Card>
  );
};

export default AccountItem;
