import {
  Card,
  Divider,
  Flex,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { CurrencyToSign, SupportedCurrencies } from '@monorepo/types';
import { FunctionComponent } from 'react';

interface Props {
  productName?: string;
  currency: string;
  paymentDataAmount?: number;
  amount?: number;
  fee?: number;
  discountPercentage?: number;
  originalAmount?: number;
  creditsUsed?: number;
}

const SummaryWidgetCard: FunctionComponent<Props> = ({
  currency,
  productName = '-',
  paymentDataAmount = 0,
  amount = 0,
  fee = 0,
  discountPercentage = 0,
  originalAmount = 0,
  creditsUsed = 0,
}) => {
  let currencySign = currency
    ? CurrencyToSign[currency]
    : CurrencyToSign.DOLLAR;

  const exchangeRate =
    amount > 0 && paymentDataAmount > 0 ? paymentDataAmount / amount : 1;

  const originalProductCurrencyPrice = originalAmount * exchangeRate;

  const subtotal = amount ? (amount - creditsUsed) * exchangeRate : 0;

  const total = paymentDataAmount;

  if (creditsUsed >= subtotal) {
    currencySign = CurrencyToSign[SupportedCurrencies.USD];
  }

  const creditsUsedInCurrency = creditsUsed * exchangeRate;

  return (
    <Card className="summary-widget" hoverable={false}>
      <Flex gap={12} vertical>
        <Flex justify="space-between" align="center">
          <TextComponent level={8} responsive={false} weight={700}>
            {productName}
          </TextComponent>
          <TextComponent level={8} responsive={false} weight={700}>
            <Statistic
              prefix={currencySign}
              value={originalProductCurrencyPrice}
              precision={2}
            />
          </TextComponent>
        </Flex>

        <Flex justify="space-between" align="center">
          <TextComponent level={8} responsive={false}>
            Currency
          </TextComponent>
          <TextComponent level={8} responsive={false}>
            {currency}
          </TextComponent>
        </Flex>

        <Flex justify="space-between" align="center">
          <TextComponent level={8} responsive={false}>
            Credits applied
          </TextComponent>
          <TextComponent level={8} responsive={false}>
            <Statistic
              precision={2}
              value={creditsUsedInCurrency}
              prefix={currencySign}
            />
          </TextComponent>
        </Flex>

        <Flex justify="space-between" align="center">
          <TextComponent level={8} responsive={false}>
            Discount
          </TextComponent>
          <TextComponent level={8} responsive={false}>
            <Statistic precision={0} value={discountPercentage} suffix="%" />
          </TextComponent>
        </Flex>

        <Flex justify="space-between" align="center">
          <TextComponent level={8} responsive={false}>
            Fee
          </TextComponent>
          <TextComponent level={8} responsive={false}>
            <Statistic prefix={currencySign} value={fee} precision={2} />
          </TextComponent>
        </Flex>

        <Flex justify="space-between" align="center">
          <TextComponent level={8} responsive={false}>
            Subtotal
          </TextComponent>
          <TextComponent level={8} responsive={false}>
            <Statistic prefix={currencySign} value={subtotal} precision={2} />
          </TextComponent>
        </Flex>

        <Divider className="summary-widget__divider" />

        <Flex justify="space-between" align="center">
          <TextComponent level={6} responsive={false}>
            Total
          </TextComponent>
          <TextComponent weight={700} level={3} responsive={false}>
            <Statistic prefix={currencySign} value={total} precision={2} />
          </TextComponent>
        </Flex>
      </Flex>
    </Card>
  );
};
export default SummaryWidgetCard;
