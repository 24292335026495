import { App, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import { useForm } from 'react-hook-form';
import { PagePath, ThemeColors, formEmailValidation } from '@monorepo/types';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import { Flex, FormInput, TextComponent } from '@monorepo/react-components';
import PageContainer from '../page-container/page-container';
import classNames from 'classnames';
import { ThemeWrapper } from '../../components/theme-wrapper/theme-wrapper';

interface ForgetPasswordPayload {
  email: string;
}

const ForgetPassword = () => {
  const { message } = App.useApp();
  const {
    dataStore: { userStore },
  } = useStore();
  const navigate = useNavigate();
  const { isDesktop } = useResponsiveness();
  const form = useForm<ForgetPasswordPayload>();
  const { loadingState, updateLoadingState } = useLoading();

  const sendingInProgress = loadingState === LoadingState.Loading;

  const goBack = () => {
    navigate(PagePath.Root);
  };

  const onFinish = async (values: ForgetPasswordPayload) => {
    try {
      updateLoadingState(LoadingState.Loading);

      const result = await userStore.forgetPassword(values.email);

      if (!result) {
        throw new Error(`failed resetting password`);
      }

      updateLoadingState(LoadingState.Loaded);

      message.success('Reset Link has been sent to your email');
    } catch (e) {
      updateLoadingState(LoadingState.Error);
      message.error('Failed sending reset link');
    }
  };

  return (
    <PageContainer helmet="Forget Password" className="forget-password">
      <Flex vertical gap={32}>
        <Flex vertical gap={6} align={isDesktop ? 'flex-start' : 'center'}>
          <TextComponent
            weight={600}
            className={classNames('title', {
              'title--mobile': !isDesktop,
            })}
          >
            Forgot Your Password?
          </TextComponent>
          <TextComponent level={3} className="sub-text">
            {` Don’t worry, we will send you a rest link`}
          </TextComponent>
        </Flex>
        <form name="forgot-password" onSubmit={form.handleSubmit(onFinish)}>
          <Flex vertical gap={16}>
            <FormInput
              placeholder="Email"
              form={form}
              controllerProps={{
                name: 'email',
                rules: formEmailValidation(),
              }}
            />
            <ThemeWrapper themeColor={ThemeColors.colorSecondary}>
              <Button
                loading={sendingInProgress}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Send Reset Link
              </Button>
              <Button block type="default" ghost onClick={goBack}>
                Go Back to Sign in
              </Button>
            </ThemeWrapper>
          </Flex>
        </form>
      </Flex>
    </PageContainer>
  );
};

export default ForgetPassword;
