import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';

const useExportDocuments = () => {
  const componentRef = useRef<any>();
  const [inProgress, setInProgress] = useState(false);

  const handleDownloadPdf = async (name: string) => {
    setInProgress(true);
    const invoiceName = `${name + '-' + Date.now()}.pdf`;
    try {
      await generatePDF(componentRef, {
        filename: invoiceName,
        method: 'save',
        resolution: Resolution.HIGH,
        page: { format: 'dl', margin: Margin.MEDIUM },
        canvas: {
          mimeType: 'image/png',
          qualityRatio: 1,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setInProgress(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: portrait;
      }
    `,
  });

  return { componentRef, handleDownloadPdf, handlePrint, inProgress };
};
export default useExportDocuments;
