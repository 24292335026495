export enum ContractType {
  Funded = 'funded',
  Affiliation = 'affiliation',
  Nyse = 'nyse',
}

export enum ContractStatus {
  Requested = 'requested',
  Signed = 'signed',
}

export interface Contract {
  type: ContractType;
  userId: string;
  status: ContractStatus;
  version: number;
  data?: ContractData;
}

export enum ContractTitle {
  Funded = 'Funded Contract',
  Affiliation = 'Affiliation Contract',
  Nyse = 'nyse',
}

export type NyseContractData = {
  firstAgreement: boolean;
  receiveDataNonBusinessUse: boolean;
  hasSECOrCFTC: boolean;
  securityAgency: boolean;
  similarToSECOrCFTC: boolean;
  investmentAdvices: boolean;
  assetManager: boolean;
  usingOtherCapital: boolean;
  tradeForOtherCorpOrOther: boolean;
  sharingProfit: boolean;
  sharingSpace: boolean;
  firstName: string;
  lastName: string;
  address: string;
  occupations: string;
  companyName: string;
  employer: string;
  employerAddress: string;
  title: string;
  signThatProvidedDataIsCorrect: boolean;
  finalAgreement: boolean;
};

export type ContractData = NyseContractData;
