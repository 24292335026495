import { FormInput } from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import { NyseFormProps } from '../nyse-modal-content';
import { Row } from 'antd';

const NyseStepEight: FunctionComponent<NyseFormProps> = ({ form }) => {
  return (
    <div className={'contracts-steps-body'}>
      <div>
        <p>
          11. PERMITTED USE – If Subscriber is a Nonprofessional Subscriber, he
          or she shall receive Market Data solely for his or her personal,
          non-business use. 12. PERSONAL AND EMPLOYMENT DATA – As a prerequisite
          to qualifying as a "Nonprofessional Subscriber", Subscriber shall
          provide the following information:
        </p>

        <Row justify={'space-evenly'} align={'middle'}>
          <FormInput
            text={'First name'}
            form={form}
            controllerProps={{ name: 'firstName' }}
          />
          <FormInput
            text={'Last name'}
            form={form}
            controllerProps={{ name: 'lastName' }}
          />
        </Row>
        <Row justify={'space-evenly'} align={'middle'}>
          <FormInput
            text={'Address'}
            form={form}
            controllerProps={{ name: 'address' }}
          />
          <FormInput
            text={'Occupations'}
            form={form}
            controllerProps={{ name: 'occupations' }}
          />
        </Row>
        <Row justify={'space-evenly'} align={'middle'}>
          <FormInput
            text={'Company name'}
            form={form}
            controllerProps={{ name: 'companyName' }}
          />
          <FormInput
            text={'Employer'}
            form={form}
            controllerProps={{ name: 'employer' }}
          />
        </Row>
        <Row justify={'space-evenly'} align={'middle'}>
          <FormInput
            text={'Employer address'}
            form={form}
            controllerProps={{ name: 'employerAddress' }}
          />
          <FormInput
            text={'Title'}
            form={form}
            controllerProps={{ name: 'title' }}
          />
        </Row>
        <Row justify={'space-evenly'} align={'middle'}>
          <FormInput
            text={'Employment functions'}
            form={form}
            controllerProps={{ name: 'employmentFunctions' }}
          />
        </Row>

        <p>
          Subscriber shall notify Vendor promptly in writing of any change in
          his or her circumstances that may cause him or her to cease to qualify
          as a Nonprofessional Subscriber or that may change his or her response
          to any of the preceding questions.
        </p>
      </div>
    </div>
  );
};

export default NyseStepEight;
