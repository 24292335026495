import {
  Card,
  Col,
  Flex,
  ProgressSlider,
  Row,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent, useEffect, useState } from 'react';
import { IconsNames } from '@monorepo/icons';
import { AccountRulesCardIcon } from '../account-rules-icon/account-rules-icon';
import { ConsistencyRules, ThemeColors } from '@monorepo/types';
import { useColor } from '../../../../../hooks';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { useStore } from '../../../../../helpers/use-store';

import '../account-rules.scss';

interface Props {
  programId: string;
  accountId: string;
}

const MinimumTradesRuleCard: FunctionComponent<Props> = ({
  programId,
  accountId,
}) => {
  const { getColor } = useColor();
  const { isLoading, updateLoadingState } = useLoading();
  const [currentTradesNumber, setCurrentTradesNumber] = useState(0);

  const {
    dataStore: { programStore, positionStore },
  } = useStore();

  const minimumTradesRule = programStore.getRule(
    programId,
    ConsistencyRules.MinimumTrades
  );

  const requiredTradesNumber = minimumTradesRule ? minimumTradesRule.value : 0;

  const completeInPercentage = requiredTradesNumber
    ? (currentTradesNumber / requiredTradesNumber) * 100
    : 0;

  useEffect(() => {
    const init = async () => {
      if (requiredTradesNumber <= 0) {
        return;
      }
      try {
        updateLoadingState(LoadingState.Loading);
        const positions = await positionStore.getValidPositionsCount(accountId);

        setCurrentTradesNumber(positions.valid);
      } catch (error) {
        console.error(error);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };
    init();
  }, [requiredTradesNumber]);

  if (!requiredTradesNumber) {
    return null;
  }

  return (
    <Card hoverable={false} dashed className={'account-rules'}>
      <Row gutter={[5, 10]}>
        <Col span={8} sm={24}>
          <Row
            gutter={[0, 15]}
            justify={'space-between'}
            className={'account-rules__header'}
          >
            <Col span={18}>
              <Flex gap={5} align={'center'} wrap="wrap">
                <AccountRulesCardIcon iconName={IconsNames.Room} />

                <TextComponent
                  responsive={false}
                  color={getColor(ThemeColors.colorPrimary)}
                  weight={600}
                >
                  Minimum trades
                </TextComponent>
              </Flex>
            </Col>
          </Row>
        </Col>

        <Col span={16} sm={24}>
          <Flex
            justify={'end'}
            vertical
            className={'account-rules__slider-section'}
          >
            <Flex justify="space-between" align="end">
              <Flex align="center">
                <TextComponent
                  loading={isLoading}
                  weight={600}
                  level={3}
                  responsive={false}
                >
                  {currentTradesNumber}/
                </TextComponent>
                <TextComponent level={3} responsive={false}>
                  {requiredTradesNumber}
                </TextComponent>
              </Flex>
              <TextComponent loading={isLoading} level={4} responsive={false}>
                {completeInPercentage?.toFixed(0)}%
              </TextComponent>
            </Flex>

            <ProgressSlider
              disabled
              value={currentTradesNumber}
              trackColor={ThemeColors.colorSuccess}
              max={requiredTradesNumber}
            />
            <TextComponent level={8} responsive={false}>
              Should have minimum {requiredTradesNumber} trades
            </TextComponent>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default MinimumTradesRuleCard;
