import { useStore } from '../../../helpers/use-store';

const FundedModalContentTtp = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const userName = `${userStore.currentUser.firstName || ''} ${
    userStore.currentUser.lastName || ''
  }`;

  const email = userStore.currentUser.email;

  return (
    <div className={'contracts-steps-body funded'}>
      <h3
        style={{
          textDecoration: 'underline',
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        Funded Account Terms
      </h3>
      <p>
        These Funded Account Terms (the <strong>"Funded Terms"</strong>) apply
        to those Users who graduated from Company's evaluation program known as
        "Evaluation" and successfully passed the User Verification Process and
        accepted these Funded Terms (the <strong>"Funded User"</strong>).
      </p>
      <p>
        The Funded Terms supplement the Company’s Terms of Use (which can be
        found at{' '}
        <a href="https://tradethepool.com/terms-and-conditions">
          https://tradethepool.com/terms-and-conditions
        </a>
        ) (the
        <strong>“Terms”</strong>) and Privacy Policy (a current copy which can
        be found at{' '}
        <a href="https://tradethepool.com/privacy-policy">
          https://tradethepool.com/privacy-policy
        </a>
        ) in effect from time to time, each of which remain in full force and
        effect, and apply to the Funded User and govern the Funded User’s access
        to and the use of the Website, the Hub or Services. All capitalized
        terms not otherwise defined herein shall have the meanings set forth in
        the Terms.
      </p>
      <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
        1. 1. Funded Account
      </span>
      <p>
        1.1 The Company shall provide the User with a Funded Account to share
        their trading skills (the <strong>"Funded Trading"</strong>) through the
        trading platform.
      </p>
      <p>
        1.2 The Funded User is aware that the Company monitors and analyzes the
        trades and executes certain trades using Company's own funds in
        real-world trading environments. Such Company trades may be based on the
        trades performed by the market order suggestions of the Funded User,
        based on its sole discretion. The Company evaluates all users' market
        order suggestions using its risk routers, and it retains exclusive
        authority to define its risk policy. However, it is crucial to note that
        the Funded User's experience should remain unaffected by the Company's
        discretion; Funded Users will be rewarded based on the overall results
        of their market order suggestions, specifically their total profit and
        loss. The Funded Users grant all rights and licenses to the Company in
        the trades and trading strategy employed by them as part of the Funded
        Trading and waive any claims as to the use of the Company of the trades
        or the trading strategies in performing its real life trades or
        developing its trading strategies.
      </p>
      <p>
        1.3 The Funded User has no ownership interest in the Funded Account and
        no control over or right to perform such trades in a real trading
        environment using Company funds. The Funded User has no right or
        entitlement to engage in trading on the trading platform except as
        permitted by the Company, and the privileges of the Funded User may be
        terminated by the Company at any time for any reason or no reason in the
        Company’s sole and absolute discretion. The Company may freeze or limit
        trading at any time in its sole discretion. It is hereby clarified that
        Nothing in these Funded Terms shall be deemed as preventing the Funded
        User from simultaneously trading on other trading platforms.
      </p>
      <p>
        1.4 The Funded User acknowledges that, once the Funded User is permitted
        to engage in trading, Funded User shall trade only using Funded User’s
        assigned user id and login information. Funded User shall maintain such
        user id and log-in information in confidence and such information shall
        be considered Confidential Information.
      </p>
      <p>
        1.5 In connection with Trading, the Trading Rules detailed in the Terms
        and the Prohibited Conduct (for more details please refer to{' '}
        <a href="https://tradethepool.com/prohibited-trading-practices">
          https://tradethepool.com/prohibited-trading-practices
        </a>
        ) continue to apply. The Funded User acknowledges and agrees that Funded
        User is solely responsible for staying current on Trading Rules and
        Prohibited Conduct, which are subject to change at any time and from
        time to time, with or without notice.
      </p>
      <p>
        1.6 Trading is subject to the trading hours of the exchanges for each
        asset, as every trading asset has different trading hours. Traders are
        only permitted to trade permitted products by the Company.{' '}
      </p>
      <p>
        Additionally, to deter and avoid violations of the Trading Rules and
        Prohibited Conduct, your positions will automatically be flattened
        (e.g., closed out) just prior to the end of the then current trading
        session as determined by the Company.
      </p>
      <p>
        If the Company, in its sole discretion, determines a Funded User has
        violated any Trading Rules or engaged in Prohibited Conduct, the Company
        may, in its sole determination, remove any profits, delete a trading
        day, reset a Funded Account, or ban a User from any further use of the
        Services.
      </p>
      <p>
        1.7 The Company reserves the right to assign a different funded program
        to the User than the one originally selected and completed during the
        Evaluation stage. This reassignment may occur if the User fails to meet
        the conditions for the particular program or due to other risk
        considerations. Additionally, the Company reserves the right to change
        the terms of the assigned Funded program entirely at its discretion,
        based on the Company's risk considerations. The assigned Funded program
        is not binding and may be modified according to the Company's
        discretion. The Funded User must continue trading with the same trading
        characteristics used during the Evaluation stage, which led to the
        successful completion of the Evaluation.
      </p>
      <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
        2. Representations, Warranties, and Covenants.
      </span>
      <p>
        Funded User represents, warrants, and covenants to the Company as
        follows:
      </p>
      <p>2.1 User acknowledges and agrees:</p>
      <p>
        a) No performance provided by the Company to the Funded User, including
        all Services, may be considered as investment services or activities
        within the meaning of the legal regulations governing, in particular,
        investment services and capital markets; in particular, the Company does
        not receive or execute in any case, whether directly or indirectly, any
        trading instructions, does not provide or conduct investment or other
        advice, research, recommendations or analyses, and does not give or
        forward to Funded User any trading instructions, does not receive any
        assets from, or manage any assets for, the Funded User, and does not
        provide any related market or exchange services.
      </p>
      <p>
        b) No employees or other staff or representatives of the Company are
        authorized to provide services within the meaning of Section 2.1.
      </p>
      <p>
        c) All market order suggestions that the Funded User performs through
        User’s Funded Account shall in no way constitute an instruction to trade
        in real financial markets or with real currency.
      </p>
      <p>
        d) Performance in Trading is not indicative of trading in real financial
        markets, and that past performance and any earned Reward are neither a
        guarantee nor an indication of any future performance.
      </p>
      <p>
        e) If a Funded User's access to the Funded Account is canceled for any
        or no reason User shall lose access to the Funded Account and the
        Services, without being entitled to any compensation.
      </p>
      <p>
        f) Excessive or unrealistic single day or single trade profits (as
        determined at the Company’s sole discretion) will be subject to review
        by the Company for any possible violations of Trading Rules or engaging
        in any Prohibited Use.
      </p>
      <p>
        g) The Funded User has the ability, means, knowledge and experience
        needed for the fulfillment of its undertakings according to this
        Agreement.
      </p>
      <p>
        h) The Company reserves the right to require Funded Users to apply
        specific risk parameters to accurately assess risk within their
        Accounts. These parameters may include, but are not limited to, leverage
        limits or position size constraints. It is important to note that these
        requirements may be temporary in nature and subject to periodic review
        and adjustment by the Company as necessary.
      </p>
      <p>
        i) In the event of extreme and unusual market movements, also known as
        “Black Swan” events, such as, but not limited to, Flash Crashes, Natural
        disasters, Technological Disruptions, Political Upheavals, Terrorist
        Attacks, Biological Events, Financial System Meltdowns and more, the
        Company may not be able to cover any losses or gains incurred. The
        Company reserves the right to exclude or adjust for such extraordinary
        market movements when calculating any gains or losses.
      </p>
      <p>
        j) To continue trading with the same characteristics used during the
        Evaluation, which led to successful completion of the Evaluation.
      </p>
      <p>
        2.2 In performing this Agreement, User shall (i) comply with all
        applicable legal regulations; (ii) if required under applicable law,
        obtain, and maintain the validity of, all permits, licenses, consents,
        or approvals, which may apply to User's activities under this Agreement;
        and (iii) fulfill all notification, tax, fee, or other obligations that
        may arise in connection with the performance of this Agreement, and in
        particular with the Reward (defined in Section 3). The User is
        authorized to access and use the Services only in accordance with the
        applicable legal regulations and is prohibited from circumventing any
        technical measures that may limit the availability of the Services in
        certain countries. In no event shall the Company bear any responsibility
        for the failure of User to fulfill any of its obligations.
      </p>
      <p>
        2.3 The Funded Account and any and all rights related to the Services
        are personal to the Funded User and cannot be transferred by contract or
        otherwise. The Funded User is prohibited from using their account for
        any commercial or marketing purposes, including but not limited to
        selling services based on the Funded Account or teaching others how to
        pass the Evaluation stage. Any commercial exploitation of the Funded
        Account will be grounds for termination of the Funded User account and
        may also lead to termination of the User account on the Hub.
      </p>
      <p>
        2.4 It is not the purpose of the Parties to establish employer –
        employee relations and the User shall use the Services independently,
        and the Reward setout in these Funded Terms constitute the full and
        final consideration for the Funded Users under this legal status.
      </p>
      <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
        3. Reward
      </span>
      <p>
        In connection with any Trading conducted by the Funded User, the Funded
        User will be rewarded as follows:
      </p>
      <p>
        3.1 <span style={{ textDecoration: 'underline' }}>Allocation</span>.
        User shall be entitled to receive a reward, as Funded User’s sole
        compensation/consideration (the <strong>“Reward”</strong>) calculated as
        an allocation of accrued Trading Profits (net of Trading Losses)
        pursuant to the Trading Rules. Funded User shall not be entitled to any
        compensation, salary, or other benefit except the appropriate Reward, if
        any. As used herein, “Reward Balance” means, as to a given Funded User,
        the total accrued and unpaid Reward of such Funded User. The profit
        split of the Reward Balance shall be determined according to the program
        selected by the User in the Evaluation stage, as detailed in the program
        web page, or at the Company's discretion, based on risk assessments. In
        order to have a different profit split the Funded User has to
        successfully complete the Evaluation in a different program.
        Notwithstanding anything to the contrary, (i) a User’s Reward Balance
        shall at no time exceed the maximum balance specified in each program,
        and (ii) no Trading Profits shall be credited to a User’s Reward Balance
        in excess of the Maximum Balance (with any such profits in excess of the
        Maximum Balance being disregarded and inapplicable to any future
        calculation of a Reward Balance or Trading Profits).
      </p>
      <p>
        3.2{' '}
        <span style={{ textDecoration: 'underline' }}>
          Calculation of Reward.
        </span>{' '}
        Amounts deemed by the Company to approximate customary market charges
        for commissions and fees shall be imputed to Trading and netted out of
        the determination of any Trading Profits (defined below) and added to
        the balance of any Trading Losses (defined below). At the time of
        determination, (A) if after netting User’s gross rewards and expenses
        from the initial balance of User’s Funded Account at the commencement of
        a trading day (the <strong>“Daily Starting Balance”</strong>) the result
        is in excess of such Daily Starting Balance, such amount in excess of
        the Daily Starting Balance shall represent hypothetical{' '}
        <strong>“Trading Profits”</strong>, and (B) if after netting User’s
        gross rewards and expenses from the Daily Starting Balance the result is
        less than the Daily Starting Balance, the difference between such result
        and the Daily Starting Balance shall represent hypothetical{' '}
        <strong>“Trading Losses”</strong>.
      </p>
      <p>
        3.3 <span style={{ textDecoration: 'underline' }}>Payout.</span> No
        payout to User shall be made except with the approval of the Company in
        its reasonable discretion. Payouts will generally be disbursed to the
        Funded User within 7 business days of the date the Company receives the
        payout request. In the event of any technical failure causing a delay in
        the payment, the Funded User will be notified by the Company, and a
        solution will be implemented as soon as practicable. Cryptocurrency
        payouts will be sent exclusively to the crypto wallet address originally
        provided by the User. Users who do not provide appropriate payment
        details will not be able to receive their Rewards. Notwithstanding
        anything to the contrary, the Company shall not be responsible and have
        no liability for any failure or inability to timely disburse any payout.
        The Company may, at its sole and absolute discretion, require User to
        withdraw some or all of User’s accrued and unpaid Rewards. Each payout
        shall reduce the balance of User’s Funded Account by the corresponding
        amount of Trading Profits used to calculate the distributed Rewards.
      </p>
      <p>
        3.4 If User engages in any of the Prohibited Conduct, violate the
        Trading Rules or if any discrepancies are discovered in the User
        Verification Process, the Company may, in its sole discretion and
        without limitation to all other rights and remedies, remove applicable
        trades and/or trading days from User’s Funded Account and not credit the
        profits and/or losses of such trades toward the calculation of any
        Trading Profits or Trading Losses.
      </p>
      <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
        4. Termination Rights
      </span>
      <p>
        4.1 These Terms shall apply to the Funded User's activity in the Funded
        Account.
      </p>
      <p>
        4.2 In the event that the Funded User fails to meet the trading targets
        in the Funded Account in accordance with the terms of the applicable
        program, the Funded Account will be terminated for that program.
      </p>
      <p>
        4.3 If the Company, in its sole discretion, believes that User has
        abandoned their Funded Account (being deemed abandoned with 60 or more
        consecutive calendar days without any trades made), the Company may,
        with or without notice, permanently close User's Funded Account. Upon
        closure due to inactivity, the User will have 90 days to claim any
        remaining settlement. If no claim is made within 90 days, any remaining
        settlement will be forfeited and deleted after a total of 150 days of
        inactivity.
      </p>
      <p>
        4.4 If the Company, in its sole discretion, believes that User has made
        a false statement or misrepresentation related to this Agreement, the
        User Verification Process or the Funded Account, has violated the
        Trading Rules, has engaged in any Prohibited Conduct, is acting in bad
        faith or in violation of these Funded Terms, or if the Funded User
        deviates from the trading characteristics used during the Evaluation
        stage, fails to comply with newly assigned risk parameters, uses their
        account for any commercial or marketing purposes (including but not
        limited to selling services based on the Funded Account or teaching
        others how to pass the Evaluation stage), or makes any defamatory
        statements or publishes harmful content about the Company or the
        Services, including, without limitation, the unauthorized disclosure or
        publication of email correspondence or other private communications with
        the Company, the Company may take such action as it deems appropriate in
        its sole discretion, including but not limited to (i) removing one or
        more trades or trading date from a Funded User’s the Funded Account, and
        (ii) permanently closing User’s Funded Account. If the Company, in its
        sole discretion, determines to close User’s Funded Account pursuant to
        this Section 4.4, no Reward shall be allocated to the Funded User, any
        accrued Reward Balance shall be forfeited, the Funded Account shall be
        immediately terminated, User shall be permanently banned from engaging
        in any other offerings with the Company, User shall immediately return
        to the Company any Reward received, and User shall indemnify, defend,
        and hold the Company harmless from all losses, damages, fines,
        penalties, and expenses incurred as a result of such violation or
        breach, including reasonable attorneys’ fees and expenses.
      </p>
      <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
        5. Data and Use
      </span>
      <p>
        5.1 Funded User acknowledges that the Company may collect and aggregate
        information about the Funded User and specific market order suggestions,
        trading patterns and preferences, and other preferences and interests
        and use such information in its sole discretion. The Company may share
        or sell such information to Company’s business partners, affiliates,
        vendors, distributors, or other collaborators for these same purposes
        and each of them may use all such information to influence their own
        trading habits through mirroring market order suggestions in actual
        financial markets or use them in any other manner. Funded User agrees
        that such activities may be performed automatically and in no
        circumstance shall Funded User be entitled to any payment or
        compensation except for the Reward as detailed in Section 3. Further,
        all such activities shall be performed without the necessity of notice,
        consent, consultation, or approval by Funded User.
      </p>
      <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
        6. Acknowledgments
      </span>
      <p>
        6.1 Funded User acknowledges that (a) no actual trading in live markets
        will ever occur through the Funded Account; (b) User has no right,
        title, or ownership in any property, funds, or positions in the Funded
        Account; (c) all undistributed Reward are at risk of loss; (d) the
        Company have full discretion whether or not to execute the market order
        suggestions of the Funded User in the real-world trading; and (e) the
        Company solely communicates using the English language. Funded User
        represents and warrants that Funded User possesses the necessary
        proficiency in the English language to communicate for all purposes
        necessary and incidental to this Agreement.
      </p>
      <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
        7. Binding Agreement and Acknowledgement
      </span>
      <p>
        I,<strong>{userName}</strong>, with the email address{' '}
        <strong>{email}</strong>, hereby acknowledge that I have read,
        understood, and agree to be bound by the Terms of Use and Privacy
        Policy, as described at this agreement. I further agree that this
        Agreement constitutes a legally binding agreement between me and the
        Company.
      </p>
    </div>
  );
};

export default FundedModalContentTtp;
