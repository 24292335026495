import { makeObservable } from 'mobx';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import {
  PaginationRequest,
  PaginationResult,
  PaymentMethod,
  Payout,
  PayoutMetadata,
  PayoutSource,
} from '@monorepo/types';

export interface WithdrawPayload {
  amount: number;
  paymentOption: PaymentMethod;
  agreedToTerms: boolean;
  acknowledgeWithdrawFormAccount: boolean;
  metadata?: PayoutMetadata;
}

export default class PayoutStore {
  constructor() {
    makeObservable(this);
  }

  async payouts(
    type: PayoutSource,
    sourceId: string,
    paginateRequest: PaginationRequest
  ): Promise<PaginationResult<Payout>> {
    try {
      const {
        apiFactory: { payoutService },
      } = getEnv();
      return await payoutService.getPayouts(type, sourceId, paginateRequest);
    } catch (e) {
      console.error(e);
      return { total: 0, results: [] };
    }
  }

  async getPayoutTotalForSource(values: {
    type: PayoutSource;
    sourceId: string;
  }) {
    try {
      const {
        apiFactory: { payoutService },
      } = getEnv();
      return await payoutService.getPayoutsTotal(values.type, values.sourceId);
    } catch (e) {
      console.error(e);
      return 0;
    }
  }

  async createPayout(values: {
    amount: number;
    paymentOption: PaymentMethod;
    source: PayoutSource;
  }) {
    try {
      const {
        apiFactory: { payoutService },
      } = getEnv();
      return await payoutService.createPayout(
        values.source,
        values.amount,
        values.paymentOption
      );
    } catch (e) {
      console.error(e);
      return { total: 0, results: [] };
    }
  }

  async createPayoutRequest(
    payload: WithdrawPayload & {
      source: PayoutSource;
      sourceId: string;
    }
  ) {
    try {
      const {
        apiFactory: { payoutService },
      } = getEnv();

      return await payoutService.createPayoutRequest(payload);
    } catch (e) {
      console.error(e);
    }
  }

  async eligibleForWithdraw(values: {
    source: PayoutSource;
    sourceId: string;
  }) {
    try {
      const {
        apiFactory: { payoutService },
      } = getEnv();
      return await payoutService.getIsEligible(values.source, values.sourceId);
    } catch (e) {
      console.error(e);
      return {
        isValid: false,
        availableAmount: 0,
      };
    }
  }
}
