import { AnalyticEventParams } from '@monorepo/types';
import TagManager from 'react-gtm-module';

class GTMService {
  private gtmInitialized: boolean = false;

  initialize(gtmId: string) {
    try {
      const tagManagerArgs = {
        gtmId,
      };

      TagManager.initialize(tagManagerArgs);

      this.gtmInitialized = true;
    } catch (e) {
      console.error(e);
    }
  }

  sendEvent(eventParams: AnalyticEventParams) {
    if (!this.gtmInitialized) {
      console.error('GTM is not initialized');

      return;
    }
    return window.dataLayer.push({
      event: eventParams.event,
      ...eventParams.params,
    });
  }
}
const gtmService = new GTMService();

export default gtmService;
