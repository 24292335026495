import React, { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { Theme } from '@monorepo/types';
import { Flex } from '@monorepo/react-components';
import classNames from 'classnames';
import { Icons, IconsNames } from '@monorepo/icons';

import './theme-switch.scss';

const ThemeSwitch: FunctionComponent = () => {
  const { uiStore } = useStore();
  const isDarkMode = uiStore.currentTheme === Theme.Dark;

  const toggleTheme = () => {
    if (isDarkMode) {
      uiStore.switchTheme(Theme.Light);
    } else {
      uiStore.switchTheme(Theme.Dark);
    }
  };

  return (
    <Flex gap={8} align="center">
      <div className="theme-switch" onClick={toggleTheme}>
        <Icons
          className={classNames('theme-switch__icon', {
            'theme-switch__icon--dark': isDarkMode,
          })}
          iconName={isDarkMode ? IconsNames.MoonStars : IconsNames.Sun}
        />
      </div>
    </Flex>
  );
};

export default observer(ThemeSwitch);
