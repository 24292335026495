import { Icons, IconsNames } from '@monorepo/icons';
import { Button } from '@monorepo/react-components';
import { FunctionComponent } from 'react';

interface Props {
  onToggle: () => void;
  isOpen: boolean;
}

const NavigationMenuHamburger: FunctionComponent<Props> = ({
  isOpen,
  onToggle,
}) => {
  return (
    <Button
      type="text"
      size="small"
      className="navigation-menu__hamburger"
      icon={
        <Icons
          iconName={isOpen ? IconsNames.MenuBurger : IconsNames.MenuBurgerClose}
        />
      }
      onClick={() => {
        onToggle?.();
      }}
    />
  );
};

export default NavigationMenuHamburger;
