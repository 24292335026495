import { App, Descriptions, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { Account, AccountStateKey, AccountStatus } from '@monorepo/types';
import TradersLogin from '../../../traders-login/traders-login';
import { EyeOutlined } from '@ant-design/icons';
import { TextComponent, Statistic, Card } from '@monorepo/react-components';
import dayjs from 'dayjs';
import AccountConsistencyRules from '../account-consistency-rules/account-consistency-rules';

interface Props {
  account: Account;
  programId: string;
  buyingPowerText: string;
}

const AccountOverview: FunctionComponent<Props> = ({
  account,
  programId,
  buyingPowerText,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();
  const { modal } = App.useApp();

  const program = programStore.get(programId);

  const openTradersLoginModal = () => {
    modal.info({
      maskClosable: true,
      title: 'Login Credentials',
      content: (
        <TradersLogin
          userId={account.tsUserId}
          tradingSystem={account.tradingSystem}
        />
      ),
    });
  };

  const getDisabledReason = () => {
    if (account.status === AccountStatus.Active) {
      return null;
    }

    const isTarget = account.state[AccountStateKey.Target] || false;
    const isDailyPause = account.state[AccountStateKey.DailyPause] || false;
    const isDailyLoss = account.state[AccountStateKey.DailyLoss] || false;
    const isExpired = account.state[AccountStateKey.Expired] || false;
    const isTerminated = account.state[AccountStateKey.Terminated] || false;

    const noReasonAvailable = [
      isTarget,
      isDailyPause,
      isDailyLoss,
      isTerminated,
      isExpired,
    ].every((flag) => !flag);

    let reasonText = '';

    if (noReasonAvailable) {
      reasonText = 'Waiting For Review';
    }

    if (isExpired) {
      reasonText = 'Expired';
    }
    if (isTarget) {
      reasonText = 'Reached Target';
    }
    if (isDailyLoss) {
      reasonText = 'Reached Daily Loss';
    }
    if (isDailyPause) {
      reasonText = 'Reached Daily Pause';
    }
    if (isTerminated) {
      reasonText = 'Reached Stopout';
    }

    return (
      <Descriptions.Item label={'Disable Reason'}>
        {reasonText}
      </Descriptions.Item>
    );
  };

  return (
    <Card title={`Account Overview`} bordered={true}>
      <Descriptions column={1}>
        <Descriptions.Item label={'Login'}>
          {account.tsAccount}
        </Descriptions.Item>
        <Descriptions.Item label={'Account Credentials'}>
          <EyeOutlined onClick={openTradersLoginModal} />
        </Descriptions.Item>
        <Descriptions.Item label={'Program'}>{program?.name}</Descriptions.Item>
        <Descriptions.Item label={buyingPowerText}>
          <Statistic
            value={program?.config.buyingPower}
            precision={0}
            prefix={'$'}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'Account Status'}>
          <TextComponent capitalize={true}>{account.status}</TextComponent>
        </Descriptions.Item>
        {getDisabledReason()}

        {account?.expireDate ? (
          <Descriptions.Item label={'Expiration Date'}>
            {dayjs(account.expireDate).format('DD/MM/YYYY')}
          </Descriptions.Item>
        ) : null}
      </Descriptions>
      <Row>
        <AccountConsistencyRules
          accountId={account.tsAccount}
          programId={account.programId}
        />
      </Row>
    </Card>
  );
};

export default AccountOverview;
