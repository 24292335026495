import { AccountType } from '../account/account';

export enum TradingSystem {
  Sterling = 'sterling',
  MatchTrader = 'match-trader',
  TraderEvolution = 'trader-evolution',
  MockTrader = 'mock-trader',
  MetaTraderFive = 'meta-trader-5',
  MetaTraderXP = 'meta-trader-xp',
}

export const TradingSystemNames: Record<
  TradingSystem,
  {
    name: string;
    icon?: string;
  }
> = {
  [TradingSystem.Sterling]: {
    name: 'Sterling',
  },
  [TradingSystem.MatchTrader]: { name: 'MatchTrader' },
  [TradingSystem.TraderEvolution]: { name: 'Trader Evolution' },
  [TradingSystem.MockTrader]: { name: 'Mock Trader' },
  [TradingSystem.MetaTraderFive]: { name: 'MetaTrader' },
  [TradingSystem.MetaTraderXP]: { name: 'MetaTrader' },
};

export enum TradingPlatforms {
  Web = 'web',
  Mac = 'mac-app',
  Windows = 'windows-app',
  Linux = 'linux-app',
  Android = 'android-app',
  iOS = 'ios-app',
}

export interface TradingSystemCredentials {
  tsAccount: string;
  tsAccountId: string;
  tsUserId: string;
}

export interface UserTradingSystemCredentials {
  login: string;
  password: string;
  externalId: string;
  tradingSystem: TradingSystem;
  accountType: AccountType;
}

export interface AccountBalanceChart {
  date: string;
  value: number;
  type: 'position' | 'deposit/withdraw';
}
export interface AccountStatsModelBase {
  accountId: string;
  totalTrades: number;
  profitTrades: number;
  longTrades: number;
  lossTrades: number;
  shortTrades: number;
  grossProfit: number;
  grossLoss: number;
  totalNetProfit: number;
  profitFactor: number;
  winRate: number;
  bestProfit: number;
  biggestLoss: number;
  avgProfit: number;
  avgLoss: number;
  expectancy: number;
  avgTradeSize: number;
  avgTradeDuration: number;
}
export interface AccountStats extends Partial<AccountStatsModelBase> {
  bestStock?: number;
  bestDay?: number;
  riskRewardRatio?: number;
  successRate?: number;
  profitableDays?: Date[];
}

export interface AccountBalance {
  balance: number;
  profitAndLoss: number;
  dailyProfitAndLoss: number;
  credits: number;
  equity: number;
  allowedDailyLosses?: number;
  invalidProfitAndLoss?: number;
  snapshotBalance?: number;
  snapshotEquity?: number;
  consecutiveInactivityDays?: number;
}

export interface AccountDailyPerformance {
  marketValue: number;
  totalVolume: number;
}

export enum LossEventType {
  DailyLoss = 'daily-loss',
  DailyPause = 'daily-pause',
  StopOut = 'stop-out',
  RaceStopout = 'race-stop-out',
  Unknown = 'unknown',
}
