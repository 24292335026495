import { Flex } from 'antd';
import { FormInput } from '@monorepo/react-components';
import React, { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { formEmailValidation } from '@monorepo/types';

interface Props {
  form: UseFormReturn<any>;
}

const RiseWithdraw: FunctionComponent<Props> = ({ form }) => {
  return (
    <Flex vertical style={{ maxWidth: '50%' }} gap={8}>
      <FormInput
        placeholder="example@example.com"
        text={'Email'}
        form={form}
        controllerProps={{
          name: 'metadata.email',
          rules: formEmailValidation(),
        }}
      />
    </Flex>
  );
};

export default RiseWithdraw;
