import {
  Card,
  Col,
  Flex,
  Row,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent, useEffect, useState } from 'react';
import { Icons, IconsNames } from '@monorepo/icons';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import classNames from 'classnames';
import { useStore } from '../../../../helpers/use-store';
import { AccountStats } from '@monorepo/types';

import './best-rates-cards.scss';

interface Props {
  accountId: string;
}

const BestRatesCards: FunctionComponent<Props> = ({ accountId }) => {
  const { isMobile } = useResponsiveness();
  const {
    dataStore: { accountStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const [accountStats, setAccountStats] = useState<AccountStats>({
    bestStock: 0,
    bestDay: 0,
    riskRewardRatio: 0,
    successRate: 0,
  });

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getStats(accountId);

        setAccountStats(result);
      } catch (e) {
        console.error(`Failed getting overview`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const isLoading = loadingState === LoadingState.Loading;
  const data = [
    {
      title: 'Best Stock',
      value: accountStats.bestStock || '-',
      icon: IconsNames.ChartAverage,
    },
    {
      title: 'Best Day',
      value: accountStats.bestDay || '-',
      icon: IconsNames.Exchange,
    },
    {
      title: 'Risk/Reward Ratio',
      value: accountStats.riskRewardRatio || '-',
      icon: IconsNames.InvoiceOutline,
    },
    {
      title: 'Success Rate',
      value: accountStats.successRate || 0 + '%',
      icon: IconsNames.CheckTwo,
    },
  ];

  return (
    <Row gutter={isMobile ? [16, 16] : [24, 24]}>
      {data.map(({ title, value, icon }) => (
        <Col key={title} xxl={6} xs={12}>
          <Card
            hoverable={false}
            borderLess
            className={classNames('best-rates-cards__card', {
              'best-rates-cards__card--mobile': isMobile,
            })}
          >
            <Flex align="center" gap={10}>
              <Flex
                align="center"
                justify="center"
                className="best-rates-cards__card-icon-box"
              >
                <Icons
                  className="best-rates-cards__card-icon"
                  iconName={icon}
                />
              </Flex>
              <Flex
                vertical
                align={isMobile ? 'flex-start' : 'center'}
                gap={isMobile ? 0 : 5}
              >
                <TextComponent weight={600} level={6}>
                  {title}
                </TextComponent>
                <TextComponent
                  loading={isLoading}
                  weight={600}
                  level={isMobile ? 6 : 4}
                >
                  {value}
                </TextComponent>
              </Flex>
            </Flex>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default BestRatesCards;
