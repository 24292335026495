import { Button } from 'antd';
import { FunctionComponent, ReactNode } from 'react';
import {
  AppleOutlined,
  GlobalOutlined,
  WindowsOutlined,
  AndroidOutlined,
  LinuxOutlined,
} from '@ant-design/icons';
import { TradingPlatforms } from '@monorepo/types';
import { ButtonProps } from 'antd/es/button/button';
import { PlatformBtnText } from '@monorepo/client-common';
export const PlatformIcon: { [key: string]: ReactNode } = {
  [TradingPlatforms.Web]: <GlobalOutlined />,
  [TradingPlatforms.Mac]: <AppleOutlined />,
  [TradingPlatforms.Windows]: <WindowsOutlined />,
  [TradingPlatforms.Linux]: <LinuxOutlined />,
  [TradingPlatforms.iOS]: <AppleOutlined />,
  [TradingPlatforms.Android]: <AndroidOutlined />,
};

export const TradingSystemBtn: FunctionComponent<
  { platform: TradingPlatforms } & ButtonProps
> = ({ platform, ...rest }) => {
  return (
    <Button icon={PlatformIcon[platform]} {...rest}>
      {PlatformBtnText[platform]}
    </Button>
  );
};
