import HttpService from './http-service';
import UsersService from '../entities/users-service';
import AccountService from '../entities/account-service';
import UserService from '../entities/user-service';
import TradeService from '../entities/trade-service';
import OrderService from '../entities/order-service';
import ProgramService from '../entities/program-service';
import PartnerService from '../entities/partner-service';
import PositionService from '../entities/position-service';
import ApplicationConfigService from '../entities/application-config-service';
import PayoutService from '../entities/payout-service';
import AddOnService from '../entities/add-on-service';
import AchievementService from '../entities/achievement-service';
import GroupService from '../entities/group-service';
import AffiliationCommissionService from '../entities/affiliation-commissions-service';
import AssetsService from '../entities/assets-service';

interface ApiItem {
  variableName: string;
  // eslint-disable-next-line
  classEntity: any;
  route: string;
}

const ApiList: ApiItem[] = [
  {
    variableName: 'usersService',
    classEntity: UsersService,
    route: UsersService.route,
  },
  {
    variableName: 'payoutService',
    classEntity: PayoutService,
    route: PayoutService.route,
  },
  {
    variableName: 'assetsService',
    classEntity: AssetsService,
    route: AssetsService.route,
  },
  {
    variableName: 'userService',
    classEntity: UserService,
    route: UserService.route,
  },
  {
    variableName: 'orderService',
    classEntity: OrderService,
    route: OrderService.route,
  },
  {
    variableName: 'accountService',
    classEntity: AccountService,
    route: AccountService.route,
  },
  {
    variableName: 'tradeService',
    classEntity: TradeService,
    route: TradeService.route,
  },
  {
    variableName: 'programService',
    classEntity: ProgramService,
    route: ProgramService.route,
  },
  {
    variableName: 'groupService',
    classEntity: GroupService,
    route: GroupService.route,
  },
  {
    variableName: 'addOnService',
    classEntity: AddOnService,
    route: AddOnService.route,
  },
  {
    variableName: 'partnerService',
    classEntity: PartnerService,
    route: PartnerService.route,
  },
  {
    variableName: 'positionService',
    classEntity: PositionService,
    route: PositionService.route,
  },
  {
    variableName: 'applicationConfigService',
    classEntity: ApplicationConfigService,
    route: ApplicationConfigService.route,
  },
  {
    variableName: 'achievementService',
    classEntity: AchievementService,
    route: AchievementService.route,
  },
  {
    variableName: 'affiliationCommissionService',
    classEntity: AffiliationCommissionService,
    route: AffiliationCommissionService.route,
  },
];

export interface ApiFactoryParams {
  httpService: HttpService;
}

class ApiFactory {
  private httpService: HttpService;

  usersService: UsersService = {} as UsersService;
  userService: UserService = {} as UserService;
  payoutService: PayoutService = {} as PayoutService;
  orderService: OrderService = {} as OrderService;
  accountService: AccountService = {} as AccountService;
  tradeService: TradeService = {} as TradeService;
  programService: ProgramService = {} as ProgramService;
  groupService: GroupService = {} as ProgramService;
  addOnService: AddOnService = {} as AddOnService;
  partnerService: PartnerService = {} as PartnerService;
  positionService: PositionService = {} as PositionService;
  applicationConfigService: ApplicationConfigService =
    {} as ApplicationConfigService;
  achievementService: AchievementService = {} as AchievementService;
  assetsService: AssetsService = {} as AssetsService;
  affiliationCommissionService: AffiliationCommissionService =
    {} as AffiliationCommissionService;

  constructor({ httpService }: ApiFactoryParams) {
    this.httpService = httpService;

    ApiList.forEach((api: ApiItem) => {
      this[api.variableName as keyof ApiFactory] = new api.classEntity({
        httpService,
        route: api.route,
      });
    });
  }

  saveToken(
    token: string,
    refreshToken: () => Promise<string>,
    adminToken = ''
  ) {
    this.httpService.setToken(token, refreshToken, adminToken);
  }

  removeToken() {
    this.httpService.removeToken();
  }
}

export default ApiFactory;
