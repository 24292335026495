import React, { FunctionComponent } from 'react';
import { CurrencyToSign, Invoice } from '@monorepo/types';
import { ColumnsType } from 'antd/lib/table';
import {
  Flex,
  Statistic,
  Table,
  TableSummaryRow,
  TableSummaryCell,
  TextComponent,
} from '@monorepo/react-components';

interface Props {
  invoice: Invoice;
}

const InvoiceTable: FunctionComponent<Props> = ({ invoice }) => {
  const columns: ColumnsType<Invoice> = [
    {
      title: 'Item',
      dataIndex: 'programName',
      key: 'programName',
    },
    {
      title: 'Price USD',
      dataIndex: 'price',
      key: 'price',
      render: (_, item: Invoice) => Number(item.price).toFixed(2),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Exchange Rate',
      dataIndex: 'exchangeRate',
      key: 'exchangeRate',
      render: (_, item: Invoice) => Number(item.exchangeRate).toFixed(3),
    },
    {
      title: `Price`,
      dataIndex: 'amountInCurrency',
      key: 'amountInCurrency',
      render: (_, item: Invoice) => {
        const priceInCurrency =
          Number(item.price || 0) * Number(item.exchangeRate || 0);

        return Number(priceInCurrency).toFixed(2);
      },
    },
  ];

  return (
    <Table
      rowHoverable={false}
      size="small"
      pagination={false}
      columns={columns}
      dataSource={[invoice]}
      rowKey={(invoice) => invoice.uniqId}
      summary={() => {
        const details = [
          {
            title: 'Discount',
            value: invoice.discountPercentage || 0,
            suffix: '%',
          },
          {
            title: 'Credits Applied',
            value: (invoice.creditsUsed || 0) * Number(invoice.exchangeRate),
            prefix: CurrencyToSign[invoice.currency],
            precision: 2,
          },
          {
            title: 'Fee',
            value: invoice.convertedFee || 0,
            prefix: CurrencyToSign[invoice.currency],
            precision: 2,
          },
        ];
        return (
          <TableSummaryRow>
            <TableSummaryCell index={0} colSpan={columns.length}>
              <Flex vertical gap={5}>
                {details.map((detail, i) => (
                  <Flex justify="space-between" key={i}>
                    <TextComponent level={8}>{detail.title}</TextComponent>
                    <TextComponent level={8} textAlign="center">
                      <Statistic
                        prefix={detail.prefix}
                        suffix={detail.suffix}
                        value={detail.value || 0}
                        precision={detail.precision || 0}
                      />
                    </TextComponent>
                  </Flex>
                ))}
              </Flex>
            </TableSummaryCell>
          </TableSummaryRow>
        );
      }}
    />
  );
};

export default InvoiceTable;
