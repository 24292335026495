import { Icons, IconsNames } from '@monorepo/icons';
import {
  Button,
  Card,
  Flex,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { Account, Application, TradingSystemNames } from '@monorepo/types';
import { useStore } from '../../../../helpers/use-store';
import dayjs from 'dayjs';
import { useResponsiveness } from '@monorepo/client-common';

import './account-info.scss';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const FORBIDDEN_PRACTICES_LINKS = {
  [Application.TTP]: 'https://tradethepool.com/the-program/#faqs',
  [Application.THE5ERS]:
    'https://help.the5ers.com/prohibited-trading-practices/',
};

interface AccountInfoProps {
  account: Account;
}

const AccountInfo: FunctionComponent<AccountInfoProps> = ({ account }) => {
  const { isDesktop } = useResponsiveness();
  const {
    dataStore: { programStore },
  } = useStore();
  const program = programStore.get(account.programId);

  const expiredDate = account.expireDate
    ? dayjs(account.expireDate).format('MMM D, YYYY')
    : '-';

  const data = [
    {
      title: 'Account type',
      value: account.type,
      bold: true,
      titleIcon: IconsNames.CloudAuditing,
    },
    {
      title: 'Account platform',
      value: TradingSystemNames[account.tradingSystem].name || '-',
      titleIcon: IconsNames.Rocket,
    },
    {
      title: 'Account size',
      value: (
        <Statistic
          prefix={'$'}
          value={program?.config.buyingPower}
          precision={2}
        />
      ),
      titleIcon: IconsNames.Bank,
    },
    {
      title: 'Status',
      value: account.status,
      titleIcon: IconsNames.Recycle,
    },
    {
      title: 'Program Ends',
      value: expiredDate,
      titleIcon: IconsNames.ClockOutline,
    },
  ];

  const AccountInfoItems = () => {
    return (
      <Flex vertical gap={16} className="account-info-items">
        {data.map((item) => (
          <Flex gap={5} justify="space-between" key={item.title}>
            <Flex align="center" gap={8}>
              <TextComponent level={6} responsive={false}>
                <Icons iconName={item.titleIcon} />
              </TextComponent>
              <TextComponent level={8} responsive={false}>
                {item.title}
              </TextComponent>
            </Flex>
            <TextComponent
              textAlign="right"
              capitalize
              weight={item.bold ? 700 : 500}
              level={6}
            >
              {item.value}
            </TextComponent>
          </Flex>
        ))}
        <Button
          href={FORBIDDEN_PRACTICES_LINKS[project]}
          target="_blank"
          icon={
            <Icons
              iconName={IconsNames.Bank}
              className="forbidden-practices-icon"
            />
          }
        >
          Forbidden Practices
        </Button>
      </Flex>
    );
  };

  if (isDesktop) {
    return <AccountInfoItems />;
  }

  return (
    <AccountInfoCompact>
      <AccountInfoItems />
    </AccountInfoCompact>
  );
};

const AccountInfoCompact: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Flex vertical gap={4} className="account-info">
      <Card
        active={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        className={classNames('account-info__trigger', {
          'account-info__trigger--open': isOpen,
        })}
      >
        <Flex justify="space-between">
          <TextComponent level={5} responsive={false}>
            Account info
          </TextComponent>

          <TextComponent level={6} responsive={false}>
            <Icons
              className="account-info-chevron"
              iconName={IconsNames.ChevronDown}
            />
          </TextComponent>
        </Flex>
      </Card>
      <div
        className={classNames('account-info__content', {
          'account-info__content--open': isOpen,
        })}
      >
        <Card className="account-info__content-card" active={isOpen}>
          {children}
        </Card>
      </div>
    </Flex>
  );
};

export default AccountInfo;
