import React, { FunctionComponent, ReactNode } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Radio } from 'antd';
import { Card } from '../card/card';

interface RadioItem {
  text: string | ReactNode;
  value: any;
}

export const FormRadioCardGroup: FunctionComponent<
  Omit<FormFieldProps, 'render'> & {
    options: RadioItem[];
  }
> = ({ options, ...formFieldProps }) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Radio.Group {...field} className="form-field__radio-card-group">
            {options.map((option) => {
              const { text, value } = option;
              const isActive = field.value === value;

              return (
                <Card
                  active={isActive}
                  className="form-field__radio-card-group__card"
                  key={value}
                >
                  <Radio value={value}>{text}</Radio>
                </Card>
              );
            })}
          </Radio.Group>
        );
      }}
    />
  );
};
