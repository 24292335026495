import { App } from 'antd';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import { useStore } from '../../helpers/use-store';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import GoogleLogin from '../../components/google-login/google-login';
import {
  Button,
  Flex,
  FormInput,
  FormPassword,
  TextComponent,
} from '@monorepo/react-components';
import {
  AnalyticAuthType,
  AnalyticsEventTypes,
  PagePath,
  ThemeColors,
} from '@monorepo/types';
import gtmService from '../../services/analytics/analytics';
import PageContainer from '../page-container/page-container';
import classNames from 'classnames';
import { ThemeWrapper } from '../../components/theme-wrapper/theme-wrapper';

import './login.scss';

interface LoginPayload {
  email: string;
  password: string;
}

const LoginButton = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const { isDesktop } = useResponsiveness();
  const navigate = useNavigate();
  const form = useForm<LoginPayload>();
  const { message } = App.useApp();

  const isLoginInProcess = loadingState === LoadingState.Loading;

  const onFinish = async (values: LoginPayload) => {
    try {
      updateLoadingState(LoadingState.Loading);

      await userStore.login(values.email, values.password);
      updateLoadingState(LoadingState.Loaded);

      gtmService.sendEvent({
        event: AnalyticsEventTypes.LoginSuccess,
        params: {
          user_id: userStore.currentUser.userId,
          method: AnalyticAuthType.Email,
        },
      });
    } catch (e: any) {
      updateLoadingState(LoadingState.Error);
      message.error(e.message);

      gtmService.sendEvent({
        event: AnalyticsEventTypes.LoginFail,
        params: {
          fail_reason: e.message,
        },
      });
    }
  };

  const goRegisterPage = () => {
    navigate(PagePath.Register);
  };

  return (
    <PageContainer helmet="Login">
      <Flex vertical className="login-card" gap={32}>
        <Flex vertical gap={6} align={isDesktop ? 'flex-start' : 'center'}>
          <TextComponent
            weight={600}
            className={classNames('title', {
              'title--mobile': !isDesktop,
            })}
          >
            Welcome Back!
          </TextComponent>
          <TextComponent level={3} className="sub-text">
            Sign in to your account
          </TextComponent>
        </Flex>
        <form name="normal_login" onSubmit={form.handleSubmit(onFinish)}>
          <Flex vertical gap={16}>
            <FormInput
              placeholder="Email"
              form={form}
              controllerProps={{
                name: 'email',
                rules: { required: 'Email is required' },
              }}
            />
            <FormPassword
              placeholder="Password"
              form={form}
              controllerProps={{
                name: 'password',
                rules: { required: 'Password is required' },
              }}
            />
            <TextComponent
              level={7}
              responsive={false}
              textAlign="right"
              className="login-card__forgot-password half-opacity"
              onClick={() => navigate(PagePath.ForgotPassword)}
            >
              Forgot password?
            </TextComponent>
          </Flex>

          <Flex vertical gap={24}>
            <Flex vertical gap={16}>
              <ThemeWrapper themeColor={ThemeColors.colorSecondary}>
                <Button
                  block
                  loading={isLoginInProcess}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="login-card__sign-in-button"
                >
                  Sign in
                </Button>
                <Button
                  block
                  type="default"
                  ghost
                  onClick={goRegisterPage}
                  className="half-opacity"
                >
                  Create Account
                </Button>
              </ThemeWrapper>
            </Flex>
            <TextComponent className="sub-text" textAlign="center">
              or
            </TextComponent>
            <GoogleLogin ghost text="Sign in with Google" />
          </Flex>
        </form>
      </Flex>
    </PageContainer>
  );
};

export default LoginButton;
