import CrudService from '../core/crud-service';
import {
  CurrencyRates,
  Order,
  PaymentPageResponse,
  PaymentProviderName,
  Invoice,
} from '@monorepo/types';
import { AxiosError } from 'axios';

export default class OrderService extends CrudService {
  static override route = 'order';

  async orderPayment(orderId: string, paymentProvider: PaymentProviderName) {
    try {
      const response: { data: PaymentPageResponse } =
        await this.httpService.post(`${this.path}/payment`, {
          orderId,
          paymentProvider,
        });
      return response.data.paymentPage;
    } catch (err) {
      console.error(`Failed creating order payment page for: ${orderId}`, err);

      if (err instanceof AxiosError) {
        throw err.response?.data;
      }
      if (err instanceof Error) {
        return false;
      }
    }
  }

  async upsertOrderByUniq(order: Order) {
    try {
      const response: { data: Order } = await this.httpService.post(
        `${this.path}/uniq`,
        order
      );

      return response.data;
    } catch (e) {
      console.error(`Failed upsertting order`, order);
      throw e;
    }
  }

  async getCurrencies(): Promise<CurrencyRates> {
    try {
      const response: { data: CurrencyRates } = await this.httpService.get(
        `${this.path}/currencies`
      );
      return response.data;
    } catch (e) {
      console.error(`Failed getting currencies`, e);
      return {} as CurrencyRates;
    }
  }

  async getPaymentProviders(currency?: string): Promise<PaymentProviderName[]> {
    try {
      const response: { data: PaymentProviderName[] } =
        await this.httpService.get(`${this.path}/payment-providers`, {
          params: { currency },
        });

      return response.data;
    } catch (e) {
      console.error(`Failed getting payment providers`, e);
      return [];
    }
  }

  async getOrder(uniqId: string): Promise<Order> {
    try {
      const response: { data: Order } = await this.httpService.get(
        `${this.path}/uniq/${uniqId}`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting currencies`, e);
      throw e;
    }
  }

  async getInvoice(orderId: string) {
    try {
      const response: { data: Invoice } = await this.httpService.get(
        `${this.path}/invoice/${orderId}`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting invoice for order: ${orderId}`, e);
      throw e;
    }
  }

  async removeCoupon(orderId: string) {
    try {
      const response: { data: Invoice } = await this.httpService.put(
        `${this.path}/coupon/${orderId}`,
        {}
      );

      return response.data;
    } catch (e) {
      console.error(`Failed removing coupon for order: ${orderId}`, e);
      throw e;
    }
  }
}
