import { BaseIdentifiers, TimeIdentifiers } from '../common/identifiers';
import { UserTradingSystemCredentials } from '../trading-system';
import { VerificationData } from '../user-verification/user-verification';
import { Abilities, Role } from '../admin-role';
import { FeatureFlags } from '../feature-flags';

export const NOT_AVAILABLE = 'N/A';

export interface CognitoUser {
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  identityProviderLastName: string;
  email: string;
  isVerified: boolean;
}

export type AdminUser = CognitoUser & {
  abilities: Abilities[];
  role: Role;
  profileUrl: string;
  affiliateId?: string;
};

export interface UserInformation {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  country: string;
  address: string;
  city: string;
  state: string;
  profileUrl?: string;
}

export type User = BaseIdentifiers &
  TimeIdentifiers &
  UserInformation &
  CognitoUser & {
    featureFlags?: FeatureFlags;
    tsUsers: UserTradingSystemCredentials[];
    confirmationCode?: string;
    resetPasswordCode?: string;
    creditBalance: number;
    verificationData: VerificationData;
    affiliateId?: string;
    deleted?: boolean;
  };
