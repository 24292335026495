import React from 'react';
import { useStore } from '../../../helpers/use-store';
import Payouts from '../../../features/payouts/payout';
import { PayoutSource } from '@monorepo/types';

export const AffiliatePayouts = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  return (
    <Payouts
      type={PayoutSource.Affiliate}
      sourceId={userStore.currentUser.affiliateId || ''}
    />
  );
};
