import CrudService from '../core/crud-service';
import { BaseResponse } from '../core/base-entity-service';
import {
  Application,
  getTotalPayoutImageTag,
  StorageContentType,
} from '@monorepo/types';
import { getEnv } from '../../helpers/mobx-easy-wrapper';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;
const isDev = import.meta.env.VITE_APP_ENV === 'development';

export default class AchievementService extends CrudService {
  static override route = 'achievement';

  async createShareableImage(
    achievementId: string,
    imageUrl: string
  ): Promise<{ shareableUrl: string }> {
    try {
      const response = await this.httpService.post<
        { imageUrl: string },
        BaseResponse<{ shareableUrl: string }>
      >(`${this.path}/shareable-content/${achievementId}`, { imageUrl });

      return response.data;
    } catch (e) {
      console.error(`Failed creating shareable content: ${achievementId}`, e);
      throw e;
    }
  }

  async checkIfTotalPayoutImageExists(
    userId: string,
    amount: number
  ): Promise<string | undefined> {
    const env = isDev ? 'dev' : 'prod';
    const imageName = `/${getTotalPayoutImageTag(userId, amount)}.jpg`;
    const cloudinaryId = getEnv().envConfig.cloudinary_folder;
    const folder = `/${project}-${env}/${StorageContentType.Achievements}`;
    const baseURL = `https://res.cloudinary.com/${cloudinaryId}/image/upload`;

    const url = baseURL + folder + imageName;

    return await this.httpService
      .get(url)
      .then(() => url)
      .catch(() => undefined);
  }
}
