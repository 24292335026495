import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../../features/login/login';
import Register from '../../features/register/register';
import ConfirmPage from '../../features/confirm-page/confirm-page';
import ForgetPassword from '../../features/forget-password/forget-password';
import ResetPassword from '../../features/reset-password/reset-password';
import { isCouponLink } from '@monorepo/types';
import LayoutNotAuth from '../../components/layout-not-auth/layout-not-auth';

const LoggedOut = () => {
  const initialUrl = localStorage.getItem('initialUrl');

  if (!initialUrl || isCouponLink(window.location.hash)) {
    localStorage.setItem('initialUrl', window.location.hash);
  }

  return (
    <Router>
      <Routes>
        <Route element={<LayoutNotAuth />}>
          <Route index path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm/:confirmCode" element={<ConfirmPage />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route
            path="/reset-password/:resetPasswordCode"
            element={<ResetPassword />}
          />
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default LoggedOut;
