import React, { FunctionComponent } from 'react';
import { Icons, IconsNames } from '@monorepo/icons';
import { AchievementType } from '@monorepo/types';

interface Props {
  imgUrl: string;
  type: AchievementType | 'total-payout';
}

const AchievementDownloadButton: FunctionComponent<Props> = ({
  imgUrl,
  type,
}) => {
  const onDownload = (imgUrl: string) => {
    fetch(imgUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement<'a'>('a');
        link.href = url;
        link.download = `${type}-${new Date().getTime()}.png`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  return (
    <Icons
      iconName={IconsNames.Download}
      className="achievement-social-icon"
      onClick={(e) => {
        e.stopPropagation();
        onDownload(imgUrl);
      }}
    />
  );
};

export default AchievementDownloadButton;
