import UserStore from './user/user-store';
import { UsersStore } from './users/users-store';
import { AccountStore } from './account/account-store';
import { TradeStore } from './trade/trade-store';
import OrderStore from './order/order-store';
import { ProgramStore } from './program/program-store';
import { PartnerStore } from './partner/partner-store';
import { PositionStore } from './position/position-store';
import RealTimeStore from './real-time/real-time-store';
import { ApplicationConfigStore } from './application-config/application-config-store';
import PayoutStore from './payout/payout-store';
import { AddOnStore } from './add-on/add-on-store';
import AchievementStore from './achievement/achievement-store';
import AffiliateCommissionsStore from './affiliation-commissions/affiliation-commissions';
import { GroupStore } from './group/group-store';

class DataStore {
  userStore: UserStore;
  usersStore: UsersStore;
  accountStore: AccountStore;
  tradeStore: TradeStore;
  orderStore: OrderStore;
  payoutStore: PayoutStore;
  programStore: ProgramStore;
  groupStore: GroupStore;
  addOnStore: AddOnStore;
  partnerStore: PartnerStore;
  positionStore: PositionStore;
  realTimeStore: RealTimeStore;
  applicationConfigStore: ApplicationConfigStore;
  achievementStore: AchievementStore;
  affiliateCommissionsStore: AffiliateCommissionsStore;

  constructor() {
    this.userStore = new UserStore();
    this.usersStore = new UsersStore();
    this.accountStore = new AccountStore();
    this.tradeStore = new TradeStore();
    this.payoutStore = new PayoutStore();
    this.orderStore = new OrderStore();
    this.programStore = new ProgramStore();
    this.groupStore = new GroupStore();
    this.partnerStore = new PartnerStore();
    this.addOnStore = new AddOnStore();
    this.positionStore = new PositionStore();
    this.realTimeStore = new RealTimeStore();
    this.applicationConfigStore = new ApplicationConfigStore();
    this.achievementStore = new AchievementStore();
    this.affiliateCommissionsStore = new AffiliateCommissionsStore();
  }
}

export default DataStore;
