import BaseEntityService from './base-entity-service';
import { PaginationRequest, PaginationResult } from '@monorepo/types';

export default class CrudService extends BaseEntityService {
  async create<T>(payload: Record<string, unknown>): Promise<T> {
    const response = await this.httpService.post<
      Record<string, unknown>,
      Record<string, unknown>
    >(`${this.path}`, payload);

    return response.data as T;
  }

  async getAll<T>(queryString = ''): Promise<T[]> {
    let url = `${this.path}`;

    if (queryString) {
      url += `?${queryString}`;
    }

    const response = await this.httpService.get<Record<string, unknown>>(url);

    return response.data as T[];
  }

  async paginate<T>(
    paginateRequest: PaginationRequest
  ): Promise<PaginationResult<T>> {
    const url = `${this.path}/paginate`;

    const response = await this.httpService.get<Record<string, unknown>>(url, {
      params: paginateRequest,
    });

    return response.data as PaginationResult<T>;
  }

  async get(id: string) {
    const response = await this.httpService.get<Record<string, unknown>>(
      `${this.path}/${id}`
    );

    return response.data;
  }

  async update(id: string, data: Record<string, unknown>) {
    let url = `${this.path}`;

    if (id) {
      url += `/${id}`;
    }

    const response = await this.httpService.put<
      Record<string, unknown>,
      Record<string, unknown>
    >(url, data);

    return response.data;
  }

  async delete(id: string) {
    const response = await this.httpService.delete<Record<string, unknown>>(
      `${this.path}/${id}`
    );

    return response.data;
  }
}
