import {
  Card,
  Col,
  Row,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useResponsiveness } from '@monorepo/client-common';

import './progress-cards.scss';

interface Props {
  profitAndLoss: number;
  isLoading?: boolean;
}

const ProfitAndLoseCard: FunctionComponent<Props> = ({
  profitAndLoss,
  isLoading,
}) => {
  const { isMobile } = useResponsiveness();

  return (
    <Card
      hoverable={false}
      className={classNames(
        'progress-cards',
        'progress-cards__card',
        'progress-cards__card--pl'
      )}
    >
      <Row justify={'space-between'}>
        <Col>
          <TextComponent weight={400} level={isMobile ? 5 : 3}>
            P&L
          </TextComponent>
        </Col>
        <Col xs={24}>
          <TextComponent
            loading={isLoading}
            level={isMobile ? 3 : 2}
            weight={600}
          >
            <Statistic value={profitAndLoss} precision={2} prefix={'$'} />
          </TextComponent>
        </Col>
        <Col xs={24}>
          <TextComponent level={8}>
            {`Net deposit +/- any realised profits and losses and associated fees`}
          </TextComponent>
        </Col>
      </Row>
    </Card>
  );
};
export default ProfitAndLoseCard;
