import React, { FunctionComponent } from 'react';
import { Flex, Statistic, TextComponent } from '@monorepo/react-components';
import { CurrencyToSign } from '@monorepo/types';

interface Props {
  amountInCurrency: string;
  currency: string;
}

const InvoiceConclusion: FunctionComponent<Props> = ({
  amountInCurrency,
  currency,
}) => {
  return (
    <Flex
      gap={20}
      justify={'flex-end'}
      align={'center'}
      className="invoice-conclusion invoice-section"
    >
      <TextComponent level={8}>Total:</TextComponent>
      <TextComponent weight={600} level={8} className="total-amount">
        <Statistic
          prefix={CurrencyToSign[currency]}
          value={amountInCurrency}
          precision={2}
        />
      </TextComponent>
    </Flex>
  );
};

export default InvoiceConclusion;
