import React, { FunctionComponent, ReactNode } from 'react';
import {
  Col,
  Divider,
  Flex,
  Row,
  TextComponent,
} from '@monorepo/react-components';
import { Skeleton } from 'antd';

interface Props {
  title: string;
  isLoading?: boolean;
  items?: ReactNode[];
}

const AchievementSection: FunctionComponent<Props> = ({
  title,
  isLoading,
  items = [],
}) => {
  const renderLoader = () => {
    if (!isLoading) {
      return null;
    }

    return Array(6)
      .fill('*')
      .map((_, index) => (
        <Col xs={12} lg={8} xxl={6} key={index}>
          <div className="achievement-image">
            <Skeleton.Image active />
          </div>
        </Col>
      ));
  };

  return (
    <Row gutter={[5, 20]}>
      <Col span={24}>
        <Flex align="center" gap={32}>
          <TextComponent noWrap loading={isLoading} weight={500} level={3}>
            {title}
          </TextComponent>
          <Divider />
        </Flex>
      </Col>
      <Col span={24}>
        <Row gutter={[22, 22]}>
          {renderLoader()}
          {items.map((item, itemIndex) => (
            <Col xs={12} lg={8} xxl={6} key={itemIndex}>
              {item}
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
export default AchievementSection;
