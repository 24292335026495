import { FunctionComponent, useEffect, useState } from 'react';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { AccountStats } from '@monorepo/types';
import { useStore } from '../../../helpers/use-store';
import AdvancedStatistics, {
  AdvancedStatisticsType,
} from '../dashboard-common/advanced-statistics/advanced-statistics';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const formatDuration = (ms: number): string => {
  const durationObj = dayjs.duration(ms);
  const hours = durationObj.hours();
  const minutes = durationObj.minutes();
  const seconds = durationObj.seconds();

  return `${hours}h : ${minutes}m : ${seconds}s`;
};

interface Props {
  accountId: string;
}

const AdvancedStatisticsFivers: FunctionComponent<Props> = ({ accountId }) => {
  const {
    dataStore: { accountStore },
  } = useStore();

  const [accountStats, setAccountStats] = useState<AccountStats>({});
  const { updateLoadingState, isLoading } = useLoading();

  const leftColumn: AdvancedStatisticsType[] = [
    {
      title: 'Total Trades',
      value: accountStats.totalTrades || 0,
      tooltipText: 'Total number of trades',
    },
    {
      title: 'Profit Trades',
      value: accountStats.profitTrades || 0,
      tooltipText: 'Number of profitable trades',
    },
    {
      title: 'Loss Trades',
      value: accountStats.lossTrades || 0,
      tooltipText: 'Number of non-profitable trades',
    },
    {
      title: 'Long Trades',
      value: accountStats.longTrades || 0,
      tooltipText: 'The total number of long (buy) trades',
    },
    {
      title: 'Short Trades',
      value: accountStats.shortTrades || 0,
      tooltipText: 'The total number of short (sell) trades',
    },
    {
      title: 'Gross Profit',
      value: accountStats.grossProfit || 0,
      tooltipText: 'The sum of all the winning trades',
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Gross Loss',
      value: accountStats.grossLoss || 0,
      tooltipText: 'The sum of all the losing trades',
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Total Net Profit',
      value: accountStats.totalNetProfit || 0,
      tooltipText: 'Gross Profit + Gross Loss',
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Profit Factor',
      value: accountStats.profitFactor || 0,
      valuePrecision: 2,
      tooltipText:
        'The profit factor shows how many times the gross profit (Sum of all the winning trades) exceeds the gross loss (Sum of all the losing trades). A positive Profit Factor is represented by a value greater than 1',
    },
    {
      title: 'Win Rate',
      value: (accountStats.winRate || 0) * 100,
      tooltipText:
        'The ratio between the number of winning trades and the total number of trades',
      valuePrecision: 2,
      valueSuffix: '%',
    },
  ];

  const rightColumn: AdvancedStatisticsType[] = [
    {
      title: 'Best Profit',
      value: Math.max(0, accountStats.bestProfit || 0),
      tooltipText: 'The most profitable trade',
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Biggest Loss',
      value: Math.min(0, accountStats.biggestLoss || 0),
      tooltipText: 'The most losing trade',
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Avg. Profit',
      value: accountStats.avgProfit || 0,
      tooltipText: 'The average profit of all profitable trades',
      valuePrecision: 2,
      valuePrefix: '$',
    },
    {
      title: 'Avg. Loss',
      value: accountStats.avgLoss || 0,
      tooltipText: 'The average loss of all losing trades',
      valuePrecision: 2,
      valuePrefix: '$',
    },
    {
      title: 'Expectancy',
      value: accountStats.expectancy || 0,
      tooltipText:
        'Expectancy tells you what you can expect to make (win or lose) on every trade',
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Avg. Trade Size',
      value: accountStats.avgTradeSize || 0,
      tooltipText: 'The average size in lots of all trades',
      valuePrecision: 2,
    },
    {
      title: 'Avg. Trade Duration',
      value: formatDuration(accountStats.avgTradeDuration || 0),
      tooltipText: 'The average length (h:m:s) of all trades',
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getStats(accountId);

        setAccountStats(result);
      } catch (e) {
        console.error(`Failed getting account stats`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  return (
    <AdvancedStatistics
      leftColumn={leftColumn}
      rightColumn={rightColumn}
      isLoading={isLoading}
    />
  );
};

export default AdvancedStatisticsFivers;
