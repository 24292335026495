import {
  BaseIdentifiers,
  OrderIdentifier,
  ProgramIdentifier,
  TradingSystemIdentifier,
  UserIdentifier,
} from '../common/identifiers';
import { TradingSystemCredentials } from '../trading-system';
import { PartnerType } from '../partner';

export const MAX_CREATION_AMOUNT = 5;

export type Account = BaseIdentifiers &
  UserIdentifier &
  OrderIdentifier &
  ProgramIdentifier &
  TradingSystemIdentifier &
  TradingSystemCredentials & {
    status: AccountStatus;
    type: AccountType;
    state: AccountState;
    expireDate: Date;
    boosters: BoosterItem[];
    level: number;
    previousProgramId?: string;
    disableReason?: string;
    terminationReason?: TerminationReason;
    creationRetries?: number;
  };

export enum AccountType {
  Evaluation = 'evaluation',
  Demo = 'demo',
  Competition = 'competition',
  Funded = 'funded',
}

export const getAccountStateKey = (accountStateKey: AccountStateKey) =>
  `state.${accountStateKey}`;

export const getAccountDefaultState = () => {
  const state: any = {};

  Object.values(AccountStateKey).forEach((key) => {
    state[key] = false;
  });

  return state as AccountState;
};

export enum AccountStatus {
  Active = 'active',
  Disabled = 'disabled',
  PendingCreation = 'pending-creation',
}

export enum AccountStateKey {
  Terminated = 'terminated',
  DailyPause = 'dailyPause',
  DailyLoss = 'dailyLoss',
  Target = 'target',
  Expired = 'expired',
  Swing = 'swing',
}

export interface AccountState {
  [AccountStateKey.Terminated]: boolean;
  [AccountStateKey.DailyPause]: boolean;
  [AccountStateKey.DailyLoss]: boolean;
  [AccountStateKey.Target]: boolean;
  [AccountStateKey.Expired]: boolean;
  [AccountStateKey.Swing]?: boolean;
}

export const ACCOUNT_STATE_TITLES = {
  [AccountStateKey.Terminated]: 'Termination',
  [AccountStateKey.DailyPause]: 'Daily Pause',
  [AccountStateKey.DailyLoss]: 'Daily Loss',
  [AccountStateKey.Target]: 'Target',
  [AccountStateKey.Expired]: 'Expired',
  [AccountStateKey.Swing]: 'Swing',
};

export interface BoosterItem {
  type: PartnerType;
  id: string;
}

export enum ReasonToDisable {
  RefundOrder = 'refund order',
}

export enum TerminationReason {
  Cancelled = 'Cancelled',
  Refunded = 'Refunded',
}
