import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Payout, PayoutSource } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { useStore } from '../../helpers/use-store';
import dayjs from 'dayjs';
import { usePagination } from '@monorepo/client-common';
import PayoutStatusTag from '../../components/payout-status-tag/payout-status-tag';
import { TextComponent } from '@monorepo/react-components';

const Payouts = ({
  type,
  sourceId,
}: {
  sourceId: string;
  type: PayoutSource;
}) => {
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { payoutStore, userStore },
  } = useStore();
  const { page, limit, total, setTotal, setLimit, setPage } = usePagination();

  useEffect(() => {
    requestPage();
  }, [page, limit]);

  const requestPage = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      const response = await payoutStore.payouts(type, sourceId, {
        page,
        limit,
      });
      updateLoadingState(LoadingState.Loaded);
      setPayouts(response.results);
      setTotal(response.total);
    } catch (e) {
      console.error(
        `Failed loading payouts for page: ${page}, limit: ${limit}`,
        e
      );
      updateLoadingState(LoadingState.Error);
    }
  };

  const columns: ColumnsType<Payout> = [
    {
      title: 'Payout ID',
      dataIndex: 'payoutId',
      render: (_, item) => {
        return <TextComponent copyable={true}>{item._id}</TextComponent>;
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      render: (_, item) => {
        return (
          <>
            <div>
              <TextComponent>{userStore.fullName} </TextComponent>
            </div>
            <div>
              <TextComponent type="secondary">
                {userStore.currentUser.email}
              </TextComponent>
            </div>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, item: Payout) => {
        return <PayoutStatusTag status={item.status} />;
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (_, item: Payout) => {
        return <span>${item.amount}</span>;
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'method',
      align: 'center',
      render: (_, item) => {
        return (
          <TextComponent>
            {item.paymentMethod ? item.paymentMethod : '-'}
          </TextComponent>
        );
      },
    },
    {
      title: 'Completed at',
      dataIndex: 'completedAt',
      render: (_, item) => {
        return (
          <span>
            {item.completedAt
              ? dayjs(item.completedAt).format('DD MMM YYYY')
              : '-'}
          </span>
        );
      },
    },
    {
      title: 'Issued at',
      dataIndex: 'createdAt',
      render: (_, item) => {
        return <span>{dayjs(item.createdAt).format('DD MMM YYYY')}</span>;
      },
    },
  ];

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <div className="payouts">
      <Table
        onChange={(pagination) => {
          if (pagination.current) {
            setPage(pagination.current);
          }
          if (pagination.pageSize) {
            setLimit(pagination.pageSize);
          }
        }}
        size="small"
        pagination={{
          current: page,
          pageSize: limit,
          total,
        }}
        loading={isLoading}
        rowKey={(record) => record._id}
        bordered={false}
        columns={columns}
        dataSource={payouts}
      />
    </div>
  );
};

export default Payouts;
