import React, { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from '../../helpers/use-store';
import { Account, PartnerType, ThemeColors } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { ThemeWrapper } from '../../components/theme-wrapper/theme-wrapper';
import { Button } from '@monorepo/react-components';
import { Icons, IconsNames } from '@monorepo/icons';
import { useColor } from '../../hooks';

interface Props {
  account: Account;
  primary?: boolean;
}

const ChoosePartnerButton: FunctionComponent<Props> = ({
  account,
  primary,
}) => {
  const {
    dataStore: { programStore, partnerStore },
  } = useStore();
  const [isAllowedToChoosePartners, setIsAllowedToChoosePartners] =
    useState(true);
  const { loadingState, updateLoadingState } = useLoading();
  const { getColor } = useColor();

  useEffect(() => {
    partnerStore
      .fetchAll()
      .then((partners) => {
        const noPartners = partners.length === 0;

        if (noPartners) {
          setIsAllowedToChoosePartners(false);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        updateLoadingState(LoadingState.Loaded);
      });
  }, []);

  const program = programStore.get(account.programId);

  const programBoosters = program?.config?.boosters || {
    [PartnerType.Journal]: -1,
    [PartnerType.Analysis]: -1,
    [PartnerType.Research]: -1,
  };

  const isReachedMaximumInProgram = Object.keys(programBoosters).every(
    (partnerType) => {
      const typeAmount = account.boosters.filter(
        (booster) => booster.type === partnerType
      ).length;

      return typeAmount >= programBoosters[partnerType as PartnerType];
    }
  );

  if (isReachedMaximumInProgram) {
    return null;
  }

  const isLoaded = loadingState === LoadingState.Loaded;

  if (!isLoaded) {
    return null;
  }

  if (!isAllowedToChoosePartners) {
    return null;
  }

  return (
    <ThemeWrapper
      themeColor={
        primary ? ThemeColors.colorPrimary : ThemeColors.colorAttention
      }
    >
      <Button
        href={`/#/partners/${account.orderId}`}
        type={primary ? 'primary' : 'default'}
        icon={
          <Icons
            color={primary ? undefined : getColor(ThemeColors.colorAttention)}
            iconName={IconsNames.Plus}
          />
        }
      >
        Partners
      </Button>
    </ThemeWrapper>
  );
};

export default ChoosePartnerButton;
