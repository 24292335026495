import { Card, Col, Row, TextComponent } from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import DashboardTooltip from '../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import classNames from 'classnames';
import { useResponsiveness } from '@monorepo/client-common';
import { InactiveDayCardProps } from '../cards-wrapper/inactive-days';

import './progress-cards.scss';

const InactiveDaysProgress: FunctionComponent<InactiveDayCardProps> = ({
  inactivityDays,
  maxInactiveDays,
  tooltipText,
  isLoading,
}) => {
  const { isMobile } = useResponsiveness();

  return (
    <Card
      hoverable={false}
      className={classNames(
        'progress-cards',
        'progress-cards__card',
        'progress-cards__card--profitable-inactive'
      )}
    >
      <DashboardTooltip tooltipText={tooltipText} />
      <Row justify={'space-between'}>
        <Col xs={16} sm={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col xs={14} sm={20}>
              <TextComponent weight={400} level={isMobile ? 5 : 3}>
                Inactive Days
              </TextComponent>
            </Col>

            <Col xs={8} span={24}>
              <TextComponent
                loading={isLoading}
                level={isMobile ? 3 : 2}
                weight={600}
              >
                {inactivityDays}/
              </TextComponent>
              <TextComponent
                loading={isLoading}
                level={isMobile ? 3 : 2}
                weight={400}
              >
                {maxInactiveDays}
              </TextComponent>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default InactiveDaysProgress;
