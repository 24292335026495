import {
  Achievement,
  AchievementType,
  Application,
  PayoutAchievement,
} from '@monorepo/types';
import dayjs from 'dayjs';

const normalizedPayoutDisplay = (userPayout: number) => {
  if (userPayout) {
    return `$${userPayout.toFixed(2)}`;
  }
  return 'N/A';
};

const adjustFontSize = (
  text: string,
  maxWidth: number,
  initialFontSize: number,
  fontWeight: number,
  canvasContext: CanvasRenderingContext2D
) => {
  let fontSize = initialFontSize;
  canvasContext.font = `${fontWeight} ${fontSize}px Mona Sans`;

  while (canvasContext.measureText(text).width > maxWidth && fontSize > 10) {
    fontSize -= 1;
    canvasContext.font = `${fontWeight} ${fontSize}px Mona Sans`;
  }

  return fontSize;
};

interface DrawParams {
  height: number;
  width: number;
  text: string;
  ctx: CanvasRenderingContext2D;
  achievement: Achievement;
  amount: string;
}

type DrawTotalPayloadParams = Omit<DrawParams, 'achievement'> & {
  app: Application;
};

const drawPayoutTTP = ({
  height,
  width,
  text,
  ctx,
  achievement,
  amount,
}: DrawParams) => {
  const x = width / 2;
  const y = height / 2;
  const textFontSize = adjustFontSize(text, width * 0.9, 32, 500, ctx);

  ctx.font = `500 ${textFontSize}px Mona Sans`;
  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, x, y + 30);

  ctx.font = '500 40px Mona Sans';
  ctx.fillStyle = '#13a8a8';
  ctx.fillText(amount, x, y + 120);

  ctx.font = '14px Mona Sans';
  ctx.fillStyle = 'white';
  ctx.fillText(
    dayjs(achievement.createdAt).format('DD/MM/YY'),
    x - 115,
    height - 120
  );
};

const drawPayout5ers = ({
  height,
  width,
  text,
  ctx,
  achievement,
  amount,
}: DrawParams) => {
  const x = width / 2;
  const y = height / 2 + 20;
  const textFontSize = adjustFontSize(text, width * 0.9, 36, 500, ctx);

  ctx.font = `500 ${textFontSize}px Mona Sans`;
  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, x, y);

  ctx.font = '500 56px Mona Sans';
  ctx.fillStyle = '#E9C10F';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  const amountX = width / 2;
  ctx.fillText(amount, amountX, y + 100);

  ctx.font = '14px Mona Sans';
  ctx.fillStyle = 'white';
  ctx.fillText(
    dayjs(achievement.createdAt).format('DD/MM/YY'),
    x - 120,
    height - 110
  );
};

const drawCertificate5ers = ({
  height,
  width,
  text,
  ctx,
  achievement,
}: DrawParams) => {
  const x = width / 2;
  const y = height / 2;
  const textFontSize = adjustFontSize(text, width * 0.9, 33, 500, ctx);
  ctx.font = `${textFontSize}px Mona Sans`;
  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, x, y + 25);

  ctx.font = '15px Mona Sans';
  ctx.fillStyle = 'white';
  ctx.fillText(
    dayjs(achievement.createdAt).format('DD/MM/YY'),
    x - 125,
    height - 120
  );
};

const drawCertificateTTP = ({
  height,
  width,
  text,
  ctx,
  achievement,
}: DrawParams) => {
  const [firstname, lastname] = text.split(' ');
  const x = width / 2;
  const y = height / 2;

  const textFontSize = adjustFontSize(text, width * 1.7, 54, 500, ctx);

  ctx.font = `500 ${textFontSize}px Mona Sans`;

  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(firstname, x + 80, y - 80);
  ctx.fillText(lastname, x + 80, y - 10);

  ctx.font = '15px Mona Sans';
  ctx.fillStyle = 'white';
  ctx.fillText(
    dayjs(achievement.createdAt).format('DD/MM/YY'),
    x - 75,
    height - 60
  );
};

const drawTotalPayout = ({
  height,
  width,
  text,
  ctx,
  amount,
  app,
}: DrawTotalPayloadParams) => {
  const isTTP = app === Application.TTP;
  const x = width / 2;
  const y = height / 2 + 15;

  const textFontSize = adjustFontSize(text, width * 0.9, 42, 500, ctx);

  ctx.font = `500 ${textFontSize}px Mona Sans`;
  ctx.fillStyle = 'black';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, x, y);

  ctx.font = `700 42px Mona Sans`;
  ctx.fillStyle = isTTP ? 'black' : '#179DA6';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(amount, x, y + 95);
};

export const drawTotalPayoutAchievementMap = ({
  ctx,
  payoutAmount,
  canvas,
  userName,
  project,
  image,
}: {
  ctx: CanvasRenderingContext2D;
  image: HTMLImageElement;
  canvas: HTMLCanvasElement;
  userName: string;
  payoutAmount: number;
  project: Application;
}) => {
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  const amount = normalizedPayoutDisplay(payoutAmount);

  drawTotalPayout({
    ctx,
    amount,
    text: userName,
    height: canvas.height,
    width: canvas.width,
    app: project,
  });

  return canvas.toDataURL('image/jpeg', 1);
};

export const DrawFunctionMap: {
  [key in Application]: Record<AchievementType, (params: DrawParams) => void>;
} = {
  [Application.TTP]: {
    [AchievementType.Payout]: drawPayoutTTP,
    [AchievementType.ProgramAchievement]: drawCertificateTTP,
  },
  [Application.THE5ERS]: {
    [AchievementType.Payout]: drawPayout5ers,
    [AchievementType.ProgramAchievement]: drawCertificate5ers,
  },
};

export const drawAchievement = (
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  text: string,
  achievement: Achievement,
  project: Application
) => {
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  const fn = DrawFunctionMap[project][achievement.type];

  const metadata = achievement.metadata as PayoutAchievement;
  const userPayout = metadata?.userPayout || 0;

  const amount = normalizedPayoutDisplay(userPayout);

  if (fn != null) {
    fn({
      height: canvas.height,
      width: canvas.width,
      text,
      ctx,
      achievement,
      amount,
    });
  }

  return canvas.toDataURL('image/jpeg', 1);
};
