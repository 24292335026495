import { IdIdentifier, TimeIdentifiers } from '../common/identifiers';

export type Partner = IdIdentifier &
  TimeIdentifiers & {
    type: PartnerType;
    title: string;
    description: string;
    videoUrl: string;
    googleSheetId: string;
    enabled: boolean;
  };

export enum PartnerType {
  Analysis = 'analysis',
  Journal = 'journal',
  Research = 'research',
}

export enum PartnerName {
  TraderSync = 'trader-sync',
}
