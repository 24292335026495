import React, { FunctionComponent } from 'react';
import { Flex, TextComponent } from '@monorepo/react-components';

interface Props {
  firstName: string;
  lastName: string;
  address: string;
  phoneNumber: string;
  email: string;
}

const InvoiceClientDetails: FunctionComponent<Props> = ({
  firstName,
  lastName,
  address,
  phoneNumber,
  email,
}) => {
  return (
    <Flex gap={4} vertical className="invoice-section">
      <TextComponent weight={600} level={7}>
        Invoice To:
      </TextComponent>
      <TextComponent level={7} capitalize={true}>
        {firstName + ' ' + lastName}
      </TextComponent>
      <TextComponent level={7} capitalize={true}>
        {address}
      </TextComponent>
      <TextComponent level={7}>{phoneNumber}</TextComponent>
      <TextComponent level={7}>{email}</TextComponent>
    </Flex>
  );
};

export default InvoiceClientDetails;
