import { IdIdentifier, TimeIdentifiers } from '../common/identifiers';
import { CouponItem } from '../coupon/coupon';
import { ProductType } from '../product/product';
import { TradingSystem } from '../trading-system';

export type UserCoupon = IdIdentifier &
  TimeIdentifiers & {
    userId: string;
    couponId: string;
    used: boolean;
    expirationDate: Date;
    name: string;
    items: CouponItem[];
  };

export enum UserCouponStatus {
  Valid = 'valid',
  Expired = 'expired',
  UsedOut = 'used-out',
  AlreadyOwn = 'already-own',
  Other = 'other',
}
export const filterCouponsForProduct = ({
  productType,
  productId,
  coupons,
  tradingSystem,
}: {
  productType: ProductType;
  tradingSystem: TradingSystem;
  productId: string;
  coupons: UserCoupon[];
}) => {
  return coupons.filter((coupon) => {
    const item = coupon.items[0];
    if (item.productType === ProductType.General) {
      return true;
    }
    if (item.productType !== productType) {
      return false;
    }
    if (!item.metadata) {
      return true;
    }
    return (
      item.metadata.productId === productId &&
      item.metadata.tradingSystem === tradingSystem
    );
  });
};
export const isUserCouponRedeemAble = (coupon: UserCoupon) => {
  const item = coupon.items[0];
  return (
    item.productType === ProductType.Program ||
    item.productType === ProductType.General
  );
};
