import React, { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import { Empty, List, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import './notification-center.scss';
import NotificationItem from './notification-item';

interface Pros {
  loading: boolean;
}

const NotificationsList: FunctionComponent<Pros> = ({ loading }) => {
  const {
    dataStore: { userStore },
  } = useStore();
  const userNotifications = userStore.notifications;
  const placeHolder = new Array(2).fill(0);

  if (!loading && userNotifications.length === 0) {
    return (
      <div className={'notifications-wrapper'}>
        <Empty />
      </div>
    );
  }

  return (
    <List
      className={'notifications-wrapper'}
      dataSource={loading ? placeHolder : userNotifications}
      renderItem={(item) => (
        <List.Item>
          <Skeleton active loading={loading} paragraph={{ rows: 1 }}>
            <NotificationItem
              date={item.createdAt}
              message={item.message}
              id={item.id}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
export default observer(NotificationsList);
