import { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import {
  Account,
  AccountDailyPerformance as AccountDailyPerformanceType,
  ThemeColors,
} from '@monorepo/types';
import { isNumber } from 'lodash';
import AdvancedStatistics, {
  AdvancedStatisticsType,
} from '../../dashboard-common/advanced-statistics/advanced-statistics';

interface Props {
  account: Account;
  dailyProfitAndLoss: number;
}

const AdvancedStatisticsTTP: FunctionComponent<Props> = ({
  account,
  dailyProfitAndLoss,
}) => {
  const {
    dataStore: { accountStore, programStore },
  } = useStore();

  const [accountDailyPerformance, setAccountDailyPerformance] =
    useState<AccountDailyPerformanceType>({
      marketValue: 0,
      totalVolume: 0,
    });
  const { loadingState, updateLoadingState } = useLoading();
  const isLoading = loadingState === LoadingState.Loading;
  const program = programStore.get(account.programId);

  const dailyPause = program?.config.dailyPause || 0;

  let remainingRisk =
    dailyProfitAndLoss > 0 ? dailyPause : dailyPause + dailyProfitAndLoss;

  if (dailyPause * -1 >= dailyProfitAndLoss) {
    remainingRisk = 0;
  }
  const data: AdvancedStatisticsType[] = [
    {
      title: 'Remaining Risk',
      value: remainingRisk,
      valuePrecision: 2,
      valuePrefix: '$',
    },
    {
      title: 'Realized P&L',
      value: dailyProfitAndLoss,
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Market Value',
      value: accountDailyPerformance.marketValue,
      valuePrefix: '$',
      valuePrecision: 2,
    },
    {
      title: 'Total Volume',
      value: accountDailyPerformance.totalVolume,
      valuePrefix: '$',
      valuePrecision: 2,
      valueColor: ThemeColors.colorSuccess,
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getDailyPerformance(
          account.tsAccount
        );

        if (
          result &&
          isNumber(result.totalVolume) &&
          isNumber(result.marketValue)
        ) {
          setAccountDailyPerformance(result);
        }
      } catch (e) {
        console.error(`Failed getting overview`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [account.tsAccountId]);

  return <AdvancedStatistics leftColumn={data} isLoading={isLoading} />;
};

export default AdvancedStatisticsTTP;
