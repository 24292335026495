import { Table } from 'antd';
import { Statistic, TextComponent } from '@monorepo/react-components';
import {
  CreditAction,
  CreditTransaction as CreditTransactionType,
} from '@monorepo/types';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { useThemeToken } from '@monorepo/client-common';

export const CreditTransaction = ({
  item,
  loading,
}: {
  item: CreditTransactionType[];
  loading: boolean;
}) => {
  item.sort((a, b) => dayjs(b.createdAt).diff(a.createdAt));
  const { token } = useThemeToken();

  const columns: ColumnsType<CreditTransactionType> = [
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const actionText =
          record.action === CreditAction.Add ? 'Added' : 'Deducted';
        return <TextComponent type="secondary">{actionText}</TextComponent>;
      },
    },

    {
      title: 'Amount',
      key: 'amount',
      render: (_, record) => {
        const amountColor =
          record.amount > 0
            ? { color: token.colorSuccess }
            : { color: token.colorError };
        return (
          <Statistic
            value={record.amount}
            prefix="$"
            precision={2}
            valueStyle={amountColor}
          />
        );
      },
    },
    {
      title: 'Created',
      key: 'created',
      render: (_, record) => (
        <TextComponent type="secondary">
          {dayjs(record.createdAt).format('DD/MM/YY')}
        </TextComponent>
      ),
    },
    {
      title: 'Comment',
      key: 'note',
      render: (_, record) => {
        const orderText = `Order Id: #${record.orderId}`;
        return (
          <TextComponent type="secondary">
            {record.notes || (record.orderId ? orderText : '')}
          </TextComponent>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={item}
      loading={loading}
      style={{ maxHeight: '300px', overflowY: 'scroll' }}
      size={'small'}
      scroll={{ x: 100 }}
      pagination={false}
    />
  );
};
