import { Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { Observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import ProgramProduct from './program-product';
import { AnalyticsEventTypes, ProductType, Program } from '@monorepo/types';
import gtmService from '../../../../services/analytics/analytics';
import Currency from '../../components/currency/currency';
import { orderBy } from 'lodash';
import { useNewOrderProvider } from '../../components/new-order-provider/new-order-provider';
import { Flex } from '@monorepo/react-components';
import { LoadingState, useLoading } from '@monorepo/client-common';

import './program-product.scss';

const Products: FunctionComponent<{
  groupName?: string;
}> = ({ groupName }) => {
  const {
    form,
    onActionClick: onProductSelect,
    currencies,
  } = useNewOrderProvider();
  const type = form.getValues('productType');

  const {
    dataStore: { applicationConfigStore },
    uiStore: { productStore },
  } = useStore();

  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);

  const selectedProgramId = form.watch('productId');

  const products = productStore.getAvailableProducts(type, groupName);

  return (
    <Flex gap={32} vertical className="products-step">
      <Currency updateLoadingState={updateLoadingState} />
      <Observer>
        {() => (
          <Row gutter={[24, 24]}>
            {orderBy(products, ['config.buyingPower', 'price']).map(
              (product, index) => {
                const currency = form.getValues('paymentData.currency');
                const currencyRate = currencies ? currencies[currency] : 1;
                const amount = product.price * currencyRate;
                const currencyProductPrice = Number(amount.toFixed(2));

                return (
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={6}
                    key={product._id}
                  >
                    <Controller
                      name={'productId'}
                      control={form.control}
                      render={({ field }) => {
                        const program = product as Program;
                        const isAdvised = Boolean(program.advised);

                        const onClick = () => {
                          gtmService.sendEvent({
                            event: AnalyticsEventTypes.ChoseAccountProgram,
                            params: {
                              program_id: product._id,
                              program_name: product.name,
                              program_value: product.price.toString(),
                            },
                          });
                          field.onChange(product._id);

                          form.setValue('amount', product.price, {
                            shouldTouch: true,
                            shouldDirty: true,
                            shouldValidate: true,
                          });

                          form.setValue(
                            'paymentData.amount',
                            currencyProductPrice,
                            {
                              shouldTouch: false,
                              shouldDirty: false,
                              shouldValidate: false,
                            }
                          );

                          if (type === ProductType.Program) {
                            const programTradingSystems =
                              program.config?.tradingSystems
                                ?.filter((programTradingSystem) => {
                                  const appConfigTradingSystem =
                                    applicationConfigStore.applicationConfig.tradingSystems?.find(
                                      (applicationTradingSystem) =>
                                        applicationTradingSystem.tradingSystem ===
                                        programTradingSystem.tradingSystem
                                    );

                                  return (
                                    appConfigTradingSystem &&
                                    appConfigTradingSystem.sellable
                                  );
                                })
                                .map(
                                  (programTradingSystem) =>
                                    programTradingSystem.tradingSystem
                                );

                            const tradingSystems =
                              programTradingSystems &&
                              programTradingSystems.length > 0
                                ? programTradingSystems
                                : [program.tradingSystem];

                            const isSingleTradingSystem =
                              tradingSystems.length === 1;

                            if (isSingleTradingSystem) {
                              const tradingSystem = tradingSystems[0];

                              form.setValue('tradingSystem', tradingSystem, {
                                shouldTouch: false,
                                shouldDirty: false,
                                shouldValidate: false,
                              });
                            }
                          }
                        };

                        return (
                          <ProgramProduct
                            currencyProductPrice={currencyProductPrice}
                            productType={type}
                            isAdvised={isAdvised}
                            active={selectedProgramId === product._id}
                            program={product as Program}
                            disabled={loadingState !== LoadingState.Loaded}
                            onClick={() => {
                              onClick();
                              onProductSelect();
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                );
              }
            )}
          </Row>
        )}
      </Observer>
    </Flex>
  );
};

export default Products;
