import { LoadingState, useLoading } from '@monorepo/client-common';
import React, { useEffect } from 'react';
import { useStore } from '../../../helpers/use-store';
import { Skeleton } from 'antd';

export const AffiliateMainDashboard = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        await userStore.getUserAffiliationDashboard();
        updateLoadingState(LoadingState.Loaded);
      } catch (e) {
        console.log('could not get affiliation dashboard', e);
        updateLoadingState(LoadingState.Error);
      }
    };
    init();
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton />;
  }

  return (
    <iframe
      src={userStore.affiliationDashboard}
      title={'Dashboard'}
      style={{
        border: 0,
      }}
      width={'100%'}
      height={'100%'}
    />
  );
};
