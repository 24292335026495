import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { AffiliationCommission } from '@monorepo/types';
import { EntityStore } from '../base/entity-store';
import AffiliationCommissionService from '../../../services/entities/affiliation-commissions-service';

export default class AffiliationCommissions extends EntityStore<
  AffiliationCommissionService,
  AffiliationCommission
> {
  constructor() {
    const {
      apiFactory: { affiliationCommissionService },
    } = getEnv();

    super(affiliationCommissionService);
  }
}
