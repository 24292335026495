import React, { useEffect, useState } from 'react';
import WithdrawFundsContent from './withdraw-funds-content';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../helpers/use-store';
import {
  PayoutMinimum,
  PayoutSource,
  WithdrawRulesResponse,
} from '@monorepo/types';

const WithdrawFunds = ({
  source,
  sourceId,
  displayId,
}: {
  source: PayoutSource;
  sourceId?: string;
  displayId?: string;
}) => {
  const {
    dataStore: { payoutStore, userStore },
  } = useStore();

  const [eligible, setEligible] = useState<WithdrawRulesResponse>({
    isValid: false,
    availableAmount: 0,
  });

  const init = async () => {
    if (!sourceId) {
      setEligible({ isValid: false, availableAmount: 0 });
      return;
    }
    const result = await payoutStore.eligibleForWithdraw({
      source: source,
      sourceId: sourceId,
    });
    setEligible(result);
  };

  useEffect(() => {
    init();
  }, []);

  const amountSelection = source === PayoutSource.Account;
  const impersonated = userStore.isImpersonated;

  return (
    <WithdrawFundsContent
      onClose={init}
      credits={eligible?.credits || 0}
      userPayoutPercentage={eligible?.userPayoutPercentage || 0}
      disabled={!eligible.isValid || impersonated}
      maxWithdrawal={eligible.availableAmount}
      reason={eligible.reason}
      showAmountSelection={amountSelection}
      source={source}
      sourceId={sourceId || ''}
      minWithdrawal={PayoutMinimum}
      displayId={displayId || ''}
    />
  );
};

export default observer(WithdrawFunds);
