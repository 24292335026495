import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import {
  Achievement,
  getTotalPayoutImageTag,
  StorageContentType,
} from '@monorepo/types';
import { EntityStore } from '../base/entity-store';
import AchievementService from '../../../services/entities/achievement-service';

export default class AchievementStore extends EntityStore<
  AchievementService,
  Achievement
> {
  constructor() {
    const {
      apiFactory: { achievementService },
    } = getEnv();

    super(achievementService);
  }

  async createShareableImage(achievement: Achievement, imageUrl: string) {
    const {
      apiFactory: { achievementService },
    } = getEnv();
    if (achievement.link) {
      return achievement.link;
    }

    const { shareableUrl } = await achievementService.createShareableImage(
      achievement._id,
      imageUrl
    );

    achievement.link = shareableUrl;
    return shareableUrl;
  }

  async createTotalPayoutImage({
    imageUrl,
    amount,
    userId,
  }: {
    amount?: number;
    imageUrl: string;
    userId: string;
  }) {
    const {
      apiFactory: { achievementService, assetsService },
    } = getEnv();

    if (amount === undefined) {
      throw new Error('Invalid amount provided for total payout image');
    }

    const urlExists = await achievementService.checkIfTotalPayoutImageExists(
      userId,
      amount
    );

    if (urlExists) {
      return urlExists;
    }

    const tag = getTotalPayoutImageTag(userId, amount);

    return await assetsService.uploadTotalPayoutImage({
      type: StorageContentType.Achievements,
      imageUrl,
      tag,
      publicId: tag,
    });
  }
}
