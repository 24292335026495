import {
  Card,
  Flex,
  TextComponent,
  ProgressSlider,
  Row,
  Col,
} from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import { IconsNames } from '@monorepo/icons';
import { AccountRulesCardIcon } from '../account-rules-icon/account-rules-icon';
import { useResponsiveness } from '@monorepo/client-common';
import { ThemeColors } from '@monorepo/types';
import { useColor } from '../../../../../hooks';
import DashboardTooltip from '../../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import { InactiveDayCardProps } from '../../cards-wrapper/inactive-days';

const InactiveDaysRule: FunctionComponent<InactiveDayCardProps> = ({
  inactivityDays,
  maxInactiveDays,
  tooltipText,
  isLoading,
}) => {
  const { isMobile } = useResponsiveness();
  const { getColor } = useColor();

  const colorLink = getColor(ThemeColors.colorLink);
  const colorError = getColor(ThemeColors.colorError);

  const violated = inactivityDays >= maxInactiveDays;

  const trackColor = violated ? ThemeColors.colorError : ThemeColors.colorLink;

  return (
    <Card hoverable={false} dashed error={violated} className={'account-rules'}>
      <Row gutter={[5, 10]}>
        <DashboardTooltip tooltipText={tooltipText} />
        <Col span={8} sm={24}>
          <Row
            gutter={[0, 15]}
            justify={'space-between'}
            className={'account-rules__header'}
          >
            <Col span={18}>
              <Flex gap={5} align={'center'}>
                {!isMobile && (
                  <AccountRulesCardIcon
                    violated={violated}
                    iconName={IconsNames.Plug}
                  />
                )}

                <TextComponent
                  responsive={false}
                  color={colorLink}
                  weight={600}
                >
                  {'Inactive Days'}
                </TextComponent>
              </Flex>
            </Col>
          </Row>
        </Col>

        <Col span={16} sm={24}>
          <Flex
            justify={'end'}
            vertical
            className={'account-rules__slider-section'}
          >
            <Flex>
              <TextComponent
                loading={isLoading}
                weight={600}
                color={violated ? colorError : undefined}
                level={3}
              >
                {inactivityDays}/
              </TextComponent>
              <TextComponent loading={isLoading} level={3}>
                {maxInactiveDays}
              </TextComponent>
            </Flex>

            <ProgressSlider
              disabled
              value={inactivityDays}
              trackColor={trackColor}
              max={maxInactiveDays}
            />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default InactiveDaysRule;
