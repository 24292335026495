import { theme } from 'antd';
import { ThemeColors } from '@monorepo/types';

export const useColor = () => {
  const { useToken } = theme;
  const { token } = useToken();

  const getColor = (color: ThemeColors) => {
    const isTokenColor = Object.keys(token).includes(color);
    const bodyStyle = getComputedStyle(document.body);

    const resultColors = isTokenColor
      ? token[color as keyof typeof token]
      : bodyStyle.getPropertyValue(`--${color}`);

    if (!resultColors) {
      console.error(`Color ${color} not found`);
      return '#e2e2e2';
      // throw new Error(`Color ${color} not found`);
    }

    return resultColors as string;
  };

  return { getColor };
};
