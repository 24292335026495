// TODO: create mongo collection of it ( keep the enum as name)
import { OrderStatus, PaymentData } from '../order/order';

export enum PaymentProviderName {
  PayPal = 'paypal',
  Checkout = 'checkout',
  Confirmo = 'confirmo',
  Fibonatix = 'fibonatix',
  Stripe = 'stripe',
  Credits = 'credits',
  Free = 'free',
}

export const internalPaymentMethods = [
  PaymentProviderName.Credits,
  PaymentProviderName.Free,
];

export type ProviderPaymentData = {
  paymentData: PaymentData;
  userId?: string;
  uniqId: string;
  status: OrderStatus;
};

export enum SupportedCurrencies {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  INR = 'INR',
}

export type CurrencyRates = Record<string, number>;

export type PaymentPageResponse = {
  paymentPage: string;
};

export interface PaymentWebhookDetails {
  orderUniqId: string;
  orderStatus: OrderStatus;
}

export interface PaymentRedirectDetails {
  orderUniqId: string;
}

export const CurrencyToSign: { [key: string]: string } = {
  [SupportedCurrencies.INR]: '₹',
  [SupportedCurrencies.USD]: '$',
  [SupportedCurrencies.EUR]: '€',
  [SupportedCurrencies.GBP]: '£',
};

export const PaymentPercentageFee = 0.028;
