import { FunctionComponent } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { Account, AccountStatus, AccountType } from '@monorepo/types';
import ProfitableDays from '../cards-wrapper/profitable-days';
import InactiveDays, {
  DashboardCardType,
} from '../cards-wrapper/inactive-days';

interface Props {
  account: Account;
  consecutiveInactivityDays?: number;
}

const AccountRules: FunctionComponent<Props> = ({
  account,
  consecutiveInactivityDays = 0,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const isFundedAccount = account.type === AccountType.Funded;
  const showProfitWithdraw =
    isFundedAccount && account.status === AccountStatus.Active;

  const showProfitableDays = programStore.hasProfitableDays(account.programId);
  const showInactiveDays = programStore.hasInactiveDays(account.programId);

  return showProfitWithdraw ? (
    <>
      {showProfitableDays && (
        <ProfitableDays
          accountId={account.tsAccount}
          programId={account.programId}
          type={DashboardCardType.Rule}
        />
      )}
      {showInactiveDays && (
        <InactiveDays
          consecutiveInactivityDays={consecutiveInactivityDays}
          accountId={account.tsAccount}
          programId={account.programId}
          type={DashboardCardType.Rule}
        />
      )}
    </>
  ) : (
    showProfitableDays && (
      <InactiveDays
        consecutiveInactivityDays={consecutiveInactivityDays}
        accountId={account.tsAccount}
        programId={account.programId}
        type={DashboardCardType.Rule}
      />
    )
  );
};

export default AccountRules;
