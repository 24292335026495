import React, { useEffect } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Alert, AlertProps, Skeleton } from 'antd';
import { UserVerificationStatus } from '@monorepo/types';

const UserVerificationWrapper = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const accessToken = userStore.getKycToken();

  useEffect(() => {
    if (!accessToken) {
      loadNewToken();
    }
  }, []);

  const loadNewToken = async () => {
    updateLoadingState(LoadingState.Loading);
    await userStore.createKycToken();
    updateLoadingState(LoadingState.Loaded);
  };

  const createProfileAlert = () => {
    const alertProps: AlertProps = {
      type: 'info',
      message: 'Verification required',
    };
    const verification = userStore.currentUser.verificationData;
    if (
      !verification ||
      verification.status === UserVerificationStatus.NotRequired
    ) {
      return null;
    }
    if (verification.status === UserVerificationStatus.Approved) {
      alertProps.type = 'success';
      alertProps.message = 'Verification completed successfully';
    }
    if (verification.status === UserVerificationStatus.InProgress) {
      alertProps.type = 'warning';
      alertProps.message = 'Verification in progress';
    }
    if (verification.status === UserVerificationStatus.Rejected) {
      alertProps.type = 'error';
      alertProps.message = 'Verification Failed';
      alertProps.description = `Verification Failed.\n ${verification.rejectionComment}`;
    }
    return <Alert showIcon {...alertProps} />;
  };

  return (
    <Skeleton loading={loadingState === LoadingState.Loading} active>
      {createProfileAlert()}
      <SumsubWebSdk
        accessToken={accessToken || ''}
        expirationHandler={loadNewToken}
        config={{
          lang: 'en',
        }}
        options={{ addViewportTag: false, adaptIframeHeight: true }}
        onMessage={(m: any) => console.log(m)}
        onError={(e: any) => console.log(e)}
      />
    </Skeleton>
  );
};

export default UserVerificationWrapper;
