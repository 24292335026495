import { FunctionComponent, useEffect } from 'react';
import { Skeleton } from 'antd';
import {
  Flex,
  FormRadioButtonGroup,
  TextComponent,
  CurrencyIcon,
} from '@monorepo/react-components';
import { useStore } from '../../../../helpers/use-store';
import { CurrencyType, LoadingState } from '@monorepo/client-common';
import { useNewOrderProvider } from '../new-order-provider/new-order-provider';

import './currency.scss';

interface CurrencyProps {
  updateLoadingState: (loadingState: LoadingState) => void;
}

const Currency: FunctionComponent<CurrencyProps> = ({ updateLoadingState }) => {
  const { form, currencies, setCurrencies } = useNewOrderProvider();
  const {
    dataStore: { orderStore },
    uiStore: { productStore },
  } = useStore();

  const productType = form.getValues('productType');
  const productId = form.getValues('productId');
  const paymentCurrency = form.getValues('paymentData.currency');
  const product = productStore.getProduct(productType, productId);

  useEffect(() => {
    orderStore
      .getCurrencies()
      .then((curr) => {
        if (curr) {
          setCurrencies(curr);

          if (form.getValues('paymentData.currency')) {
            return;
          }

          const firstCurrencyName = Object.keys(curr)[0];

          form.setValue('paymentData.currency', firstCurrencyName, {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      })
      .finally(() => {
        updateLoadingState(LoadingState.Loaded);
      });
  }, []);

  useEffect(() => {
    if (currencies) {
      const currentCurrency = currencies[paymentCurrency];
      const currentCurrencyPrice = (
        (product?.price || 0) * currentCurrency
      ).toFixed(2);
      form.setValue('paymentData.amount', Number(currentCurrencyPrice));
    }
  }, [paymentCurrency]);

  if (currencies === null) {
    return (
      <span className="currency__loader">
        <Skeleton.Input size="large" active={true} />
      </span>
    );
  }

  const currenciesDataOptions = Object.keys(currencies).map((currency) => {
    return {
      text: (
        <Flex gap={8} align="center" justify="center">
          <CurrencyIcon type={CurrencyType.Regular} name={currency} />
          <TextComponent responsive={false} level={7}>
            {currency}
          </TextComponent>
        </Flex>
      ),
      value: currency,
    };
  });

  return (
    <Flex justify="center" className="currency__container">
      <div className="currency__container__radio-group">
        <FormRadioButtonGroup
          form={form}
          controllerProps={{
            name: 'paymentData.currency',
          }}
          options={currenciesDataOptions}
        />
      </div>
    </Flex>
  );
};

export default Currency;
