import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import './notification-center.scss';
import { Avatar, Flex } from 'antd';
import { TextComponent } from '@monorepo/react-components';
import dayjs from 'dayjs';
import { useThemeToken } from '@monorepo/client-common';
import { UserOutlined } from '@ant-design/icons';

interface Pros {
  message: string;
  icon?: string;
  id: string;
  date?: Date;
}

const createDisplayNotificationDate = (date: Date) => {
  const daysSince = dayjs(dayjs()).diff(date, 'day');

  if (daysSince > 30) {
    return dayjs(date).format('DD/MM/YYYY');
  }

  if (daysSince < 30 && daysSince >= 1) {
    return `${daysSince}d ago`;
  }
  const minutesSince = dayjs(dayjs()).diff(date, 'minutes');

  const hours =
    Math.floor(minutesSince / 60) >= 1
      ? `${Math.floor(minutesSince / 60)}h and`
      : ``;

  return `${hours} ${minutesSince % 60}m ago`;
};

const NotificationsItem: FunctionComponent<Pros> = ({
  message,
  id,
  icon,
  date,
}) => {
  const dateDisplay = createDisplayNotificationDate(date || new Date());

  const { token } = useThemeToken();

  return (
    <Flex className={'notification-item'} key={id}>
      <Avatar src={icon} size={'large'} icon={<UserOutlined />} />
      <Flex align={'flex-start'} vertical>
        <span
          className="notification-message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <TextComponent className={'date'} style={{ color: token.colorPrimary }}>
          {dateDisplay}
        </TextComponent>
      </Flex>
    </Flex>
  );
};
export default observer(NotificationsItem);
