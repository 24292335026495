import { FunctionComponent } from 'react';
import { Flex } from '@monorepo/react-components';
import { Icons, IconsNames } from '@monorepo/icons';
import { useResponsiveness } from '@monorepo/client-common';
import classNames from 'classnames';

type Props = {
  isActive?: boolean;
  icon: IconsNames;
};
const ProgramGroupIcon: FunctionComponent<Props> = ({ icon, isActive }) => {
  const { isDesktop } = useResponsiveness();

  return (
    <Flex
      align="center"
      justify="center"
      className={classNames(`program-groups__card__icon`, {
        'program-groups__card__icon--desktop': isDesktop,
        'program-groups__card__icon--mobile': !isDesktop,
        'program-groups__card__icon--active': isActive,
      })}
    >
      <Icons iconName={icon} />
    </Flex>
  );
};

export default ProgramGroupIcon;
