import { Descriptions, Empty, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import dayjs from 'dayjs';
import { Flex, TextComponent } from '@monorepo/react-components';

interface Props {
  profitableDays?: Date[];
}

const AccountProfitableDays: FunctionComponent<Props> = ({
  profitableDays,
}) => {
  const items = (profitableDays || []).map((day, index) => {
    return {
      key: index,
      label: `Day ${index + 1}`,
      children: dayjs(day).format('DD-MM-YY'),
    };
  });

  if (items.length == 0) {
    return (
      <>
        <Typography.Text>No profitable days</Typography.Text>
        <Empty />
      </>
    );
  }

  return (
    <Flex vertical gap={20}>
      <TextComponent level={4} weight={600}>
        Profitable Days ({items.length})
      </TextComponent>
      <Descriptions column={1} items={items} />
    </Flex>
  );
};

export default AccountProfitableDays;
