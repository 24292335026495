export interface Position {
  _id: string;
  createdAt: string;
  updatedAt: string;

  accountId: string;
  symbol: string;
  state: PositionState;
  openTime: Date;
  closeTime?: Date;
  side: PositionSide;
  finalQuantity?: number;
  openQuantity: number;
  entryPrice: number;
  exitPrice?: number;
  profitAndLoss: number;
  totalFee: number;
}

export enum PositionSide {
  Buy = 'buy',
  Sell = 'sell',
}

export enum PositionState {
  Open = 'open',
  Closed = 'closed',
}
