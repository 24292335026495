import { IdIdentifier } from '../common/identifiers';

export enum ProductType {
  Program = 'program',
  AddOn = 'add-on',
  Credit = 'credit',
  General = 'general',
}

export type Product = IdIdentifier & {
  name: string;
  price: number;
};

export type ProductMetaData = {
  productName?: string;
  accountId?: string;
};
