import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import {
  Application,
  ContractData,
  ContractTitle,
  ContractType,
  User,
} from '@monorepo/types';
import { Button, Checkbox, Flex, Modal } from 'antd';
import FundedModalContent5ers from './funded-modal-content/funded-modal-content-5ers';
import { Space } from '@monorepo/react-components';
import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import AffiliationModalContent from './affiliation-modal-content/affiliation-modal-content';
import { useForm, UseFormReturn } from 'react-hook-form';
import NyseModalContent from './nyse-modal-content/nyse-modal-content';
import './contracts.scss';
import FundedModalContentTtp from './funded-modal-content/funded-modal-content-ttp';
const project = import.meta.env.VITE_PROJECT as Application;

interface Props {
  contractType: ContractType;
  onContractHandled?: () => void;
}

const getContract = (
  contractType: ContractType,
  form: UseFormReturn<ContractData>
): { content: ReactNode; title: string } => {
  const ContractMap: {
    [key: string]: { [key: string]: { content: ReactNode; title: string } };
  } = {
    [Application.THE5ERS]: {
      [ContractType.Funded]: {
        content: <FundedModalContent5ers />,
        title: ContractTitle.Funded,
      },
      [ContractType.Affiliation]: {
        content: <AffiliationModalContent />,
        title: ContractTitle.Funded,
      },
    },
    [Application.TTP]: {
      [ContractType.Funded]: {
        content: <FundedModalContentTtp />,
        title: ContractTitle.Funded,
      },
      [ContractType.Affiliation]: {
        content: <AffiliationModalContent />,
        title: ContractTitle.Funded,
      },
      [ContractType.Nyse]: {
        content: <NyseModalContent form={form} />,
        title: ContractTitle.Nyse,
      },
    },
  };
  const contracts = ContractMap[project];
  return contracts[contractType] || {};
};

const formDefaultValues = (contractType: ContractType, currentUser: User) => {
  const map: { [key: string]: any } = {
    [ContractType.Nyse]: {
      firstAgreement: false,
      receiveDataNonBusinessUse: false,
      hasSECOrCFTC: false,
      securityAgency: false,
      similarToSECOrCFTC: false,
      investmentAdvices: false,
      assetManager: false,
      usingOtherCapital: false,
      tradeForOtherCorpOrOther: false,
      sharingProfit: false,
      sharingSpace: false,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      address: currentUser.address,
      occupations: '',
      companyName: '',
      employer: '',
      employerAddress: '',
      title: '',
      signThatProvidedDataIsCorrect: false,
      finalAgreement: false,
    },
  };
  return map[contractType] || {};
};

const isContractWithData = (contractType: ContractType) => {
  return ContractType.Nyse === contractType;
};

const isContractSigned = (
  contractType: ContractType,
  acceptedTermsAndConditions: boolean,
  form: UseFormReturn<ContractData>
) => {
  if (ContractType.Nyse === contractType) {
    return form.watch('finalAgreement');
  }
  return acceptedTermsAndConditions;
};

const ContractModal: FunctionComponent<Props> = ({
  contractType,
  onContractHandled,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);
  const {
    dataStore: { userStore, applicationConfigStore },
  } = useStore();

  const form = useForm<ContractData>({
    mode: 'all',
    defaultValues: formDefaultValues(contractType, userStore.currentUser),
  });

  const contractWithData = isContractWithData(contractType);
  const contractSigned = isContractSigned(
    contractType,
    acceptedTermsAndConditions,
    form
  );

  useEffect(() => {
    const availableContracts =
      applicationConfigStore.applicationConfig.contracts || [];
    const contract = availableContracts.find(
      (contract) => contract.type === contractType
    );

    if (!contract) {
      onClose();
      return;
    }

    const showModal = userStore.contractSignNeeded(
      contractType,
      contract.currentVersion
    );

    if (!showModal) {
      onClose();
      return;
    }

    setModalVisible(true);
  }, []);

  const handleContractSign = async () => {
    try {
      const contractData = form.getValues();
      updateLoadingState(LoadingState.Loading);
      await userStore.signUserContract(contractType, contractData);
      updateLoadingState(LoadingState.Loaded);
      onClose();
    } catch (e) {
      updateLoadingState(LoadingState.Error);
      console.error(e);
    }
  };

  const onClose = () => {
    setModalVisible(false);
    onContractHandled && onContractHandled();
  };

  const isLoading = loadingState === LoadingState.Loading;
  const contract = getContract(contractType, form);

  return (
    <Modal
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      title={contract.title}
      open={modalVisible}
      width={700}
      footer={
        contractSigned && (
          <Button
            disabled={isLoading}
            onClick={handleContractSign}
            loading={isLoading}
          >
            Accept and Continue
          </Button>
        )
      }
    >
      <Space direction={'vertical'} size={20}>
        <div>{contract.content}</div>

        {!contractWithData && (
          <Flex gap={10}>
            <Checkbox
              onChange={(value) => {
                setAcceptedTermsAndConditions(!acceptedTermsAndConditions);
              }}
              value={acceptedTermsAndConditions}
            />
            <div>Agree with terms and condition and sign the contract</div>
          </Flex>
        )}
      </Space>
    </Modal>
  );
};

export default ContractModal;
