import {
  Card,
  Flex,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import DashboardTooltip from '../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import { useResponsiveness } from '@monorepo/client-common';
import { Account, Application } from '@monorepo/types';

import './progress-cards.scss';

interface Props {
  profit: number;
  account: Account;
  application: Application;
}

const ProfitWithdrawal: FunctionComponent<Props> = ({
  profit,
  account,
  application,
}) => {
  const { isMobile } = useResponsiveness();

  const tooltipText = {
    [Application.TTP]: 'Balance-Invalid Profits',
    [Application.THE5ERS]: 'P&L + Credits considered for withdrawal',
  };

  return (
    <Card
      hoverable={false}
      className={classNames(
        'progress-cards',
        'progress-cards__card',
        'progress-cards__card--withdraw'
      )}
    >
      <Flex vertical>
        <Flex justify={'space-between'}>
          <TextComponent weight={400} level={3}>
            Profit
          </TextComponent>
          <DashboardTooltip tooltipText={tooltipText[application]} />
        </Flex>
        <Flex
          vertical={!isMobile}
          gap={4}
          justify={'space-between'}
          align={isMobile ? 'center' : 'start'}
        >
          <TextComponent level={isMobile ? 3 : 2} weight={600}>
            <Statistic prefix={'$'} value={profit} precision={2} />
          </TextComponent>
        </Flex>
      </Flex>
    </Card>
  );
};
export default ProfitWithdrawal;
