import { FunctionComponent, useEffect, useState } from 'react';
import { App } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  Application,
  ClosedPosition,
  ClosedPositionStatus,
  PositionInvalidReason,
  SortDirection,
  ThemeColors,
  TimeZone,
} from '@monorepo/types';
import dayjs from 'dayjs';
import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading, useSort } from '@monorepo/client-common';
import TradesTable from '../trades-table/trades-table';
import { PositionState } from '@monorepo/sterling-types';
import {
  Space,
  Table,
  TextComponent,
  Statistic,
  Flex,
  Label,
  Tooltip,
} from '@monorepo/react-components';
import { isBoolean, orderBy } from 'lodash';
import { Icons, IconsNames } from '@monorepo/icons';
import { useColor } from '../../hooks';
import { PositionSideTag } from './positions.util';

import './trades-positions.scss';

const PositionInvalidReasonToText: Record<PositionInvalidReason, string> = {
  [PositionInvalidReason.Minimum60SecondsBetweenOpenAndClose]: `There should be at least 60 seconds between position open and close time.`,
  [PositionInvalidReason.DifferenceBetweenEntryAndExit10Cents]: `There should be at least 10 cents between position open and close price.`,
  [PositionInvalidReason.NewsTime]: `Invalid Trade - Trades opened 2 minutes before or after high-impact news are deducted.`,
};

export interface Props {
  application?: Application;
  accountId: string;
  expandTrades?: boolean;
  hideSwap?: boolean;
}

const ClosedPositionsTable: FunctionComponent<Props> = ({
  application,
  accountId,
  expandTrades = true,
  hideSwap = false,
}) => {
  const {
    dataStore: { positionStore },
  } = useStore();
  const { sort, onSortChange } = useSort<ClosedPosition>({
    sortField: 'closeDate',
  });

  const [closedPositions, setClosedPositions] = useState<ClosedPosition[]>([]);
  const { getColor } = useColor();
  const { loadingState, updateLoadingState } = useLoading();
  const { modal } = App.useApp();
  const isThe5ers = application === Application.THE5ERS;

  const sortedClosedPositions = sort
    ? orderBy(
        closedPositions,
        [sort.field],
        [sort.direction === SortDirection.Desc ? 'desc' : 'asc']
      )
    : closedPositions;

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await positionStore.getClosedPositions(accountId);
        setClosedPositions(result.results);
      } catch (e) {
        console.error(e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const columns: ColumnsType<ClosedPosition> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (_, position) => {
        return (
          <TextComponent level={8} responsive={false} weight={600}>
            {position.id || position.externalId}
          </TextComponent>
        );
      },
    },
    {
      title: 'Open Date',
      dataIndex: 'openDate',
      sorter: true,

      render: (_, position) => {
        let date = dayjs(position.openDate).format('MMM D hh:mm A');

        if (isThe5ers) {
          date = dayjs(position.openDate)
            .tz(TimeZone.Kyiv)
            .format('MMM D HH:mm:ss');
        }

        return <span>{date}</span>;
      },
    },
    {
      title: 'Close Date',
      dataIndex: 'closeDate',
      sorter: true,
      render: (_, position) => {
        let date = dayjs(position.closeDate).format('MMM D hh:mm A');

        if (isThe5ers) {
          date = dayjs(position.closeDate)
            .tz(TimeZone.Kyiv)
            .format('MMM D HH:mm:ss');
        }

        return <span>{date}</span>;
      },
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      sorter: true,
      render: (_, position) => {
        return <Label color="colorInfo">{position.symbol}</Label>;
      },
    },
    {
      title: 'Side',
      dataIndex: 'side',
      sorter: true,
      render: (_, position) => {
        return <PositionSideTag side={position.side} />;
      },
    },
    {
      title: 'Entry',
      dataIndex: 'entry',

      render: (_, position) => {
        return (
          <Statistic value={position.entry} precision={isThe5ers ? 4 : 2} />
        );
      },
    },
    {
      title: 'Exit',
      dataIndex: 'exit',

      render: (_, position) => {
        return (
          <Statistic value={position.exit} precision={isThe5ers ? 4 : 2} />
        );
      },
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Fee',
      dataIndex: 'fee',

      render: (_, position) => {
        return <Statistic value={position.fee} precision={2} prefix={'$'} />;
      },
    },
    !hideSwap
      ? {
          title: 'Swap',
          dataIndex: 'swap',
          render: (_, position) => {
            return (
              <Statistic
                value={position.swap || 0}
                precision={2}
                prefix={'$'}
              />
            );
          },
        }
      : {},
    {
      title: 'P&L',
      dataIndex: 'profitAndLoss',
      sorter: true,
      render: (_, position) => {
        const isInvalid = isBoolean(position.isValid) && !position.isValid;
        const invalidReasons = position.invalidReasons || [];
        const stat = (
          <TextComponent
            color={isInvalid ? getColor(ThemeColors.colorError) : undefined}
            weight={600}
          >
            <Statistic
              value={position.profitAndLoss}
              precision={2}
              prefix={'$'}
            />
          </TextComponent>
        );

        if (isInvalid && invalidReasons.length > 0) {
          const TooltipTexts = invalidReasons.map((reason) => (
            <TextComponent>{PositionInvalidReasonToText[reason]}</TextComponent>
          ));

          return (
            <Tooltip
              title={<Space direction={'vertical'}>{TooltipTexts}</Space>}
            >
              {stat}
            </Tooltip>
          );
        }

        return stat;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',

      render: (_, position) => {
        const isWin = position.profitAndLoss > 0;

        const status = isWin
          ? ClosedPositionStatus.Win
          : ClosedPositionStatus.Loss;
        const icon = isWin ? IconsNames.CaretUp : IconsNames.CaretDown;

        return (
          <Label color={isWin ? 'colorSuccess' : 'colorActive'}>
            <Flex align="baseline" gap={5}>
              <TextComponent level={6} responsive={false}>
                <Icons iconName={icon} />
              </TextComponent>
              {status}
            </Flex>
          </Label>
        );
      },
    },
  ];

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Table
      label={
        <Flex align="center" gap={4}>
          <TextComponent responsive={false} level={3}>
            <Icons iconName={IconsNames.Lock} />
          </TextComponent>
          <TextComponent responsive={false} level={4}>
            Closed Trades
          </TextComponent>
        </Flex>
      }
      size={'middle'}
      onChange={(pagination, filters, sorter) => {
        onSortChange(sorter);
      }}
      onRow={(record: ClosedPosition) => {
        return {
          onClick: () => {
            if (!expandTrades) {
              return;
            }

            modal.info({
              width: '80%',
              title: `Position Details for: ${record.symbol}`,
              closable: true,
              maskClosable: true,
              content: (
                <TradesTable
                  positionState={PositionState.Closed}
                  accountId={accountId}
                  positionId={record.id}
                />
              ),
              okText: 'Close',
            });
          },
        };
      }}
      loading={isLoading}
      columns={columns.filter((col) => col.title)}
      dataSource={sortedClosedPositions}
      rowKey="id"
      rowHoverable={expandTrades}
    />
  );
};

export default ClosedPositionsTable;
