import { Flex, TextComponent } from '@monorepo/react-components';
import { lazy } from 'react';

const EmptyImg = lazy(() => import('./achievements-empty.svg?react'));

const AchievementsEmpty = () => {
  return (
    <Flex vertical gap={16} justify="center" align="center">
      <Flex wrap="wrap" gap={10} justify="center" align="center">
        <TextComponent weight={700} fontFamily="Oswald" level={1}>
          NOTHING HERE YET...
        </TextComponent>
        <TextComponent fontFamily="Oswald" level={1}>
          KEEP ROLLING!
        </TextComponent>
      </Flex>
      <TextComponent level={4}>
        You're almost there to achieving your first target
      </TextComponent>
      <EmptyImg className="empty-img" />
    </Flex>
  );
};

export default AchievementsEmpty;
