import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { EntityStore } from '../base/entity-store';
import UsersService from '../../../services/entities/users-service';

//TODO: add user type
export class UsersStore extends EntityStore<
  UsersService,
  unknown & { _id: string }
> {
  constructor() {
    const {
      apiFactory: { usersService },
    } = getEnv();

    super(usersService);
  }
}
