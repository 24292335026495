import { useState } from 'react';

export enum LoadingState {
  Initial = 'initial',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

export const useLoading = (initialLoadingState?: LoadingState) => {
  const [loadingState, setIsLoading] = useState(
    initialLoadingState || LoadingState.Initial
  );

  const updateLoadingState = (loadingState: LoadingState) => {
    setIsLoading(loadingState);
  };

  return {
    loadingState,
    updateLoadingState,
    isLoading: loadingState === LoadingState.Loading,
    isLoaded: loadingState === LoadingState.Loaded,
    isLoadError: loadingState === LoadingState.Error,
  };
};
