import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Select, SelectProps } from 'antd';
import { getCountries } from '@monorepo/client-common';
import { CountryFilter } from '@monorepo/types';

export const FormCountry: FunctionComponent<
  Omit<FormFieldProps, 'render'> & {
    countryFilter?: CountryFilter;
  }
> = ({ countryFilter, ...formFieldProps }) => {
  const fieldProps: SelectProps = {
    placeholder: formFieldProps.placeholder,
    style: { width: '100%' },
    mode: formFieldProps.mode || undefined,
    options: getCountries(countryFilter).map((country) => {
      return {
        label: country.text,
        value: country.value,
      };
    }),
  };

  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return <Select size="large" {...fieldProps} {...field} />;
      }}
    />
  );
};
