import {
  Card,
  Flex,
  ProgressSlider,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { ThemeColors } from '@monorepo/types';
import DashboardTooltip from '../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import { useResponsiveness } from '@monorepo/client-common';
import { useColor } from '../../../../hooks';

import './progress-cards.scss';

interface Props {
  target: number;
  base: number;
  profit: number;
  stopOut: number;
  cardBgColor?: ThemeColors;
  fontColorReverse?: boolean;
}

const ProfitTarget: FunctionComponent<Props> = ({
  target,
  base,
  profit = 0,
  stopOut,
  cardBgColor = ThemeColors.colorWarning,
  fontColorReverse,
}) => {
  const { isMobile } = useResponsiveness();
  const { getColor } = useColor();
  const cardBackgroundColor = getColor(cardBgColor);

  const customTrackWidth = (stopOut / (stopOut + target)) * 100;
  const stopoutLevel = base - stopOut;
  const targetLevel = base + target;
  const equity = base + profit;

  const tooltipText =
    'Minimum balance level (with all positions closed) required to complete the stage.';

  return (
    <Card
      hoverable={false}
      style={{
        backgroundColor: cardBackgroundColor,
        borderColor: cardBackgroundColor,
        color: fontColorReverse
          ? getColor(ThemeColors.colorReverse)
          : 'inherit',
      }}
      className={classNames('progress-cards', 'progress-cards__card')}
    >
      <Flex vertical>
        <Flex justify={'space-between'}>
          <TextComponent weight={400} level={isMobile ? 5 : 3}>
            Profit Target
          </TextComponent>
          <DashboardTooltip tooltipText={tooltipText} />
        </Flex>
        <Flex align="baseline" wrap={'wrap'}>
          <TextComponent level={isMobile ? 3 : 2} weight={600}>
            <Statistic prefix={'$'} value={profit} precision={2} />
          </TextComponent>
          <TextComponent level={isMobile ? 7 : 3}>
            <Statistic prefix={'/$'} value={target} precision={0} />
          </TextComponent>
        </Flex>

        <Flex vertical>
          <ProgressSlider
            disabled
            range
            value={[base, equity]}
            min={base - stopOut}
            max={base + target}
            negative={profit < 0}
            trackColor={ThemeColors.colorPrimary}
            primaryHandle={false}
            customTrackWidth={customTrackWidth}
            tooltip={{
              formatter: () => (
                <Statistic prefix={'$'} value={equity} precision={2} />
              ),
            }}
          />

          <Flex justify="space-between">
            <Flex vertical>
              <TextComponent level={7} weight={600}>
                <Statistic prefix={'$'} value={stopoutLevel} precision={0} />
              </TextComponent>
              <TextComponent level={7}>Stopout Level</TextComponent>
            </Flex>
            <Flex vertical align="end">
              <TextComponent level={7} weight={600}>
                <Statistic prefix={'$'} value={targetLevel} precision={0} />
              </TextComponent>
              <TextComponent level={7}>Target</TextComponent>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
export default ProfitTarget;
