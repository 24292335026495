import React, { forwardRef } from 'react';
import { Card as AntdCard, CardProps } from 'antd';
import classNames from 'classnames';

import './card.scss';

type Props = CardProps & {
  active?: boolean;
  dashed?: boolean;
  error?: boolean;
  bgColor?: string;
  borderLess?: boolean;
};

export const Card = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      children,
      dashed,
      error,
      borderLess = false,
      hoverable = true,
      active,
      bgColor,
      ...antdProps
    },
    ref
  ) => {
    return (
      <AntdCard
        ref={ref}
        style={{
          backgroundColor: bgColor,
        }}
        {...antdProps}
        className={classNames('the5ers-card', className, {
          'the5ers-card--hoverable': hoverable,
          'the5ers-card--active': active,
          'the5ers-card--dashed': dashed,
          'the5ers-card--error': error,
          'the5ers-card--border-less': borderLess,
        })}
      >
        {children}
      </AntdCard>
    );
  }
);
