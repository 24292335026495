import 'reflect-metadata';
import 'core-js/stable/array/at';

import { ErrorBoundary } from './features/error-page/index';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import createStore, { Environment } from './helpers/create-store';
import { StoreProvider } from './helpers/store-provider';

import './index.scss';
import 'antd/dist/reset.css';

import 'rc-tooltip/assets/bootstrap_white.css';
import LeadDyno from './components/leaddyno/leaddyno';
import { Application } from '@monorepo/types';
import gtmService from './services/analytics/analytics';
import AppWithLaunchDarkly from './components/launch-darkly/launch-darkly-provider';
import { init as initFullStory } from '@fullstory/browser';
const isProduction = import.meta.env.VITE_APP_ENV === 'production';
const reactEnv = import.meta.env.VITE_APP_ENV || 'development';
const project = import.meta.env.VITE_PROJECT || Application.TTP;
const gtmId = import.meta.env.VITE_GTM_ID;
const fullStoryOrgId = import.meta.env.VITE_FS_ORG_ID;

const initApp = async () => {
  let envConfig: Environment;

  try {
    envConfig = await fetch(`/${project}/${reactEnv}.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => res.json());
  } catch {
    try {
      envConfig = await fetch(`/${project}/development.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((res) => res.json());
    } catch (e) {
      console.error('Error fetching configuration:', e);
      return;
    }
  }

  const { rootStore } = createStore({ envConfig });
  const rootElement = document.getElementById('root');

  if (!rootElement) {
    console.warn(`Root element not found`);
    return;
  }

  const reactRoot = createRoot(rootElement);

  rootStore.uiStore.initTheme();

  gtmService.initialize(gtmId);

  const AppToRender = isProduction ? AppWithLaunchDarkly : App;

  if (isProduction) {
    initFullStory({
      orgId: fullStoryOrgId,
      recordCrossDomainIFrames: true,
      startCaptureManually: true,
    });
  }

  reactRoot.render(
    <ErrorBoundary>
      <StoreProvider value={rootStore}>
        <LeadDyno />
        <AppToRender />
      </StoreProvider>
    </ErrorBoundary>
  );
};

initApp();
