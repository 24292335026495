import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Order,
  OrderStatus,
  PagePath,
  ProductType,
  Program,
  TradingPlatforms,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import {
  getTradingPlatform,
  LoadingState,
  useLoading,
  useThemeToken,
  useResponsiveness,
  PlatformBtnText,
} from '@monorepo/client-common';
import { Result, Skeleton } from 'antd';
import {
  Card,
  TextComponent,
  Flex,
  Row,
  Col,
  Button,
} from '@monorepo/react-components';
import { Icons, IconsNames } from '@monorepo/icons';
import classNames from 'classnames';
import PageContainer from '../page-container/page-container';

import './thank-you.scss';
import SummaryWidgetCard from '../new-order/components/summary-widget/summary-widget-card';

export const PlatformIcon: { [key: string]: IconsNames } = {
  [TradingPlatforms.Web]: IconsNames.Web,
  [TradingPlatforms.Mac]: IconsNames.Mac,
  [TradingPlatforms.Windows]: IconsNames.Windows,
  [TradingPlatforms.Linux]: IconsNames.Linux,
  [TradingPlatforms.iOS]: IconsNames.iOS,
  [TradingPlatforms.Android]: IconsNames.Android,
};

const ThankYou = () => {
  const [order, setOrder] = useState<Order>();
  const { id } = useParams<{ id: string }>();
  const {
    dataStore: { orderStore, userStore, applicationConfigStore },
    uiStore,
  } = useStore();
  const { productStore } = uiStore;
  const { name } = applicationConfigStore.applicationConfig;
  const { loadingState, updateLoadingState } = useLoading();
  const navigate = useNavigate();
  const { token } = useThemeToken();
  const { isMobile, isDesktop } = useResponsiveness();

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }

      try {
        updateLoadingState(LoadingState.Loading);
        const response = await orderStore.getOrder(id);

        if (!response) {
          throw new Error(`Can't find order`);
        }

        if (response.status !== OrderStatus.Paid) {
          navigate(`${PagePath.NewAccount}/${response.uniqId}`);
        }

        await userStore.updateCredits();
        setOrder(response);
        updateLoadingState(LoadingState.Loaded);
      } catch (e) {
        updateLoadingState(LoadingState.Error);
        console.error(`Failed getting order: ${id}`, e);
      }
    };

    init();
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton />;
  }

  if (loadingState === LoadingState.Error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
      />
    );
  }

  if (!order) {
    return null;
  }

  const product = productStore.getProduct(order.productType, order.productId);

  const goHomePage = () => {
    navigate(PagePath.Overview);
  };

  const goPartners = () => {
    navigate(`${PagePath.Partners}/${id}`);
  };

  const isProgram = order.productType === ProductType.Program;

  const hasBoosters = (program: Program) => {
    return Boolean(program.config.boosters);
  };

  const showBoosters = isProgram && hasBoosters(product as Program);

  const handleTraderClick = (link: string) => {
    window.open(link, '_blank');
  };

  const showPlatforms = () => {
    if (order.productType !== ProductType.Program) {
      return null;
    }
    const system = order.tradingSystem;
    const platforms = getTradingPlatform(system, uiStore.isProd) || [];

    return (
      <Flex gap={32} vertical>
        <TextComponent level={6} className={'show-platforms-text'} weight={400}>
          Don’t wait, download the trading app using one of the options below
        </TextComponent>
        <Row gutter={[24, 24]} className={'platforms-row'}>
          {platforms.map((platformData) => (
            <Col xs={8} md={6} key={platformData.platform}>
              <Card
                className={classNames('platforms-row__card', {
                  'platforms-row__card--mobile': isMobile,
                })}
                onClick={() => handleTraderClick(platformData.link)}
              >
                <Flex
                  justify="space-between"
                  align="center"
                  className="platforms-row__card--item"
                  vertical={isMobile ? true : false}
                  gap={isMobile ? 16 : 0}
                >
                  <TextComponent level={7}>
                    {PlatformBtnText[platformData.platform]}
                  </TextComponent>
                  <Icons
                    className={classNames('item-icon', {
                      'item-icon--mobile': isMobile,
                    })}
                    iconName={PlatformIcon[platformData.platform]}
                  />
                </Flex>
              </Card>
            </Col>
          ))}
        </Row>
      </Flex>
    );
  };

  const chooseBooster = () => {
    if (!showBoosters) {
      return (
        <Button
          key="home"
          type={'primary'}
          onClick={goHomePage}
          style={{ width: '100%' }}
        >
          Home Page
        </Button>
      );
    }

    return (
      <>
        <Button
          key="partners"
          type={'primary'}
          size={'large'}
          onClick={goPartners}
          style={{ width: '100%', marginTop: 12 }}
        >
          Choose your boosters
        </Button>
      </>
    );
  };

  const thankYouText = () => {
    return (
      <Flex vertical gap={32}>
        <TextComponent
          className={classNames('thank-you__headline', {
            'thank-you__headline--mobile': !isDesktop,
          })}
          weight={600}
          level={isDesktop ? 1 : 2}
          color={token.colorPrimary}
        >
          <Icons iconName={IconsNames.Validation} /> Thank You for your
          purchase!
        </TextComponent>
        <TextComponent level={4}>
          Your order is being processed and will be ready in a few moments
        </TextComponent>
        {showBoosters && (
          <TextComponent weight={400} level={6}>
            {name} has teamed up with top software and service providers to
            enhance your trading experience. Enjoy 30 days of free access (will
            get access by email within 48 hours).
          </TextComponent>
        )}
      </Flex>
    );
  };

  const RenderSummaryCard = () => (
    <SummaryWidgetCard
      currency={order.paymentData.currency}
      amount={order.amount}
      fee={order.paymentData.fee}
      creditsUsed={order.paymentData.creditsUsed}
      discountPercentage={order.paymentData.discountPercentage}
      originalAmount={order.originalAmount}
      paymentDataAmount={order.paymentData.amount}
      productName={order.productMetaData?.productName}
    />
  );

  return (
    <PageContainer helmet="Thank You" className="thank-you">
      <Row gutter={[25, 0]}>
        <Col xs={24} lg={16}>
          <Row gutter={[0, 32]}>
            <Col span={24}>{thankYouText()}</Col>
            {!isDesktop && (
              <Col span={24}>
                <Row justify="center">
                  <Col span={18} className={'summary-column'}>
                    <RenderSummaryCard />
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={24}>{showPlatforms()}</Col>
            <Col span={24}>{chooseBooster()}</Col>
          </Row>
        </Col>
        {isDesktop && (
          <Col xs={24} lg={8}>
            <Row justify="end">
              <Col span={20} className={'summary-column'}>
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <TextComponent responsive={false} level={4}>
                      Order summary
                    </TextComponent>
                  </Col>
                  <Col span={24}>
                    <RenderSummaryCard />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </PageContainer>
  );
};

export default ThankYou;
