import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { EntityStore } from '../base/entity-store';
import {
  ConsistencyRules,
  ConsistentRulesConfig,
  Program,
} from '@monorepo/types';
import ProgramService from '../../../services/entities/program-service';

export class ProgramStore extends EntityStore<ProgramService, Program> {
  constructor() {
    const {
      apiFactory: { programService },
    } = getEnv();

    super(programService);
  }

  hasDailyMetrics(programId: string): boolean {
    const program = this.get(programId);

    if (!program) {
      return false;
    }

    const dailyLimit =
      program.config.dailyPausePercentage ||
      program.config?.dailyLossPercentage ||
      program.config?.dailyPause ||
      0;
    return dailyLimit > 0;
  }

  hasViolations(programId: string): boolean {
    return false;
  }

  hasRule(programId: string, rule: ConsistencyRules): boolean {
    const program = this.get(programId);

    if (!program) {
      return false;
    }

    const consistencyRules = program.config.consistencyRules || [];

    return consistencyRules.some(
      (consistencyRule) => consistencyRule.rule === rule
    );
  }

  hasProfitableDays(programId: string): boolean {
    return this.hasRule(programId, ConsistencyRules.ProfitableDays);
  }

  hasInactiveDays(programId: string): boolean {
    const program = this.get(programId);

    return Boolean(program && program.config.inactivityDays);
  }

  getRule(
    programId: string,
    rule: ConsistencyRules
  ): ConsistentRulesConfig | undefined {
    const program = this.get(programId);

    if (!program) {
      return;
    }

    return program.config.consistencyRules?.find(
      (consistencyRule) => consistencyRule.rule === rule
    );
  }
}
