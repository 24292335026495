import { PaymentProviderName } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { TextComponent } from '@monorepo/react-components';

const paymentProviderData = {
  [PaymentProviderName.Fibonatix]:
    'https://bucket-assets-5ers-ttp.s3.amazonaws.com/TTP/images/payment_processors/CC_payment_button.png',
  [PaymentProviderName.Checkout]:
    'https://bucket-assets-5ers-ttp.s3.amazonaws.com/TTP/images/payment_processors/Checkout_logo.png',
  [PaymentProviderName.Confirmo]:
    'https://bucket-assets-5ers-ttp.s3.amazonaws.com/TTP/images/payment_processors/Confirmo.png',
  [PaymentProviderName.Stripe]:
    'https://bucket-assets-5ers-ttp.s3.amazonaws.com/TTP/images/payment_processors/Stripe_payment_button.png',
  [PaymentProviderName.Credits]: '',
  [PaymentProviderName.Free]: '',
  [PaymentProviderName.PayPal]:
    'https://bucket-assets-5ers-ttp.s3.amazonaws.com/TTP/images/payment_processors/PayPal.png',
};
interface Pros {
  paymentProvider: PaymentProviderName;
}

export const PaymentProviderCard: FunctionComponent<Pros> = ({
  paymentProvider,
}) => {
  const imageName = paymentProviderData[paymentProvider];

  return imageName ? (
    <img src={imageName} alt={paymentProvider} />
  ) : (
    <TextComponent level={4}>Pay with {paymentProvider}</TextComponent>
  );
};
