import React, { FunctionComponent } from 'react';
import { Avatar, AvatarProps } from 'antd';
import {
  CurrencyComponentProps,
  getCurrencyIcon,
} from '@monorepo/client-common';

import './currency-avatar.scss';

type Props = AvatarProps & CurrencyComponentProps;

export const CurrencyAvatar: FunctionComponent<Props> = ({
  type,
  name,
  ...antdProps
}) => {
  const icon = getCurrencyIcon({ name, type });

  return <Avatar src={icon} {...antdProps} />;
};
