import React, { lazy, Suspense } from 'react';
import {
  CouponItem,
  CouponItemType,
  isUserCouponRedeemAble,
  ProductType,
  UserCoupon,
} from '@monorepo/types';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useResponsiveness } from '@monorepo/client-common';
import { Statistic } from '@monorepo/react-components';

const DiscountCoupon = lazy(() => import('./icons/discount-coupon.svg?react'));
const CreditCoupon = lazy(() => import('./icons/credits-coupon.svg?react'));
const AddOnCoupon = lazy(() => import('./icons/add-on-coupon.svg?react'));
const ProgramCoupon = lazy(() => import('./icons/program-coupon.svg?react'));
const EmptySkeleton = lazy(() => import('./icons/empty-coupon.svg?react'));

const logoMap = {
  [ProductType.General]: DiscountCoupon,
  [ProductType.AddOn]: AddOnCoupon,
  [ProductType.Program]: ProgramCoupon,
  [ProductType.Credit]: CreditCoupon,
};

interface Props {
  coupon: UserCoupon;
}
const createTitle = (couponItem: CouponItem) => {
  const valueSign =
    couponItem.couponType === CouponItemType.Nominal
      ? {
          prefix: '$',
        }
      : {
          suffix: '%',
        };

  const titleMap: { [key: string]: React.ReactNode } = {
    [ProductType.General]: (
      <Statistic {...valueSign} value={couponItem.amount} precision={0} />
    ),
    [ProductType.AddOn]: `${
      couponItem.metadata?.productName
        ? couponItem.metadata?.productName
        : 'Add-On'
    }`,
    [ProductType.Program]: `${
      couponItem.metadata?.productName
        ? couponItem.metadata?.productName
        : 'Account'
    }`,
    [ProductType.Credit]: `${couponItem.amount}`,
  };
  return titleMap[couponItem.productType];
};
const Coupon: React.FC<Props> = ({ coupon }) => {
  const navigate = useNavigate();
  const couponItem = coupon.items[0];
  const expiration = dayjs(coupon.expirationDate).format('DD.MM.YYYY');
  const couponType = couponItem.productType;
  const title = createTitle(couponItem);

  const handleClick = () => {
    const isRedeemAble = isUserCouponRedeemAble(coupon);
    if (isRedeemAble) {
      navigate(`/new-account/?couponId=${coupon._id}`);
    }
  };
  const CouponImage = logoMap[couponType];
  const { isMobile } = useResponsiveness();
  return (
    <div
      className={classNames('coupon', `coupon__${couponItem.productType}`, {
        'coupon--minimal': isMobile,
      })}
      onClick={() => handleClick()}
    >
      <Suspense>
        <CouponImage
          className={'image'}
          style={{ width: '100%', height: 'auto', display: 'block' }}
        />
      </Suspense>
      <div className="main">{title}</div>
      <div className="second">exp. {expiration}</div>
    </div>
  );
};

export const CouponSkeleton = () => {
  const { isMobile } = useResponsiveness();
  return (
    <div
      className={classNames('coupon coupon--skeleton', {
        'coupon--minimal': isMobile,
      })}
    >
      <Suspense>
        <EmptySkeleton
          className={'image'}
          style={{ width: '100%', height: 'auto', display: 'block' }}
        />
      </Suspense>
    </div>
  );
};

export default Coupon;
