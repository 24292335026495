import { FormCountry } from '@monorepo/react-components';
import { ComponentProps, FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';

type Props = ComponentProps<typeof FormCountry>;

const FormCountrySelect: FunctionComponent<Props> = (props) => {
  const {
    dataStore: { applicationConfigStore },
  } = useStore();

  const filter = applicationConfigStore.applicationConfig.countryFilter;

  return <FormCountry countryFilter={filter} {...props} />;
};
export default FormCountrySelect;
