import {
  AFFILIATION_COMMISSION_LIMIT,
  AffiliationCommission,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { ColumnsType } from 'antd/lib/table';
import {
  CommissionStatusTag,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import dayjs from 'dayjs';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  LoadingState,
  useLoading,
  usePagination,
} from '@monorepo/client-common';

export const AffiliationCommissions = () => {
  const {
    dataStore: { affiliateCommissionsStore },
  } = useStore();
  const [commissions, setCommissions] = useState<AffiliationCommission[]>([]);
  const { page, limit, total, setTotal, setLimit, setPage } = usePagination({
    limit: AFFILIATION_COMMISSION_LIMIT,
  });
  const { loadingState, updateLoadingState } = useLoading();

  const isLoading = loadingState === LoadingState.Loading;

  useEffect(() => {
    requestPage();
  }, [page, limit]);

  const requestPage = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      const response = await affiliateCommissionsStore.paginate({
        page,
        limit,
      });
      updateLoadingState(LoadingState.Loaded);
      setCommissions(response.results);
      setTotal(response.total);
    } catch (e) {
      console.error(
        `Failed loading payouts for page: ${page}, limit: ${limit}`,
        e
      );
      updateLoadingState(LoadingState.Error);
    }
  };

  const columns: ColumnsType<AffiliationCommission> = [
    {
      title: 'Commission Id',
      dataIndex: 'externalId',
      align: 'center',
      width: 150,
      render: (_, item) => {
        return <TextComponent>{item.externalId}</TextComponent>;
      },
    },
    {
      title: 'Commission amount',
      dataIndex: 'amount',
      align: 'center',
      width: 150,
      render: (_, item) => {
        return <Statistic value={item.amount} precision={2} prefix={'$'} />;
      },
    },
    {
      title: 'Due',
      dataIndex: 'dueAt',
      align: 'center',
      width: 150,
      render: (_, item) => {
        return <span>{dayjs(item.dueAt).format('DD/MM/YYYY HH:mm')}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (_, item) => {
        return <CommissionStatusTag status={item.status} />;
      },
    },
  ];

  return (
    <div className="commissions">
      <Table
        style={{ marginBottom: 20 }}
        size={'small'}
        scroll={{ y: 500 }}
        onChange={(pagination) => {
          if (pagination.current) {
            setPage(pagination.current);
          }
          if (pagination.pageSize) {
            setLimit(pagination.pageSize);
          }
        }}
        pagination={{
          showSizeChanger: false,
          current: page,
          pageSize: limit,
          total,
        }}
        loading={isLoading}
        rowKey={(record) => record._id}
        bordered={false}
        columns={columns}
        dataSource={commissions}
      />
    </div>
  );
};
