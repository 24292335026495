import { Banner, BannerPlacement } from '@monorepo/types';
import { Carousel, Flex } from 'antd';
import { FunctionComponent } from 'react';

import './sider-banner.scss';

interface Props {
  banners?: Banner[];
}

const SiderBanner: FunctionComponent<Props> = ({ banners = [] }) => {
  const bannersSider = banners.filter(
    (banner) => banner.placement === BannerPlacement.Sider
  );

  if (!bannersSider.length) {
    return null;
  }

  const onBannerClick = (link?: string) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <Flex vertical={true} className={'sider-banner'}>
      <Carousel autoplay={bannersSider.length > 1} dots={false}>
        {bannersSider.map((banner: Banner) => (
          <img
            key={banner.name}
            className="sider-banner__image"
            src={banner.images.desktopUrl}
            onError={(e) => (e.currentTarget.style.display = 'none')}
            alt="side-banner"
            onClick={() => onBannerClick(banner.link)}
          />
        ))}
      </Carousel>
    </Flex>
  );
};

export default SiderBanner;
