import { FormRadioGroup } from '@monorepo/react-components';
import { NyseFormProps } from '../nyse-modal-content';
import { FunctionComponent } from 'react';

const NyseStepSix: FunctionComponent<NyseFormProps> = ({ form }) => {
  return (
    <div className={'contracts-steps-body'}>
      <div>
        <p>
          10. PERMITTED RECEIPT - Subscriber may not receive Market Data from
          Vendor, and Vendor may not provide Market Data to Subscriber, on a
          “Nonprofessional Subscriber” basis unless Vendor first properly
          determines that Subscriber qualifies as a “Nonprofessional Subscriber”
          as defined in Paragraph 9 and Subscriber in fact qualifies as a
          “Nonprofessional Subscriber.” In determining whether a Subscriber
          qualifies as a “Nonprofessional Subscriber,” Vendor shall consider
          whether Subscriber would answer “No” to the following questions:
        </p>
        <div>
          <div>
            <p>
              (a) Do you receive Market Data for any reason other than your
              personal, nonbusiness use?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'receiveDataNonBusinessUse' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
          </div>
          <div>
            <p>
              (b) Are you currently registered or qualified with the SEC or the
              CFTC?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'hasSECOrCFTC' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
          </div>
          <div>
            <p>
              (c) Are you currently registered or qualified with any securities
              agency, any securities exchange, association or regulatory body,
              or any commodities or futures contract market, association or
              regulatory body, in the United States or elsewhere?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'securityAgency' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
          </div>
          <div>
            <p>
              (d) Whether you are located within or outside of the United
              States, do you perform any functions that are similar to those
              that require an individual to register or qualify with the SEC,
              the CFTC, any other securities agency or regulatory body, any
              securities exchange or association, or any commodities or futures
              contract market, association or regulatory body?
            </p>
            <FormRadioGroup
              form={form}
              controllerProps={{ name: 'similarToSECOrCFTC' }}
              options={[
                {
                  value: false,
                  text: 'No',
                },
                {
                  value: true,
                  text: 'Yes',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NyseStepSix;
