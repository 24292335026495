import React from 'react';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Flex, Result, Skeleton } from 'antd';

import './static-page.scss';

interface Props {
  src: string;
}

export const StaticPage: FunctionComponent<Props> = ({ src }) => {
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Loading);
  const isLoading = loadingState === LoadingState.Loading;

  const onLoad = () => updateLoadingState(LoadingState.Loaded);
  const onError = () => updateLoadingState(LoadingState.Error);

  return (
    <Flex className="static-page" vertical>
      {isLoading && <Skeleton active={true} />}

      <iframe
        src={src}
        onLoad={onLoad}
        onError={onError}
        className={classNames('static-page__iframe', {
          'static-page__iframe--loading': isLoading,
        })}
      />
      {loadingState === LoadingState.Error && (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
        />
      )}
    </Flex>
  );
};
