import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Divider, Row } from '@monorepo/react-components';
import { Account, Application } from '@monorepo/types';
import ClosedPositionsTable from '../../positions/closed-positions';
import OpenPositions from '../../positions/open-positions';
import AccountBalance from '../dashboard-common/account-balance/account-balance';
import AccountOverview from '../dashboard-common/account-overview/account-overview';
import { useStore } from '../../../helpers/use-store';
import AccountDailyProgress from '../dashboard-common/account-daily-progress/account-daily-progress';
import AccountStopout from '../dashboard-common/account-stoput/account-stopout';
import AccountTarget from '../dashboard-common/account-target/account-target';

interface Props {
  account: Account;
}

const FiversDashboard: FunctionComponent<Props> = ({ account }) => {
  const {
    dataStore: { accountStore, programStore },
  } = useStore();

  const [accountDetails, setAccountDetails] = useState({
    profitAndLoss: 0,
    balance: 0,
    equity: 0,
    credits: 0,
    dailyProfitAndLoss: 0,
    allowedDailyLosses: 0,
    snapshotBalance: 0,
    snapshotEquity: 0,
  });
  const id = account.tsAccount as string;

  useEffect(() => {
    const getAccountBalance = async () => {
      try {
        const result = await accountStore.getBalance(id);

        setAccountDetails((details) => ({
          ...details,
          profitAndLoss: result.profitAndLoss as number,
          dailyProfitAndLoss: result.dailyProfitAndLoss as number,
          balance: result.balance,
          credits: result.credits as number,
          allowedDailyLosses: result.allowedDailyLosses as number,
          snapshotBalance: result.snapshotBalance as number,
          snapshotEquity: result.snapshotEquity as number,
          equity: result.equity as number,
        }));
      } catch (e) {
        console.error(`Failed getting account details`, e);
      }
    };

    getAccountBalance();
  }, [id]);

  const showDailyMetrics = programStore.hasDailyMetrics(account.programId);

  const program = programStore.get(account.programId);

  return (
    <div className="dashboard">
      <Row gutter={[15, 15]}>
        <Col md={24} lg={10}>
          <AccountOverview
            account={account}
            buyingPowerText={'Initial Balance'}
            programId={account?.programId as string}
          />
        </Col>
        <Col flex={'auto'} xs={24} md={12} lg={7}>
          <Row gutter={[15, 15]} style={{ height: '100%' }}>
            <Col span={24}>
              <AccountStopout
                title={'Stopout Level'}
                baseBalance={program?.config?.buyingPower || 0}
                equity={accountDetails.equity}
                programId={account?.programId as string}
              />
            </Col>
            <Col span={24}>
              <AccountTarget
                title={`Target`}
                profitAndLoss={accountDetails.profitAndLoss}
                programId={account?.programId as string}
              />
            </Col>
          </Row>
        </Col>
        {showDailyMetrics && (
          <Col flex={1} xs={24} md={12} lg={7}>
            <AccountDailyProgress
              equity={accountDetails.equity}
              snapshotBalance={
                accountDetails.snapshotBalance ||
                program?.config?.buyingPower ||
                0
              }
              snapshotEquity={
                accountDetails.snapshotEquity ||
                program?.config?.buyingPower ||
                0
              }
              allowedDailyLosses={accountDetails.allowedDailyLosses}
              programId={account?.programId as string}
            />
          </Col>
        )}

        <Col span={24}>
          <AccountBalance
            credits={accountDetails.credits}
            equity={accountDetails.equity}
            balance={accountDetails.balance}
            baseBalance={program?.config.buyingPower}
            profitAndLoss={accountDetails.profitAndLoss}
            accountId={id}
            application={Application.THE5ERS}
          />
        </Col>
      </Row>

      <Divider />
      <OpenPositions
        application={Application.THE5ERS}
        expandTrades={false}
        accountId={id}
      />
      <Divider />
      <ClosedPositionsTable
        application={Application.THE5ERS}
        expandTrades={false}
        accountId={id}
      />
    </div>
  );
};

export default FiversDashboard;
