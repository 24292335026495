import React, { FunctionComponent, ReactNode } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Radio, RadioGroupProps } from 'antd';

interface RadioItem {
  text: string | ReactNode;
  value: any;
  disabled?: boolean;
}

export const FormRadioButtonGroup: FunctionComponent<
  Omit<FormFieldProps, 'render'> & {
    options: RadioItem[];
    radioGroupProps?: RadioGroupProps;
  }
> = ({ options, radioGroupProps, ...formFieldProps }) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Radio.Group
            {...field}
            size="large"
            className="form-field__radio-button-group"
            {...radioGroupProps}
          >
            <RenderRadioButtons options={options} />
          </Radio.Group>
        );
      }}
    />
  );
};

interface Props {
  options: RadioItem[];
  radioGroupProps?: RadioGroupProps;
}

export const RadioButtonGroup: FunctionComponent<Props> = ({
  options,
  radioGroupProps,
}) => (
  <Radio.Group
    size="large"
    className="form-field__radio-button-group"
    {...radioGroupProps}
  >
    <RenderRadioButtons options={options} />
  </Radio.Group>
);

const RenderRadioButtons: FunctionComponent<{ options: RadioItem[] }> = ({
  options,
}) => {
  return options.map((option) => {
    return (
      <Radio.Button
        disabled={option.disabled}
        key={option.value}
        value={option.value}
      >
        {option.text}
      </Radio.Button>
    );
  });
};
