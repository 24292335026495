import BaseEntityService, { BaseResponse } from '../core/base-entity-service';
import {
  PaginationRequest,
  PaginationResult,
  PaymentMethod,
  Payout,
  PayoutSource,
  WithdrawRulesResponse,
} from '@monorepo/types';
import { WithdrawPayload } from '../../stores/data/payout/payout-store';

export default class PayoutService extends BaseEntityService {
  static override route = 'payout';

  async getPayouts(
    type: PayoutSource,
    sourceId: string,
    paginateRequest: PaginationRequest
  ) {
    try {
      const response = await this.httpService.get<
        BaseResponse<PaginationResult<Payout>>
      >(`${this.path}/${type}/${sourceId}`, { params: paginateRequest });

      return response.data;
    } catch (e) {
      console.error(`Failed getting payouts`);
      return {
        total: 0,
        results: [],
      };
    }
  }

  async getPayoutsTotal(type: PayoutSource, sourceId: string) {
    try {
      const response = await this.httpService.get<BaseResponse<number>>(
        `${this.path}/total/${type}/${sourceId}`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting payouts total`);
      return 0;
    }
  }

  async getIsEligible(type: PayoutSource, sourceId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<WithdrawRulesResponse>
      >(`${this.path}/eligible-withdraw/${type}/${sourceId}`);

      return response.data;
    } catch (e) {
      console.error(`Failed getting payouts`);
      return {
        isValid: false,
        availableAmount: 0,
      };
    }
  }

  async createPayout(
    source: PayoutSource,
    amount: number,
    paymentOption: PaymentMethod
  ) {
    try {
      const response = await this.httpService.post<
        {
          amount: number;
          paymentOption: PaymentMethod;
        },
        BaseResponse<Payout>
      >(`${this.path}/${source}`, { amount, paymentOption });

      return response.data;
    } catch (e) {
      console.error(`Failed creating a payout`);
      return {
        total: 0,
        results: [],
      };
    }
  }

  async createPayoutRequest(
    withdrawRequest: WithdrawPayload & {
      source: PayoutSource;
      sourceId: string;
    }
  ) {
    const { source, sourceId, ...rest } = withdrawRequest;

    try {
      const response = await this.httpService.post<
        WithdrawPayload,
        BaseResponse<Payout>
      >(`${this.path}/${source}/${sourceId}`, rest);
      return response.data;
    } catch (e) {
      console.error(`Failed creating a payout`);
      return {
        total: 0,
        results: [],
      };
    }
  }
}
