import { ThemeConfigurations } from './theme-configs';
import ProductStore from './product-store/product-store';
import { getEnv } from '../../helpers/mobx-easy-wrapper';
import { action, makeObservable, observable } from 'mobx';
import { Application, Theme, ThemeConfigurationType } from '@monorepo/types';

const project =
  (import.meta.env.VITE_PROJECT as Application) || Application.TTP;

const reactEnv = import.meta.env.VITE_APP_ENV;

class UiStore {
  @observable
  currentTheme: Theme = Theme.Default;

  initialUrl?: string;

  productStore: ProductStore;

  displayedNotifications: Set<string> = new Set<string>();

  themeConfigurations: {
    [key in Theme]?: ThemeConfigurationType;
  } = {};

  constructor() {
    const initialUrl = localStorage.getItem('initialUrl');

    this.initialUrl = initialUrl?.replace('#', '');

    this.productStore = new ProductStore();

    makeObservable(this);
  }

  get googleLoginUrl() {
    const {
      envConfig: {
        api_end_point: serverUrl,
        cognito_pool_name: poolName,
        aws_region: awsRegion,
        aws_client_id: clientId,
      },
    } = getEnv();
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = urlParams.toString(); // Encode query parameters
    const pathname = window.location.pathname;
    const hash = window.location.hash;
    const redirectUri = encodeURIComponent(`${serverUrl}user/auth/redirect`);
    const state = encodeURIComponent(
      JSON.stringify({ pathname, queryParams, hash })
    );
    return `https://${poolName}.auth.${awsRegion}.amazoncognito.com/oauth2/authorize?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirectUri}&state=${state}`;
  }

  get getLogoutUrl() {
    const {
      envConfig: {
        cognito_pool_name: poolName,
        aws_region: awsRegion,
        aws_client_id: clientId,
      },
    } = getEnv();

    return `https://${poolName}.auth.${awsRegion}.amazoncognito.com/logout?client_id=${clientId}&logout_uri=${window.location.origin}`;
  }

  getThemeConfig(theme: Theme) {
    this.themeConfigurations[theme] = ThemeConfigurations[project][theme];
    return this.themeConfigurations[theme];
  }

  initAppCssVars(cssVars: Record<string, string>) {
    try {
      const root = document.documentElement;
      for (const key in cssVars) {
        root.style.setProperty(`--${key}`, cssVars[key]);
      }
    } catch (e) {
      console.error(`failed fetching theme with error: ${e}`);
    }
  }

  getStoredTheme() {
    const storedTheme = localStorage.getItem(this.getThemeKey());
    return storedTheme as Theme;
  }

  switchTheme(theme: Theme) {
    this.initTheme(theme);
  }

  initTheme(themeToInit?: Theme) {
    const storedTheme = this.getStoredTheme();

    const theme =
      themeToInit || storedTheme || this.currentTheme || Theme.Default;

    const themeConfig = this.getThemeConfig(theme);

    this.initAppCssVars(themeConfig?.cssVars || {});

    this.setTheme(theme);
  }

  getThemeKey() {
    return `${project}-theme`;
  }

  @action
  setTheme(theme: Theme) {
    localStorage.setItem(this.getThemeKey(), theme);
    this.currentTheme = theme;
  }

  get isProd() {
    return reactEnv === 'production';
  }
}

export default UiStore;
