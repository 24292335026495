import { AffiliateCommissionStatus } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { Tag } from 'antd';
import classNames from 'classnames';

interface Props {
  status: AffiliateCommissionStatus;
}

export const CommissionStatusTag: FunctionComponent<Props> = ({
  status = AffiliateCommissionStatus.Cancelled,
}) => {
  const commissionStatusToColor = {
    success: AffiliateCommissionStatus.Paid === status,
    warning: AffiliateCommissionStatus.Pending === status,
    error: AffiliateCommissionStatus.Cancelled === status,
    processing: AffiliateCommissionStatus.Approved === status,
  };

  return (
    <Tag
      style={{ textTransform: 'capitalize' }}
      color={classNames(commissionStatusToColor)}
    >
      {status}
    </Tag>
  );
};
