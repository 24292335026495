import {
  IdIdentifier,
  TimeIdentifiers,
  UserIdentifier,
} from '../common/identifiers';
import { AccountStateKey } from '../account/account';

export type CreditTransaction = IdIdentifier &
  TimeIdentifiers &
  UserIdentifier & {
    amount: number;
    type: CreditType;
    action: CreditAction;
    notes?: string;
    accountId?: string;
    expirationDate?: string;
    orderId?: string;
    createdByUserId?: string;
    creditIds?: string[];
    metadata?: CreditMetadata;
  };

export interface CreditMetadata {
  accountState?: AccountStateKey;
}

export enum CreditAction {
  Add = 'add',
  Deduct = 'deduct',
}

export enum CreditType {
  Test = 'test',
  PassedEvaluation = 'passed-evaluation',
  AccountStateChange = 'account-state-change',
  Payment = 'payment',
}
