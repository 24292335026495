import { PaginationSort, SortDirection } from '@monorepo/types';
import { SorterResult } from 'antd/es/table/interface';
import { useState } from 'react';

export type Sorter<DataType> =
  | SorterResult<DataType>
  | SorterResult<DataType>[];

interface Props<T> {
  sortField?: PaginationSort<T>['field'];
  sortDirection?: PaginationSort<T>['direction'];
}

export const useSort = <T,>(props?: Props<T>) => {
  const defaultSort = props?.sortField
    ? {
        field: props.sortField,
        direction: props?.sortDirection || SortDirection.Desc,
      }
    : undefined;

  const [sort, setSort] = useState<PaginationSort<T> | undefined>(defaultSort);

  const onSortChange = (sorter?: Sorter<T>) => {
    if (sorter && !Array.isArray(sorter)) {
      const newSort: PaginationSort = {
        field: sorter.field as string,
        direction:
          sorter.order === 'ascend' ? SortDirection.Asc : SortDirection.Desc,
      };

      setSort(sorter?.column ? newSort : defaultSort);
    }
  };

  return {
    sort,
    onSortChange,
  };
};
