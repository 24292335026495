import { useStore } from '../../../helpers/use-store';
import Coupon, { CouponSkeleton } from './coupon';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useResponsiveness } from '@monorepo/client-common';
import { TextComponent, Divider, Flex } from '@monorepo/react-components';

import './coupons.scss';

const Coupons = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  const { isDesktop } = useResponsiveness();
  const hasCoupons = userStore.coupons && userStore.coupons.length > 0;

  const renderCoupons = () => {
    if (!hasCoupons) {
      return Array(isDesktop ? 5 : 2)
        .fill('')
        .map((_, index) => <CouponSkeleton key={index} />);
    }

    return userStore.coupons.map((coupon) => (
      <Coupon coupon={coupon} key={coupon._id} />
    ));
  };

  return (
    <div>
      <Divider>
        <TextComponent level={4} responsive={false} weight={600}>
          Available coupons
        </TextComponent>
      </Divider>
      <Flex
        wrap={hasCoupons ? 'wrap' : 'nowrap'}
        className={classNames('coupons', { 'coupons--minimal': !isDesktop })}
      >
        {renderCoupons()}
      </Flex>
    </div>
  );
};

export default observer(Coupons);
