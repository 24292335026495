import React, { FunctionComponent } from 'react';
import { Button, Col, Flex, Image, Row, Typography } from 'antd';
import TraderSyncDark from './partners-icons/trader-sync-logo.svg';
import TraderSyncLight from './partners-icons/trader-sync-logo-light.svg';
import { observer } from 'mobx-react-lite';
import { Card } from '@monorepo/react-components';
import ReactPlayer from 'react-player/lazy';
import { PartnerName, Theme } from '@monorepo/types';
import { useStore } from '../../../helpers/use-store';
import {
  LoadingState,
  useLoading,
  useThemeToken,
} from '@monorepo/client-common';

import './partner-card.scss';

interface Props {
  title: string;
  description: string;
  videoUrl: string;
  selectPartner: () => Promise<void>;
  isDisabled: boolean;
  isSelected: boolean;
}

export enum PartnerSelectButtonText {
  Select = 'Select',
  Selected = 'Selected',
  LimitReached = 'Limit reached',
}

const partnersLogoMap: { [key: string]: string } = {
  [`${PartnerName.TraderSync}-${Theme.Dark}`]: TraderSyncDark,
  [`${PartnerName.TraderSync}-${Theme.Light}`]: TraderSyncLight,
};

const PartnerCard: FunctionComponent<Props> = ({
  description,
  videoUrl,
  isSelected,
  isDisabled,
  selectPartner,
  title,
}) => {
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);

  const { token } = useThemeToken();

  const { uiStore } = useStore();

  const isLoading = loadingState === LoadingState.Loading;

  const getSelectButtonText = () => {
    if (isSelected) {
      return PartnerSelectButtonText.Selected;
    }
    if (isDisabled) {
      return PartnerSelectButtonText.LimitReached;
    }

    return PartnerSelectButtonText.Select;
  };

  const selectPartnerCard = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      await selectPartner();
      updateLoadingState(LoadingState.Loaded);
    } catch (e) {
      updateLoadingState(LoadingState.Error);
    }
  };

  return (
    <Card bordered={false} className={'partner-card'}>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Flex justify={'center'} align={'center'}>
            <Image
              style={{ maxWidth: '155px' }}
              preview={false}
              src={partnersLogoMap[`${title}-${uiStore.currentTheme}`]}
            />
          </Flex>
        </Col>
        <Col span={24} style={{ height: '190px' }}>
          <ReactPlayer width={'100%'} height={'100%'} url={videoUrl} />
        </Col>
        <Col span={24} className={'partner-body'}>
          <Typography.Paragraph
            ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}
          >
            {description}
          </Typography.Paragraph>
        </Col>
        <Col span={24} className={'partner-body'}>
          <Button
            disabled={isDisabled}
            onClick={selectPartnerCard}
            loading={isLoading}
            className="select-btn"
            style={{
              width: '100%',
              color: isSelected ? token.colorSuccess : '',
            }}
          >
            {getSelectButtonText()}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default observer(PartnerCard);
