import { Row } from 'antd';
import { ConsistencyRuleResult, ConsistencyRules } from '@monorepo/types';
import { FunctionComponent } from 'react';
import ConsistencyRule from './consistency-rule';

interface Props {
  consistencyRules: Record<ConsistencyRules, ConsistencyRuleResult>;
  programId: string;
  accountId: string;
}

const ConsistencyRulesComponent: FunctionComponent<Props> = ({
  consistencyRules,
  programId,
  accountId,
}) => {
  const rules = Object.keys(consistencyRules);

  return (
    <Row style={{ width: '100%' }}>
      {rules.map((rule) => {
        const ruleResult: ConsistencyRuleResult =
          consistencyRules[rule as ConsistencyRules];

        return (
          <ConsistencyRule
            key={rule}
            programId={programId}
            accountId={accountId}
            ruleResult={ruleResult}
            rule={rule as ConsistencyRules}
          />
        );
      })}
    </Row>
  );
};

export default ConsistencyRulesComponent;
