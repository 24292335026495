export enum AffiliateAction {
  Purchases = 'purchases',
  Affiliates = 'affiliates',
  Leads = 'leads',
  Commissions = 'commissions',
}

export const AFFILIATION_COMMISSION_LIMIT = 100;

export interface AffiliatePurchaseRequest {
  email: string;
  plan_code?: string;
  purchase_code?: string;
  purchase_amount?: number;
  code?: string;
  commission_amt_override?: number;
  description?: string;
}

export interface AffiliateCreateLeadRequest {
  email: string;
  first_name?: string;
  last_name?: string;
  referrer?: string;
  remote_ip?: string;
  agent?: string;
  url?: string;
  code?: string;
}

export interface AffiliateUserRequest {
  email: string;
  first_name?: string;
  last_name?: string;
  affiliate_code?: string;
  affiliate_type?: string;
}

export interface AffiliateUserResponse {
  id: number;
  email: string;
  first_name: string | null;
  last_name: string | null;
  created_at: string;
  updated_at: string;
  affiliate_url: string;
  compensation_tier_code: string;
  affiliate_dashboard_url: string;
}
export type AffiliateCommissionResponse = {
  id: number;
  date: string;
  due_at: string;
  amount: string;
  currency: string;
  payment_type: string;
  custom_singular: string;
  custom_plural: string;
  cancelled: boolean;
  note?: string;
  paid: boolean;
  purchase: AffiliateCommissionPurchase;
  affiliate: {
    id: number;
    email: string;
  };
};
export interface AffiliateCommissionsTotalResponse {
  total_count: number;
  total_cancelled_count: number;
  total_due_count: number;
  total_paid_count: number;
  total_cost: string;
  total_cancelled_cost: string;
  total_due_cost: string;
  total_paid_cost: string;
}

export interface AffiliateLeadResponse {
  id: number;
  email: string;
  created_at: string;
  updated_at: string;
  status: string;
  custom_status: string | null;
  first_name: string | null;
  last_name: string | null;
  latest_visitor: {
    id: number;
    tracking_code: string;
  };
  url: string | null;
  affiliate: { id: number; email: string };
  campaign: string | null;
  search_term: string | null;
  tracking_code: string;
}
export type AffiliateCommissionPurchase = {
  id: number;
  created_at: string;
  updated_at: string;
  cancelled: boolean;
  currency: string;
  purchase_code: string;
  note?: string;
  purchase_amount: string;
  commission_amount_override: string | null;
  cancellation: {
    id: number;
    cancellation_code: string;
    effective_date: string;
    source: string | null;
  };
  lead: {
    id: number;
    email: string;
  };
  affiliate: {
    id: number;
    email: string;
  };
  plan: {
    id: number;
    code: string;
  };
};
