import { App, Avatar, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useStore } from '../../helpers/use-store';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import { Button, Card, Flex } from '@monorepo/react-components';
import { Icons, IconsNames } from '@monorepo/icons';
import classNames from 'classnames';

interface HandleUploadParams {
  file: string | RcFile | Blob;
  onSuccess: () => void;
  onError: () => void;
}
const supportedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

const checkImageFormat = (file: RcFile) => {
  return supportedImageTypes.includes(file.type);
};

const validateImageSize = (file: RcFile, imageSizeInMB: number) => {
  return file.size / 1024 / 1024 < imageSizeInMB;
};

const UploadProfile = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { message } = App.useApp();
  const { isMobile } = useResponsiveness();
  const { loadingState, updateLoadingState } = useLoading();
  const isLoading = loadingState === LoadingState.Loading;

  const beforeUpload = async (file: RcFile) => {
    const maxSizeInMegabytes = 2;
    const isImageFormatValid = checkImageFormat(file);
    const isImageSizeValid = validateImageSize(file, maxSizeInMegabytes);

    if (!isImageFormatValid) {
      message.open({
        type: 'warning',
        content: 'Wrong file format uploaded!',
      });

      return false;
    }
    if (!isImageSizeValid) {
      message.open({
        type: 'warning',
        content: `Image size exceeds the limit of ${maxSizeInMegabytes} MB`,
      });

      return false;
    }

    return true;
  };

  const handleUpload = async ({
    file,
    onSuccess,
    onError,
  }: HandleUploadParams) => {
    try {
      updateLoadingState(LoadingState.Loading);
      const formData = new FormData();
      formData.append('file', file);

      const response = await userStore.uploadProfileImage(formData);
      if (response) {
        message.open({
          type: 'success',
          content: 'Profile picture updated successfully',
        });
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.open({
        type: 'warning',
        content: 'Something went wrong',
      });
      onError();
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const handleDeleteProfileImage = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      await userStore.deleteProfileImage();
      message.open({
        type: 'success',
        content: 'Profile picture removed successfully',
      });
    } catch (error) {
      console.error('Error removing profile image:', error);
      message.open({
        type: 'warning',
        content: 'Something went wrong',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <Card className="upload-profile">
      <Flex vertical={!isMobile} gap={24} align="center" justify="center">
        <Flex
          justify="center"
          align="center"
          className={classNames('profile-image-container', {
            'profile-image-container--empty': !userStore.currentUser.profileUrl,
          })}
        >
          {userStore.currentUser.profileUrl ? (
            <Avatar
              src={
                userStore.currentUser.profileUrl || (
                  <Icons color="red" iconName={IconsNames.People} />
                )
              }
              alt="avatar"
              className="profile-image"
            />
          ) : (
            <Icons className="noavatar" iconName={IconsNames.People} />
          )}
        </Flex>
        <Flex gap={4} vertical flex={1}>
          <Upload
            style={{ width: '100%' }}
            disabled={isLoading}
            accept={supportedImageTypes.join(',')}
            showUploadList={false}
            beforeUpload={beforeUpload}
            //@ts-expect-error/handle-later
            customRequest={handleUpload}
          >
            <Button
              style={{ width: '100%' }}
              loading={isLoading}
              type="primary"
              icon={<Icons iconName={IconsNames.Plus} />}
            >
              Upload Photo
            </Button>
          </Upload>
          <Button
            style={{ width: '100%' }}
            disabled={!userStore.currentUser.profileUrl || isLoading}
            type="text"
            onClick={handleDeleteProfileImage}
          >
            Delete Photo
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default UploadProfile;
