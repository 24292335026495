import CrudService from '../core/crud-service';
import { BaseResponse } from '../core/base-entity-service';
import { StorageContentType } from '@monorepo/types';

export default class AssetsService extends CrudService {
  static override route = 'assets';

  async uploadTotalPayoutImage({
    imageUrl,
    tag,
    publicId,
    type,
  }: {
    imageUrl: string;
    tag?: string;
    publicId?: string;
    type: StorageContentType;
  }): Promise<string> {
    try {
      const response = await this.httpService.post<
        Record<string, unknown>,
        BaseResponse<string>
      >(`${this.path}/upload-image-url`, {
        imageUrl,
        tag,
        publicId,
        type,
      });

      return response.data;
    } catch (e) {
      console.error(`Failed creating total payout image`, e);
      throw e;
    }
  }
}
