export interface WithdrawRule {
  type: WithdrawRulesType;
  value: number;
}

export enum WithdrawRulesType {
  MinimumBalance = 'minimum-balance',
  MinimumAmount = 'minimum-amount',
  MinimumVesting = 'minimum-vesting',
  MinimumPayoutCoolDown = 'minimum-payout-cool-down',
  OpenPositions = 'open-positions',
}

export type AffiliationWithdrawRules = WithdrawRulesType.MinimumAmount;

export type WithdrawRulesResponse = {
  isValid: boolean;
  reason?: WithdrawBlockReason;
  availableAmount: number;
  credits?: number;
  userPayoutPercentage?: number;
};

export type WithdrawBlockReason = {
  rule: WithdrawRulesType;
  currentValue: number;
  ruleValue: number;
};
