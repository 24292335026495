import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useStore } from '../../helpers/use-store';
import { FullStory } from '@fullstory/browser';

const isProduction = import.meta.env.VITE_APP_ENV === 'production';

export const FullStoryConsumer: FunctionComponent<
  PropsWithChildren & { page: string | null }
> = ({ children, page }) => {
  const flags = useFlags();
  const [isStarted, setIsStarted] = useState(false);

  const {
    dataStore: { userStore },
  } = useStore();

  useEffect(() => {
    if (!isProduction || !flags.fullstoryRecordSession || !page || isStarted) {
      return;
    }
    FullStory('start');
    FullStory('setIdentity', {
      uid: userStore.currentUser.userId,
      properties: {
        displayName: userStore.fullName,
        email: userStore.currentUser.email,
      },
    });
    setIsStarted(true);
    console.log('Full story started');
  }, [flags]);

  useEffect(() => {
    if (page && isStarted) {
      FullStory('setProperties', {
        type: 'page',
        properties: {
          pageName: page,
        },
      });
      console.log(`Full story page ${page}`);
    }
  }, [page, isStarted]);

  return <>{children}</>;
};
