import { App, Button } from 'antd';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import { useStore } from '../../helpers/use-store';
import {
  AnalyticAuthType,
  AnalyticsEventTypes,
  formEmailValidation,
  formPasswordValidation,
  PagePath,
  formNameValidation,
  UserPayload,
  ThemeColors,
} from '@monorepo/types';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import GoogleLogin from '../../components/google-login/google-login';
import {
  Flex,
  FormInput,
  FormPassword,
  TextComponent,
} from '@monorepo/react-components';
import gtmService from '../../services/analytics/analytics';
import PageContainer from '../page-container/page-container';
import classNames from 'classnames';
import { ThemeWrapper } from '../../components/theme-wrapper/theme-wrapper';
import FormCountrySelect from '../forms/form-country-select';

const Register = () => {
  const { message } = App.useApp();
  const {
    dataStore: { userStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const { isDesktop } = useResponsiveness();
  const navigate = useNavigate();
  const form = useForm<UserPayload>();
  const isRegistrationInProgress = loadingState === LoadingState.Loading;

  const onRegister = async (values: UserPayload) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await userStore.register(values);
      updateLoadingState(LoadingState.Loaded);
      message.success(
        'Registered successfully please check your email',
        2,
        () => navigate(PagePath.Root)
      );

      gtmService.sendEvent({
        event: AnalyticsEventTypes.RegisterSuccess,
        params: {
          method: AnalyticAuthType.Email,
          user_id: userStore.currentUser.userId,
          country: userStore.currentUser.country,
          email: userStore.currentUser.email,
          first_name: userStore.currentUser.firstName,
          last_name: userStore.currentUser.lastName,
        },
      });
    } catch (e) {
      updateLoadingState(LoadingState.Error);

      message.error(e as string);

      gtmService.sendEvent({
        event: AnalyticsEventTypes.RegistrationFail,
        params: {
          fail_reason: e as string,
        },
      });
    }
  };
  const goBack = () => {
    navigate(PagePath.Root);
  };

  return (
    <PageContainer helmet="Register" className="register-page">
      <Flex vertical className="forget-password-card" gap={32}>
        <Flex vertical gap={6} align={isDesktop ? 'flex-start' : 'center'}>
          <TextComponent
            weight={600}
            className={classNames('title', {
              'title--mobile': !isDesktop,
            })}
          >
            Sign Up!
          </TextComponent>
        </Flex>
        <form name="normal_register" onSubmit={form.handleSubmit(onRegister)}>
          <Flex vertical gap={16}>
            <FormInput
              placeholder="Email"
              form={form}
              controllerProps={{
                name: 'email',
                rules: formEmailValidation(),
              }}
            />
            <FormInput
              placeholder="First Name"
              form={form}
              controllerProps={{
                name: 'firstName',
                rules: formNameValidation(),
              }}
            />
            <FormInput
              placeholder="Last Name"
              form={form}
              controllerProps={{
                name: 'lastName',
                rules: formNameValidation(),
              }}
            />
            <FormCountrySelect
              placeholder="Select Country"
              form={form}
              controllerProps={{
                name: 'country',
                rules: { required: 'Country is required' },
              }}
            />
            <FormPassword
              placeholder="Password"
              form={form}
              controllerProps={{
                name: 'password',
                rules: formPasswordValidation(),
              }}
            />
            <ThemeWrapper themeColor={ThemeColors.colorSecondary}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                loading={isRegistrationInProgress}
              >
                Sign Up
              </Button>
              <GoogleLogin ghost text="Sign up with Google" />{' '}
              <Button onClick={goBack} block type="default" ghost>
                Back
              </Button>
            </ThemeWrapper>
          </Flex>
        </form>
      </Flex>
    </PageContainer>
  );
};

export default Register;
