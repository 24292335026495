import { Icons, IconsNames } from '@monorepo/icons';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

import './account-rules-icon.scss';

interface Props {
  violated?: boolean;
  iconName: IconsNames;
}

export const AccountRulesCardIcon: FunctionComponent<Props> = ({
  violated,
  iconName,
}) => {
  return (
    <Icons
      className={classNames('account-rules-icon', {
        'account-rules-icon--violated': violated,
      })}
      iconName={violated ? IconsNames.Close : iconName}
    />
  );
};
