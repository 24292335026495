import React, { useState } from 'react';
import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';
import NavigationMenu from '../navigation-menu';
import NavigationMenuHamburger from '../navigation-menu-hamburger';

const NavigationDrawer = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavigationMenuHamburger isOpen onToggle={showDrawer} />
      <Drawer
        onClose={onClose}
        open={open}
        placement={'left'}
        width={296}
        closable={false}
      >
        <NavigationMenu showBanner={false} onClose={onClose} />
      </Drawer>
    </>
  );
};

export default observer(NavigationDrawer);
