const NyseStepTwo = () => {
  return (
    <div className={'contracts-steps-body'}>
      <h2>SECTION 1: TERMS AND CONDITIONS OF GENERAL APPLICABILITY</h2>
      <ol>
        <li>
          MARKET DATA DEFINITION – For all purposes of this Agreement, "Market
          Data" means (a) last sale information and quotation information
          relating to securities that are admitted to dealings on the New York
          Stock Exchange ("NYSE*"), (b) such bond and other equity last sale and
          quotation information, and such index and other market information, as
          United States-registered national securities exchanges and national
          securities associations (each, an "Authorizing SRO") may make
          available and as the NYSE* may from time to time designate as "Market
          Data"; and (c) all information that derives from any such information.
        </li>
        <li>
          PROPRIETARY NATURE OF DATA – Subscriber understands and acknowledges
          that each Authorizing SRO and Other Data Disseminator has a
          proprietary interest in the Market Data that originates on or derives
          from it or its market(s).
        </li>
        <li>
          ENFORCEMENT – Subscriber understands and acknowledges that (a) the
          Authorizing SROs are third-party beneficiaries under this Agreement
          and (b) the Authorizing SROs or their authorized representative(s) may
          enforce this Agreement, by legal proceedings or otherwise, against
          Subscriber or any person that obtains Market Data that is made
          available pursuant to this Agreement other than as this Agreement
          contemplates. Subscriber shall pay the reasonable attorney's fees that
          any Authorizing SRO incurs in enforcing this Agreement against
          Subscriber
        </li>
      </ol>
    </div>
  );
};

export default NyseStepTwo;
