import React, { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Checkbox } from 'antd';

export const FormCheckbox: FunctionComponent<
  Omit<FormFieldProps, 'render'> & { checkboxText?: React.ReactNode | string }
> = ({ checkboxText, ...formFieldProps }) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Checkbox
            className="form-field__checkbox"
            checked={field.value}
            {...field}
          >
            {checkboxText}
          </Checkbox>
        );
      }}
    />
  );
};
