import { App, Skeleton } from 'antd';
import { PaymentProviderCard } from '../../../payment-provider-card/payment-provider-card';
import React, { ReactNode, useEffect, useState } from 'react';
import { useStore } from '../../../../helpers/use-store';
import {
  AnalyticsEventTypes,
  OrderStatus,
  PaymentProviderName,
  SupportedCurrencies,
} from '@monorepo/types';
import {
  LoadingState,
  useLoading,
  useThemeToken,
} from '@monorepo/client-common';
import gtmService from '../../../../services/analytics/analytics';
import {
  Col,
  Divider,
  Flex,
  FormRadioCardGroup,
  Row,
  TextComponent,
} from '@monorepo/react-components';
import { useNewOrderProvider } from '../../components/new-order-provider/new-order-provider';
import WithSummarySide from '../../components/with-summary-side/with-summary-column';
import { Icons, IconsNames } from '@monorepo/icons';
import ConfirmationDrawer from '../../components/confirmation-drawer/confirmation-drawer';

import './summary.scss';

const Summary = () => {
  const { form, isLoading: isFormLoading } = useNewOrderProvider();
  const [paymentProviders, setPaymentProviders] = useState<
    PaymentProviderName[]
  >([]);
  const {
    dataStore: { orderStore },
    uiStore: { productStore },
  } = useStore();
  const values = form.getValues();
  const { message } = App.useApp();
  const { loadingState, updateLoadingState } = useLoading();
  const { token } = useThemeToken();

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const foundProduct = productStore.getProduct(
          form.getValues('productType'),
          form.getValues('productId')
        );

        if (foundProduct) {
          gtmService.sendEvent({
            event: AnalyticsEventTypes.BeginCheckout,
            params: {
              ecommerce: {
                currency:
                  SupportedCurrencies[
                    values.paymentData.currency as SupportedCurrencies
                  ],
                value: values.amount,
                items: [
                  {
                    item_id: values.productId,
                    discount: values.amount - values.paymentData.amount,
                    item_name: foundProduct.name,
                    quantity: 1,
                    price: values.amount,
                  },
                ],
              },
            },
          });
        }

        const [providers, curr] = await Promise.all([
          orderStore.getPaymentProviders(values.paymentData.currency),
          orderStore.getCurrencies(),
        ]);

        if (!curr || !providers) {
          throw new Error(`Failed loading payment page`);
        }

        setPaymentProviders(providers || []);
      } catch (e) {
        console.error(`Failed loading payment page`, e);
        message.error({ content: `Failed loading payment page` });
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, []);

  const orderAmount = Number(form.getValues('amount'));

  const useCredits = values.paymentData.useCredits;
  const canPayFullyWithCredits = orderAmount === 0 && useCredits;
  const isFree = orderAmount === 0;
  const allowedToPay = values.status === OrderStatus.Pending;

  const renderPaymentsOptions = () => {
    if (!allowedToPay) {
      return [];
    }
    if (canPayFullyWithCredits) {
      return [
        {
          value: PaymentProviderName.Credits,
          text: (
            <PaymentProviderCard
              paymentProvider={PaymentProviderName.Credits}
            />
          ),
        },
      ];
    }
    if (isFree) {
      return [
        {
          value: PaymentProviderName.Free,
          text: (
            <PaymentProviderCard paymentProvider={PaymentProviderName.Free} />
          ),
        },
      ];
    }

    return paymentProviders.map((provider) => ({
      value: provider,
      text: <PaymentProviderCard paymentProvider={provider} />,
    }));
  };

  if (loadingState !== LoadingState.Loaded || isFormLoading) {
    return <Skeleton />;
  }

  const DescItem = ({
    label,
    value,
  }: {
    label: string | ReactNode;
    value: string | ReactNode;
  }) => (
    <Col xs={12} lg={8} xxl={6}>
      <Flex vertical gap={3} justify="start" align="start">
        <TextComponent type="secondary" level={8} responsive={false}>
          {label}
        </TextComponent>
        <TextComponent level={5}>{value}</TextComponent>
      </Flex>
    </Col>
  );

  return (
    <WithSummarySide>
      <Flex gap={32} className="review-pay-step" vertical>
        <Flex vertical gap={16}>
          <TextComponent
            weight={600}
            responsive={false}
            className="review-pay-step__order_number"
            level={3}
          >
            Order#: {values.uniqId}
          </TextComponent>
          <Row gutter={[10, 20]}>
            <DescItem
              label={
                <Flex align="center" gap={5}>
                  <Icons iconName={IconsNames.Person} />
                  <TextComponent level={8} responsive={false}>
                    First Name
                  </TextComponent>
                </Flex>
              }
              value={values.information.firstName}
            />
            <DescItem
              label={
                <Flex align="center" gap={5}>
                  <Icons iconName={IconsNames.Person} />
                  <TextComponent level={8} responsive={false}>
                    Last Name
                  </TextComponent>
                </Flex>
              }
              value={values.information.lastName}
            />
            <DescItem label="Email" value={values.information.email} />
            <DescItem label="Phone" value={values.information.phoneNumber} />
          </Row>
        </Flex>

        <Divider className="review-pay-step__divider" />

        <Flex vertical gap={16}>
          <TextComponent responsive={false} level={4}>
            Billing Address
          </TextComponent>
          <Row gutter={[10, 20]}>
            <DescItem label="Street" value={values.information.address} />
            <DescItem label="City" value={values.information.city} />
            <DescItem label="Postcode" value={values.information.zipCode} />
            <DescItem label="Country" value={values.information.country} />
          </Row>
        </Flex>

        <Divider className="review-pay-step__divider" />

        <Flex vertical gap={16}>
          <TextComponent responsive={false} level={4}>
            Select payment method
          </TextComponent>
          {!isFree && (
            <Flex markedColor={token.colorError}>
              <TextComponent
                responsive={false}
                level={8}
                color={token.colorError}
              >
                Please note, you will be redirected to external site to complete
                your purchase
              </TextComponent>
            </Flex>
          )}
          <FormRadioCardGroup
            form={form}
            options={renderPaymentsOptions()}
            controllerProps={{
              name: 'paymentData.provider',
              rules: { required: true },
            }}
          />
        </Flex>
        <ConfirmationDrawer buttonText="Purchase Now" />
      </Flex>
    </WithSummarySide>
  );
};

export default Summary;
