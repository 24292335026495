import { TradingPlatforms, TradingSystem } from '@monorepo/types';

export type TradingPlatformData = {
  platform: TradingPlatforms;
  link: string;
};

export const PlatformBtnText: { [key: string]: string } = {
  [TradingPlatforms.Web]: 'Web',
  [TradingPlatforms.Mac]: 'Mac',
  [TradingPlatforms.Android]: 'Android',
  [TradingPlatforms.iOS]: 'iOS',
  [TradingPlatforms.Windows]: 'Windows',
  [TradingPlatforms.Linux]: 'Linux',
};

export const TradingPlatformServers: Record<TradingSystem, string> = {
  [TradingSystem.MetaTraderFive]: 'FivePercentOnline-Real',
  [TradingSystem.MetaTraderXP]: 'FivePercentOnline-Experience ',
  [TradingSystem.Sterling]: '',
  [TradingSystem.MatchTrader]: '',
  [TradingSystem.TraderEvolution]: '',
  [TradingSystem.MockTrader]: '',
};

export const getTradingPlatform = (
  tradingSystem: TradingSystem,
  isProd = true
) => {
  const tradingSystemPlatformMap: {
    [key: string]: TradingPlatformData[];
  } = {
    [TradingSystem.MatchTrader]: [
      { platform: TradingPlatforms.Web, link: 'https://mtr.the5ers.com/' },
    ],
    [TradingSystem.MetaTraderFive]: [
      {
        platform: TradingPlatforms.Windows,
        link: 'https://download.mql5.com/cdn/web/five.percent.online/mt5/fivepercentonline5setup.exe',
      },
      {
        platform: TradingPlatforms.Mac,
        link: 'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip',
      },
      {
        platform: TradingPlatforms.Linux,
        link: 'https://www.metatrader5.com/en/terminal/help/start_advanced/install_linux/',
      },
      {
        platform: TradingPlatforms.Web,
        link: 'https://mt5.the5ers.com/terminal',
      },
    ],
    [TradingSystem.MetaTraderXP]: [
      {
        platform: TradingPlatforms.Windows,
        link: 'https://download.mql5.com/cdn/web/five.percent.online/mt5/fivepercentonline5setup.exe/',
      },
      {
        platform: TradingPlatforms.Mac,
        link: 'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip/',
      },
      {
        platform: TradingPlatforms.Linux,
        link: 'https://www.metatrader5.com/en/terminal/help/start_advanced/install_linux/',
      },
      {
        platform: TradingPlatforms.Web,
        link: 'https://mt5.the5ers.com/terminal?_gl=1*1678toa*_gcl_au*MjA3Mjc5NTc1NS4xNzE2NDQ4MTM1*_ga*Nzc3NzU4MjAuMTcwODYxODgzMA..*_ga_QZWD62J8CK*MTcxODcwODY4OS44NS4wLjE3MTg3MDg2ODkuNjAuMC4w/',
      },
    ],
    [TradingSystem.TraderEvolution]: [
      {
        platform: TradingPlatforms.Windows,
        link: isProd
          ? 'https://update.traderevolution.com/Trade_The_Pool/Trade%20The%20Pool.exe'
          : 'https://update.traderevolution.com/Trade_The_Pool_UAT/Trade%20The%20Pool%20UAT.exe',
      },
      {
        platform: TradingPlatforms.Android,
        link: 'https://play.google.com/store/apps/details?id=com.traderevolution.tradethepool',
      },
      {
        platform: TradingPlatforms.iOS,
        link: 'https://apps.apple.com/us/app/trade-the-pool/id1628363224',
      },
    ],
    [TradingSystem.MockTrader]: [
      {
        platform: TradingPlatforms.Windows,
        link: isProd
          ? 'https://update.traderevolution.com/Trade_The_Pool/Trade%20The%20Pool.exe'
          : 'https://update.traderevolution.com/Trade_The_Pool_UAT/Trade%20The%20Pool%20UAT.exe',
      },
      {
        platform: TradingPlatforms.Android,
        link: 'https://play.google.com/store/apps/details?id=com.traderevolution.tradethepool',
      },
      {
        platform: TradingPlatforms.iOS,
        link: 'https://apps.apple.com/us/app/trade-the-pool/id1628363224',
      },
    ],
  };
  return tradingSystemPlatformMap[tradingSystem];
};
