import {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Flex, Popconfirm, Space } from 'antd';
import {
  CloseCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Abilities, PaginationResult } from '@monorepo/types';
import './entity-page-with-drawer.scss';
import { EntityDrawer } from '../entity-drawer/entity-drawer';
import { UseFormReturn } from 'react-hook-form';
import {
  EntityPageBase,
  EntityPageBaseRef,
} from '../entity-page-base/entity-page-base';
import { TableRowSelection } from 'antd/lib/table/interface';

interface Props {
  drawerBody: ReactNode;
  extraActions?: ReactNode;
  onDelete?: (id: string) => void;
  isEditAllowed?: boolean;
  isCreateAllowed?: boolean;
  initialLimit?: number;
  rowKey?: string;
  onSubmit: (isEdit?: boolean) => Promise<void>;
  validateForm: () => Promise<boolean>;
  form: UseFormReturn<any>;
  getDefaultValues: (entity: any) => Partial<any>;
  columns: ColumnsType<any>;
  rowSelection?: TableRowSelection<any>;
  abilities: Abilities[];
  showSizeChanger?: boolean;
  requestNextPage: ({
    page,
    limit,
    filters,
  }: {
    page: number;
    limit: number;
    filters?: any;
  }) => Promise<PaginationResult>;
}

export const EntityPageWithDrawer = forwardRef<EntityPageBaseRef, Props>(
  (
    {
      drawerBody,
      getDefaultValues,
      columns,
      onDelete,
      isEditAllowed,
      requestNextPage,
      initialLimit = 10,
      rowKey = '_id',
      isCreateAllowed,
      validateForm,
      onSubmit,
      form,
      extraActions,
      abilities,
      rowSelection,
      showSizeChanger,
    },
    ref
  ) => {
    const entityPageRef = useRef<EntityPageBaseRef>({} as EntityPageBaseRef);
    const [drawerState, setDrawerState] = useState<{
      entity: any | null;
      visible: boolean;
    }>({
      entity: null,
      visible: false,
    });

    useImperativeHandle(
      ref,
      () => {
        return {
          reFetch: entityPageRef.current.reFetch,
          openDrawer: openDrawer,
        };
      },
      []
    );

    const closeDrawer = () => {
      setDrawerState((state) => ({ ...state, visible: false }));
      entityPageRef.current?.reFetch();
    };

    const openDrawer = (entity: any | null) =>
      setDrawerState({ visible: true, entity });

    const onDeleteWrapper = async (id: string) => {
      if (onDelete) {
        await onDelete(id);
        entityPageRef.current?.reFetch();
      }
    };

    const submitWrapper = async (isEdit?: boolean) => {
      await onSubmit(isEdit);
    };

    const completeColumns = [...columns];

    if (onDelete || isEditAllowed) {
      completeColumns.push({
        title: 'Actions',
        dataIndex: '',
        width: '10%',
        render: (_: any, record: any) => {
          return (
            <Space size="middle">
              {onDelete ? (
                <Popconfirm
                  title="Delete"
                  description="Are you sure?"
                  onConfirm={() => {
                    onDeleteWrapper(record._id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    size="small"
                    icon={<CloseCircleOutlined />}
                    danger={true}
                  />
                </Popconfirm>
              ) : null}
              {isEditAllowed ? (
                <Button
                  size="small"
                  onClick={() => openDrawer(record)}
                  icon={<EditOutlined />}
                />
              ) : null}
            </Space>
          );
        },
      });
    }

    return (
      <div className={`entity-page`}>
        <Flex className="actions">
          {isCreateAllowed ? (
            <Button
              icon={<PlusOutlined />}
              onClick={() => openDrawer(null)}
              type={'primary'}
              style={{ float: 'right' }}
            >
              Create
            </Button>
          ) : null}
        </Flex>
        <EntityPageBase
          rowSelection={rowSelection}
          ref={entityPageRef}
          columns={completeColumns}
          initialLimit={initialLimit}
          rowKey={rowKey}
          requestNextPage={requestNextPage}
          abilities={abilities}
          showSizeChanger={showSizeChanger}
        />
        <EntityDrawer
          form={form}
          getDefaultValues={getDefaultValues}
          entity={drawerState.entity}
          visible={drawerState.visible}
          submitAction={submitWrapper}
          validateForm={validateForm}
          onClose={closeDrawer}
          extraActions={extraActions}
        >
          {drawerBody}
        </EntityDrawer>
      </div>
    );
  }
);
