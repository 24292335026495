import { Flex } from '@monorepo/react-components';
import Logo from '../../logo/logo';
import NavigationMenuHamburger from './navigation-menu-hamburger';
import { FunctionComponent } from 'react';
import { useResponsiveness } from '@monorepo/client-common';

interface Props {
  onLogoClick: () => void;
  onCloseMenu?: () => void;
}

const NavigationMenuHeader: FunctionComponent<Props> = ({
  onLogoClick,
  onCloseMenu,
}) => {
  const { isDesktop } = useResponsiveness();
  return (
    <Flex
      justify="space-between"
      align="end"
      className="navigation-menu__logo-container"
    >
      <Flex align="end" onClick={onLogoClick}>
        <Logo />
      </Flex>
      {!isDesktop && (
        <NavigationMenuHamburger
          isOpen={false}
          onToggle={() => {
            onCloseMenu?.();
          }}
        />
      )}
    </Flex>
  );
};
export default NavigationMenuHeader;
