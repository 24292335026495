import {
  Product,
  ProductMetaData,
  ProductType,
  Program,
} from '@monorepo/types';
import { getRoot } from '../../../helpers/mobx-easy-wrapper';
export default class ProductStore {
  getProduct(type: ProductType, id: string): Product | null {
    const {
      dataStore: { programStore, addOnStore },
    } = getRoot();
    if (type === ProductType.Program) {
      return programStore.get(id) || null;
    }

    if (type === ProductType.AddOn) {
      return addOnStore.get(id) || null;
    }
    return null;
  }

  getAvailableProducts(type: ProductType, groupName?: string): Product[] {
    const {
      dataStore: { programStore, addOnStore },
    } = getRoot();

    if (type === ProductType.Program) {
      const programs = programStore.items.filter(
        (product) => (product as Program).sellable
      );

      if (groupName) {
        return programs.filter((program) => program.groupName === groupName);
      }

      return programs;
    }

    if (type === ProductType.AddOn) {
      return addOnStore.items;
    }
    return [];
  }

  getProgramGroupNameByProduct(productId: string) {
    const {
      dataStore: { programStore },
    } = getRoot();

    const program = programStore.items.find(
      (program) => program._id === productId
    );

    return program ? program.groupName : '';
  }

  async fetchAllByType(type: ProductType, productMetadata?: ProductMetaData) {
    const {
      dataStore: { programStore, addOnStore },
    } = getRoot();

    if (type === ProductType.Program) {
      await programStore.fetchAll();
    }

    if (type === ProductType.AddOn && productMetadata?.accountId) {
      await addOnStore.getAddOnsForAccount(productMetadata.accountId);
    }
  }
}
