import { Button, Drawer, Space } from 'antd';
import {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from 'react';
import { LoadingState, useLoading } from '@monorepo/client-common';

interface Props {
  visible: boolean;
  title?: string;
  form: any;
  submitAction: (isEdit?: boolean) => Promise<void>;
  onClose: () => void;
  validateForm: () => Promise<boolean>;
  getDefaultValues: (entity: any | null) => Partial<any>;
  entity: any | null;
  extraActions?: ReactNode;
}

export const EntityDrawer: FunctionComponent<PropsWithChildren<Props>> = ({
  visible,
  submitAction,
  entity,
  getDefaultValues,
  children,
  form,
  title,
  validateForm,
  onClose,
  extraActions,
}) => {
  const { loadingState, updateLoadingState } = useLoading();

  useEffect(() => {
    form.reset(getDefaultValues(entity));
  }, [entity]);

  const isEditMode = entity !== null;

  const onSubmit = async () => {
    const isValid = await validateForm();
    if (!isValid) {
      return;
    }
    updateLoadingState(LoadingState.Loading);
    try {
      await submitAction(isEditMode);
      updateLoadingState(LoadingState.Loaded);
    } catch (e) {
      updateLoadingState(LoadingState.Error);
    } finally {
      onCloseDrawer();
    }
  };

  const onCloseDrawer = () => {
    onClose();
    form.reset();
  };

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Drawer
      title={title}
      width={500}
      onClose={onCloseDrawer}
      open={visible}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={onCloseDrawer}>Cancel</Button>
          <Button onClick={onSubmit} type="primary" loading={isLoading}>
            Save
          </Button>
          {extraActions}
        </Space>
      }
    >
      {children}
    </Drawer>
  );
};
