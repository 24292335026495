import { FunctionComponent, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Trade } from '@monorepo/types';
import dayjs from 'dayjs';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { useStore } from '../../helpers/use-store';
import { sortBy } from 'lodash';
import { PositionState } from '@monorepo/sterling-types';
import { Table, Statistic } from '@monorepo/react-components';

export interface Props {
  accountId: string;
  positionId: string;
  positionState: PositionState;
}

const TradesTable: FunctionComponent<Props> = ({
  accountId,
  positionId,
  positionState,
}) => {
  const {
    dataStore: { positionStore },
  } = useStore();
  const [trades, setTrades] = useState<Trade[]>([]);
  const { loadingState, updateLoadingState } = useLoading();

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await positionStore.getTrades(accountId, positionId);

        setTrades(result.results);
      } catch (e) {
        console.error(e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const sortedTrades = sortBy(trades, 'date');

  const columns: ColumnsType<Trade> = [
    {
      title: 'Time',
      width: '10%',
      dataIndex: 'date',
      render: (_, trade) => {
        return <span>{dayjs(trade.date).format('MMM D hh:mm:ss')}</span>;
      },
    },
    {
      title: 'Side',
      width: '5%',

      dataIndex: 'side',
    },
    {
      title: 'Action',
      width: '10%',
      dataIndex: 'action',
      render: (_, trade) => {
        const isPositionClosed = positionState === PositionState.Closed;
        const isFirst = sortedTrades[0].id === trade.id;

        if (isFirst) {
          return `Open`;
        }

        const isLast = sortedTrades[sortedTrades.length - 1].id === trade.id;

        if (isLast && isPositionClosed) {
          return 'Close';
        }

        if (trade.isCover) {
          return 'Decrease';
        }

        return 'Increase';
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: '10%',

      render: (_, trade) => {
        return <Statistic value={trade.price} precision={2} prefix={'$'} />;
      },
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      width: '10%',

      render: (_, trade) => {
        return <Statistic value={trade.fee} precision={2} prefix={'$'} />;
      },
    },
    {
      title: 'Qty',
      width: '3%',

      dataIndex: 'quantity',
    },
  ];

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Table
      size={'small'}
      bordered={false}
      loading={isLoading}
      columns={columns}
      dataSource={trades}
      rowKey="id"
    />
  );
};

export default TradesTable;
