import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Achievement, AchievementType, Application } from '@monorepo/types';
import { useStore } from '../../../helpers/use-store';
import { Image as AntdImage } from 'antd';
import { AchievementsSocialLinks } from '../achievements-social-links';
import { drawAchievement } from './draw-achievements';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const baseURL = 'https://res.cloudinary.com/dvt6xbblx/image/upload';

const BaseImageByType = {
  [Application.TTP]: {
    [AchievementType.Payout]: `${baseURL}/v1716360101/ttp/achievement-templates/payout-template_jllfik.png`,
    [AchievementType.ProgramAchievement]: `${baseURL}/v1716360100/ttp/achievement-templates/funded-template_iaq6d5.png`,
  },
  [Application.THE5ERS]: {
    [AchievementType.Payout]: `${baseURL}/v1721719745/ttp-prod/achievement-templates/the5ers-payout_judtiz.png`,
    [AchievementType.ProgramAchievement]: `${baseURL}/v1721714670/ttp-prod/achievement-templates/the5ers-cert_lgu0yd.png`,
  },
};

interface Props {
  achievement: Achievement;
}

const AchievementTemplate: FunctionComponent<Props> = ({ achievement }) => {
  const {
    dataStore: { userStore },
  } = useStore();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [url, setUrl] = useState('');
  const baseImage = BaseImageByType[project][achievement.type];

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const text = userStore.fullName
      .split(' ')
      .filter((word) => {
        return word.length > 0;
      })
      .map((word) => {
        const [firstLetter, ...rest] = word;

        return `${firstLetter.toUpperCase()}${rest.join('')}`;
      })
      .join(' ');

    if (ctx && canvas) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = baseImage;
      image.onload = () => {
        const dataUrl = drawAchievement(
          ctx,
          image,
          canvas,
          text,
          achievement,
          project
        );
        setUrl(dataUrl);
      };
    }
    return () => {};
  }, []);

  return (
    <>
      {url === '' ? (
        <canvas
          ref={canvasRef}
          height={580}
          width={600}
          style={{ borderRadius: '10px', display: 'none' }}
        />
      ) : (
        <AntdImage
          className="achievement-image"
          src={url}
          preview={{
            mask: (
              <AchievementsSocialLinks
                imageUrl={url}
                achievement={achievement}
              />
            ),
          }}
        />
      )}
    </>
  );
};

export default AchievementTemplate;
