import {
  IdIdentifier,
  TimeIdentifiers,
  UserIdentifier,
} from '../common/identifiers';
import { PayoutSource } from '../payout/payout';
import { AccountType } from '../account/account';

export type AchievementMetadata = Partial<PayoutAchievement> &
  Partial<ProgramAchievement>;

export type PayoutAchievement = {
  source: PayoutSource;
  amount: number;
  userPayout: number;
};

export type ProgramAchievement = {
  programId: string;
  accountType: AccountType;
  nextAccountType?: AccountType;
};

export enum AchievementType {
  Payout = 'payout',
  ProgramAchievement = 'program-achievement',
}

export type Achievement = IdIdentifier &
  TimeIdentifiers &
  UserIdentifier & {
    sourceId: string;
    metadata: AchievementMetadata;
    type: AchievementType;
    link?: string;
  };

export const getTotalPayoutImageTag = (userId: string, amount: number) => {
  return `total-payout-${amount}-${userId}`;
};
