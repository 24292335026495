import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { AddOn } from '@monorepo/types';
import { computed, makeObservable, observable, runInAction } from 'mobx';

export class AddOnStore {
  @observable
  _items: Map<string, AddOn> = new Map();

  constructor() {
    makeObservable(this);
  }

  async getAddOnsForAccount(accountId: string) {
    const {
      apiFactory: { addOnService },
    } = getEnv();

    this._items.clear();
    const items = await addOnService.getAddOnsForAccount(accountId);
    runInAction(() => {
      items.forEach((item) => {
        this._items.set(item._id, item);
      });
    });
  }

  get(id: string) {
    return this._items.get(id);
  }

  @computed
  get items() {
    return Array.from(this._items.values());
  }
}
