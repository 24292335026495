import { Flex, TextComponent } from '@monorepo/react-components';
import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useResponsiveness } from '@monorepo/client-common';
import { Result } from 'antd';

import './page-container.scss';

interface Props extends PropsWithChildren {
  className?: string;
  helmet: string;
  pageTitle?: ReactNode;
  titlePostfix?: ReactNode;
  titleBar?: ReactNode;
  isError?: boolean;
}

const PageContainer: FunctionComponent<Props> = ({
  helmet,
  className,
  children,
  pageTitle,
  titlePostfix,
  titleBar,
  isError,
}) => {
  const { isDesktop } = useResponsiveness();
  const shouldShowHeader = pageTitle || titlePostfix || titleBar;

  const Content = () => {
    if (isError) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
        />
      );
    }
    return children;
  };
  return (
    <div className={classNames('page-container', className)}>
      <Helmet>
        <title>{helmet}</title>
      </Helmet>
      {shouldShowHeader && (
        <Flex
          className="page-container__header"
          align="center"
          justify="space-between"
          wrap="wrap"
          gap={5}
        >
          <Flex align="center" gap={8} wrap="wrap">
            {pageTitle && (
              <TextComponent weight={500} level={isDesktop ? 2 : 4}>
                {pageTitle}
              </TextComponent>
            )}
            {titlePostfix}
          </Flex>
          {titleBar}
        </Flex>
      )}

      {Content()}
    </div>
  );
};
export default PageContainer;
