import CrudService from '../core/crud-service';
import {
  AffiliateLeadData,
  Contract,
  ContractData,
  ContractType,
  Credit,
  CreditTransaction,
  LoginResponse,
  NotificationData,
  TradingSystem,
  User,
  UserCoupon,
  UserCouponStatus,
  UserPayload,
  UserTradingSystemCredentials,
  UserVerificationPlatform,
  VerificationData,
} from '@monorepo/types';
import { BaseResponse } from '../core/base-entity-service';
import { AxiosError } from 'axios';

export default class UserService extends CrudService {
  static override route = 'user';

  async getCredits() {
    const response = await this.httpService.get<BaseResponse<number>>(
      `${this.path}/credits`
    );

    return response.data;
  }

  async getCreditsHistory() {
    const response = await this.httpService.get<
      BaseResponse<{ transactions: CreditTransaction[]; credits: Credit[] }>
    >(`${this.path}/credits-history`);

    return response.data;
  }

  async getLogins() {
    const response = await this.httpService.get<
      BaseResponse<{ logins: UserTradingSystemCredentials[] }>
    >(`${this.path}/logins`);

    if (!response.data.logins) {
      throw new Error(`Missing logins`);
    }

    return response.data.logins;
  }

  async getUserVerificationData() {
    const response = await this.httpService.get<BaseResponse<VerificationData>>(
      `${this.path}/verification`
    );

    return response.data;
  }

  async registerUser(userDetails: UserPayload) {
    try {
      const response = await this.httpService.post(
        `${this.path}/register`,
        userDetails
      );
      return response;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw e.response?.data.message;
      }
      if (e instanceof Error) {
        throw new Error('Something went wrong');
      }
    }
  }

  async getNotifications() {
    const response = await this.httpService.get<
      BaseResponse<NotificationData[]>
    >(`${this.path}/notifications`);

    return response.data;
  }

  async getUnreadNotifications() {
    const response = await this.httpService.get<BaseResponse<number>>(
      `${this.path}/unread-notifications-count`
    );
    return response.data;
  }

  async login(email?: string, password?: string) {
    try {
      const response: { data: LoginResponse } = await this.httpService.post(
        `${this.path}/login`,
        {
          username: email,
          password,
        }
      );
      return response.data;
    } catch (e) {
      this.handleLoginError(e);
    }
  }

  async refreshToken(refreshToken: string, userId: string) {
    const tokenData = await this.httpService.post<
      { refreshToken: string; userId: string },
      { data: string }
    >(`${this.path}/refresh-token`, {
      refreshToken,
      userId,
    });
    return tokenData.data;
  }

  async confirm(confirmCode: string) {
    const response = await this.httpService.post<
      { confirmCode: string },
      { data: boolean }
    >(`${this.path}/confirm`, {
      confirmCode,
    });

    return response.data;
  }

  async forgetPassword(email: string) {
    const response = await this.httpService.post<
      { email: string },
      { data: boolean }
    >(`${this.path}/forget-password`, {
      email,
    });

    return response.data;
  }

  async resetPassword(resetPasswordCode: string, newPassword: string) {
    const response = await this.httpService.post<
      { resetPasswordCode: string; newPassword: string },
      { data: boolean }
    >(`${this.path}/reset-password`, {
      resetPasswordCode,
      newPassword,
    });

    return response.data;
  }

  async uploadProfilePicture(file: FormData) {
    const response = await this.httpService.post<FormData, { data: string }>(
      `${this.path}/upload-profile-image`,
      file,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data;
  }

  async deleteProfilePicture() {
    const response = await this.httpService.delete<BaseResponse<string>>(
      `${this.path}/delete-profile-image`
    );
    return response.data;
  }

  async updateUser(userDataToUpdate: Partial<User>) {
    try {
      const response = await this.httpService.put<
        Partial<User>,
        { data: Partial<User> }
      >(`${this.path}`, userDataToUpdate);
      return response.data as User;
    } catch (e) {
      console.error(`Failed update user details with error: ${e}`);

      if (e instanceof AxiosError) {
        throw e.response?.data.message[0];
      }
      if (e instanceof Error) {
        throw new Error('Something went wrong');
      }
    }
  }

  async getKycAccessToken() {
    try {
      const response = await this.httpService.get<BaseResponse<string>>(
        `${this.path}/verification/token/${UserVerificationPlatform.SumSub}`
      );
      return response.data;
    } catch (e) {
      throw new Error('Could not get kyc access token for kyc process');
    }
  }

  handleLoginError(error: unknown) {
    if (error instanceof AxiosError) {
      const responseError = error.response?.data;
      if (responseError?.statusCode === 401) {
        throw new Error(error.response?.data.message);
      }
      throw new Error('Something went wrong');
    }
    if (error instanceof Error) {
      throw new Error('Something went wrong');
    }
    throw new Error('Something went wrong');
  }

  async registerAsAffiliate() {
    try {
      const response = await this.httpService.post<
        unknown,
        Record<string, string>
      >(`${this.path}/affiliate/register`);
      return response.data;
    } catch (e) {
      throw new Error('Could not register as an affiliate');
    }
  }

  async getUserAffiliationDashboard() {
    try {
      const response = await this.httpService.get<BaseResponse<string>>(
        `${this.path}/affiliate`
      );
      return response.data;
    } catch (e) {
      throw new Error('Could not register as an affiliate');
    }
  }

  async signUserContract(params: {
    contractType: ContractType;
    contractData?: ContractData;
  }) {
    try {
      const response = await this.httpService.post<
        { contractType: ContractType },
        BaseResponse<Contract>
      >(`${this.path}/contract`, params);

      return response.data;
    } catch (e) {
      throw new Error('Failed signing up contract');
    }
  }

  async getUserContracts() {
    try {
      const response = await this.httpService.get<BaseResponse<Contract[]>>(
        `${this.path}/contracts`
      );

      return response.data || [];
    } catch (e) {
      throw new Error('Failed getting contracts');
    }
  }

  async getUserCoupons() {
    try {
      const response = await this.httpService.get<BaseResponse<UserCoupon[]>>(
        `${this.path}/coupons`
      );
      return response.data || [];
    } catch (e) {
      throw new Error('Failed getting coupons');
    }
  }

  async createUserCoupon(params: { org: string; name: string }) {
    try {
      const response = await this.httpService.post<
        { org: string; name: string },
        { data: { coupon: UserCoupon | null; status: UserCouponStatus } }
      >(`${this.path}/coupons`, params);
      return response.data || null;
    } catch (e) {
      throw new Error('Failed getting coupons');
    }
  }

  async recordLeadByOldAffiliation(data: AffiliateLeadData) {
    try {
      const response = await this.httpService.post<
        AffiliateLeadData,
        { data: boolean }
      >(`${this.path}/record-lead-old-affiliation`, data);
      return response.data;
    } catch (e) {
      throw new Error('Failed getting contracts');
    }
  }

  async decryptPassword(login: string, tradingSystem: TradingSystem) {
    try {
      const response = await this.httpService.post<
        { login: string; tradingSystem: TradingSystem },
        { data: string }
      >(`${this.path}/decrypt-password`, { login, tradingSystem });

      return response.data;
    } catch (e) {
      throw new Error('Failed decrypting password');
    }
  }

  async getAffiliateAvailableCommissions() {
    try {
      const response = await this.httpService.get<{ data: number }>(
        `${this.path}/affiliate/commissions/available-sum`
      );

      return response.data;
    } catch (e) {
      throw new Error('Failed decrypting password');
    }
  }
}
