import { Divider, TextComponent, Flex } from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import { NewOrderSteps } from '../../new-order';
import { useResponsiveness } from '@monorepo/client-common';
import { useStore } from '../../../../helpers/use-store';
import classNames from 'classnames';

import './new-order-step-headline.scss';

interface Props {
  type: NewOrderSteps | undefined;
  step: number;
}

const NewOrderStepHeadline: FunctionComponent<Props> = ({ type, step }) => {
  const {
    dataStore: { userStore },
  } = useStore();

  const { isMobile } = useResponsiveness();

  const stepHeadlineTextMap = {
    [NewOrderSteps.Groups]:
      'Please select a program to begin the evaluation process',
    [NewOrderSteps.Product]:
      'Please choose currency and funding option to begin the evaluation process',
    [NewOrderSteps.Info]: 'Add billing information',
    [NewOrderSteps.Summary]: 'Review details and select payment provider',
  };

  if (!type) {
    return null;
  }

  const isInfoOrSummaryStep =
    type === NewOrderSteps.Info || type === NewOrderSteps.Summary;

  const headline = (
    <TextComponent weight={600} level={3}>{`${step + 1}. ${
      stepHeadlineTextMap[type]
    }`}</TextComponent>
  );

  return (
    <Flex
      vertical
      className={classNames('step-headline', {
        'step-headline--mobile': isMobile,
      })}
      align={'center'}
      gap={15}
    >
      {step === 0 && (
        <TextComponent weight={600} level={1}>
          Welcome, {userStore.currentUser.firstName}
        </TextComponent>
      )}
      <Divider
        orientation={isInfoOrSummaryStep ? 'left' : 'center'}
        orientationMargin={0}
      >
        {headline}
      </Divider>
    </Flex>
  );
};

export default NewOrderStepHeadline;
