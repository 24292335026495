import { Achievement, PayoutAchievement } from '@monorepo/types';
import { FunctionComponent } from 'react';
import AchievementSection from '../achievement-section';
import AchievementTotalPayoutDraw from './achievement-total-payout.draw';

interface Props {
  payoutAchievements: Achievement[];
  isLoading?: boolean;
}

const AchievementTotalPayout: FunctionComponent<Props> = ({
  payoutAchievements,
}) => {
  if (!payoutAchievements.length) {
    return null;
  }

  const totalPayout = payoutAchievements
    .map(
      (achievement) => (achievement.metadata as PayoutAchievement)?.userPayout
    )
    .reduce((acc, value) => acc + value, 0);

  if (!totalPayout) {
    return null;
  }

  return (
    <AchievementSection
      title="Total Payouts"
      items={[<AchievementTotalPayoutDraw amount={totalPayout} />]}
    />
  );
};

export default AchievementTotalPayout;
