import { PaymentProviderName, SupportedCurrencies } from '../payment';

export enum AnalyticsEventTypes {
  BeginCheckout = 'begin_checkout',
  ChoseAccountProgram = 'choose_account_program',
  LoginSuccess = 'login_success',
  RegisterSuccess = 'sign_up_completed_successfully',
  PageView = 'pageview',
  NewAccountClick = 'new_account_cta_click',
  RegisterAsAffiliate = 'affiliate_plan_upgrade_cta',
  LoginFail = 'login_fail',
  RegistrationFail = 'sign_up_fail',
  Logout = 'logout',
  AddPaymentInfo = 'add_payment_info',
  Purchase = 'purchase',
}

export enum ServiceSuffix {
  DB = 'DB',
  API = 'API',
}

export enum AnalyticAuthType {
  Email = 'Email',
  Google = 'Google',
}

interface CheckoutEcommerce {
  currency: SupportedCurrencies;
  value: number;
  items: CheckoutEcommerceItem[];
}

interface AddPaymentInfoEcommerce {
  currency: SupportedCurrencies;
  value: number;
  payment_type: PaymentProviderName;
  items: CheckoutEcommerceItem[];
}

interface AddPaymentInfo {
  ecommerce: AddPaymentInfoEcommerce;
}

export interface Purchase extends CheckoutEcommerce {
  transaction_id: string;
  session_id: string;
  engagement_time_msec: string;
  currency: SupportedCurrencies;
  value: number;
  items: CheckoutEcommerceItem[];
}

interface CheckoutEcommerceItem {
  item_id: string;
  item_name?: string;
  discount: number;
  price: number;
  quantity: number;
}

interface PageViewParams {
  title: string;
  location: string;
}

interface LoginFail {
  fail_reason: string;
}

interface RegisterFail {
  fail_reason: string;
}

interface SuccessLogout {
  user_id: string;
}

interface SuccessLogin {
  user_id: string;
  method: AnalyticAuthType;
}

interface SuccessLogin {
  user_id: string;
  method: AnalyticAuthType;
}

interface SuccessLocalRegister {
  user_id: string;
  method: AnalyticAuthType;
  email: string;
  first_name?: string;
  last_name?: string;
  country?: string;
}

interface ButtonClick {
  button_text: string;
}

interface ChooseProgram {
  program_name: string;
  program_id: string;
  program_value: string;
}

interface BeginCheckout {
  ecommerce: CheckoutEcommerce;
}

export type AnalyticEventParams =
  | { event: AnalyticsEventTypes.PageView; params: PageViewParams }
  | { event: AnalyticsEventTypes.LoginFail; params: LoginFail }
  | { event: AnalyticsEventTypes.BeginCheckout; params: BeginCheckout }
  | { event: AnalyticsEventTypes.ChoseAccountProgram; params: ChooseProgram }
  | { event: AnalyticsEventTypes.NewAccountClick; params: ButtonClick }
  | { event: AnalyticsEventTypes.RegisterAsAffiliate; params: ButtonClick }
  | { event: AnalyticsEventTypes.RegisterSuccess; params: SuccessLocalRegister }
  | {
      event: AnalyticsEventTypes.LoginSuccess;
      params: SuccessLogin;
    }
  | {
      event: AnalyticsEventTypes.Logout;
      params: SuccessLogout;
    }
  | {
      event: AnalyticsEventTypes.RegistrationFail;
      params: RegisterFail;
    }
  | {
      event: AnalyticsEventTypes.AddPaymentInfo;
      params: AddPaymentInfo;
    }
  | {
      event: AnalyticsEventTypes.Purchase;
      params: Purchase;
    };
