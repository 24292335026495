import React, { CSSProperties, FunctionComponent } from 'react';
import {
  CurrencyComponentProps,
  getCurrencyIcon,
} from '@monorepo/client-common';
import classNames from 'classnames';

import './currency-avatar.scss';

type Props = CurrencyComponentProps & {
  className?: string;
  style?: CSSProperties;
};

export const CurrencyIcon: FunctionComponent<Props> = ({
  type,
  name,
  className,
  ...props
}) => {
  const icon = getCurrencyIcon({ name, type });

  return (
    <img
      src={icon}
      alt="currency-icon"
      className={classNames('currency-avatar__icon', className)}
      {...props}
    />
  );
};
