import {
  Card,
  Flex,
  TextComponent,
  ProgressSlider,
  Row,
  Col,
} from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import { IconsNames } from '@monorepo/icons';
import { AccountRulesCardIcon } from '../account-rules-icon/account-rules-icon';
import { useResponsiveness } from '@monorepo/client-common';
import { ThemeColors } from '@monorepo/types';
import { useColor } from '../../../../../hooks';
import DashboardTooltip from '../../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import { ProfitableDaysProps } from '../../progress-cards/profitable-days-progress';

const ProfitableDaysRule: FunctionComponent<ProfitableDaysProps> = ({
  currentProfitableDays,
  profitableDaysTarget,
  tooltipText,
  openModal,
  isLoading,
}) => {
  const { getColor } = useColor();
  const { isMobile } = useResponsiveness();

  const colorLink = getColor(ThemeColors.colorLink);

  return (
    <Card
      hoverable={currentProfitableDays > 0}
      onClick={openModal}
      dashed
      className={'account-rules'}
    >
      <Row gutter={[5, 10]}>
        <DashboardTooltip tooltipText={tooltipText} />
        <Col span={8} sm={24}>
          <Row
            gutter={[0, 15]}
            justify={'space-between'}
            className={'account-rules__header'}
          >
            <Col span={18}>
              <Flex gap={5} align={'center'}>
                {!isMobile && (
                  <AccountRulesCardIcon iconName={IconsNames.Burn} />
                )}

                <TextComponent
                  responsive={false}
                  color={colorLink}
                  weight={600}
                >
                  {'Profitable Days'}
                </TextComponent>
              </Flex>
            </Col>
          </Row>
        </Col>

        <Col span={16} sm={24}>
          <Flex
            justify={'end'}
            vertical
            className={'account-rules__slider-section'}
          >
            <Flex>
              <TextComponent loading={isLoading} weight={600} level={3}>
                {currentProfitableDays}/
              </TextComponent>
              <TextComponent loading={isLoading} level={3}>
                {profitableDaysTarget}
              </TextComponent>
            </Flex>

            <ProgressSlider
              disabled
              value={currentProfitableDays}
              trackColor={ThemeColors.colorPrimary}
              max={profitableDaysTarget}
            />
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default ProfitableDaysRule;
