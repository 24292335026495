import React, { useEffect } from 'react';
import { useStore } from '../../../../helpers/use-store';
import {
  FormCheckbox,
  TextComponent,
  Flex,
  FormRadioButtonGroup,
} from '@monorepo/react-components';
import { useThemeToken } from '@monorepo/client-common';
import CreditIcon from '../../../../components/credit-icon/credit-icon';
import { Link } from 'react-router-dom';
import { useNewOrderProvider } from '../../components/new-order-provider/new-order-provider';
import ConfirmationDrawer from '../../components/confirmation-drawer/confirmation-drawer';
import AccountForm from '../../../forms/account-form';
import AvailableCouponsSelect from '../../components/available-coupons-select/available-coupons-select';

import './billing-info.scss';

const BillingInfo = () => {
  const { form } = useNewOrderProvider();
  const {
    dataStore: { applicationConfigStore, userStore },
    uiStore: { productStore },
  } = useStore();
  const { token } = useThemeToken();
  const isCompany = form.watch('information.isCompany');
  const currentCreditsAmount = userStore.currentUser.creditBalance;
  const shouldAllowCredits = currentCreditsAmount > 0;

  const getCreditsValue = () => {
    const product = productStore.getProduct(
      form.getValues('productType'),
      form.getValues('productId')
    );
    const orderAmount = product?.price || form.getValues('amount');
    const userCredits = userStore.currentUser.creditBalance;
    const amountOfCreditsToUse =
      userCredits >= orderAmount ? orderAmount : userCredits;

    return amountOfCreditsToUse.toFixed(2);
  };
  const termsAndConditionsLink =
    applicationConfigStore.applicationConfig.termsAndConditions;

  useEffect(() => {
    // Need to reset field because of it being inserted dynamically
    form.resetField('information.email', {
      defaultValue: form.getValues('information.email'),
    });
  }, []);

  return (
    <Flex vertical gap={24} flex={1} className="billing-info-step">
      <FormRadioButtonGroup
        form={form}
        radioGroupProps={{
          className: 'billing-info-step-radio-group',
        }}
        options={[
          {
            value: false,
            text: 'Personal Use',
          },
          {
            value: true,
            text: 'Company',
          },
        ]}
        controllerProps={{
          name: 'information.isCompany',
        }}
      />
      <AccountForm
        showCompanyFields={isCompany}
        addressTitle="Billing Address"
        personalInfoTitle="Trader Information"
        formInstance={form}
        fieldPrefix="information"
      />
      <Flex vertical gap={16}>
        <FormCheckbox
          checkboxText={
            <TextComponent level={8} responsive={false}>
              I agree to{' '}
              <Link to={termsAndConditionsLink || ''} target="_blank">
                Terms and Conditions
              </Link>
            </TextComponent>
          }
          form={form}
          controllerProps={{
            name: 'information.agreedToTerms',
            rules: { required: 'Terms and Conditions is required' },
          }}
        />
        <FormCheckbox
          checkboxText={
            <TextComponent capitalize level={8} responsive={false}>
              I declare that all the information filled are correct and
              corresponds to government issued identification
            </TextComponent>
          }
          form={form}
          controllerProps={{
            name: 'information.declaredInformationIsCorrect',
            rules: { required: 'Terms and Conditions is required' },
          }}
        />
        {shouldAllowCredits && (
          <Flex markedColor={token.colorPrimary}>
            <FormCheckbox
              checkboxText={
                <Flex align="center" gap={5}>
                  <CreditIcon />
                  <TextComponent level={8} responsive={false}>
                    Use{' '}
                    <TextComponent weight={700} level={8} responsive={false}>
                      {getCreditsValue()}
                    </TextComponent>{' '}
                    Credits To Purchase New Trading Account
                  </TextComponent>
                </Flex>
              }
              form={form}
              controllerProps={{
                name: 'paymentData.useCredits',
              }}
            />
          </Flex>
        )}
        <AvailableCouponsSelect />
      </Flex>
      <ConfirmationDrawer showSummary={false} buttonText="Place Order Now" />
    </Flex>
  );
};

export default BillingInfo;
