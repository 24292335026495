import { Descriptions, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Statistic } from '@monorepo/react-components';
interface Props {
  equity: number;
  allowedDailyLosses: number;
  snapshotBalance?: number;
  snapshotEquity?: number;
  programId: string;
}

const AccountDailyProfitsAndLosses: FunctionComponent<Props> = ({
  equity,
  allowedDailyLosses,
  snapshotBalance,
  snapshotEquity,
  programId,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const program = programStore.get(programId);

  const maxDailyLoss =
    program?.config?.dailyLossPercentage ||
    program?.config?.dailyPausePercentage ||
    0;

  const titleText = program?.config?.dailyLossPercentage
    ? 'Daily Loss'
    : 'Daily Pause';

  const allowedLoss = allowedDailyLosses * -1;
  const getMaxSnapshot = () => {
    if (!snapshotBalance || !snapshotEquity) {
      return 0;
    }

    return Math.max(snapshotBalance, snapshotEquity);
  };

  return (
    <Card
      title={titleText}
      extra={
        <Tooltip
          title={`-${maxDailyLoss}% of the highest between the starting equity or balance of the day. Going below it will lead to account termination.
`}
        >
          <InfoCircleOutlined />
        </Tooltip>
      }
    >
      <Descriptions column={1}>
        <Descriptions.Item label={'Current Level'}>
          <Statistic prefix={'$'} value={equity} precision={2} />
        </Descriptions.Item>
        <Descriptions.Item label={'Min Level'}>
          <Statistic
            prefix={'$'}
            value={getMaxSnapshot() - allowedLoss}
            precision={2}
          />
        </Descriptions.Item>
        {snapshotBalance ? (
          <Descriptions.Item label={'Midnight Balance'}>
            <Statistic prefix={'$'} value={snapshotBalance} precision={2} />
          </Descriptions.Item>
        ) : null}
        {snapshotEquity ? (
          <Descriptions.Item label={'Midnight Equity'}>
            <Statistic prefix={'$'} value={snapshotEquity} precision={2} />
          </Descriptions.Item>
        ) : null}
      </Descriptions>
    </Card>
  );
};

export default AccountDailyProfitsAndLosses;
