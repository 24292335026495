import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Flex, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  LoadingState,
  Sorter,
  useLoading,
  usePagination,
} from '@monorepo/client-common';
import { Abilities, PaginationResult, PaginationSort } from '@monorepo/types';
import { Table } from '../table/table';
import { noop } from 'lodash';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Button } from '../button/button';
import { ReloadOutlined } from '@ant-design/icons';
import { Tooltip } from '../tooltip/tooltip';

import './entity-page-base.scss';

export interface EntityPageBaseRef {
  reFetch: (filters?: any, sort?: PaginationSort) => Promise<void>;
  openDrawer?: (entity: any | null) => void;
}

interface Props {
  name?: string;
  columns: ColumnsType<any>;
  rowClassName?: string;
  onRowClick?: (item: any) => void;
  initialLimit?: number;
  showSizeChanger?: boolean;
  filters?: any;
  sort?: PaginationSort;
  setSort?: (sorter?: Sorter<any>) => void;
  rowKey?: string;
  actions?: ReactNode[];
  requestNextPage: ({
    filters,
    page,
    limit,
    sort,
  }: {
    filters?: any;
    page: number;
    limit: number;
    sort?: PaginationSort;
  }) => Promise<PaginationResult>;
  abilities?: Abilities[];
  rowSelection?: TableRowSelection<any>;
}

export const EntityPageBase = forwardRef<EntityPageBaseRef, Props>(
  (
    {
      columns,
      onRowClick = noop,
      rowClassName,
      name,
      requestNextPage,
      initialLimit,
      rowKey = '_id',
      actions,
      filters = {},
      sort,
      setSort = () => {},
      abilities,
      rowSelection,
      showSizeChanger = true,
    },
    ref
  ) => {
    const [records, setRecords] = useState<any[]>([]);
    const { loadingState, updateLoadingState } = useLoading();
    const isLoading = loadingState === LoadingState.Loading;

    const isViewSingle = abilities?.includes(Abilities.ViewSingle);

    const { page, limit, total, setTotal, setLimit, setPage, firstPage } =
      usePagination({
        limit: isViewSingle ? 1 : initialLimit,
      });

    useImperativeHandle(
      ref,
      () => ({
        reFetch: requestPage,
      }),
      [page, limit, filters, sort]
    );

    const requestPage = async (filters: any = {}, sort?: PaginationSort) => {
      try {
        updateLoadingState(LoadingState.Loading);
        const response = await requestNextPage({
          page,
          limit,
          filters,
          sort,
        });
        setRecords(response.results);
        setTotal(response.total);
      } catch (e) {
        console.error(
          `Failed loading payouts for page: ${page}, limit: ${limit}`,
          e
        );
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    useEffect(() => {
      requestPage(filters, sort);
    }, [page, limit, sort]);

    return (
      <div className={`entity-page`}>
        <Flex justify="space-between">
          <Typography.Title level={4}>{name}</Typography.Title>
          {actions ? (
            <div className="actions">{actions.map((action) => action)}</div>
          ) : null}
        </Flex>
        <Space
          direction="vertical"
          size="large"
          className="entity-page__table-container"
        >
          <Tooltip title="Reload page">
            <Button
              disabled={isLoading}
              onClick={() => requestPage(filters, sort)}
              shape="circle"
              size="small"
              type="primary"
              icon={<ReloadOutlined />}
              className="entity-page__reload-button"
            />
          </Tooltip>
          <Table
            onRow={(record) => {
              return {
                onClick: () => onRowClick(record),
              };
            }}
            bordered={true}
            scroll={{ x: 400 }}
            rowClassName={rowClassName || ''}
            rowSelection={rowSelection}
            loading={isLoading}
            pagination={{
              current: page,
              showSizeChanger: !isViewSingle && showSizeChanger,
              pageSizeOptions: [10, 20, 30, 50],
              pageSize: limit,
              total,
            }}
            onChange={(pagination, filters, sorter: any) => {
              if (pagination.current) {
                setPage(pagination.current);
              }
              if (pagination.pageSize) {
                setLimit(pagination.pageSize);
              }
              setSort(sorter);
            }}
            size="small"
            rowKey={rowKey}
            dataSource={records}
            columns={columns}
          />
        </Space>
      </div>
    );
  }
);
