import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Ribbon as RibbonType } from '@monorepo/types';
import { useColor } from '../../hooks/use-color';
import { BadgeRibbon, TextComponent } from '@monorepo/react-components';

interface Props {
  ribbon: RibbonType | undefined;
}

export const Ribbon: FunctionComponent<PropsWithChildren<Props>> = ({
  ribbon,
  children,
}) => {
  const { getColor } = useColor();

  if (!ribbon || !ribbon.html) {
    return children;
  }

  const ribbonColor = getColor(ribbon.color);

  const content = (
    <TextComponent level={7} responsive={false}>
      <span dangerouslySetInnerHTML={{ __html: ribbon.html }} />
    </TextComponent>
  );

  return (
    <BadgeRibbon text={content} color={ribbonColor}>
      {children}
    </BadgeRibbon>
  );
};
