export type KeysOfType<T> = keyof T;
export interface PaginationRequest<T = any> {
  filters?: { [Key in KeysOfType<T>]?: any };
  sort?: PaginationSort<T>;
  returnTotal?: boolean;
  limit: number;
  page: number;
}

export interface PaginationResult<T = any> {
  total: number;
  results: T[];
}

export interface PaginationSort<T = any> {
  direction: SortDirection;
  field: keyof T;
}

export enum SortDirection {
  Asc = 1,
  Desc = -1,
}

export enum SortType {
  Date = 'date',
}
