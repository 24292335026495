import { useStore } from '../../../helpers/use-store';
import React from 'react';

const AffiliationModalContent = () => {
  const {
    dataStore: { applicationConfigStore },
  } = useStore();
  const { name } = applicationConfigStore.applicationConfig;
  return (
    <div className={'contracts-steps-body'}>
      <p>
        The Terms and Conditions for ’${name}’ Referral Program:
        <br />
        <br />
        1. No limit to the number of new traders introduced by a referring
        trader.
        <br />
        2. Any trader can sign up only once using only one referral link.
        <br />
        3. The reward is only paid once the trader has signed up and paid for
        the program in full and once the payment is cleared on ${name}’s side.
        <br />
        4. Reward amounts are commissioned based on the selected program by the
        new trader: 10% for all programs (you can split 5% each with your
        followers by request).
        <br />
        5. The reward is a payment sent to you by your preferred payout method
        through Bank transfer(WISE) crypto or credit card.
        <br />
        6. Once the reward exceeds $150 and 3 sign-ups, the affiliate can ask to
        withdraw his/her commission.
        <br />
        7. Payout is available only upon request once all terms have been
        filled.
        <br />
        8. It is prohibited to run Google ads without using the ${name}
        brand as keywords. If you run a Google ads campaign, you must include
        the following words as negative keyword phrases: “TradeThePool” and
        “TTP.”
        <br />
        9. Accounts disrespecting the previous point will be suspended, and
        commissions will be canceled.
        <br />
        10. Before running any PPC campaign, you must request the advertising
        guidelines from us and give us access as a view-only.
        <br />
      </p>
    </div>
  );
};

export default AffiliationModalContent;
