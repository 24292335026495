import Pusher, { Channel } from 'pusher-js';
import { getEnv, getRoot } from '../../../helpers/mobx-easy-wrapper';

export type RealTimeData = { realTime?: boolean };

type ConnectionCallbackArgs = { previous: string; current: string };

enum PusherConnectionState {
  DISCONNECTED = 'disconnected',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  FAILED = 'failed',
  INITIALIZED = 'initialized',
  UNAVAILABLE = 'unavailable',
}

export default class RealTimeStore {
  private channel: Channel | null = null;
  private pusher: Pusher | null = null;
  private maxReconnects = 5;
  private reconnectInterval: NodeJS.Timer | null = null;

  initSocket() {
    if (this.pusher) {
      return;
    }

    const {
      envConfig: { pusher_api_key, pusher_cluster },
    } = getEnv();
    const {
      dataStore: { userStore },
    } = getRoot();

    const pusher = new Pusher(pusher_api_key, {
      cluster: pusher_cluster,
    });
    this.channel = pusher.subscribe(userStore.currentUser.userId);
    this.pusher = pusher;

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        pusher.disconnect();
      } else {
        pusher.connect();
      }
    });

    this.pusher.connection.bind(
      'state_change',
      ({ previous, current }: ConnectionCallbackArgs) => {
        console.log(`[Pusher] status changed from ${previous} to ${current}`);
        const isConnected = current === PusherConnectionState.CONNECTED;
        const isDisconnected = current === PusherConnectionState.DISCONNECTED;
        const isPrevNotDisconnected =
          previous !== PusherConnectionState.DISCONNECTED;
        const shouldReconnect =
          isDisconnected && isPrevNotDisconnected && !document.hidden;

        if (shouldReconnect) {
          this.handleReconnection();
        }

        if (isConnected) {
          this.clearReconnectionInterval();
        }
      }
    );
  }

  private clearReconnectionInterval() {
    if (this.reconnectInterval) {
      clearInterval(this.reconnectInterval);
      this.reconnectInterval = null;
    }
  }

  private handleReconnection() {
    let attempts = 0;
    this.reconnectInterval = setInterval(() => {
      const isDisconnected =
        this.pusher?.connection.state === PusherConnectionState.DISCONNECTED;
      const reachedMaxAttempts = attempts >= this.maxReconnects;
      const canReconnect = isDisconnected && !reachedMaxAttempts && this.pusher;

      if (canReconnect) {
        console.log(
          `[Pusher] reconnecting ${attempts + 1}/${this.maxReconnects}`
        );
        attempts += 1;
        this.pusher!.connect();
      } else {
        this.clearReconnectionInterval();
      }
    }, 5000);
  }

  registerToEvent<T>(eventName: string, cb: (data: T & RealTimeData) => void) {
    if (!this.channel) {
      return;
    }
    this.channel.bind(eventName, (data: T & RealTimeData) => {
      cb({ ...data, realTime: true });
    });
  }

  disconnect() {
    if (!this.pusher) {
      return;
    }
    this.pusher.disconnect();
    this.pusher = null;
  }
}
