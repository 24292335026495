import { getEnv } from '../../../helpers/mobx-easy-wrapper';

export class PositionStore {
  async getClosedPositions(accountId: string) {
    try {
      const {
        apiFactory: { positionService },
      } = getEnv();

      return positionService.getClosedPositions(accountId);
    } catch (e) {
      console.error(`Failed getting closed positions`, e);

      return {
        results: [],
        total: 0,
      };
    }
  }

  async getOpenPositions(accountId: string) {
    try {
      const {
        apiFactory: { positionService },
      } = getEnv();

      return positionService.getOpenPositions(accountId);
    } catch (e) {
      console.error(`Failed getting open positions`, e);

      return {
        results: [],
        total: 0,
      };
    }
  }

  async getValidPositionsCount(accountId: string) {
    const {
      apiFactory: { positionService },
    } = getEnv();

    return positionService.getValidPositionsCount(accountId);
  }

  async getTrades(accountId: string, positionId: string) {
    try {
      const {
        apiFactory: { positionService },
      } = getEnv();

      return positionService.getTrades(accountId, positionId);
    } catch (e) {
      console.error(`Failed getting trades`, e);

      return {
        results: [],
        total: 0,
      };
    }
  }
}
