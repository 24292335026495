import { FunctionComponent, useEffect, useState } from 'react';
import { App } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Application, OpenPosition, TimeZone } from '@monorepo/types';
import dayjs from 'dayjs';
import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import TradesTable from '../trades-table/trades-table';
import { PositionState } from '@monorepo/sterling-types';
import {
  Card,
  Flex,
  Label,
  Statistic,
  Table,
  TextComponent,
} from '@monorepo/react-components';
import { PositionSideTag } from './positions.util';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import './trades-positions.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Props {
  application?: Application;
  accountId: string;
  hideSwap?: boolean;
  expandTrades?: boolean;
}

const OpenPositionsTable: FunctionComponent<Props> = ({
  accountId,
  application,
  expandTrades = true,
  hideSwap = false,
}) => {
  const {
    dataStore: { positionStore },
  } = useStore();
  const [openPositions, setOpenPositions] = useState<OpenPosition[]>([]);
  const { loadingState, updateLoadingState } = useLoading();
  const { modal } = App.useApp();
  const isThe5ers = application === Application.THE5ERS;

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await positionStore.getOpenPositions(accountId);

        setOpenPositions(result.results);
      } catch (e) {
        console.error(e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const shouldHidePnl = openPositions.every(
    (position) => position.profitAndLoss === 0
  );

  const columns: ColumnsType<OpenPosition> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (_, position) => {
        return (
          <TextComponent level={8} responsive={false} weight={600}>
            {position.id}
          </TextComponent>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, position) => {
        let date = dayjs(position.date).format('MMM D hh:mm A');

        if (isThe5ers) {
          date = dayjs(position.date)
            .tz(TimeZone.Kyiv)
            .format('MMM D HH:mm:ss');
        }

        return <span>{date}</span>;
      },
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      render: (_, position) => {
        return <Label color="colorInfo">{position.symbol}</Label>;
      },
    },
    {
      title: 'Side',
      dataIndex: 'side',
      render: (_, position) => {
        return <PositionSideTag side={position.side} />;
      },
    },
    {
      title: 'Entry',
      dataIndex: 'entry',
      render: (_, position) => {
        return (
          <TextComponent textAlign="center">
            <Statistic value={position.entry} precision={isThe5ers ? 4 : 2} />
          </TextComponent>
        );
      },
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      render: (_, position) => {
        return (
          <Statistic value={position.fee || 0} precision={2} prefix={'$'} />
        );
      },
    },
    !hideSwap
      ? {
          title: 'Swap',
          dataIndex: 'swap',
          render: (_, position) => {
            return (
              <Statistic
                value={position.swap || 0}
                precision={2}
                prefix={'$'}
              />
            );
          },
        }
      : {},
    shouldHidePnl
      ? {}
      : {
          title: 'P&L',
          dataIndex: 'profitAndLoss',
          render: (_, position) => {
            return (
              <Statistic
                value={position.profitAndLoss || 0}
                precision={2}
                prefix={'$'}
              />
            );
          },
        },
  ];

  const isLoading = loadingState === LoadingState.Loading;
  return (
    <Card className="trades-positions" hoverable={false}>
      <Flex vertical gap={16}>
        <TextComponent responsive={false} level={4}>
          Open Trades
        </TextComponent>
        <Table
          size={'middle'}
          onRow={(record: OpenPosition) => {
            return {
              onClick: () => {
                if (!expandTrades) {
                  return;
                }

                modal.info({
                  width: '80%',
                  closable: true,
                  maskClosable: true,
                  title: `Position Details for: ${record.symbol}`,
                  content: (
                    <TradesTable
                      positionState={PositionState.Open}
                      accountId={accountId}
                      positionId={record.id}
                    />
                  ),
                  okText: 'Close',
                });
              },
            };
          }}
          loading={isLoading}
          columns={columns.filter((col) => col.title)}
          dataSource={openPositions}
          rowKey="id"
          rowHoverable={expandTrades}
        />
      </Flex>
    </Card>
  );
};

export default OpenPositionsTable;
