import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import LoggedIn from './routers/logged-in/logged-in';
import LoggedOut from './routers/logged-out/logged-out';
import { useStore } from './helpers/use-store';
import { App as AntdApp, ConfigProvider, Spin } from 'antd';
import { Helmet } from 'react-helmet';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from './stores/data/user/user-store';
import { AnalyticAuthType, AnalyticsEventTypes } from '@monorepo/types';
import gtmService from './services/analytics/analytics';

function App() {
  const [isLoading, setLoading] = useState(true);
  const {
    uiStore,
    dataStore: { userStore, applicationConfigStore },
  } = useStore();

  const isAuthenticated = userStore.isLoggedIn;
  useEffect(() => {
    const init = async () => {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      const idTokenParam = params.get('id_token');
      const refreshTokenParam = params.get('refresh_token');

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_1, urlHash] = window.location.hash.split('?');
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_2, adminToken] = (urlHash || '').split('=');

      const token = idTokenParam || localStorage.getItem(TOKEN_KEY);
      const refreshToken =
        refreshTokenParam || localStorage.getItem(REFRESH_TOKEN_KEY);

      if (adminToken) {
        userStore.toggleImpersonation();
      }

      if (token && refreshToken) {
        userStore.saveToken(token, refreshToken, adminToken);
        await userStore
          .login()
          .catch((e) => console.info(`Couldn't refresh token`, e));

        if (idTokenParam && refreshTokenParam) {
          gtmService.sendEvent({
            event: AnalyticsEventTypes.LoginSuccess,
            params: {
              user_id: userStore.currentUser.userId,
              method: AnalyticAuthType.Google,
            },
          });

          params.delete('id_token');
          params.delete('refresh_token');
          params.delete('new_user');
          window.history.pushState('', '', url);
        }
      }
      setLoading(false);
    };
    init();
  }, []);

  const getTitle = () => {
    return applicationConfigStore.applicationConfig.name
      ? `${applicationConfigStore.applicationConfig.name}-HUB`
      : 'HUB';
  };

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        ...uiStore.themeConfigurations[uiStore.currentTheme],
      }}
    >
      <AntdApp>
        <div className="App">
          <Helmet defaultTitle="HUB">
            <title>{getTitle()}</title>
          </Helmet>
          {isLoading && <Spin size="large" className="loading-spinner" />}
          {!isLoading && isAuthenticated && <LoggedIn />}
          {!isLoading && !isAuthenticated && <LoggedOut />}
        </div>
      </AntdApp>
    </ConfigProvider>
  );
}

export default observer(App);
