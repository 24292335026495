import React, { FunctionComponent } from 'react';
import { Flex, Space, Tooltip } from 'antd';
import { PagePath } from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import VerificationRequiredButton from '../../components/verification-required-button/verification-required-button';

const AddOns: FunctionComponent<{ accountId: string }> = ({ accountId }) => {
  const {
    dataStore: { addOnStore },
  } = useStore();
  const navigate = useNavigate();
  return (
    <Flex
      style={{
        justifyContent: 'flex-end',
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
    >
      <Space direction="horizontal" size="middle">
        {addOnStore.items.map((item) => (
          <VerificationRequiredButton
            key={item._id}
            onClick={() => navigate(`${PagePath.NewAddon}/${accountId}/`)}
            type={'primary'}
          >
            <Tooltip title={item.description}>{item.name}</Tooltip>
          </VerificationRequiredButton>
        ))}
      </Space>
    </Flex>
  );
};

export default observer(AddOns);
