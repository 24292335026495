import UiStore from './ui/ui-store';
import DataStore from './data/data-store';

// eslint-disable-next-line
interface IRootStore {
  uiStore: UiStore;
  dataStore: DataStore;
}
// eslint-disable-next-line
class RootStore implements IRootStore {
  uiStore: UiStore = {} as UiStore;
  dataStore: DataStore = {} as DataStore;

  // Used by mobx-easy
  init() {
    this.uiStore = new UiStore();
    this.dataStore = new DataStore();
  }
}

export default RootStore;
