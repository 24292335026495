import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Order } from '@monorepo/types';

interface Context {
  form: UseFormReturn<Order>;
  isCurrentStepValid: boolean;
  onActionClick: () => void;
  isLoading?: boolean;
  currencies: Record<string, number> | null;
  setCurrencies: React.Dispatch<
    React.SetStateAction<Record<string, number> | null>
  >;
}
const NewOrderProviderContext = createContext<Context>({} as Context);

export const useNewOrderProvider = () => useContext(NewOrderProviderContext);

type Props = PropsWithChildren & Omit<Context, 'setCurrencies' | 'currencies'>;

const NewOrderProvider: FunctionComponent<Props> = ({ children, ...props }) => {
  const [currencies, setCurrencies] = useState<Record<string, number> | null>(
    null
  );
  return (
    <NewOrderProviderContext.Provider
      value={{ ...props, currencies, setCurrencies }}
    >
      {children}
    </NewOrderProviderContext.Provider>
  );
};
export default NewOrderProvider;
