import { FunctionComponent, ReactNode } from 'react';
import {
  TextComponent,
  Col,
  Row,
  Flex,
  Statistic,
  TextComponentProps,
} from '@monorepo/react-components';
import { useResponsiveness } from '@monorepo/client-common';
import DashboardTooltip from '../dashboard-tooltip/dashboard-tooltip';
import { useColor } from '../../../../hooks';
import { ThemeColors } from '@monorepo/types';

import './advanced-statistics.scss';

export type AdvancedStatisticsType = {
  title: string;
  tooltipText?: ReactNode;
  value?: number | string;
  valueColor?: ThemeColors;
  valuePrefix?: string;
  valueSuffix?: string;
  valuePrecision?: number;
  valueWeight?: TextComponentProps['weight'];
};

interface Props {
  leftColumn?: AdvancedStatisticsType[];
  rightColumn?: AdvancedStatisticsType[];
  isLoading?: boolean;
}

const AdvancedStatistics: FunctionComponent<Props> = ({
  leftColumn = [],
  rightColumn = [],
  isLoading,
}) => {
  const { isDesktop } = useResponsiveness();
  const { getColor } = useColor();

  return (
    <Flex vertical className="advanced-statistics" gap={isDesktop ? 25 : 18}>
      <TextComponent weight={600} responsive={false} level={5}>
        Advanced Statistics
      </TextComponent>

      <Row gutter={[14, 16]}>
        {[leftColumn, rightColumn].map((column, colIndex) => (
          <Col key={colIndex} xs={24} xxl={12}>
            <Flex vertical gap={20}>
              {column.map(
                (
                  {
                    title,
                    value,
                    tooltipText,
                    valueColor,
                    valueSuffix,
                    valuePrefix,
                    valuePrecision,
                    valueWeight = 600,
                  },
                  statIndex
                ) => (
                  <Flex
                    key={statIndex}
                    align="center"
                    gap={5}
                    wrap="wrap"
                    justify="space-between"
                  >
                    <Flex align="center" gap={4}>
                      <TextComponent
                        noWrap
                        responsive={false}
                        level={8}
                        className="advanced-statistics__title"
                      >
                        {title}
                      </TextComponent>
                      {tooltipText && (
                        <DashboardTooltip
                          absolute={false}
                          tooltipText={tooltipText}
                          tooltipLevel={9}
                        />
                      )}
                    </Flex>
                    <TextComponent
                      noWrap
                      color={valueColor ? getColor(valueColor) : undefined}
                      weight={valueWeight}
                      loading={isLoading}
                      textAlign="left"
                      responsive={false}
                      level={8}
                    >
                      <Statistic
                        prefix={valuePrefix}
                        suffix={valueSuffix}
                        value={value}
                        precision={valuePrecision || 0}
                      />
                    </TextComponent>
                  </Flex>
                )
              )}
            </Flex>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};
export default AdvancedStatistics;
