import { ThemeConfigurationType } from '@monorepo/types';
import {
  commonComponentDarkTheme,
  commonComponentTheme,
  commonCssVars,
  commonDarkCssEnv,
} from './common.theme-settings';
import { theme } from 'antd';

export const DarkThemeConfigTtp: ThemeConfigurationType = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: '#FF569D',
    colorSuccess: '#2CD6E1',
    colorWarning: '#E9C10F',
    colorError: '#FF569D',
    colorInfo: '#719FF0',
    colorLink: '#256BE4',
  },
  components: { ...commonComponentTheme, ...commonComponentDarkTheme },
  cssVars: {
    ...commonCssVars,
    ...commonDarkCssEnv,

    colorPrimary: '#FF569D',
    colorSuccess: '#2CD6E1',
    colorWarning: '#E9C10F',
    colorError: '#FF569D',
    colorInfo: '#719FF0',
    colorLink: '#256BE4',
    colorAttention: '#2CD6E1',
    colorActive: '#EB0064',
    colorReverse: '#ffffff',
    colorErrorLight: 'rgb(255, 208, 228)',
    colorSecondary: '#FF4D9E',
  },
};
