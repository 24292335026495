import { Order, PaymentProviderName } from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { EntityStore } from '../base/entity-store';
import OrderService from '../../../services/entities/order-service';

export default class OrderStore extends EntityStore<OrderService, Order> {
  constructor() {
    const {
      apiFactory: { orderService },
    } = getEnv();
    super(orderService);
  }

  async orderPayment(orderId: string, paymentProvider: PaymentProviderName) {
    const page = await this.service.orderPayment(orderId, paymentProvider);
    if (!page) {
      throw Error('Payment page failed');
    }
    return page;
  }

  async upsertByUniq(order: Order) {
    try {
      const newOrder = await this.service.upsertOrderByUniq(order);

      return newOrder;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getCurrencies() {
    try {
      return await this.service.getCurrencies();
    } catch (e) {
      console.log(e);
    }
  }

  async getPaymentProviders(currency?: string) {
    try {
      return await this.service.getPaymentProviders(currency);
    } catch (e) {
      console.log(e);
    }
  }

  async getOrder(uniqId: string) {
    try {
      return await this.service.getOrder(uniqId);
    } catch (e) {
      console.log(e);
    }
  }

  async getInvoice(orderId: string) {
    try {
      return await this.service.getInvoice(orderId);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async removeCoupon(uniqId: string) {
    try {
      return await this.service.removeCoupon(uniqId);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
