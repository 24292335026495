import { ConsistencyRuleResult, ConsistencyRules } from '@monorepo/types';
import { Button, Col, Flex } from 'antd';
import {
  CheckOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useStore } from '../../../../helpers/use-store';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Card } from '@monorepo/react-components';
import ProfitableDaysModal from './profitable-days-modal/profitable-days-modal';

import './consistency-rule.scss';

const RulesToTitle = {
  [ConsistencyRules.BestTradeProfitLimit]: () => `Best Trade Profit Limit`,
  [ConsistencyRules.ProfitableDays]: () => `Profitable Days`,
  [ConsistencyRules.MinimumTrades]: () => `Minimum Trades`,
};

const RulesToResult = {
  [ConsistencyRules.BestTradeProfitLimit]: (value: number) =>
    `${Math.round(value)}%`,
  [ConsistencyRules.ProfitableDays]: (value: number) => value,
  [ConsistencyRules.MinimumTrades]: (value: number) => value,
};

const RulesToText = {
  [ConsistencyRules.BestTradeProfitLimit]: (value: number) =>
    `Your best trade can not exceed ${value}% of the total profit`,
  [ConsistencyRules.ProfitableDays]: (value: number) =>
    `Should have at least ${value} profitable days`,
  [ConsistencyRules.MinimumTrades]: (value: number) =>
    `Should have at least ${value} trades`,
};

const RuleHasExtraInfo = [ConsistencyRules.ProfitableDays];

interface Props {
  rule: ConsistencyRules;
  ruleResult: ConsistencyRuleResult;
  programId: string;
  accountId: string;
}

const ConsistencyRule: FunctionComponent<Props> = ({
  rule,
  programId,
  accountId,
  ruleResult,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const program = programStore.get(programId);

  const programRule = program?.config.consistencyRules?.find(
    (configRule) => configRule.rule === rule
  );

  if (!programRule) {
    return null;
  }

  const getModalByRule = () => {
    const modalsMap: { [key: string]: ReactNode } = {
      [ConsistencyRules.ProfitableDays]: (
        <ProfitableDaysModal
          accountId={accountId}
          close={() => setModalOpen(false)}
          open={modalOpen}
        />
      ),
    };

    const modal = modalsMap[rule as ConsistencyRules];

    return modal || null;
  };

  const ruleTextFn = RulesToText[rule as ConsistencyRules];
  const ruleText = ruleTextFn(programRule?.value);
  const ruleTitle = RulesToTitle[rule as ConsistencyRules]();
  const ruleValue = RulesToResult[rule as ConsistencyRules](ruleResult.current);
  const hasModal = RuleHasExtraInfo.includes(rule);

  const title = (
    <Flex justify="space-between">
      <span>
        {ruleTitle}
        {hasModal ? (
          <Button shape="circle" onClick={() => setModalOpen(true)} type="text">
            <InfoCircleOutlined />
          </Button>
        ) : null}
      </span>
      <span>{ruleValue}</span>
    </Flex>
  );

  return (
    <>
      <Col className="consistency-rule" span={24} key={rule}>
        <Card
          bordered={false}
          title={title}
          bodyStyle={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>
            {ruleResult.isValid ? (
              <CheckOutlined style={{ color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ color: 'red' }} />
            )}
          </span>
          <span style={{ fontSize: '12px', maxWidth: '200px' }}>
            {ruleText}
          </span>
        </Card>
      </Col>
      {getModalByRule()}
    </>
  );
};

export default ConsistencyRule;
