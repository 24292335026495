import { useStore } from '../../../../helpers/use-store';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Button, Card, TextComponent, Flex } from '@monorepo/react-components';
import { useResponsiveness } from '@monorepo/client-common';
import { Icons, IconsNames } from '@monorepo/icons';
import { Order, ProductType, Program } from '@monorepo/types';
import ProgramGroupIcon from './program-group-icon';
import { useNewOrderProvider } from '../../components/new-order-provider/new-order-provider';
import { orderBy } from 'lodash';
import { Ribbon } from '../../../../components/ribbon/ribbon';

import './program-groups.scss';

const ProgramGroups: FunctionComponent<{
  defaultValues: Partial<Order>;
}> = ({ defaultValues }) => {
  const { form, onActionClick: onGroupSelect } = useNewOrderProvider();
  const {
    dataStore: { groupStore },
    uiStore: { productStore },
  } = useStore();
  const { isDesktop, isMobile } = useResponsiveness();
  const currentGroupName = form.watch('groupName');

  const handleGroupClick = (groupName?: string) => {
    if (form.getValues('groupName')) {
      form.reset(defaultValues);
    }

    if (groupName) {
      form.setValue('groupName', groupName);
    }

    onGroupSelect();
  };

  return (
    <Flex gap={20} vertical className="program-groups">
      {orderBy(groupStore.items, ['displayOrder']).map((group) => {
        const currentGroupPrograms = productStore.getAvailableProducts(
          ProductType.Program,
          group.name
        );
        const hasSellablePrograms = currentGroupPrograms.some(
          (program) => (program as Program).sellable
        );

        if (!hasSellablePrograms) {
          return null;
        }
        const isAdvised = group.advised;
        const isSelectedGroupName = currentGroupName === group.name;
        const isActiveCard =
          isSelectedGroupName || (!currentGroupName && isAdvised);

        return (
          <Ribbon key={group._id} ribbon={group.ribbon}>
            <Card
              active={isActiveCard}
              key={group._id}
              onClick={() => handleGroupClick(group.name)}
            >
              <div
                className={classNames('program-groups__card', {
                  'program-groups__card--mobile': !isDesktop,
                })}
              >
                <div
                  className={classNames(
                    'program-groups__card__content',
                    !isDesktop && 'program-groups__card__content--mobile'
                  )}
                >
                  {isDesktop && (
                    <ProgramGroupIcon
                      isActive={isAdvised}
                      icon={group.icon as IconsNames}
                    />
                  )}
                  <Flex vertical>
                    <TextComponent weight={700} level={3}>
                      {group.name}
                    </TextComponent>
                    <TextComponent
                      className="program-groups__card__subtitle"
                      level={6}
                    >
                      {group.subtitle}
                    </TextComponent>
                  </Flex>

                  <TextComponent level={6}>{group.description}</TextComponent>
                </div>

                <Flex
                  gap={9}
                  vertical={!isDesktop}
                  align="center"
                  style={{ height: '100%' }}
                >
                  {!isDesktop && (
                    <ProgramGroupIcon
                      icon={group.icon as IconsNames}
                      isActive={isAdvised}
                    />
                  )}
                  <Button
                    ghost={!isActiveCard}
                    type={'primary'}
                    size={isMobile ? 'middle' : 'large'}
                    className="program-groups__card__button"
                  >
                    {isDesktop && (
                      <Icons
                        className="program-groups__card__button-icon"
                        iconName={IconsNames.Check}
                      />
                    )}
                    <TextComponent level={5} weight={600}>
                      {!isDesktop ? 'Start now' : 'Start Process'}
                    </TextComponent>
                  </Button>
                </Flex>
              </div>
            </Card>
          </Ribbon>
        );
      })}
    </Flex>
  );
};

export default ProgramGroups;
