import { OrderStatus, ThemeColors } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { Label } from '@monorepo/react-components';

interface Props {
  status: OrderStatus;
}

const OrderStatusTag: FunctionComponent<Props> = ({
  status = OrderStatus.Pending,
}) => {
  const orderStatusToColor: Record<OrderStatus, ThemeColors> = {
    [OrderStatus.Paid]: ThemeColors.colorSuccess,
    [OrderStatus.Pending]: ThemeColors.colorWarning,
    [OrderStatus.Declined]: ThemeColors.colorError,
    [OrderStatus.Processing]: ThemeColors.colorInfo,
    [OrderStatus.Refunded]: ThemeColors.colorAttention,
  };

  return (
    <Label
      color={orderStatusToColor[status]}
      style={{ textTransform: 'capitalize' }}
    >
      {status}
    </Label>
  );
};

export default OrderStatusTag;
