import { AccountStatus, ThemeColors } from '@monorepo/types';
import { Label, TextComponent } from '@monorepo/react-components';
import { FunctionComponent } from 'react';

interface Props {
  accountStatus: AccountStatus;
}

const tagColors = {
  [AccountStatus.Active]: ThemeColors.colorLink,
  [AccountStatus.Disabled]: ThemeColors.colorActive,
  [AccountStatus.PendingCreation]: ThemeColors.colorInfo,
};

const DashboardAccountStatusTag: FunctionComponent<Props> = ({
  accountStatus,
}) => {
  return (
    <Label color={tagColors[accountStatus]}>
      <TextComponent capitalize level={9}>
        {accountStatus}
      </TextComponent>
    </Label>
  );
};
export default DashboardAccountStatusTag;
