import React, { FunctionComponent } from 'react';
import Logo from '../../../../components/logo/logo';
import { Flex, TextComponent } from '@monorepo/react-components';

import './invoice-header.scss';

interface Props {
  uniqId: string;
}

const InvoiceHeader: FunctionComponent<Props> = ({ uniqId }) => {
  return (
    <Flex
      justify={'space-between'}
      className="header-container invoice-section"
    >
      <Logo />
      <Flex
        className="details-container"
        vertical
        align={'flex-end'}
        justify={'space-between'}
      >
        <TextComponent level={7}>Invoice</TextComponent>
        <TextComponent weight={600} level={6}>
          #{uniqId}
        </TextComponent>
      </Flex>
    </Flex>
  );
};

export default InvoiceHeader;
