import React, { useEffect, useState } from 'react';
import './affiliate.scss';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { App, Button, Result } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import {
  LoadingState,
  useLoading,
  useThemeToken,
} from '@monorepo/client-common';
import AffiliationDashboard from '../../components/affiliation-dashboard/affiliation-dashboard';
import { Helmet } from 'react-helmet';
import { AnalyticsEventTypes, ButtonText, ContractType } from '@monorepo/types';
import gtmService from '../../services/analytics/analytics';
import ContractModal from '../../components/contract-modal/contract-modal';

const Affiliation = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const { modal } = App.useApp();
  const [showDashboard, setShowDashBoard] = useState(false);
  const [register, setRegister] = useState(false);
  const isLoading = loadingState === LoadingState.Loading;

  const { token } = useThemeToken();

  useEffect(() => {
    if (userStore.currentUser.affiliateId) {
      setShowDashBoard(true);
    }
  }, []);

  const onRegister = async () => {
    updateLoadingState(LoadingState.Loading);
    const result = await userStore.registerAsAffiliate();
    handleRegistrationResult(result);
    updateLoadingState(LoadingState.Loaded);
  };

  const handleRegistrationResult = (result: boolean) => {
    if (result) {
      modal.success({
        icon: null,
        width: '550px',
        content: (
          <Result
            status="success"
            title="Successfully Registered as affiliate!"
            subTitle="You can start sharing and earning"
          />
        ),
        okText: 'Go to Dashboard',
        onOk: () => {
          setShowDashBoard(true);
        },
      });

      gtmService.sendEvent({
        event: AnalyticsEventTypes.RegisterAsAffiliate,
        params: {
          button_text: ButtonText.Register,
        },
      });
    } else {
      modal.error({
        icon: null,
        width: '550px',
        content: (
          <Result
            status="error"
            title="Registration Failed"
            subTitle="Something went wrong, please contact support."
          />
        ),
        cancelText: 'Close',
      });
    }
  };

  return (
    <div className="affiliate">
      <Helmet>
        <title>Affiliation</title>
      </Helmet>
      {showDashboard ? (
        <AffiliationDashboard />
      ) : (
        <Result
          icon={
            <UsergroupAddOutlined
              style={{ color: token.colorPrimary }}
              className={'affiliation-icon'}
            />
          }
          title="Become an affiliate now and start earning!"
          extra={
            <>
              {register && (
                <ContractModal
                  contractType={ContractType.Affiliation}
                  onContractHandled={onRegister}
                />
              )}
              <Button
                type="primary"
                onClick={() => setRegister(true)}
                loading={isLoading}
              >
                Register
              </Button>
            </>
          }
        />
      )}
    </div>
  );
};

export default observer(Affiliation);
