import { computed, makeObservable, observable, runInAction } from 'mobx';
import { PaginationRequest } from '@monorepo/types';
import { CrudService } from '../http';
import { AxiosError } from 'axios';

export class EntityStore<
  Service extends CrudService,
  Entity extends { _id: string }
> {
  @observable
  _items: Map<string, Entity> = new Map();

  protected readonly service: Service;
  protected readonly normalizeFunc: (item: Entity) => Entity;

  isAllFetched: boolean = false;

  constructor(service: Service, normalizeFunc?: (item: Entity) => Entity) {
    this.service = service;

    const defaultFn = (item: Entity) => item;
    this.normalizeFunc = normalizeFunc || defaultFn;

    makeObservable(this);
  }

  async paginate(paginateRequest: PaginationRequest) {
    const response = await this.service.paginate<Entity>(paginateRequest);

    runInAction(() => {
      response.results.forEach((result) =>
        this._items.set(result._id, this.normalizeFunc(result))
      );
    });

    return response;
  }

  async fetch(id: string) {
    const item = await this.service.get<Entity>(id);

    runInAction(() => {
      this._items.set(item._id, this.normalizeFunc(item));
    });

    return this.normalizeFunc(item);
  }

  get(id: string) {
    return this._items.get(id);
  }

  async create(item: Partial<Entity>) {
    try {
      const newItem = await this.service.create<Entity>(item);

      runInAction(() => {
        this._items.set(newItem._id, this.normalizeFunc(newItem));
      });

      return this.normalizeFunc(newItem);
    } catch (e: unknown) {
      console.error(`Failed creating item`, item);
      if (e instanceof AxiosError) {
        throw new Error(e?.response?.data.message);
      }

      throw e;
    }
  }

  async delete(id: string) {
    try {
      const result = await this.service.delete(id);

      runInAction(() => {
        this._items.delete(id);
      });

      return result;
    } catch (e) {
      console.error(`Failed delete for id: ${id}`, e);
      if (e instanceof AxiosError) {
        throw new Error(e?.response?.data.message);
      }

      throw e;
    }
  }

  async update(id: string, item: Partial<Entity>) {
    try {
      const newItem = await this.service.update(id, item);

      runInAction(() => {
        this._items.set(id, this.normalizeFunc(newItem as Entity));
      });
    } catch (e) {
      console.error(`Failed updating id: ${id}`, item, e);
      if (e instanceof AxiosError) {
        throw new Error(e?.response?.data.message);
      }

      throw e;
    }
  }

  async fetchAll() {
    const items = await this.service.getAll<Entity>();

    runInAction(() => {
      items.forEach((item) => {
        this._items.set(item._id, this.normalizeFunc(item));
      });

      this.isAllFetched = true;
    });

    return items.map((item) => this.normalizeFunc(item));
  }

  @computed
  get items() {
    return Array.from(this._items.values());
  }
}
