import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { App, Descriptions, Result, Skeleton } from 'antd';
import useExportDocuments from '../../../hooks/use-export-documents';
import dayjs from 'dayjs';
import { Invoice, PagePath, ThemeColors } from '@monorepo/types';
import InvoiceHeader from './invoice-header/invoice-header';
import InvoiceClientDetails from './invoice-client-details/invoice-client-details';
import InvoiceCompanyDetails from './invoice-company-details/invoice-company-details';
import InvoiceTable from './invoice-table/invoice-table';
import InvoiceConclusion from './invoice-conclusion/invoice-conclustion';
import {
  Button,
  Divider,
  Flex,
  TextComponent,
} from '@monorepo/react-components';
import { ThemeWrapper } from '../../../components/theme-wrapper/theme-wrapper';

import './preview-invoice.scss';

const formatDate = (invoiceDate: Date) => {
  let dateToCalculate = dayjs(invoiceDate);
  try {
    if (dateToCalculate.year() === 1970) {
      // fix date in unix time
      const fixedDate = dayjs(dateToCalculate.valueOf() * 1000);
      if (fixedDate.isBefore(dayjs())) {
        dateToCalculate = fixedDate;
      }
    }
  } catch (e) {
    console.error('error parsing data', e);
  }
  return dateToCalculate.format('YYYY/MM/DD HH:mm');
};

const PreviewInvoice = () => {
  const {
    dataStore: { orderStore },
  } = useStore();
  const { id } = useParams<{
    id: string;
  }>();
  const { loadingState, updateLoadingState } = useLoading();
  const [invoice, setInvoice] = useState<Invoice>();
  const { componentRef, handleDownloadPdf, handlePrint, inProgress } =
    useExportDocuments();
  const { message } = App.useApp();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const invoiceData = await orderStore.getInvoice(id as string);

        if (invoiceData) {
          setInvoice(invoiceData);
          updateLoadingState(LoadingState.Loaded);
        } else {
          updateLoadingState(LoadingState.Error);
        }
      } catch (e) {
        message.error('Something went wrong');
        updateLoadingState(LoadingState.Error);
      }
    };
    init();
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton />;
  }

  if (loadingState === LoadingState.Error || !invoice) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the invoice does not exists"
        extra={
          <Button type="primary" onClick={() => navigate(PagePath.Root)}>
            Back Home
          </Button>
        }
      />
    );
  }

  const descriptionItems = [
    {
      label: (
        <TextComponent
          level={7}
          capitalize={true}
          className="payment-details-label"
        >
          Method
        </TextComponent>
      ),
      children: (
        <TextComponent level={7} capitalize={true}>
          {invoice.provider}
        </TextComponent>
      ),
    },

    {
      label: (
        <TextComponent
          level={7}
          capitalize={true}
          className="payment-details-label"
        >
          Date Paid
        </TextComponent>
      ),
      children: (
        <TextComponent level={7} capitalize={true}>
          {formatDate(invoice.updatedAt)}
        </TextComponent>
      ),
    },
  ];

  if (invoice.transactionId) {
    descriptionItems.push({
      label: (
        <TextComponent
          level={7}
          capitalize={true}
          className="payment-details-label"
        >
          Transaction ID
        </TextComponent>
      ),
      children: (
        <TextComponent level={7} capitalize={true}>
          {invoice.transactionId}
        </TextComponent>
      ),
    });
  }

  return (
    <Flex vertical gap={50} className="invoice-page">
      <div ref={componentRef} className="preview-invoice">
        <InvoiceHeader uniqId={invoice.uniqId} />
        <InvoiceCompanyDetails
          paymentDate={invoice.updatedAt}
          name={invoice.companyDetails.companyName}
          city={invoice.companyDetails.city}
          address={invoice.companyDetails.address}
          country={invoice.companyDetails.country}
          title={invoice.companyDetails.companyTitle}
          street={invoice.companyDetails.street}
        />
        <Divider className="invoice-divider" />
        <InvoiceClientDetails
          firstName={invoice.firstName}
          lastName={invoice.lastName}
          address={invoice.address}
          phoneNumber={invoice.phoneNumber}
          email={invoice.email}
        />
        <InvoiceTable invoice={invoice} />
        <InvoiceConclusion
          amountInCurrency={invoice.amountInCurrency}
          currency={invoice.currency}
        />
        <Divider className="invoice-divider" />
        <Descriptions
          className="invoice-section"
          title={
            <TextComponent weight={600} level={7}>
              Payment Details
            </TextComponent>
          }
          items={descriptionItems}
          size={'small'}
          labelStyle={{ minWidth: '100px' }}
          column={1}
        />
      </div>

      <Flex gap={10}>
        <ThemeWrapper themeColor={ThemeColors.colorAttention}>
          <Button
            disabled={inProgress}
            block={true}
            type="primary"
            onClick={() => handleDownloadPdf(invoice.uniqId)}
          >
            Download
          </Button>
        </ThemeWrapper>
        <Button disabled={inProgress} block={true} onClick={handlePrint}>
          Print
        </Button>
      </Flex>
    </Flex>
  );
};

export default PreviewInvoice;
