import { ColumnsType } from 'antd/lib/table';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Order, OrderStatus, PagePath, ThemeColors } from '@monorepo/types';
import {
  LoadingState,
  useLoading,
  usePagination,
} from '@monorepo/client-common';
import { useStore } from '../../helpers/use-store';
import dayjs from 'dayjs';
import OrderStatusTag from '../../components/order-status-tag/order-status-tag';
import {
  Button,
  Flex,
  Statistic,
  Table,
  TextComponent,
  PaginationProps,
  Label,
} from '@monorepo/react-components';
import VerificationRequiredButton from '../../components/verification-required-button/verification-required-button';
import Coupons from './coupons/coupons';
import { CloseCircleOutlined } from '@ant-design/icons';
import CouponModal from '../coupon-modal/coupon-modal';
import PageContainer from '../page-container/page-container';
import { Icons, IconsNames } from '@monorepo/icons';
import { ThemeWrapper } from '../../components/theme-wrapper/theme-wrapper';

const Orders = () => {
  const [currentOrders, setOrders] = useState<Order[]>([]);
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { orderStore, userStore },
  } = useStore();
  const { page, limit, total, setTotal, setLimit, setPage } = usePagination();

  const navigate = useNavigate();

  useEffect(() => {
    requestPage();
  }, []);

  useEffect(() => {
    if (loadingState !== LoadingState.Loaded) {
      return;
    }

    requestPage();
  }, [page, limit]);

  const requestPage = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      const response = await orderStore.paginate({
        page,
        limit,
      });
      updateLoadingState(LoadingState.Loaded);
      setOrders(response.results);
      setTotal(response.total);
    } catch (e) {
      console.error(
        `Failed loading orders for page: ${page}, limit: ${limit}`,
        e
      );
      updateLoadingState(LoadingState.Error);
    }
  };

  const handleRemoveCoupon = async (uniqId: string) => {
    await orderStore.removeCoupon(uniqId);
    requestPage();
  };

  const columns: ColumnsType<Order> = [
    {
      title: 'Order ID',
      dataIndex: 'uniqId',
      width: '5%',
      render: (_, item) => {
        return (
          <TextComponent responsive={false} level={8} weight={600}>
            {item.uniqId}
          </TextComponent>
        );
      },
    },
    {
      width: '10%',

      title: 'Status',
      dataIndex: 'status',
      render: (_, item: Order) => {
        return <OrderStatusTag status={item.status} />;
      },
    },
    {
      width: '10%',

      title: 'Customer name',
      dataIndex: 'customer',
      render: () => {
        return (
          <TextComponent responsive={false} level={8}>
            {userStore.fullName}
          </TextComponent>
        );
      },
    },
    {
      width: '10%',

      title: 'Email',
      dataIndex: 'customer',
      render: () => {
        return (
          <TextComponent responsive={false} level={8}>
            {userStore.currentUser.email}
          </TextComponent>
        );
      },
    },

    {
      width: '10%',

      title: 'Total',
      dataIndex: 'total',
      render: (_, item: Order) => {
        return (
          <TextComponent responsive={false} level={8}>
            <Statistic value={item.amount} prefix={'$'} precision={2} />
          </TextComponent>
        );
      },
    },
    {
      width: '10%',

      title: 'Issue Date',
      dataIndex: 'createdAt',
      render: (_, item) => {
        return <span>{dayjs(item.createdAt).format('DD MMM YYYY')}</span>;
      },
    },
    {
      width: '5%',
      title: 'Coupon',
      dataIndex: 'Coupon',
      render: (_, item: Order) => {
        const isOrderPending = item.status === OrderStatus.Pending;

        if (!item.coupon) {
          return null;
        }

        return isOrderPending ? (
          <Label
            closeIcon={<CloseCircleOutlined />}
            onClose={(e) => {
              e.preventDefault();
              handleRemoveCoupon(item.uniqId);
            }}
          >
            Remove
          </Label>
        ) : (
          <Label color="colorSuccess">Applied</Label>
        );
      },
    },
    {
      width: '8%',
      title: 'Actions',
      render: (_, item) => {
        const isPaid = item.status === OrderStatus.Paid;
        let Actions: React.ReactNode = null;

        if (isPaid) {
          Actions = (
            <ThemeWrapper themeColor={ThemeColors.colorSuccess}>
              <Button
                type="default"
                size="small"
                onClick={() => {
                  navigate(`${PagePath.Orders}/${item.uniqId}`);
                }}
              >
                <Icons iconName={IconsNames.CertificateCheck} />
                View
              </Button>
            </ThemeWrapper>
          );
        } else {
          Actions = (
            <VerificationRequiredButton
              type="default"
              size="small"
              onClick={() => navigate(`${PagePath.NewAccount}/${item.uniqId}`)}
            >
              <Icons iconName={IconsNames.Exchange} />
              Pay
            </VerificationRequiredButton>
          );
        }

        const isDeclined = item.status === OrderStatus.Declined;

        if (isDeclined) {
          const diffInHours = Math.abs(dayjs().diff(item.createdAt, 'hours'));
          const is24HoursPassed = diffInHours > 24;

          if (is24HoursPassed) {
            Actions = null;
          }
        }

        return Actions ? <Flex justify="center">{Actions}</Flex> : null;
      },
    },
  ];

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <PageContainer pageTitle="Orders" helmet="Orders" className="orders">
      <Outlet />
      <CouponModal />
      <Coupons />
      <Table
        rowHoverable={false}
        label="My Orders"
        onChange={(pagination: PaginationProps) => {
          if (pagination.current) {
            setPage(pagination.current);
          }
          if (pagination.pageSize) {
            setLimit(pagination.pageSize);
          }
        }}
        size="small"
        pagination={{
          current: page,
          pageSize: limit,
          total,
          hideOnSinglePage: true,
        }}
        loading={isLoading}
        rowKey={(record: Order) => record.uniqId}
        bordered={false}
        columns={columns}
        dataSource={currentOrders}
      />
    </PageContainer>
  );
};

export default Orders;
