import { useEffect } from 'react';
import { useStore } from '../../helpers/use-store';
import { useNavigate, useLocation } from 'react-router-dom';
import { PagePath } from '@monorepo/types';

const MainRouter = () => {
  const { uiStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // if there was url before login redirect back to it
    if (uiStore.initialUrl && uiStore.initialUrl !== location.pathname) {
      navigate(uiStore.initialUrl);
      uiStore.initialUrl = '';
      localStorage.removeItem('initialUrl');
    } else {
      navigate(PagePath.Overview);
    }
    // eslint-disable-next-line
  }, []);

  return null;
};

export default MainRouter;
