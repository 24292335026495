import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Achievement } from '@monorepo/types';
import { Icons, IconsNames } from '@monorepo/icons';
import { Flex } from '@monorepo/react-components';
import AchievementDownloadButton from './achievement-download-button';

interface Props {
  imageUrl: string;
  achievement?: Achievement;
  totalPayoutAmount?: number;
}

enum SocialNetwork {
  Twitter = 'twitter',
  Whatsapp = 'whatsapp',
  Facebook = 'facebook',
}

const SocialLinks: { [key: string]: string } = {
  [SocialNetwork.Whatsapp]: `https://web.whatsapp.com/send?text=${encodeURIComponent(
    'Check out my achievement'
  )}%20`,
  [SocialNetwork.Twitter]: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    'Check out my achievement'
  )}&url=`,
  [SocialNetwork.Facebook]: `https://www.facebook.com/sharer/sharer.php?u=`,
};

export const AchievementsSocialLinks: FunctionComponent<Props> = ({
  imageUrl,
  achievement,
  totalPayoutAmount,
}) => {
  const {
    dataStore: { achievementStore, userStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();

  const getShareableImageURL = async () => {
    if (achievement) {
      return await achievementStore.createShareableImage(achievement, imageUrl);
    }

    return await achievementStore.createTotalPayoutImage({
      amount: totalPayoutAmount,
      userId: userStore.currentUser.userId,
      imageUrl,
    });
  };

  const handleSocialImageCreation = async (
    e: React.MouseEvent,
    network: SocialNetwork
  ) => {
    e.stopPropagation();

    try {
      updateLoadingState(LoadingState.Loading);
      const shareImgUrl = await getShareableImageURL();
      const windowMessage =
        SocialLinks[network] + encodeURIComponent(shareImgUrl);

      window.open(windowMessage, '_blank', 'width=300,height=291');
    } catch (e) {
      message.error('Something went wrong');
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <Flex gap={16} justify="center">
      {loadingState === LoadingState.Loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />} />
      ) : (
        <>
          <Icons
            iconName={IconsNames.Facebook}
            className="achievement-social-icon"
            onClick={(e) =>
              handleSocialImageCreation(e, SocialNetwork.Facebook)
            }
          />
          <Icons
            iconName={IconsNames.Twitter}
            className="achievement-social-icon"
            onClick={(e) => handleSocialImageCreation(e, SocialNetwork.Twitter)}
          />
          <Icons
            iconName={IconsNames.Whatsapp}
            className="achievement-social-icon"
            onClick={(e) =>
              handleSocialImageCreation(e, SocialNetwork.Whatsapp)
            }
          />
          <AchievementDownloadButton
            type={achievement?.type || 'total-payout'}
            imgUrl={imageUrl}
          />
        </>
      )}
    </Flex>
  );
};
