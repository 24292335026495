import { filterCouponsForProduct } from '@monorepo/types';
import { useStore } from '../../../../helpers/use-store';
import { useSearchParams } from 'react-router-dom';
import { useNewOrderProvider } from '../new-order-provider/new-order-provider';
import { useEffect } from 'react';
import { Flex, FormSelect, TextComponent } from '@monorepo/react-components';

const AvailableCouponsSelect = () => {
  const {
    dataStore: {
      userStore: { coupons },
    },
  } = useStore();

  const { form } = useNewOrderProvider();
  const { productType, productId, tradingSystem } = form.getValues();
  const [searchParams] = useSearchParams();
  const couponId = searchParams.get('couponId') || undefined;

  const availableCoupons = filterCouponsForProduct({
    coupons,
    productType,
    productId,
    tradingSystem,
  });

  const couponsSelectOptions = availableCoupons.map((coupon) => ({
    label: coupon.name,
    value: coupon._id,
  }));

  useEffect(() => {
    if (availableCoupons.find((coupon) => coupon._id === couponId)) {
      form.setValue('coupon', couponId);
    }
  }, []);

  return couponsSelectOptions.length > 0 ? (
    <Flex vertical gap={4} style={{ maxWidth: 400 }}>
      <TextComponent level={8} responsive={false}>
        You have {couponsSelectOptions.length} available coupons for applying
      </TextComponent>
      <FormSelect
        form={form}
        controllerProps={{
          name: 'coupon',
        }}
        allowClear
        placeholder="Select Coupon to Apply"
        defaultValue={undefined}
        options={couponsSelectOptions}
      />
    </Flex>
  ) : null;
};

export default AvailableCouponsSelect;
