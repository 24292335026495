import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import AchievementsBlock from './achievements-block';
import React, { useEffect, useState } from 'react';
import {
  AccountType,
  Achievement,
  AchievementType,
  ProgramAchievement,
} from '@monorepo/types';
import PageContainer from '../page-container/page-container';
import { Flex } from '@monorepo/react-components';
import AchievementsEmpty from './achievements-empty/achievements-empty';

import './achievments.scss';
import AchievementTotalPayout from './achievement-total-payout/achievement-total-payout';

const achievementTypeTitle: { [key: string]: string } = {
  [AchievementType.ProgramAchievement]: 'Funding Certificates',
  [AchievementType.Payout]: 'Payouts',
};

const filterAchievementsByType = (
  achievements: Achievement[],
  type: AchievementType
) => {
  return achievements.filter((achievement) => {
    if (achievement.type !== type) {
      return false;
    }
    return !(
      type === AchievementType.ProgramAchievement &&
      (achievement.metadata as ProgramAchievement).nextAccountType !==
        AccountType.Funded
    );
  });
};

const Achievements = () => {
  const { updateLoadingState, isLoadError, isLoaded } = useLoading();
  const [achievements, updateAchievements] = useState<Achievement[]>([]);
  const {
    dataStore: { achievementStore },
  } = useStore();

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const achievements = await achievementStore.fetchAll();
        updateAchievements(achievements);
      } catch (e) {
        console.error(`Failed fetching achievements`, e);
        updateLoadingState(LoadingState.Error);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };
    init();
  }, []);

  return (
    <PageContainer
      isError={isLoadError}
      helmet="Achievements"
      pageTitle="Achievements"
    >
      <div className={'achievements'}>
        <Flex vertical gap={24}>
          {isLoaded && achievements.length === 0 ? (
            <AchievementsEmpty />
          ) : (
            <>
              <AchievementTotalPayout
                isLoading={!isLoaded}
                payoutAchievements={filterAchievementsByType(
                  achievements,
                  AchievementType.Payout
                )}
              />

              {Object.values(AchievementType).map((type) => (
                <AchievementsBlock
                  key={type}
                  isLoaded={isLoaded}
                  achievements={filterAchievementsByType(achievements, type)}
                  title={achievementTypeTitle[type]}
                />
              ))}
            </>
          )}
        </Flex>
      </div>
    </PageContainer>
  );
};

export default Achievements;
