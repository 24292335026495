import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Select, SelectProps } from 'antd';
import { getStates } from '@monorepo/client-common';

export const FormState: FunctionComponent<
  Omit<FormFieldProps, 'render'> & { country: string }
> = ({ country, ...formFieldProps }) => {
  const fieldProps: SelectProps = {
    placeholder: formFieldProps.placeholder,
    style: { width: '100%' },
    options: getStates(country).map((state) => {
      return {
        label: state.text,
        value: state.value,
      };
    }),
  };

  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return <Select {...fieldProps} {...field} />;
      }}
    />
  );
};
