import { useStore } from '../helpers/use-store';
import {
  FeatureFlagNames,
  isFeatureEnabled as _isFeatureEnabled,
} from '@monorepo/types';

export const useFeatureFlags = () => {
  const {
    dataStore: { userStore, applicationConfigStore },
  } = useStore();

  const appFeatureFlags =
    applicationConfigStore.applicationConfig.featureFlags || {};
  const userFeatureFlags = userStore.currentUser?.featureFlags || {};

  const isFeatureEnabled = (flag: FeatureFlagNames) => {
    return _isFeatureEnabled({
      appFeatureFlags,
      userFeatureFlags,
      flag,
    });
  };

  return {
    isFeatureEnabled,
  };
};
