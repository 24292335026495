import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PagePath } from '@monorepo/types';
import { useState } from 'react';
import PreviewInvoice from '../preview-invoice/preview-invoice';

const PreviewInvoiceModal = () => {
  const [open, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();

  return (
    <Modal
      onCancel={() => setOpen(false)}
      afterClose={() => navigate(PagePath.Orders)}
      closable={true}
      open={open}
      rootClassName="preview-invoice-modal"
      maskClosable={true}
      footer={null}
    >
      <PreviewInvoice />
    </Modal>
  );
};
export default PreviewInvoiceModal;
