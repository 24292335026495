import {
  Card,
  Flex,
  ProgressSlider,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent, ReactNode } from 'react';
import DashboardTooltip from '../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import classNames from 'classnames';
import { useResponsiveness } from '@monorepo/client-common';
import { ThemeColors } from '@monorepo/types';

import './progress-cards.scss';

export interface ProfitableDaysProps {
  currentProfitableDays: number;
  profitableDaysTarget: number;
  tooltipText: string | ReactNode;
  isLoading?: boolean;
  openModal: () => void;
}

const ProfitableDaysProgress: FunctionComponent<ProfitableDaysProps> = ({
  currentProfitableDays,
  profitableDaysTarget,
  tooltipText,
  isLoading,
  openModal,
}) => {
  const { isMobile } = useResponsiveness();

  return (
    <Card
      hoverable={currentProfitableDays > 0}
      onClick={openModal}
      className={classNames(
        'progress-cards',
        'progress-cards__card',
        'progress-cards__card--profitable-inactive'
      )}
    >
      <Flex vertical gap={isMobile ? 3 : 0}>
        <Flex justify={'space-between'}>
          <TextComponent weight={400} level={isMobile ? 5 : 3}>
            Profitable Days
          </TextComponent>

          <DashboardTooltip tooltipText={tooltipText} />
        </Flex>

        <Flex align={'baseline'}>
          <TextComponent
            loading={isLoading}
            level={isMobile ? 3 : 2}
            weight={600}
          >
            {currentProfitableDays}/
          </TextComponent>
          <TextComponent
            loading={isLoading}
            level={isMobile ? 5 : 2}
            weight={400}
          >
            {profitableDaysTarget}
          </TextComponent>
        </Flex>

        <Flex vertical>
          <ProgressSlider
            disabled
            trackColor={ThemeColors.colorSecondary}
            value={currentProfitableDays}
            min={0}
            max={profitableDaysTarget}
          />
        </Flex>
      </Flex>
    </Card>
  );
};
export default ProfitableDaysProgress;
