import { Skeleton, Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { useResponsiveness } from '@monorepo/client-common';

import './text.scss';

export type TextComponentProps = TextProps & {
  opacity?: number;
  capitalize?: boolean;
  children?: ReactNode;
  level?: 10 | 9 | 8 | 7 | 6 | 5 | 4 | 3 | 2 | 1;
  weight?: 400 | 500 | 600 | 700;
  responsive?: boolean;
  noWrap?: boolean;
  loading?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  fontFamily?: 'Mono Sans' | 'Oswald';
};

const LEVELS = [
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
];

export const TextComponent: FunctionComponent<TextComponentProps> = ({
  children,
  fontFamily,
  color,
  capitalize = false,
  loading,
  className,
  level = 6,
  weight,
  responsive = true,
  noWrap,
  opacity,
  textAlign,
  ...antdProps
}) => {
  const { isMobile } = useResponsiveness();
  return (
    <Typography.Text
      style={{ fontWeight: weight, color, textAlign, opacity, fontFamily }}
      className={classNames('the5ers-text', className, {
        capitalized: capitalize,
        [`the5ers-text__level-${LEVELS[level - 1]}`]: level,
        [`the5ers-text__level-${LEVELS[level - 1]}-mobile`]:
          level && responsive && isMobile,
        'the5ers-text__no-wrap': noWrap,
      })}
      {...antdProps}
    >
      {loading ? <Skeleton.Button size="small" active /> : children}
    </Typography.Text>
  );
};
