import { Button, Col, Image, Row } from 'antd';
import { FunctionComponent, useState } from 'react';
import { useResponsiveness } from '@monorepo/client-common';
import { CloseOutlined } from '@ant-design/icons';
import { Banner, BannerImages, BannerPlacement } from '@monorepo/types';

import './header-banner.scss';

interface Props {
  banners?: Banner[];
}

const HeaderBanner: FunctionComponent<Props> = ({ banners = [] }) => {
  const { isMobile, isTablet } = useResponsiveness();
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  if (!isBannerVisible) {
    return null;
  }

  const banner = banners.find(
    (banner) => banner.placement === BannerPlacement.Header
  );

  if (!banner) {
    return null;
  }

  const getBannerUrl = (images: BannerImages) => {
    if (isMobile && images.mobileUrl) {
      return images.mobileUrl;
    }

    if (isTablet && images.tabletUrl) {
      return images.tabletUrl;
    }

    return images.desktopUrl;
  };

  const bannerUrl = getBannerUrl(banner.images);

  if (!bannerUrl) {
    console.error(`Banner url is not defined for ${banner.name}`);
    return null;
  }

  const hideBanner = () => {
    setIsBannerVisible(false);
  };

  const onBannerClick = () => {
    if (banner.link) {
      window.open(banner.link, '_blank');
    }
  };

  return (
    <Row justify={'center'} className={'header-banner'}>
      <Col>
        <Image src={bannerUrl} preview={false} onClick={onBannerClick} />
        <Button
          type={'text'}
          className={'close-btn'}
          icon={<CloseOutlined />}
          onClick={hideBanner}
        />
      </Col>
    </Row>
  );
};

export default HeaderBanner;
