import { Table } from 'antd';
import { Statistic, TextComponent } from '@monorepo/react-components';
import { Credit } from '@monorepo/types';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';

export const Credits = ({
  items,
  loading,
}: {
  items: Credit[];
  loading: boolean;
}) => {
  const filteredCredits = items.sort((a, b) =>
    dayjs(a.expirationDate).diff(b.expirationDate)
  );

  const columns: ColumnsType<Credit> = [
    {
      title: 'Amount',
      key: 'amount',
      render: (_, record) => (
        <Statistic value={record.amount} prefix="$" precision={2} />
      ),
    },
    {
      title: 'Used',
      key: 'used',
      render: (_, record) => (
        <Statistic value={record.used} prefix="$" precision={2} />
      ),
    },
    {
      title: 'Remained',
      key: 'amountLeft',
      render: (_, record) => (
        <Statistic
          value={record.amount - record.used}
          prefix="$"
          precision={2}
        />
      ),
    },
    {
      title: 'Expiration',
      key: 'expirationDate',
      render: (_, record) => (
        <TextComponent type="secondary">
          {record.expirationDate
            ? dayjs(record.expirationDate).format('DD/MM/YY')
            : '-'}
        </TextComponent>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={filteredCredits}
      loading={loading}
      size={'small'}
      scroll={{ x: 100 }}
      style={{ maxHeight: '500px', overflowY: 'scroll' }}
      pagination={false}
    />
  );
};
