import { App } from 'antd';
import { Button, Flex, TextComponent } from '@monorepo/react-components';
import { Account, ThemeColors } from '@monorepo/types';
import { Icons, IconsNames } from '@monorepo/icons';
import { useResponsiveness } from '@monorepo/client-common';
import { FunctionComponent } from 'react';
import { useColor } from '../../hooks';
import TradersLogin from '../traders-login/traders-login';
import { ThemeWrapper } from '../../components/theme-wrapper/theme-wrapper';

import './account-credentials-button.scss';

interface Props {
  account: Account;
}

const AccountCredentialsButton: FunctionComponent<Props> = ({ account }) => {
  const { modal } = App.useApp();
  const { getColor } = useColor();
  const { isDesktop } = useResponsiveness();
  const displayAccountId =
    '#' + account.tsAccount.split('accountName').join('');

  const openTradersLoginModal = () => {
    modal.info({
      maskClosable: true,
      icon: null,
      closable: true,
      title: (
        <Flex align="center" gap={8}>
          <TextComponent level={3} color={getColor(ThemeColors.colorPrimary)}>
            <Icons iconName={IconsNames.Lock} />
          </TextComponent>
          <TextComponent color={getColor(ThemeColors.colorPrimary)}>
            {displayAccountId}
          </TextComponent>
          <TextComponent weight={600}>Account Credentials</TextComponent>
        </Flex>
      ),
      content: (
        <TradersLogin
          userId={account.tsUserId}
          tradingSystem={account.tradingSystem}
        />
      ),
    });
  };

  if (isDesktop) {
    return (
      <ThemeWrapper themeColor={ThemeColors.colorAttention}>
        <Button
          type="default"
          onClick={openTradersLoginModal}
          icon={
            <Icons
              color={getColor(ThemeColors.colorSecondary)}
              iconName={IconsNames.Password}
            />
          }
        >
          Credentials
        </Button>
      </ThemeWrapper>
    );
  }
  return (
    <Flex
      onClick={openTradersLoginModal}
      justify="center"
      align="center"
      className="account-credentials-float-button"
    >
      <Icons iconName={IconsNames.Password} />
    </Flex>
  );
};
export default AccountCredentialsButton;
