import { IdIdentifier, TimeIdentifiers } from '../common/identifiers';
import { ProductType } from '../product/product';
import { TradingSystem } from '../trading-system';

export enum CouponItemType {
  Percentage = 'percentage',
  Nominal = 'nominal',
}

export type Coupon = IdIdentifier &
  TimeIdentifiers & {
    organizationCode: string;
    name: string;
    link: string;
    amount: number;
    used: number;
    expirationDate: Date;
    items: CouponItem[];
    comment?: string;
  };

export type CouponItem = {
  productType: ProductType;
  couponType: CouponItemType;
  amount: number;
  metadata?: CouponItemsMetaData;
};

export type CouponItemsMetaData = {
  productId?: string;
  tradingSystem?: TradingSystem;
  productName?: string;
};

export const isCouponLink = (href: string) => href.includes('coupons');
