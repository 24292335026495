import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import React, { useEffect, useState } from 'react';
import {
  Account,
  AccountType,
  ContractType,
  DashboardLayouts,
  PagePath,
} from '@monorepo/types';
import TtpDashboard from './ttp-dashboard/ttp-dashboard';
import FiversDashboard from './5ers-dashboard/5ers-dashboard';
import ContractModal from '../../components/contract-modal/contract-modal';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Button } from '@monorepo/react-components';
import { Result, Skeleton } from 'antd';
import DashboardWrapper from './dashboard-wrapper';
import FiversDashboardNew from './5ers-dashboard-new/5ers-dashboard-new';
import TtpDashboardNew from './ttp-dashboard-new/ttp-dashboard-new';

import './dashboard-container.scss';

const DashboardContainer = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Loading);

  const {
    dataStore: {
      accountStore,
      programStore,
      applicationConfigStore,
      addOnStore,
    },
  } = useStore();
  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }
      try {
        const accountResponse = await accountStore.getByTradingSystemAccount(
          id
        );

        if (accountResponse) {
          if (applicationConfigStore.applicationConfig.enableMigrationSync) {
            try {
              await accountStore.sync(accountResponse.tsAccount);
            } catch (e) {
              console.error(`failed syncing account`, e);
            }
          }

          setAccount(accountResponse);
          addOnStore.getAddOnsForAccount(accountResponse._id);
        }
      } catch (e) {
        console.error(`Failed fetching account: ${id}`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [id]);

  if (!id) {
    console.log(`No id found`);
    return null;
  }

  if (loadingState === LoadingState.Loading) {
    return <Skeleton active={true} />;
  }

  if (!account) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, The page is unavailable, please try again shortly."
        extra={
          <Button type="primary" onClick={() => navigate(PagePath.Root)}>
            Back Home
          </Button>
        }
      />
    );
  }
  const program = programStore.get(account.programId);
  const programLayout = program?.config?.dashboardLayout;
  const applicationLayout =
    applicationConfigStore.applicationConfig.dashboardLayout;
  const selectedDashboard =
    programLayout || applicationLayout || DashboardLayouts.TTPDashboard;

  const getDashboard = () => {
    const dashboardLayoutToComponent = {
      [DashboardLayouts.TTPDashboard]: <TtpDashboard account={account} />,
      [DashboardLayouts.TTPDashboardNew]: <TtpDashboardNew account={account} />,
      [DashboardLayouts.FiversDashboardNew]: (
        <FiversDashboardNew account={account} />
      ),
      [DashboardLayouts.FiversDashboard]: <FiversDashboard account={account} />,
    };

    return dashboardLayoutToComponent[selectedDashboard];
  };

  const isFundedAccount = account.type === AccountType.Funded;

  return (
    <DashboardWrapper
      account={account}
      dashboard={selectedDashboard}
      program={program}
    >
      {isFundedAccount && <ContractModal contractType={ContractType.Funded} />}
      <ContractModal contractType={ContractType.Nyse} />
      <div className="dashboard-container">{getDashboard()}</div>
    </DashboardWrapper>
  );
};

export default DashboardContainer;
