import { App, Button } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './reset-password.scss';
import { useStore } from '../../helpers/use-store';
import { useForm } from 'react-hook-form';
import { PagePath, formPasswordValidation } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Helmet } from 'react-helmet';
import { Card, FormPassword } from '@monorepo/react-components';

interface ResetPasswordPayload {
  newPassword: string;
}

const ResetPassword = () => {
  const { message } = App.useApp();
  const {
    dataStore: { userStore },
  } = useStore();
  const navigate = useNavigate();
  const { resetPasswordCode } = useParams<{ resetPasswordCode: string }>();
  const form = useForm<ResetPasswordPayload>();
  const { loadingState, updateLoadingState } = useLoading();

  const isLoading = loadingState === LoadingState.Loading;

  const goBack = () => {
    navigate(PagePath.Login);
  };

  const onFinish = async (values: ResetPasswordPayload) => {
    if (!resetPasswordCode) {
      return;
    }

    try {
      updateLoadingState(LoadingState.Loading);
      const result = await userStore.resetPassword(
        resetPasswordCode,
        values.newPassword
      );

      if (result) {
        updateLoadingState(LoadingState.Loaded);

        message.success('Password reset successfully', 3, () =>
          navigate(PagePath.Login)
        );
      } else {
        updateLoadingState(LoadingState.Error);
        throw new Error('Password reset failed');
      }
    } catch (e) {
      updateLoadingState(LoadingState.Error);
      message.error('Failed resetting password');
    }
  };

  return (
    <div className="reset-password">
      <Helmet>Reset Password</Helmet>
      <Card title="Reset Password" className="reset-password-card">
        <form name="forgot-password" onSubmit={form.handleSubmit(onFinish)}>
          <FormPassword
            text="New Password"
            placeholder="******"
            form={form}
            controllerProps={{
              name: 'newPassword',
              rules: formPasswordValidation(),
            }}
          />
          <Button
            type="primary"
            htmlType="submit"
            className={'reset-password-button'}
            loading={isLoading}
          >
            Reset Password
          </Button>
          <Button onClick={goBack} type="default" className={'back-button'}>
            Back
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default ResetPassword;
