import { getEnv } from '../../helpers/mobx-easy-wrapper';
import { useScript } from 'usehooks-ts';
import { useEffect } from 'react';

const LeadDyno = () => {
  const {
    envConfig: { leaddyno_public_key, domain_name },
  } = getEnv();
  const status = useScript(`https://static.leaddyno.com/js`);

  useEffect(() => {
    if (typeof window.LeadDyno !== 'undefined') {
      if (domain_name) {
        window.LeadDyno.domain = domain_name;
      }
      window.LeadDyno.key = leaddyno_public_key;
      window.LeadDyno.recordVisit();
    }
  }, [status]);

  return <></>;
};

export default LeadDyno;
