import { Descriptions } from 'antd';
import React, { FunctionComponent, ReactNode } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { Card, Statistic } from '@monorepo/react-components';

interface Props {
  programId: string;
  title: string;
  profitAndLoss: number;
  extra?: ReactNode;
}

const AccountTarget: FunctionComponent<Props> = ({
  programId,
  title,
  profitAndLoss,
  extra,
}) => {
  const {
    dataStore: { programStore },
  } = useStore();

  const program = programStore.get(programId);

  const target = program?.config?.target || 0;

  return (
    <Card title={title} extra={extra}>
      <Descriptions column={1}>
        <Descriptions.Item label={'Minimum'}>
          <Statistic prefix={'$'} value={target} precision={0} />
        </Descriptions.Item>
        <Descriptions.Item label={'Current'}>
          <Statistic prefix={'$'} value={profitAndLoss} precision={2} />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default AccountTarget;
