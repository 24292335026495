import { withLDProvider } from 'launchdarkly-react-client-sdk';
import App from '../../app';

const LAUNCH_DARKLY_KEY = import.meta.env.VITE_LAUNCH_DARKLY_CILENT_SIDE_ID;

export default withLDProvider({
  clientSideID: LAUNCH_DARKLY_KEY,
  options: { streaming: false },
  reactOptions: { useCamelCaseFlagKeys: true },
})(App);
