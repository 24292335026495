import React, { FunctionComponent } from 'react';
import { Application, Program, TradingSystemNames } from '@monorepo/types';
import { useThemeToken } from '@monorepo/client-common';
import { Flex, Statistic, TextComponent } from '@monorepo/react-components';
import { Icons, IconsNames } from '@monorepo/icons';
import { useStore } from '../../../../helpers/use-store';

const application: Application =
  import.meta.env.VITE_PROJECT || Application.TTP;

export const ProgramDetails: FunctionComponent<{
  program: Program;
}> = ({ program }) => {
  const isTTP = application === Application.TTP;
  const { token } = useThemeToken();
  const {
    dataStore: { applicationConfigStore },
  } = useStore();

  const sellableTs = applicationConfigStore.sellableTs;
  const tradingSystem = program?.config?.tradingSystems?.find((ts) =>
    sellableTs.includes(ts.tradingSystem)
  )?.tradingSystem;

  const programTradingSystem = tradingSystem
    ? TradingSystemNames[tradingSystem].name
    : TradingSystemNames[program.tradingSystem].name;

  const getProgramContentValues = () => {
    const dailyPause = {
      title: 'Daily Pause',
      value: program.config.dailyPause,
      prefix: '$',
    };

    const dailyPausePercentage = {
      title: 'Daily Pause',
      value: program.config.dailyPausePercentage,
      prefix: '%',
    };

    const dailyLoss = {
      title: 'Daily Loss',
      value: program.config.dailyLossPercentage,
      prefix: '%',
    };

    const daily = dailyPause.value
      ? dailyPause
      : dailyPausePercentage.value
      ? dailyPausePercentage
      : dailyLoss;

    const buyingPower = {
      title: 'Initial Balance',
    };

    if (isTTP) {
      daily.title = 'Max Daily Drawdown';
      buyingPower.title = 'Buying Power';
    }

    return { daily, buyingPower };
  };

  const values = getProgramContentValues();

  const programContent = [
    {
      title: 'Trade on',
      value: (
        <TextComponent responsive={false} weight={700} level={8}>
          {programTradingSystem}
        </TextComponent>
      ),
    },
    {
      title: 'Profit Split',
      value: (
        <TextComponent responsive={false} weight={500} level={8}>
          <Statistic
            prefix={'%'}
            value={program.config.userPayoutPercentage}
            precision={0}
          />
        </TextComponent>
      ),
    },
    {
      title: values.daily.title,
      value: (
        <TextComponent responsive={false} weight={500} level={8}>
          <Statistic
            prefix={values.daily.prefix}
            value={values.daily.value}
            precision={0}
          />
        </TextComponent>
      ),
    },
    {
      title: values.buyingPower.title,
      value: (
        <TextComponent responsive={false} weight={500} level={8}>
          <Statistic
            prefix={'$'}
            value={program.config.buyingPower}
            precision={0}
          />
        </TextComponent>
      ),
    },
    {
      title: 'Max Total Drawdown',
      value: (
        <TextComponent responsive={false} weight={500} level={8}>
          <Statistic
            prefix={'$'}
            value={program.config.maxLoss}
            precision={0}
          />
        </TextComponent>
      ),
    },
  ];

  return (
    <Flex vertical className="program-details">
      {programContent.map(({ title, value }, index) => (
        <Flex justify="space-between" align="center" key={index}>
          <Flex align="center" gap={4}>
            <Icons
              style={{ fontSize: 19, color: token.colorSuccess }}
              iconName={IconsNames.CheckTwo}
            />
            <TextComponent level={9}>{title}</TextComponent>
          </Flex>
          {value}
        </Flex>
      ))}
    </Flex>
  );
};
