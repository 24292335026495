import { FunctionComponent, Suspense, lazy } from 'react';
import { Application, Theme } from '@monorepo/types';

import './logo.scss';
import { useStore } from '../../helpers/use-store';

const TtpDesktopDarkLogo = lazy(
  () => import('./ttp-logo-desktop-dark.svg?react')
);
const TtpDesktopLightLogo = lazy(
  () => import('./ttp-logo-desktop-light.svg?react')
);
const The5ersDarkLogo = lazy(() => import('./5ers-logo-dark.svg?react'));
const The5ersLightLogo = lazy(() => import('./5ers-logo-light.svg?react'));

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

interface Props {
  mode?: Theme;
}

const Logo: FunctionComponent<Props> = ({ mode }) => {
  const { uiStore } = useStore();

  const LogoMap: Record<Application, Record<string, FunctionComponent>> = {
    [Application.TTP]: { light: TtpDesktopLightLogo, dark: TtpDesktopDarkLogo },
    [Application.THE5ERS]: { light: The5ersLightLogo, dark: The5ersDarkLogo },
  };

  const Component = LogoMap[project][
    mode || uiStore.currentTheme
  ] as FunctionComponent<any>;

  return (
    <Suspense>
      <div className="logo">
        <Component />
      </div>
    </Suspense>
  );
};

export default Logo;
