import {
  IdIdentifier,
  TimeIdentifiers,
  UserIdentifier,
} from '../common/identifiers';

export enum PayoutStatus {
  Pending = 'pending',
  Canceled = 'canceled',
  Paid = 'paid',
  Declined = 'declined',
  Processing = 'processing',
  Approved = 'approved',
}
export const PayoutMinimum = 150;

export enum PaymentMethod {
  Wise = 'wise',
  Crypto = 'crypto',
  Rise = 'rise',
  CreditCard = 'credit-card',
  HubCredits = 'hub-credits',
}

export enum CryptoCurrency {
  USDT = 'USDT',
  USDC = 'USDC',
  ETH = 'ETH',
  LTC = 'LTC',
}

export enum ReasonToDecline {
  Fix = 'fix',
  Invalid = 'invalid',
  NotEligble = 'not-eligble',
}

export type PayoutMetadata = {
  email?: string;
  currency?: string;
  walletAddress?: string;
  cryptoCurrency?: string;
  creditAmount?: number;
  balanceAmount?: number;
  userShare?: number;
  userPayoutAfterCommission?: number;
  commission?: number;
  companyShare?: number;
  fullName?: string;
  paymentIdentifier?: string;
};

const baseCommissions = {
  [PaymentMethod.Wise]: 0.4,
  [PaymentMethod.Rise]: 2,
  [PaymentMethod.Crypto]: 2,
  [PaymentMethod.CreditCard]: 1.5,
  [PaymentMethod.HubCredits]: 0,
};

export enum PayoutSource {
  Affiliate = 'affiliate',
  Account = 'account',
}

export type Payout = IdIdentifier &
  TimeIdentifiers &
  UserIdentifier & {
    amount: number;
    sourceId: string;
    status: PayoutStatus;
    type: PayoutSource;
    completedAt?: Date;
    paymentMethod: PaymentMethod;
    metadata?: PayoutMetadata;
    transactionId?: string;
    comment?: string;
    reasonToDecline?: ReasonToDecline;
  };

export const getPayoutCalculation = ({
  credits,
  amount,
  userPayoutPercentage,
  source,
}: {
  credits: number;
  amount: number;
  userPayoutPercentage: number;
  source: PayoutSource;
}) => {
  if (source === PayoutSource.Affiliate) {
    return {
      userShare: amount,
      companyShare: 0,
      amountToSplit: 0,
      creditsToDeduct: 0,
    };
  }

  let amountToSplit = amount,
    creditsToDeduct = 0;

  if (credits) {
    creditsToDeduct = credits - amount;

    if (creditsToDeduct >= 0) {
      creditsToDeduct = amount;
      amountToSplit = 0;
    } else {
      creditsToDeduct = credits;
      amountToSplit = amount - credits;
    }
  }

  let userShare = 0,
    companyShare = 0;

  if (amountToSplit > 0) {
    userShare = amountToSplit * (userPayoutPercentage / 100);

    companyShare = amountToSplit - userShare;
  }

  return {
    userShare,
    companyShare,
    amountToSplit,
    creditsToDeduct,
  };
};

export const getPayoutCommission = ({
  paymentMethod,
  paymentMetadata,
}: {
  paymentMethod: PaymentMethod;
  paymentMetadata?: PayoutMetadata;
}): number => {
  if (
    paymentMethod === PaymentMethod.Wise &&
    paymentMetadata &&
    paymentMetadata.currency !== 'USD'
  ) {
    return 3.5;
  }

  return baseCommissions[paymentMethod];
};
