import { PayoutStatus, ThemeColors } from '@monorepo/types';
import { FunctionComponent } from 'react';
import { Label } from '@monorepo/react-components';

interface Props {
  status: PayoutStatus;
}

const PayoutStatusTag: FunctionComponent<Props> = ({
  status = PayoutStatus.Canceled,
}) => {
  const orderStatusToColor: Record<PayoutStatus, ThemeColors> = {
    [PayoutStatus.Paid]: ThemeColors.colorSuccess,
    [PayoutStatus.Pending]: ThemeColors.colorWarning,
    [PayoutStatus.Declined]: ThemeColors.colorError,
    [PayoutStatus.Canceled]: ThemeColors.colorError,
    [PayoutStatus.Processing]: ThemeColors.colorInfo,
    [PayoutStatus.Approved]: ThemeColors.colorInfo,
  };

  return (
    <Label
      style={{ textTransform: 'capitalize' }}
      color={orderStatusToColor[status]}
    >
      {status}
    </Label>
  );
};

export default PayoutStatusTag;
