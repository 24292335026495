import { useNewOrderProvider } from '../new-order-provider/new-order-provider';
import SummaryWidgetCard from './summary-widget-card';

import './summary-widget.scss';

const SummaryWidget = () => {
  const { form } = useNewOrderProvider();
  const { paymentData, amount, originalAmount, productMetaData } =
    form.getValues();

  return (
    <SummaryWidgetCard
      currency={paymentData.currency}
      productName={productMetaData?.productName}
      paymentDataAmount={paymentData.amount}
      fee={paymentData.convertedFee}
      amount={amount}
      originalAmount={originalAmount}
      creditsUsed={paymentData.creditsUsed}
      discountPercentage={paymentData.discountPercentage}
    />
  );
};
export default SummaryWidget;
