import { Card, Col, Row, TextComponent } from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import DashboardTooltip from '../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import classNames from 'classnames';
import { useResponsiveness } from '@monorepo/client-common';

import './progress-cards.scss';

interface Props {
  currentViolations: number;
  maxViolations: number;
}

const Violations: FunctionComponent<Props> = ({
  currentViolations,
  maxViolations,
}) => {
  const { isMobile } = useResponsiveness();

  const tooltipText = `Opening a position without a SL order, or risking more than 2% of the account balance in a single trade, will be considered a risk violation`;

  return (
    <Card
      hoverable={false}
      className={classNames(
        'progress-cards',
        'progress-cards__card',
        'progress-cards__card--daily-violations'
      )}
    >
      <Row justify={'space-between'}>
        <Col xs={16} sm={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col xs={14} sm={20}>
              <TextComponent weight={400} level={isMobile ? 5 : 3}>
                Violations
              </TextComponent>
            </Col>

            {!isMobile && <DashboardTooltip tooltipText={tooltipText} />}

            <Col xs={8} span={24}>
              <TextComponent level={isMobile ? 3 : 2} weight={600}>
                {currentViolations}/
              </TextComponent>
              <TextComponent level={isMobile ? 3 : 2} weight={400}>
                {maxViolations}
              </TextComponent>
            </Col>
          </Row>
        </Col>

        {isMobile && (
          <Row align={'middle'}>
            <DashboardTooltip tooltipText={tooltipText} />
          </Row>
        )}
      </Row>
    </Card>
  );
};
export default Violations;
