import { observer } from 'mobx-react-lite';
import { Layout } from 'antd/lib';
import NavigationMenu from '../navigation-menu';
import { useState } from 'react';

const NavigationSider = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout.Sider
      collapsed={collapsed}
      collapsible={true}
      theme="light"
      breakpoint="lg"
      width={296}
      trigger={null}
      onCollapse={(collapsed, type) => {
        toggleCollapsed();
      }}
    >
      <NavigationMenu />
    </Layout.Sider>
  );
};

export default observer(NavigationSider);
