import { FunctionComponent } from 'react';
import { NyseFormProps } from '../nyse-modal-content';
import { FormCheckbox } from '@monorepo/react-components';
import { Flex } from 'antd';

const NyseStepNine: FunctionComponent<NyseFormProps> = ({ form }) => {
  return (
    <div className={'contracts-steps-body'}>
      <div>
        <p>
          13. CERTIFICATION – By executing this Agreement, Subscriber hereby
          certifies that he or she falls within Paragraph 9's definition of
          "Nonprofessional Subscriber" and that the personal and employment
          information that he or she has included in Paragraph 12 is truthful
          and accurate.
        </p>
        <div>
          <Flex justify={'center'} gap={10}>
            <FormCheckbox
              form={form}
              controllerProps={{ name: 'signThatProvidedDataIsCorrect' }}
            />
            <span>Agree</span>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default NyseStepNine;
