import { Application, Theme, ThemeConfigurationType } from '@monorepo/types';
import {
  DarkThemeConfigFivers,
  DarkThemeConfigTtp,
  LightThemeConfigFivers,
  LightThemeConfigTtp,
} from './index';

export const ThemeConfigurations: Record<
  Application,
  Record<Theme, ThemeConfigurationType>
> = {
  [Application.THE5ERS]: {
    [Theme.Light]: LightThemeConfigFivers,
    [Theme.Dark]: DarkThemeConfigFivers,
  },
  [Application.TTP]: {
    [Theme.Light]: LightThemeConfigTtp,
    [Theme.Dark]: DarkThemeConfigTtp,
  },
};
