import { Divider as AndDivider, DividerProps } from 'antd';
import { FunctionComponent } from 'react';
import { Flex } from '../flex/flex';

import './divider.scss';

export const Divider: FunctionComponent<DividerProps> = (props) => {
  const isDividerWithElementInside =
    props.children && props.orientation === 'center';

  if (!isDividerWithElementInside) {
    return <AndDivider {...props} />;
  }

  return (
    <Flex
      className={'divider-with-element-inside'}
      align={'center'}
      justify={'center'}
      gap={20}
    >
      <AndDivider />
      <div className="divider-with-element-inside__children">
        {props.children}
      </div>
      <AndDivider />
    </Flex>
  );
};
