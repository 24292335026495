import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Account,
  Order,
  OrderStatus,
  Partner,
  PagePath,
  PartnerType,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Col, Flex, Result, Row, Skeleton, Typography } from 'antd';
import PartnerCard from './partner-card/partner-card';

import './partners.scss';
import { Card } from '@monorepo/react-components';
import SkeletonInput from 'antd/es/skeleton/Input';
import SkeletonButton from 'antd/es/skeleton/Button';
import { StepForwardOutlined } from '@ant-design/icons';

const Partners = () => {
  const [order, setOrder] = useState<Order>();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [account, setAccount] = useState<Account>();
  const { id } = useParams<{ id: string }>();
  const {
    dataStore: { orderStore, programStore, partnerStore, accountStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }

      try {
        updateLoadingState(LoadingState.Loading);
        const response = await orderStore.getOrder(id);

        if (!response) {
          throw new Error(`Can't find order`);
        }

        if (response.status !== OrderStatus.Paid) {
          navigate(`${PagePath.NewAccount}/${response.uniqId}`);
        }

        const partners = await partnerStore.fetchAll();
        const accountResponse = await accountStore.getByOrderUniqId(id);

        // User doesn't eligble to partners or selected all already
        if (partners.length === 0) {
          navigate(`${PagePath.Overview}/${accountResponse.tsAccount}`);
        }

        setPartners(partners);
        setAccount(accountResponse);

        setOrder(response);
        updateLoadingState(LoadingState.Loaded);
      } catch (e) {
        updateLoadingState(LoadingState.Error);
        console.error(`Failed getting order: ${id}`, e);
      }
    };

    init();
  }, []);

  const skeletons = () => {
    const arr = [1, 2, 3, 4];
    return (
      <Row gutter={[25, 25]} className="partners">
        <Col span={24}>
          <Typography.Title level={3}>Select Your Partners:</Typography.Title>
        </Col>
        <Row gutter={[20, 15]} className="partners">
          {arr.map((a) => (
            <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} key={a}>
              <Card bordered={false} className={'partner-card'}>
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <Flex justify={'center'} align={'center'}>
                      <SkeletonInput active />
                    </Flex>
                  </Col>
                  <Col span={24}>
                    <Flex
                      justify={'center'}
                      align={'center'}
                      style={{ height: '190px' }}
                    >
                      <Skeleton.Node active className={'loader-content'}>
                        <StepForwardOutlined />
                      </Skeleton.Node>
                    </Flex>
                  </Col>
                  <Col span={24} className={'partner-body'}>
                    <Skeleton paragraph={{ rows: 3 }} title={false} active />
                  </Col>
                  <Col span={24} className={'partner-body'}>
                    <SkeletonButton active block />
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Row>
    );
  };

  if (loadingState === LoadingState.Loading) {
    return skeletons();
  }

  if (!order) {
    return null;
  }

  const program = programStore.get(order.productId);

  if (loadingState === LoadingState.Error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
      />
    );
  }

  if (!account) {
    return null;
  }

  const programBoosters = program?.config.boosters || {
    [PartnerType.Journal]: -1,
    [PartnerType.Analysis]: -1,
    [PartnerType.Research]: -1,
  };

  const accountBoosters = account.boosters || [];

  return (
    <>
      <Row gutter={[25, 25]} className="partners">
        <Col span={24}>
          <Typography.Title level={3}>Select Your Partners:</Typography.Title>
        </Col>
        {partners.map((partner) => {
          const isSelected = Boolean(
            accountBoosters.find(
              (booster) =>
                booster.id === partner._id && booster.type === partner.type
            )
          );
          const maximumBoostersPerPartnerType =
            programBoosters[partner.type] || -1;

          const accountPartnerTypeAmount = accountBoosters.filter(
            (booster) => booster.type === partner.type
          ).length;

          const isMaxLimitReached =
            accountPartnerTypeAmount >= maximumBoostersPerPartnerType;

          const isDisabled = isSelected || isMaxLimitReached;

          const selectPartner = async () => {
            if (isDisabled) {
              return;
            }

            try {
              const updatedAccount = await accountStore.selectPartner(
                account.orderId,
                partner._id
              );

              setAccount(updatedAccount);
            } catch (e) {
              console.error(`Failed selecting partner`, partner._id);
            }
          };

          return (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              key={partner._id}
            >
              <PartnerCard
                title={partner.title}
                description={partner.description}
                videoUrl={partner.videoUrl}
                selectPartner={selectPartner}
                isSelected={isSelected}
                isDisabled={isDisabled}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default Partners;
