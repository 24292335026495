import CrudService from '../core/crud-service';
import { BaseResponse } from '../core/base-entity-service';
import { AddOn } from '@monorepo/types';

export default class AddOnService extends CrudService {
  static override route = 'add-on';

  async getAddOnsForAccount(accountId: string) {
    try {
      const response = await this.httpService.get<BaseResponse<AddOn[]>>(
        `${this.path}/${accountId}`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting account add ons: ${accountId}`, e);
      throw e;
    }
  }
}
