const NyseStepOne = () => {
  return (
    <div className={'contracts-steps-body'}>
      <h1>AGREEMENT FOR MARKET DATA DISPLAY SERVICES</h1>
      <div>
        <p>
          (Nonprofessional Subscriber Status) Five Percent Online LTD (Trade The
          Pool) agrees to make "Market Data" available to you pursuant to the
          terms and conditions set forth in this agreement. By executing this
          Agreement in the space indicated below, you ("Subscriber") agree to
          comply with those terms and conditions. Section 1 sets forth terms and
          conditions of general applicability. Section 2 applies insofar as
          Subscriber receives and uses Market Data made available pursuant to
          this Agreement as a Nonprofessional Subscriber.
        </p>
      </div>
    </div>
  );
};

export default NyseStepOne;
