import { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { ConsistencyRules } from '@monorepo/types';
import { DashboardCardType } from './inactive-days';
import ProfitableDaysProgress from '../progress-cards/profitable-days-progress';
import ProfitableDaysRule from '../account-rules/profitable-days/profitable-days-rule';
import { LoadingState, useLoading } from '@monorepo/client-common';
import ProfitableDaysModal from '../../dashboard-common/account-consistency-rules/profitable-days-modal/profitable-days-modal';
import { Button, Flex, TextComponent } from '@monorepo/react-components';

interface Props {
  accountId: string;
  programId: string;
  type: DashboardCardType;
}

const ProfitableDays: FunctionComponent<Props> = ({
  accountId,
  programId,
  type,
}) => {
  const {
    dataStore: { accountStore, programStore },
  } = useStore();
  const [profitableDays, setProfitableDays] = useState<Date[]>([]);
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const isLoading = loadingState === LoadingState.Loading;

  const consistencyRules =
    programStore.get(programId)?.config?.consistencyRules;

  const profitableDaysTarget = consistencyRules?.find(
    (rule) => rule.rule === ConsistencyRules.ProfitableDays
  )?.value;

  useEffect(() => {
    try {
      updateLoadingState(LoadingState.Loading);
      accountStore
        .getConsistencyRule(accountId, ConsistencyRules.ProfitableDays)
        .then((result) => {
          const { profitableDays } = result;

          if (profitableDays) {
            setProfitableDays(profitableDays);
          }
        });
    } catch (error) {
      console.error(`Failed getting account consistency rules`, error);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  }, []);

  if (!profitableDaysTarget || !profitableDays) {
    return null;
  }

  const currentProfitableDays = profitableDays.length;
  const hasProfitableDays = currentProfitableDays > 0;
  const tooltipLabel = `A profitable day is a day on which the closed positions made a positive profit of at least 0.5% of the initial balance`;

  const TooltipText = (
    <Flex vertical gap={15}>
      <TextComponent level={7}>{tooltipLabel}</TextComponent>
      {hasProfitableDays && (
        <Button type="default" size="small">
          Show profitable days
        </Button>
      )}
    </Flex>
  );
  const openModal = () => {
    if (hasProfitableDays) {
      setModalOpen(true);
    }
  };
  const ProfitableDaysCard =
    type === DashboardCardType.Rule
      ? ProfitableDaysRule
      : ProfitableDaysProgress;

  return (
    <>
      <ProfitableDaysModal
        accountId={accountId}
        close={() => setModalOpen(false)}
        open={modalOpen}
      />
      <ProfitableDaysCard
        openModal={openModal}
        currentProfitableDays={currentProfitableDays}
        profitableDaysTarget={profitableDaysTarget}
        tooltipText={TooltipText}
        isLoading={isLoading}
      />
    </>
  );
};

export default ProfitableDays;
