import { Descriptions } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from '../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { AccountDailyPerformance as AccountDailyPerformanceType } from '@monorepo/types';
import { isNumber } from 'lodash';
import { Card, Statistic } from '@monorepo/react-components';

interface Props {
  accountId: string;
  programId: string;
  dailyProfitAndLoss: number;
}

const AccountDailyPerformance: FunctionComponent<Props> = ({
  accountId,
  programId,
  dailyProfitAndLoss,
}) => {
  const {
    dataStore: { accountStore, programStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const [accountDailyPerformance, setAccountDailyPerformance] =
    useState<AccountDailyPerformanceType>({
      marketValue: 0,
      totalVolume: 0,
    });

  const program = programStore.get(programId);

  const dailyPause = program?.config.dailyPause || 0;

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getDailyPerformance(accountId);

        if (
          result &&
          isNumber(result.totalVolume) &&
          isNumber(result.marketValue)
        ) {
          setAccountDailyPerformance(result);
        }
      } catch (e) {
        console.error(`Failed getting overview`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  const isLoading = loadingState === LoadingState.Loading;

  let remainingRisk =
    dailyProfitAndLoss > 0 ? dailyPause : dailyPause + dailyProfitAndLoss;

  if (dailyPause * -1 >= dailyProfitAndLoss) {
    remainingRisk = 0;
  }

  return (
    <Card
      className="metric-card"
      loading={isLoading}
      title={`Daily Performance`}
    >
      <Descriptions column={1} size="small">
        <Descriptions.Item label="Remaining Risk today">
          <Statistic value={remainingRisk} precision={2} prefix={'$'} />
        </Descriptions.Item>
        <Descriptions.Item label="Realized P&L">
          <Statistic value={dailyProfitAndLoss} precision={2} prefix={'$'} />
        </Descriptions.Item>
        <Descriptions.Item label="Market Value">
          <Statistic
            value={accountDailyPerformance.marketValue}
            precision={2}
            prefix={'$'}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Total Volume">
          <Statistic
            precision={0}
            value={accountDailyPerformance.totalVolume}
            prefix={''}
          />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default AccountDailyPerformance;
