import { Flex, TextComponent } from '@monorepo/react-components';
import SummaryWidget from '../summary-widget/summary-widget';
import { useResponsiveness } from '@monorepo/client-common';
import { FunctionComponent, PropsWithChildren } from 'react';

import './with-summary-column.scss';

const WithSummarySide: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isDesktop } = useResponsiveness();

  return (
    <Flex justify="space-between" gap={50} className="summary-side">
      {children}
      {isDesktop && (
        <Flex vertical gap={16} className="summary-column">
          <TextComponent responsive={false} level={4}>
            Order summary
          </TextComponent>
          <SummaryWidget />
        </Flex>
      )}
    </Flex>
  );
};
export default WithSummarySide;
