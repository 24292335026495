import { useEffect } from 'react';
import { useStore } from '../../helpers/use-store';

const LiveChat = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      window.__lc = window.__lc || {};
      window.__lc.license = 14119155;
      window.__lc.chat_between_groups = false;

        // Set the user's name and email
        window.__lc.visitor = {
          name: "${userStore.fullName}",
          email: "${userStore.currentUser.email}"
        };

  // Disable the pre-chat survey (intro screen)
  window.__lc.group = 0; // Ensure this is set to the correct group, typically 0
  window.__lc.params = [
    { name: 'disable_prechat_survey', value: 'true' }
  ];

      (function(n,t,c){
        function i(n){return e._h ? e._h.apply(null,n) : e._q.push(n)}
        var e = {
          _q: [],
          _h: null,
          _v: "2.0",
          on: function(){i(["on", c.call(arguments)])},
          once: function(){i(["once", c.call(arguments)])},
          off: function(){i(["off", c.call(arguments)])},
          get: function(){
            if(!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
            return i(["get", c.call(arguments)]);
          },
          call: function(){i(["call", c.call(arguments)])},
          init: function(){
            var n = t.createElement("script");
            n.async = !0;
            n.type = "text/javascript";
            n.src = "https://cdn.livechatinc.com/tracking.js";
            t.head.appendChild(n);
          }
        };
        !n.__lc.asyncInit && e.init();
        n.LiveChatWidget = n.LiveChatWidget || e;
      }(window, document, [].slice));
    `;
    document.body.appendChild(script);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <a href="https://www.livechat.com/chat-with/14119155/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
    `;
    document.body.appendChild(noscript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  return null;
};

export default LiveChat;
