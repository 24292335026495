import { FunctionComponent } from 'react';
import MinimumTradesRuleCard from '../../dashboard-common-new/account-rules/minimum-trades/minimum-trades';
import { Account } from '@monorepo/types';

interface Props {
  account: Account;
}

const AccountRulesTtp: FunctionComponent<Props> = ({ account }) => {
  return (
    <MinimumTradesRuleCard
      accountId={account.tsAccount}
      programId={account.programId}
    />
  );
};

export default AccountRulesTtp;
